import React from 'react';
import { connect } from 'react-redux';
import { meta, preload } from 'react-website';

import { EditTable } from '../../components/EditTable';
import {
  deleteSavedSearch,
  getSavedSearches,
  updateSavedSearch,
} from '../../redux/search';
import { metaKey } from '../../utils/helpers';

@meta(() => ({ ...metaKey('title', 'Account Saved Searches') }))
@preload(async ({ dispatch }) => {
  await dispatch(getSavedSearches());
})
@connect(({ search }) => ({
  savedSearches: search.savedSearches,
}), {
  deleteSavedSearch,
  updateSavedSearch,
})
export default class SavedSearches extends React.Component {
  constructor () {
    super();

    this.editTable = React.createRef();
    this.deleteSearch = this.deleteSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  async updateSearch (searchToUpdate) {
    const { updateSavedSearch } = this.props;

    await updateSavedSearch(searchToUpdate);
  }

  async deleteSearch (search) {
    const { deleteSavedSearch } = this.props;

    await deleteSavedSearch(search.id);
    this.editTable.current.deleteModal.current.toggle();
  }

  render () {
    return (
      <EditTable
        ref={this.editTable}
        subject={'saved search'}
        subjectPlural={'saved searches'}
        tableValues={this.props.savedSearches}
        onDelete={this.deleteSearch}
        onUpdate={this.updateSearch}
      />
    );
  }
}
