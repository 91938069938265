import React from 'react';
import * as _ from 'lodash';

export class ScrollTable extends React.Component {
  constructor (props) {
    super(props);

    this.table = React.createRef();
    this.syncColumnWidths = this.syncColumnWidths.bind(this);
  }

  componentDidMount () {
    if (this.syncColumnWidths()) {
      const bodyCols = this.table.current.querySelectorAll(
        '.xsl-table--body table colgroup col'
      );

      let ResizeObserver = window['ResizeObserver'];
      if (typeof ResizeObserver === 'undefined') {
        ResizeObserver = require('@juggle/resize-observer').ResizeObserver;
      }

      this.observer = new ResizeObserver(this.syncColumnWidths);
      for (const col of bodyCols) {
        this.observer.observe(col);
      }
    }
  }

  componentWillUnmount () {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  syncColumnWidths () {
    const scrollTable = this.table.current;
    const headerTable = scrollTable.querySelector('.xsl-table--header table');
    const bodyTable = scrollTable.querySelector('.xsl-table--body table');

    const headerCols = headerTable.querySelectorAll('colgroup col');
    const bodyCols = bodyTable.querySelectorAll('tfoot td');

    const getComputedStyle = window['getComputedStyle'];

    if (headerTable && bodyTable && headerCols.length == bodyCols.length) {
      // Update table max-height to include header height
      scrollTable.style.maxHeight = `${headerTable.offsetHeight + 300}px`;

      // Copy table precise width
      headerTable.style.width = `${getComputedStyle(bodyTable).width}`;

      _.forEach(
        bodyCols,
        (col, idx) => {
          // Copy column precise width
          headerCols[idx].style.width = `${getComputedStyle(col).width}`;
        }
      );

      return true;
    }

    return false;
  }

  render () {
    return (
      <div
        ref={this.table}
        className="xsl-table--scroll"
      >
        { this.props.children }
      </div>
    );
  }
}
