import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUploadAlt,
  faExternalLinkSquareAlt,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import {
  ConfirmDeleteModal,
  EditMinuteModal,
  PublishMinuteModal,
  PublishAllMinutesModal,
} from '../../modals';
import {
  createMinute,
  deleteMinute,
  updateClient,
} from '../../redux/admin';

import './ManageMinutes.scss';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    deleteMinutePending: admin.deleteMinutePending,
    deleteMinuteError: admin.deleteMinuteError,
    minutesUpdating: admin.minutesUpdating,
    updateClientPending: admin.updateClientPending,
  }),
  {
    createMinute,
    deleteMinute,
    updateClient,
  }
)
export default class ManageMinutes extends React.Component {
  constructor () {
    super();

    this.uploadRef = React.createRef();
    this.createMinuteModal = React.createRef();
    this.editMinuteModal = React.createRef();
    this.deleteMinuteModal = React.createRef();
    this.publishMinuteModal = React.createRef();
    this.publishAllMinutesModal = React.createRef();

    this.deleteMinute = this.deleteMinute.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.uploadMinutes = this.uploadMinutes.bind(this);
    this.renderUnpublished = this.renderUnpublished.bind(this);
    this.renderPublished = this.renderPublished.bind(this);
  }

  async deleteMinute (m, toggleModal = true) {
    const { deleteMinute } = this.props;

    await deleteMinute(m.id);
    if (toggleModal) {
      this.deleteMinuteModal.current.toggle();
    }
  }

  uploadMinutes (files) {
    const {
      currClient,
      createMinute,
    } = this.props;

    _.forEach(files, (pdf) => {
      createMinute({
        client: currClient.id,
        pdf,
      });
    });

    this.uploadRef.current.value = '';
  }

  async updateClient (newValues) {
    const {
      currClient,
      updateClient,
    } = this.props;

    await updateClient(currClient.slug, newValues);
  }

  renderUnpublished () {
    const { currClient, minutesUpdating } = this.props;

    return (
      currClient.unpublished_minutes.length && _.map(
        currClient.unpublished_minutes,
        (m) => {
          return (
            <tr
              key={`draft-${m.id}`}
              className={classnames({
                'tr--hidden': (
                  m.deleting ||
                  m.publishing
                ),
              })}
            >
              <td>{m.title}</td>
              <td>{m.date || '- unset -'}</td>
              <td>
                {
                  (
                    minutesUpdating[m.id] &&
                    <div className="draft-progress">
                      <div className="spinner-border spinner-border-sm m-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  ) ||
                  <div className="draft-actions">
                    <Button
                      aria-label={`Publish ${m.title}`}
                      color="link"
                      title="Publish"
                      onClick={() => this.publishMinuteModal.current.wrappedInstance.show(m)}
                      disabled={!m.date}
                    >
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                    </Button>
                    <Button
                      aria-label={`Edit ${m.title}`}
                      color="link"
                      title="Edit"
                      onClick={() => this.editMinuteModal.current.wrappedInstance.show(m)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Link
                      aria-label={`Preview ${m.title}. Opens preview in a new tab`}
                      title="Preview"
                      to={m.pdf_path}
                      className="btn-link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                    </Link>
                    <Button
                      aria-label={`Delete ${m.title}`}
                      color="link"
                      title="Delete"
                      onClick={() => this.deleteMinute(m, false)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </div>
                }
              </td>
            </tr>
          );
        },
      ) ||
      <tr className="tr--no-data">
        <td colSpan={3}>
          <p>You don&#39;t have any drafts imported yet.</p>
        </td>
      </tr>
    );
  }

  renderPublished () {
    const { currClient, minutesUpdating } = this.props;

    return (
      currClient.published_minutes.length && _.map(
        currClient.published_minutes,
        (m) => {
          return (
            <tr
              key={`minute-${m.id}`}
              className={classnames({
                'tr--hidden': (
                  m.deleting ||
                  m.publishing
                ),
              })}
            >
              <td>{m.title}</td>
              <td>{m.date || '- unset -'}</td>
              <td>
                {
                  (
                    minutesUpdating[m.id] &&
                    <div className="draft-progress">
                      <div className="spinner-border spinner-border-sm m-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  ) ||
                  <div className="draft-actions">
                    <Button
                      aria-label={`Edit ${m.title}`}
                      color="link"
                      title="Edit"
                      onClick={() => this.editMinuteModal.current.wrappedInstance.show(m)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Link
                      aria-label={`Preview ${m.title}. Opens preview in a new tab`}
                      title="Preview"
                      to={m.pdf_path}
                      className="btn-link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                    </Link>
                    <Button
                      aria-label={`Delete ${m.title}`}
                      color="link"
                      title="Delete"
                      onClick={() => this.deleteMinuteModal.current.show(m)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </div>
                }
              </td>
            </tr>
          );
        },
      ) ||
      <tr className="tr--no-data">
        <td colSpan={3}>
          <p>You don&#39;t have any minutes published yet.</p>
        </td>
      </tr>
    );
  }

  render () {
    const {
      currClient,
      deleteMinutePending,
      deleteMinuteError,
      updateClientPending,
    } = this.props;

    if (!currClient) {
      return null;
    }

    const unpublished = _.get(currClient, 'unpublished_minutes', []);

    return (
      <Dropzone
        accept="application/pdf"
        onDrop={this.uploadMinutes}
      >
        {({getRootProps, isDragActive}) => (
          <div
            className={classnames('minutes', {'minutes--dimmed': isDragActive })}
            { ...getRootProps() }
          >
            <h3>Manage Meeting Minutes</h3>
            <p>View and edit the location&#39;s meeting minutes.</p>
            {/* Drafts */}
            <Table
              className="table--minutes"
              hover
              responsive
            >
              <thead>
                <tr>
                  <th colSpan={2} style={{textAlign: 'left'}}>DRAFTS</th>
                  <th
                    className="text-right"
                    style={{ padding: '0 .75rem' }}
                  >
                    <Button
                      color="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => this.publishAllMinutesModal.current.wrappedInstance.toggle()}
                      disabled={!_.some(unpublished, (m) => !!m.date)}
                    >
                      Publish All
                    </Button>
                    <input
                      id="minute-files"
                      ref={this.uploadRef}
                      type="file"
                      name="minute_files"
                      className="am-file-input"
                      accept="application/pdf"
                      onChange={(e) => this.uploadMinutes(e.target.files)}
                      multiple
                    />
                    <label
                      htmlFor="minute-files"
                      className="btn btn-sm btn-primary"
                      style={{marginBottom: 0}}
                    >
                      Upload
                    </label>
                  </th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderUnpublished() }
              </tbody>
            </Table>
            {/* Published */}
            <Table
              className="table--minutes"
              hover
              responsive
            >
              <thead>
                <tr>
                  <th colSpan={3} style={{textAlign: 'left'}}>PUBLISHED</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                { this.renderPublished() }
              </tbody>
            </Table>

            {/* Settings */}
            <h3 style={{marginTop: 30}}>Settings</h3>
            <div className="row">
              <FormGroup className="col">
                <Input
                  id="hide_minutes_from_search"
                  type="checkbox"
                  name="hide_minutes_from_search"
                  checked={currClient.hide_minutes_from_search}
                  disabled={updateClientPending}
                  onChange={() => this.updateClient({hide_minutes_from_search: !currClient.hide_minutes_from_search})}
                />
                <Label for="hide_minutes_from_search" check>
                  Hide client&#39;s minutes from all searches and location minutes listings.
                </Label>
              </FormGroup>
            </div>

            {/* Modals */}
            <EditMinuteModal ref={this.editMinuteModal}/>
            <PublishMinuteModal ref={this.publishMinuteModal} />
            <PublishAllMinutesModal ref={this.publishAllMinutesModal} />
            <ConfirmDeleteModal
              ref={this.deleteMinuteModal}
              onDelete={this.deleteMinute}
              subject={'minute'}
              busy={deleteMinutePending}
              error={deleteMinuteError}
            />

            { isDragActive && (
              <div className="drop-overlay">
                <div className="drop-overlay__inner">
                  <h2>Drop PDFs Here!</h2>
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}
