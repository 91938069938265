import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Collapse,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import './ScopeInput.scss';

import {
  onUserInteract,
  smoothScrollTo,
} from '../utils/helpers';
import {
  tocKey,
  codeToggle,
  expandToc,
  collapseToc,
  toggleCodeSelect,
  toggleTocSelect,
  clearSelected,
  tocChainMissing,
  SCOPE_INPUT_PADDING,
} from '../redux/codes';

@connect(
  ({ codes }) => ({
    currSection: codes.currSection,
    scopeTocLookup: codes.scopeTocLookup,
    scopeCodeLookup: codes.scopeCodeLookup,
  }),
  {
    codeToggle,
    expandToc,
    collapseToc,
    toggleCodeSelect,
    toggleTocSelect,
    clearSelected,
    tocChainMissing,
  },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class ScopeInput extends React.Component {
  static propTypes = {
    version: PropTypes.object,
    clearOnMount: PropTypes.bool,
    pdfEnabled: PropTypes.bool,
  }
  static defaultProps = {
    clearOnMount: true,
    pdfEnabled: false,
  }
  state = {
    open: true,
    deleteMode: {},
  }

  constructor () {
    super();

    this.addInEntry = this.addInEntry.bind(this);
    this.tocToggle = this.tocToggle.bind(this);
    this.codeToggle = this.codeToggle.bind(this);
    this.toggleCodeSelect = this.toggleCodeSelect.bind(this);
    this.toggleTocSelect = this.toggleTocSelect.bind(this);
    this.renderToc = this.renderToc.bind(this);
  }

  async componentDidMount () {
    const {
      clearOnMount,
      clearSelected,
      version,
      currSection,
      hash,
      tocChainMissing,
    } = this.props;

    const destID = hash && _.replace(hash, 'rid-', '');

    let scopeTocLookup = this.props.scopeTocLookup;

    if (clearOnMount) {
      await clearSelected(version);
    }

    if (!currSection) {
      return;
    }

    // Re-fetch with latest values
    scopeTocLookup = this.props.scopeTocLookup;
    const code = _.find(version.toc, (c) => c.uuid == currSection.code_uuid);

    // If there are children, we don't have them
    const isTocInStore = _.findKey(
      scopeTocLookup,
      (entry) => {
        return (
          entry.code_uuid == code.uuid &&
          entry.doc_id == currSection.doc_id
        );
      },
    ) !== undefined;

    // If the entry cannot be found, we need it by default
    let needToc = true;

    // Consult first-level sections to find entry
    let child = !destID && _.find(
      code.sections,
      (s) => _.get(s, 'doc_id') == currSection.doc_id,
    );

    if (!child) {
      // Consult scopeTocLookup for entry
      for (let key in scopeTocLookup) {
        child = (
          scopeTocLookup[key].code_uuid == code.uuid &&
          _.find(
            scopeTocLookup[key].children,
            (c) => (
              destID || _.get(c, 'doc_id') == currSection.doc_id
            ),
          )
        );
        if (!!child) {
          // Found it. Bail
          break;
        }
      }
    }

    if (!!child) {
      // No need if there are no nested children
      needToc = child.has_children;
    }

    // Only fetch TOC chain if we need to
    if (!isTocInStore) {
      if (needToc) {
        await tocChainMissing({
          ctx: 'scope',
          rootEntry: { doc_id: currSection.root_section_doc_id },
          code,
          docID: destID || currSection.doc_id,
          version,
          fromScope: true,
        });
      } else {
        if (version.toc.length > 1) {
          await this.codeToggle(code.uuid);
        }
        await this.toggleTocSelect(code, child);

        const queryString = `.scope-input .toc-link[data-docid="${child.doc_id}"]`;

        // Wait for render
        setTimeout(() => {
          const scopeInput = document.querySelector('.scope-input');
          const tocEntry = document.querySelector(queryString);

          smoothScrollTo(
            scopeInput,
            tocEntry.parentElement.offsetTop - SCOPE_INPUT_PADDING - (scopeInput.offsetHeight / 2),
          );
        }, 350);
      }
    }
  }

  addInEntry (result, code, entry) {
    const { scopeTocLookup } = this.props;

    const entryKey = tocKey(code, entry);
    const lookupEntry = _.get(scopeTocLookup, [entryKey]);

    const partial = _.get(lookupEntry, 'partial', false);
    const selected = _.get(lookupEntry, 'selected', false);

    if (selected && !partial) {
      result.push({
        code_slug: code.slug,
        doc_id: entry.doc_id,
        parent_doc_id: entry.parent_doc_id,
      });
    } else if (partial) {
      _.forEach(
        _.get(lookupEntry, 'children', []),
        (childEntry) => {
          this.addInEntry(result, code, childEntry);
        }
      );
    }
  }

  /**
    * A scope is a JSON string that serializes an array of objects. Each object
    * denotes a particular instance of Code, CodeSection or TocEntry. To compress
    * the length of this string, parameters are abbreviated. Each object in the
    * array has two keys:
    *
    * 't' => Type of model
    * 'id' => Primary key of model
    *
    * The value of 't' is one of 'c', 's', or 't'. These correspond to Code,
    * CodeSection, or TocEntry.
    */
  getScope () {
    const {
      version,
      scopeCodeLookup,
    } = this.props;

    return _.reduce(
      version.toc,
      (result, code) => {
        if (
          _.get(scopeCodeLookup, [code.uuid, 'selected'], false) &&
          !_.get(scopeCodeLookup, [code.uuid, 'partial'], false)
        ) {
          result.push({ code_slug: code.slug });
        } else {
          _.forEach(code.sections, (sec) => this.addInEntry(result, code, sec));
        }

        return result;
      },
      [],
    );
  }

  async codeToggle (uuid) {
    const { codeToggle } = this.props;

    await codeToggle({ uuid, ctx: 'scope' });
  }

  async toggleCodeSelect (uuid) {
    const {
      version,
      toggleCodeSelect,
    } = this.props;

    await toggleCodeSelect({
      version,
      code: { uuid },
      ctx: 'scope',
    });
  }

  async toggleTocSelect (code, entry) {
    const {
      version,
      toggleTocSelect,
    } = this.props;

    await toggleTocSelect({
      version,
      code,
      entry,
      ctx: 'scope',
    });
  }

  async tocToggle (code, entry) {
    const {
      expandToc,
      collapseToc,
      scopeTocLookup,
    } = this.props;

    if (!_.get(scopeTocLookup, [tocKey(code, entry), 'expanded'], false)) {
      await expandToc({ ctx: 'scope', code, entry });
    } else {
      await collapseToc({ ctx: 'scope', code, entry });
    }
  }

  renderToc (code, entry) {
    const { scopeTocLookup } = this.props;

    const lookupEntry = _.get(scopeTocLookup, [tocKey(code, entry)]);

    const collapseContent = !_.get(lookupEntry, 'loading', false)
      ? _.map(
        _.get(lookupEntry, `children`, []),
        (s) => this.renderToc(code, s),
      )
      : (
        <div className="toc-entry__loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          Loading...
        </div>
      );

    const clickHandler = _.get(lookupEntry, 'loading', false)
      ? null // disable anchor
      : () => this.tocToggle(code, entry);

    const dataParams = { ['data-docid']: entry.doc_id };

    // Reference: https://www.w3.org/TR/wai-aria-practices-1.1/#checkbox
    let ariaChecked = (
      _.get(lookupEntry, 'selected', false) ||
      _.get(lookupEntry, 'partial', false)
    ).toString();
    if (_.get(lookupEntry, 'partial', false)) {
      ariaChecked = 'mixed';
    }

    return (
      <div
        key={`${entry.type}-${entry.doc_id}`}
        className={classnames(
          'toc-entry',
          { 'toc-entry--has-children': entry.has_children },
        )}
      >
        <div className="toc-entry__wrap">
          {entry.has_children && (
            <button
              className="toc-caret dropdown-toggle btn-toggle"
              aria-label="Expand or collapse table of contents"
              aria-haspopup="true"
              aria-expanded={_.get(lookupEntry, 'expanded', false)}
              onClick={clickHandler}
            />
          )}
          <div className="toc-entry__check">
            <Input
              id={`entry-check-${entry.id}`}
              aria-label={entry.title}
              aria-checked={ariaChecked}
              type="checkbox"
              checked={(
                _.get(lookupEntry, 'selected', false) ||
                _.get(lookupEntry, 'partial', false)
              )}
              onChange={() => this.toggleTocSelect(code, entry)}
              onKeyDown={onUserInteract((e) => {
                // Prevent double callback
                e.preventDefault();
                e.stopPropagation();

                this.toggleTocSelect(code, entry);
              })}
            />
            <Label
              for={`entry-check-${entry.id}`}
              className={classnames(
                'toc-link',
                {'check--partial': _.get(lookupEntry, 'partial', false)},
              )}
              {...dataParams}
              check
            >
              {entry.title}
            </Label>
          </div>
        </div>
        {entry.has_children && (
          <Collapse isOpen={_.get(lookupEntry, 'expanded', false)}>
            { collapseContent }
          </Collapse>
        )}
      </div>
    );
  }

  render () {
    const {
      version,
      scopeCodeLookup,
      pdfEnabled,
    } = this.props;

    const toc = _.map(
      _.filter(_.get(version, 'toc', []), (c) => pdfEnabled || !c.is_pdf),
      (code) => {
        // Reference: https://www.w3.org/TR/wai-aria-practices-1.1/#checkbox
        let ariaChecked = _.get(scopeCodeLookup, [code.uuid, 'selected'], false).toString();
        if (_.get(scopeCodeLookup, [code.uuid, 'partial'], false)) {
          ariaChecked = 'mixed';
        }

        return (
          <div
            className="toc-entry toc-entry--code"
            key={`code-${code.id}`}
          >
            <div className="toc-entry__wrap">
              <button
                className="toc-caret dropdown-toggle btn-toggle"
                aria-label="Toggle code"
                aria-haspopup="true"
                aria-expanded={_.get(scopeCodeLookup, [code.uuid, 'expanded'])}
                onClick={() => this.codeToggle(code.uuid)}
              />
              <div className="toc-entry__check">
                <Input
                  id={`code-check-${code.uuid}`}
                  aria-label={code.title}
                  aria-checked={ariaChecked}
                  type="checkbox"
                  checked={_.get(scopeCodeLookup, [code.uuid, 'selected'], false)}
                  onChange={() => this.toggleCodeSelect(code.uuid)}
                  onKeyDown={onUserInteract((e) => {
                    // Prevent double callback
                    e.preventDefault();
                    e.stopPropagation();

                    this.toggleCodeSelect(code.uuid);
                  })}
                />
                <Label
                  for={`code-check-${code.uuid}`}
                  className={classnames(
                    'toc-link',
                    {'check--partial': _.get(scopeCodeLookup, [code.uuid, 'partial'], false)}
                  )}
                  check
                >
                  {code.title}
                </Label>
              </div>
            </div>
            <Collapse isOpen={_.get(scopeCodeLookup, [code.uuid, 'expanded'])}>
              {_.map(code.sections, (s) => this.renderToc(code, s))}
            </Collapse>
          </div>
        );
      },
    );

    return (
      <div className="scope-input">
        { toc }
      </div>
    );
  }
}
