import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { updateCode } from '../redux/admin';

@connect(
  ({ admin }) => ({
    doUpdateCodePending: admin.doUpdateCodePending,
    doUpdateCodeError: admin.doUpdateCodeError,
  }),
  { updateCode },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class EditCodeModal extends StatefulForm {
  static propTypes = {
    code: PropTypes.object,
    doUpdateCodePending : PropTypes.bool,
    doUpdateCodeError : PropTypes.object,
  }
  state = {
    open: false,
    code: null,
    formValue: {
      title: '',
    },
  }

  constructor (props) {
    super(props);

    this.titleInput = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (code) {
    await this.setState({
      firstGoDone: false,
      code,
      formValue: { title: code.title },
    });

    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const { updateCode } = this.props;
    const {
      code,
      formValue,
    } = this.state;

    this.setState({firstGoDone: true});

    await updateCode({
      id: code.id,
      newValues: formValue,
      onSuccess: () => this.setState({open: false}),
    });
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const { doUpdateCodePending, doUpdateCodeError } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Edit Code</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Edit the title for the code and click Save.
          </p>
          <FormGroup>
            <InputGroup>
              <Input
                autoComplete="off"
                innerRef={this.titleInput}
                name="title"
                type="text"
                placeholder="Code Title"
                value={ formValue.title }
                invalid={ firstGoDone && _.has(doUpdateCodeError, 'title') }
                onChange={ this.handleChange }
              />
              <InputGroupAddon addonType="append">
                <Button
                  type="submit"
                  color="primary"
                  disabled={ doUpdateCodePending }
                  onClick={this.submitForm}
                >
                  Save
                </Button>
              </InputGroupAddon>
              <FormFeedback role="alert">
                { _.get(doUpdateCodeError, 'title') }
              </FormFeedback>
            </InputGroup>
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  }
}
