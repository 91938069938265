import { default as config } from '../../configuration';

export const setupAnalytics = async (setFirebase) => {
  if (
    !config.firebase_api_key ||
    !config.firebase_app_id ||
    !config.firebase_measurement_id
  ) {
    return;
  }

  const $script = require('scriptjs');

  if (!window['firebase']) {
    // Chain script loads to ensure all dependencies are loaded
    await $script(
      'https://www.gstatic.com/firebasejs/7.2.1/firebase-app.js',
      async () => await $script(
        'https://www.gstatic.com/firebasejs/7.2.1/firebase-analytics.js',
        () => {
          if (!!window['firebase']) {
            const firebase = window['firebase'];
            // Your web app's Firebase configuration
            var firebaseConfig = {
              apiKey: config.firebase_api_key,
              authDomain: 'american-legal-publishing.firebaseapp.com',
              databaseURL: 'https://american-legal-publishing.firebaseio.com',
              projectId: 'american-legal-publishing',
              storageBucket: 'american-legal-publishing.appspot.com',
              messagingSenderId: '744994453678',
              appId: config.firebase_app_id,
              measurementId: config.firebase_measurement_id,
            };

            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
          }

          setFirebase(window['firebase']);
        },
      ),
    );
  } else {
    setFirebase(window['firebase']);
  }
};
