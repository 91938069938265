import { Component } from 'react';
import * as _ from 'lodash';

export class StatefulForm extends Component {
  state = { formValue: {} }

  constructor () {
    super();
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = async (event) => {
    const { target } = event;
    let value;
    if (target.type == 'checkbox') {
      value = target.checked;
    } else if (target.type == 'file') {
      value = _.get(target, 'files[0]');
    } else {
      value = target.value;
    }
    const { name } = target;
    await this.setState({
      formValue: {
        ...this.state.formValue,
        [ name ]: value,
      },
    });
  }

  async submitForm () { }
}
