import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Input,
} from 'reactstrap';

export class EditTableInput extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    titleChange: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props);
    
    this.tableInput = React.createRef();

    this.state = {
      tableInputValue: props.item.title || '',
    };
  }

  componentDidMount () {
    this.tableInput.current.focus();
  }

  async inputChange (title) {
    await this.setState({tableInputValue: title});

    this.submitChange();
  }

  async submitChange () {
    const { item, titleChange } = this.props;
    const { tableInputValue } = this.state;
    
    await titleChange(tableInputValue, item);
  }

  render () {
    const { item } = this.props;

    return (
      <FormGroup>
        <Input
          aria-label={item.title}
          name="title"
          type="text"
          value={this.state.tableInputValue}
          innerRef={this.tableInput}
          onChange={(e) => this.inputChange(e.target.value)}
        />
      </FormGroup>
    );
  }
}
