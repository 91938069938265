import React from 'react';
import { connect } from 'react-redux';
import { Link, goto, redirect } from 'react-website';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { BackToSearchModal } from '../../modals/BackToSearchModal';
import {
  gotoNextMatch,
  gotoPrevMatch,
  getFirstMatch,
  getNextMatch,
  getPrevMatch,
  getLastMinutesMatch,
  getNextMinutesMatch,
  getPrevMinutesMatch,
  getNextDocUrl,
  getPrevDocUrl,
  getVisibleMarks,
} from '../../redux/codes';

@connect(({ codes, sections, search, found }) => ({
  currSection: codes.currSection,
  currClient: codes.currClient,
  selectedVersion: codes.selectedVersion,
  leftNavClosed: codes.leftNavClosed,
  getNextMatchPending: codes.getNextMatchPending,
  getPrevMatchPending: codes.getPrevMatchPending,
  prevMatch: codes.prevMatch,
  nextMatch: codes.nextMatch,
  searchCtx: search.searchContext,
  nextDocUrl: getNextDocUrl({found, codes}),
  prevDocUrl: getPrevDocUrl({found, codes}),
  beforeIndex: sections.beforeIndex,
  afterIndex: sections.afterIndex,
  highlightMarks: codes.highlightMarks,
}), {
  gotoNextMatch,
  gotoPrevMatch,
  getFirstMatch,
  getNextMatch,
  getPrevMatch,
  getLastMinutesMatch,
  getNextMinutesMatch,
  getPrevMinutesMatch,
  goto,
  redirect,
})
export class NavBottom extends React.Component {
  static propTypes = {
    match : PropTypes.object.isRequired,
    urlPrefix: PropTypes.string.isRequired,
  }

  constructor () {
    super();

    this.backToSearch = React.createRef();
    this.gotoNextMatch = this.gotoNextMatch.bind(this);
    this.gotoPrevMatch = this.gotoPrevMatch.bind(this);
    this.onMessage = this.onMessage.bind(this);
  }

  componentDidMount () {
    window.addEventListener('message', this.onMessage);
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage (e) {
    const func = _.get(e, 'data.func');
    if (func) {
      _.invoke(this, func);
    }
  }

  doHighlighterJsFunc (func) {
    const iframeWindow = _.get(
      document.getElementsByClassName('highlighter-viewer'),
      '[0].contentWindow',
    );

    if (iframeWindow) {
      iframeWindow.postMessage({ func }, '*');
    }
  }

  gotoPrevMatch (p) {
    const {
      searchCtx,
      currSection,
      currClient,
      gotoPrevMatch,
      getPrevMatch,
      getLastMinutesMatch,
      getPrevMinutesMatch,
      beforeIndex,
      afterIndex,
      highlightMarks,
      goto,
    } = this.props;

    if (_.get(currSection, 'pdf_path')) {
      this['alpOnNoPrevHit'] = async () => {
        try {
          if (_.has(currSection, 'date')) {
            // Meeting minutes
            await getPrevMinutesMatch(
              currSection.id,
              searchCtx,
            );
            const prevMatch = this.props.prevMatch;
            if (!!prevMatch) {
              const suffix = prevMatch.minute_order_idx > 0 ? `/${prevMatch.minute_order_idx}` : '';

              goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}m/${prevMatch.date_url}${suffix}`);
            }
          } else {
            // PDF Code
            await getPrevMatch(currSection.id, searchCtx);
            const prevMatch = this.props.prevMatch;
            if (!!prevMatch) {
              const docId = encodeURIComponent(prevMatch.doc_id);
              goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}${prevMatch.code_slug}/${docId}`);
            }
          }
        } catch (error) {
          let displayError = true;
          try {
            if (!_.has(currSection, 'date') && currClient.minutes_years.length > 0) {
              // Ran out of codes
              await getLastMinutesMatch(currClient.slug, searchCtx);
              const prevMatch = this.props.prevMatch;
              if (!!prevMatch) {
                const suffix = prevMatch.minute_order_idx > 0 ? `/${prevMatch.minute_order_idx}` : '';

                goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}m/${prevMatch.date_url}${suffix}`);
              }
              displayError = false;
            }
          } finally {
            if (displayError) {
              this.backToSearch.current.wrappedInstance.toggle();
            }
          }
        }
      };
      this.doHighlighterJsFunc('showPrevHit');
    } else {
      p.marks = getVisibleMarks(beforeIndex, afterIndex, highlightMarks);
      gotoPrevMatch(p);
    }
  }

  gotoNextMatch (p) {
    const {
      searchCtx,
      currSection,
      currClient,
      selectedVersion,
      getFirstMatch,
      gotoNextMatch,
      getNextMatch,
      getNextMinutesMatch,
      beforeIndex,
      afterIndex,
      highlightMarks,
      goto,
    } = this.props;

    let docId;

    if (_.get(currSection, 'pdf_path')) {
      this['alpOnNoNextHit'] = async () => {
        try {
          if (_.has(currSection, 'date')) {
            // Meeting minutes
            await getNextMinutesMatch(
              currSection.id,
              searchCtx,
            );
            const nextMatch = this.props.nextMatch;
            if (!!nextMatch) {
              const suffix = nextMatch.minute_order_idx > 0 ? `/${nextMatch.minute_order_idx}` : '';

              goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}m/${nextMatch.date_url}${suffix}`);
            }
          } else {
            // PDF Code
            await getNextMatch(currSection.id, searchCtx);
            const nextMatch = this.props.nextMatch;
            if (!!nextMatch) {
              docId = encodeURIComponent(nextMatch.doc_id);
              goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}${nextMatch.code_slug}/${docId}`);
            }
          }
        } catch (error) {

          let displayError = true;
          try {
            if (_.has(currSection, 'date') && _.get(selectedVersion, 'toc.length', 0) > 0) {
              // Ran out of minutes
              await getFirstMatch(currClient.slug, searchCtx);
              const nextMatch = this.props.nextMatch;
              if (!!nextMatch) {
                docId = encodeURIComponent(nextMatch.doc_id);
                goto(`/${p.urlPrefix}${p.urlClient}${p.urlVersion}${nextMatch.code_slug}/${docId}`);
              }
              displayError = false;
            }
          } finally {
            if (displayError) {
              this.backToSearch.current.wrappedInstance.toggle();
            }
          }
        }
      };
      this.doHighlighterJsFunc('showNextHit');
    } else {
      p.marks = getVisibleMarks(beforeIndex, afterIndex, highlightMarks);
      gotoNextMatch(p);
    }
  }

  render () {
    const {
      match,
      goto,
      urlPrefix,
      searchCtx,
      leftNavClosed,
      getNextMatchPending,
      getPrevMatchPending,
      prevDocUrl,
      nextDocUrl,
    } = this.props;

    const urlClient = _.get(match, 'clientslug') ? `${_.get(match, 'clientslug')}/` : '';
    const urlVersion = _.get(match, 'version') ? `${_.get(match, 'version')}/` : `${_.get(match, 'uuid')}/`;

    const prevIcon = (
      <FontAwesomeIcon
        icon={faChevronLeft}
        size="lg"
        style={{marginRight: '.25rem'}}
      />
    );

    const nextIcon = (
      <FontAwesomeIcon
        icon={faChevronRight}
        size="lg"
        style={{marginLeft: '.25rem'}}
      />
    );

    return (
      <div className="codenav__bottom">
        <div className={classnames(
          'codenav__bottom-left',
          {'codenav__bottom-left--closed': leftNavClosed}
        )}>
          <div className="codenav__bottom-left-inner">
            <Link
              color="transparent"
              to="/"
            >
              <FontAwesomeIcon
                color='white'
                icon={faArrowCircleLeft}
                size="lg"
              />
              Back to Code Library
            </Link>
          </div>
        </div>
        <div className="codenav__bottom-right">
          <div className="bottom-right__doc bottom-right--left">
            
            {
              prevDocUrl
                ?
                <Link
                  to={prevDocUrl}
                  rel="nofollow"
                >
                  { prevIcon }
                  Previous Doc
                </Link>
                :
                <span className="disabled-link">
                  { prevIcon }
                  Previous Doc
                </span>
            }
          </div>
          {searchCtx && [
            <div
              className="bottom-right__match bottom-right--left"
              key="prev-match"
            >
              <Button
                id="prev-match"
                color="link"
                className={classnames({'btn--loading': getPrevMatchPending})}
                onClick={() => this.gotoPrevMatch({
                  urlPrefix,
                  urlClient,
                  urlVersion,
                  goto,
                  errorModal: this.backToSearch.current.wrappedInstance,
                })}
              >
                { prevIcon }
                Previous Match
              </Button>
            </div>,
            <div
              className="bottom-right__match bottom-right--right"
              key="next-match"
            >
              <Button
                id="next-match"
                color="link"
                className={classnames({'btn--loading': getNextMatchPending})}
                onClick={() => this.gotoNextMatch({
                  urlPrefix,
                  urlClient,
                  urlVersion,
                  goto,
                  errorModal: this.backToSearch.current.wrappedInstance,
                })}
              >
                Next Match
                { nextIcon }
              </Button>
            </div>,
          ]}
          <div className="bottom-right__doc bottom-right--right">
            {
              nextDocUrl
              &&
              <Link
                to={nextDocUrl}
                rel="nofollow"
              >
                Next Doc
                { nextIcon }
              </Link>
              ||
              <span className="disabled-link">
                Next Doc
                { nextIcon }
              </span>
            }
          </div>
        </div>
        <BackToSearchModal ref={this.backToSearch} />
      </div>
    );
  }
}
