import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  meta, preload, redirect } from 'react-website';
import * as _ from 'lodash';

import { default as config } from '../../../configuration';
import {
  minutesKey,
  viewMinutes,
  highlightMinutes,
  getMinutesYear,
  getMinutesMonth,
  markMinutesLoading,
} from '../../redux/codes';
import { metaKey } from '../../utils/helpers';

import './SectionLoader.scss';

@preload(async ({ dispatch, getState, params, location }) => {
  if (params.version != 'latest') {
    await dispatch(redirect(
      location.pathname.split(`${params.version}/`)[0] + `${params.version}/overview`,
    ));

    return;
  }

  let delayMonth = true;

  const year = _.get(
    getState().codes.minutesLookup,
    [minutesKey(_.pick(params, ['year'])), 'children'],
  );
  if (!year) {
    delayMonth = false;
    await dispatch(markMinutesLoading(_.pick(params, ['year'])));
    await dispatch(getMinutesYear(params.clientslug, params.year));
  }

  let month = _.get(
    getState().codes.minutesLookup,
    [minutesKey(_.pick(params, ['year', 'month'])), 'children'],
  );

  if (!month) {
    await dispatch(markMinutesLoading(_.pick(params, ['year', 'month'])));
    await dispatch(getMinutesMonth(params.clientslug, params.year, params.month, delayMonth));

    month = _.get(
      getState().codes.minutesLookup,
      [minutesKey(_.pick(params, ['year', 'month'])), 'children'],
    );
  }

  const orderIdx = parseInt(params.index || 0);
  const day = _.orderBy(
    _.filter(month, (m) => m.day == params.day),
    'order_idx',
  );
  const minutes = orderIdx < 0
    ? day[day.length - 1]
    : day[orderIdx];

  await dispatch(viewMinutes(minutes));

  const search = getState().search;
  // Get highlighting for minutes
  if (search.searchContext) {
    const currSection = _.get(getState().codes, 'currSection');
    if (currSection) {
      await dispatch(highlightMinutes(
        minutes.id,
        search.searchContext,
      ));
    }
  }

})
@connect(({ codes }) => ({
  currSection: codes.currSection,
  highlightTerms: codes.highlightTerms,
}))
@meta((state) => ({ ...metaKey('title', _.get(state, 'codes.currSection.title')) }))
export default class Minutes extends Component {

  doubleEncode (str) {
    str = _.replace(str, '#', '%23');

    return encodeURIComponent(str);
  }

  render () {
    const {
      currSection,
      highlightTerms,
    } = this.props;

    if (!currSection) {
      return null;
    }

    let pdfUrl = `/highlighter/viewer/?file=${this.doubleEncode(currSection.pdf_path)}` +
      '&hitNavLoc=2&powerSearch=1&hideHlMessages=1&hideHlErrors=1&roll=0&nativePrint=1' +
      `&script=${config.dev ? '/api/static' : '/static'}/js/highlighter-hooks.js`;
    if (highlightTerms) {
      pdfUrl = `/highlighter/highlight-for-query?uri=${this.doubleEncode(currSection.pdf_path)}` +
        `&query=${encodeURIComponent(_.join(_.map(highlightTerms, (t) => `"${t}"`), ' '))}&language=general`;
    }

    return (
      <iframe
        className="highlighter-viewer"
        src={`${config.exports_host || ''}${pdfUrl}`}
        title={_.get(currSection, 'title', 'pdf')}
      ></iframe>
    );
  }
}
