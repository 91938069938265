import React from 'react';
import { meta, preload } from 'react-website';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { EditTable } from '../../components/EditTable';
import {
  getBookmarks,
  deleteBookmark,
  updateBookmark,
} from '../../redux/account';
import { metaKey } from '../../utils/helpers';

@meta(() => ({ ...metaKey('title', 'Account Bookmarks') }))
@preload(async ({ dispatch}) => {
  await dispatch(getBookmarks());
})
@connect(({ account }) => ({
  bookmarks: _.get(account, 'user.bookmarks', []),
}), {
  deleteBookmark,
  updateBookmark,
})
export default class Bookmarks extends React.Component {
  constructor () {
    super();

    this.editTable = React.createRef();
    this.deleteBookmark = this.deleteBookmark.bind(this);
    this.updateBookmark = this.updateBookmark.bind(this);
  }

  async updateBookmark (bookmarkToUpdate) {
    const { updateBookmark } = this.props;

    await updateBookmark(bookmarkToUpdate);
  }

  async deleteBookmark (b) {
    const { deleteBookmark } = this.props;

    await deleteBookmark(b.id);
    this.editTable.current.deleteModal.current.toggle();
  }

  render () {
    return (
      <EditTable
        ref={this.editTable}
        subject={'bookmark'}
        subjectPlural={'bookmarks'}
        tableValues={this.props.bookmarks}
        onDelete={this.deleteBookmark}
        onUpdate={this.updateBookmark}
      />
    );
  }
}
