import React from 'react';
import { connect } from 'react-redux';
import { meta } from 'react-website';
import {
  Badge,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../../utils/StatefulForm';
import { metaKey } from '../../utils/helpers';
import { updateUser } from '../../redux/account';

import './Profile.scss';

@meta(() => ({ ...metaKey('title', 'Account Profile') }))
@connect(({ account }) => ({
  user: account.user,
  updateUserPending: account.updateUserPending,
  updateUserError: account.updateUserError,
}), { updateUser })
export default class Profile extends StatefulForm {
  constructor (props) {
    super(props);

    const { user } = props;

    this.submitForm = this.submitForm.bind(this);
    this.formUnchanged = this.formUnchanged.bind(this);
    this.state.formValue = {
      ..._.pick(
        user,
        [
          'name',
          'email',
        ]
      ),
      new_password1: '',
      new_password2: '',
    };
  }

  async submitForm (e) {
    const { updateUser } = this.props;

    const formValue = _.omitBy(
      this.state.formValue,
      (v, k) => {
        if(!this.state.formValue.new_password1) {
          return _.includes(k, 'password');
        }

        return false;
      },
    );

    e.preventDefault();

    await updateUser(formValue);
    await this.setState({
      formValue: {
        ...formValue,
        new_password1: '',
        new_password2: '',
      },
    });
  }

  formUnchanged () {
    const { user } = this.props;
    const { formValue } = this.state;

    return (
      !formValue.new_password1 &&
      _.isMatch(user, _.omit(formValue, ['new_password1', 'new_password2']))
    );
  }

  render () {
    const {
      user,
      updateUserPending,
      updateUserError,
    } = this.props;

    const { formValue } = this.state;

    if (!user) {
      return null;
    }

    return (
      <Form onSubmit={ this.submitForm }>
        <div className="row">
          <FormGroup className="col-lg-6">
            <Label for="name">Name</Label>
            <Input
              required
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              value={ formValue.name }
              invalid={ _.has(updateUserError, 'name') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(updateUserError, 'name') }
            </FormFeedback>
          </FormGroup>
          <FormGroup className="col-lg-6">
            <Label for="email">Email</Label>
            <Input
              required
              id="email"
              name="email"
              type="text"
              placeholder="email"
              value={ formValue.email }
              invalid={ _.has(updateUserError, 'email') }
              onChange={ this.handleChange }
            />
            {
              !user.email_verified &&
              <Badge
                className="email-unverified"
                title="An email was sent to verify ownership."
              >
                UNVERIFIED
              </Badge>
            }
            <FormFeedback role="alert">
              { _.get(updateUserError, 'email') }
            </FormFeedback>
          </FormGroup>

          <FormGroup className="col-lg-6">
            <Label for="new_password1">New Password</Label>
            <Input
              id="new_password1"
              name="new_password1"
              type="password"
              autoComplete="new-password"
              placeholder="New Password"
              value={ formValue.new_password1 }
              invalid={ _.has(updateUserError, 'new_password1') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(updateUserError, 'new_password1') }
            </FormFeedback>
          </FormGroup>

          <FormGroup className="col-lg-6">
            <Label for="new_password2">Confirm Password</Label>
            <Input
              id="new_password2"
              name="new_password2"
              type="password"
              autoComplete="new-password"
              placeholder="Confirm Password"
              value={ formValue.new_password2 }
              invalid={ _.has(updateUserError, 'new_password2') }
              disabled={!formValue.new_password1}
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(updateUserError, 'new_password2') }
            </FormFeedback>
          </FormGroup>
        </div>

        <div className="am-page__bottom">
          <Button
            type="submit"
            color="success"
            disabled={updateUserPending || this.formUnchanged()}
          >
            Save
          </Button>
        </div>
      </Form>
    );
  }
}
