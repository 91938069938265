import { render } from 'react-website';
import { resetServerContext } from 'react-beautiful-dnd';
import { resetIdCounter } from 'react-tabs';

import settings from './react-website';

export default async function () {
  // Per the docs, this avoids context state persisting across multiple renders
  resetServerContext();
  resetIdCounter();

  // Renders the webpage on the client side
  const { store, rerender } = await render(settings);

  // Webpack "Hot Module Replacement"
  if (module.hot) {
    module.hot.accept('./react-website', () => {
      console.log('[HMR] App updated. Recompiling...');
      store.hotReload(settings.reducers);
      rerender();
      console.log('[HMR] App is up to date.');
    });
  }
}
