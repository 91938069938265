import React from 'react';
import { connect } from 'react-redux';
import { meta, preload } from 'react-website';

import { clearClient } from '../../redux/admin';
import { metaKey } from '../../utils/helpers';

@preload(async ({ dispatch }) => {
  await dispatch(clearClient());
})
@connect(() => ({}), { clearClient })
@meta(() => ({ ...metaKey('title', 'Admin') }))
export default class AdminIndex extends React.Component {

  render () {
    return (
      <div className="no-data">
        Select an American Legal location to make updates to important city code
        components: Customize the header, edit the disclaimer and update
        the splash screen.
      </div>
    );
  }
}
