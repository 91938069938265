import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'reactstrap';

export class ExportButton extends React.Component {
  static propTypes = {
    format : PropTypes.object.isRequired,
  }

  render () {
    const {
      format,
      onClick,
    } = this.props;

    return (
      <Button
        className="export-button"
        color="export"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={format.icon}
        />
        <br/>
        Save {format.display}
      </Button>
    );
  }
}
