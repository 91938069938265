import { ReduxModule } from 'react-website';
import Cookies from 'universal-cookie';
import { getRecaptchaToken } from '../utils/recaptcha';
import * as _ from 'lodash';

const redux = new ReduxModule('ACCOUNT');

export const getUser = redux.action(
  'GET_USER',
  () => async (http) => {
    try {
      return await http.get('/api/me/');
    } catch(e) {
      return null;
    }
  },
  'user'
);

export const updateUser = redux.action(
  'UPDATE_USER',
  (info) => async (http) => await http.patch(`/api/user/`, info),
  (state, user) => ({
    ...state,
    user,
  })
);

export const setUserHash = redux.simpleAction(
  'SET_USER_HASH',
  'userHash',
);

export const signup = redux.action(
  'SIGNUP',
  (info) => async (http) => {
    return await http.post(`/api/signup/`, {
      ...info,
      recaptcha_token: await getRecaptchaToken(),
    });
  },
  (state, result) => ({
    ...state,
    user: result.user,
  })
);

export const verifyEmail = redux.action(
  'VERIFY_EMAIL',
  (key) => async (http) => {
    await http.post(`/api/signup/verify-email/`, { key });
  },
  // This is needed to properly reset pending on success (seems to be a bug)
  (state) => ({ ...state }),
);

export const forgotPassword = redux.action(
  'FORGOT_PASSWORD',
  (email) => async (http) => {
    await http.post(`/api/password/reset/`, {
      email,
      recaptcha_token: await getRecaptchaToken(),
    });
  },
  // This is needed to properly reset pending on success (seems to be a bug)
  (state) => ({ ...state }),
);

export const resetPassword = redux.action(
  'RESET_PASSWORD',
  (info) => async (http) => {
    await http.post(`/api/password/reset/confirm/`, info);
  },
  // This is needed to properly reset pending on success (seems to be a bug)
  (state) => ({ ...state }),
);

export const login = redux.action(
  'LOGIN',
  (creds) => async (http) => {
    return await http.post(`/api/login/`, creds);
  },
  (state, result) => {
    const cookies = new Cookies();
    cookies.set('token', result.key, { path: '/' });

    return {
      ...state,
      user: result.user,
    };
  }
);

export const logout = redux.action(
  'LOGOUT',
  () => async () => await Promise.resolve(),
  (state) => {
    const cookies = new Cookies();
    cookies.remove('token', { path: '/' });

    return {
      ...state,
      user: null,
    };
  }
);

export const getBookmarks = redux.action(
  'GET_BOOKMARKS',
  () => async (http) => await http.get('/api/bookmarks/'),
  (state, bookmarks) => ({
    ...state,
    user: {
      ...state.user,
      bookmarks,
    },
  }),
);

export const createBookmark = redux.action(
  'CREATE_BOOKMARK',
  (params) => async (http) => await http.post('/api/bookmarks/', params),
  (state, result) => ({
    ...state,
    user:{
      ...state.user,
      bookmarks: [
        ...state.user.bookmarks,
        result,
      ],
    },
  })
);

export const deleteBookmark = redux.action(
  'DELETE_BOOKMARK',
  (id) => async (http) => {
    await http.delete(`/api/bookmarks/${id}/`);

    return id;
  },
  (state, deleted_id) => {
    const bookmarks =  _.filter(state.user.bookmarks, (b) => b.id !== deleted_id);

    return {
      ...state,
      user: {
        ...state.user,
        bookmarks,
      },
    };
  },
);

export const updateBookmark = redux.action(
  'UPDATE_BOOKMARK',
  (params) => async (http) => {
    return await http.patch(`/api/bookmarks/${params.id}/`, params);
  },
  (state, result) => {
    const newBookmarks =  _.cloneDeep(state.user.bookmarks);

    const bookmarkIdx = _.findIndex(newBookmarks, (b) => b.id == result.id);
    if (bookmarkIdx >= 0) {
      newBookmarks.splice(
        bookmarkIdx,
        1,
        {
          ...newBookmarks[bookmarkIdx],
          ...result,
        }
      );
    }

    return{
      ...state,
      user: {
        ...state.user,
        bookmarks: newBookmarks,
      },
    };
  },
);

export const getFollows = redux.action(
  'GET_FOLLOWS',
  () => async (http) => await http.get('/api/follows/'),
  (state, follows) => ({
    ...state,
    user: {
      ...state.user,
      follows,
    },
  }),
);

export const createFollow = redux.action(
  'CREATE_FOLLOW',
  (params) => async (http) => {
    const { id: oldId } = params;

    return  {
      follow: await http.post('/api/follows/', params),
      oldId,
    };
  },
  (state, result) => {
    const newFollows =  _.cloneDeep(state.user.follows);

    if (result.oldId) {
      const followIdx = _.findIndex(newFollows, (f) => f.id == result.oldId);
      if (followIdx >= 0) {

        newFollows.splice(
          followIdx,
          1,
          {
            ...newFollows[followIdx],
            ...result.follow,
            deleted: undefined,
          }
        );
      }
    } else {
      newFollows.push(result.follow);
    }

    return {
      ...state,
      user: {
        ...state.user,
        follows: newFollows,
      },
    };
  }
);

export const deleteFollow = redux.action(
  'DELETE_FOLLOW',
  (id, removeFromState = false) => async (http) => {
    await http.delete(`/api/follows/${id}/`);

    return { deleted_id: id, removeFromState};
  },
  (state, params) => {
    const newFollows =  _.cloneDeep(state.user.follows);

    const followIdx = _.findIndex(newFollows, (f) => f.id == params.deleted_id);
    if (params.removeFromState) {
      // Remove it
      newFollows.splice(followIdx, 1);
    } else if (followIdx >= 0) {
      // Mark it deleted
      newFollows.splice(
        followIdx,
        1,
        {
          ...newFollows[followIdx],
          deleted: true,
        }
      );
    }

    return {
      ...state,
      user: {
        ...state.user,
        follows: newFollows,
      },
    };
  }
);

const initialState = {
  user: null,
  userHash: null,
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
