import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faDownload,
  faLink,
  faShareAlt,
  faArrowCircleLeft,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import copy from 'copy-to-clipboard';
import * as _ from 'lodash';

import {
  exportData,
  getExportsAvailable,
} from '../redux/exports';
import { getCanDownloadScope } from '../redux/codes';
import { createBookmark } from '../redux/account';
import { logFirebaseEvent } from '../redux/analytics';

import { CodeAction } from '../utils/CodeAction';
import { EXPORT_FORMATS } from '../utils/ExportFormats';
import { StatefulForm } from '../utils/StatefulForm';
import { ScopeInput } from '../components/ScopeInput';
import { ExportButton } from '../components/ExportButton';
import './CodeOptionsModal.scss';

@connect(
  ({ account, codes, exports, found }) => ({
    user: account.user,
    userHash: account.userHash,
    selectedVersion: codes.selectedVersion,
    currSection: codes.currSection,
    scopeCodeLookup: codes.scopeCodeLookup,
    scopeTocLookup: codes.scopeTocLookup,
    exportsAvailable: getExportsAvailable({ account, exports }),
    exportRequests: exports.exportRequests,
    createBookmarkPending: account.createBookmarkPending,
    createBookmarkError: account.createBookmarkError,
    canDownloadScope: getCanDownloadScope(codes),
    location: _.get(found, 'match.location'),
  }),
  { exportData, createBookmark, logFirebaseEvent },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class CodeOptionsModal extends StatefulForm {
  state = {
    open: false,
    copied: false,
    tabIndex: 0,
    downloadSelected: false,
    exportScope: null,
    onConfirmExport: null,
    formValue: {
      title: '',
      url:'',
    },
  }

  constructor (props) {
    super(props);

    this.titleInput = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.download = this.download.bind(this);
    this.exportData = this.exportData.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.scopeInput = React.createRef();
  }

  // Public method to initialize state and toggle on
  async show (params) {
    const {
      action,
      destID,
    } = params;

    const { canDownloadScope } = this.props;

    let newState = {
      ...params,
      downloadSelected: false,
      onConfirmExport: null,
    };

    switch(action) {
      case CodeAction.SHARE:
        newState.tabIndex = 0;
        break;
      case CodeAction.DOWNLOAD:
        newState.tabIndex = 1;
        break;
      case CodeAction.BOOKMARK:
        newState.tabIndex = canDownloadScope ? 2 : 1;
        break;
      case CodeAction.PRINT:
        newState.tabIndex = 3;
        break;
    }

    let url = _.get(params, 'location.href');
    if (url && destID) {
      // Remove hash
      url = url.replace(/#(\S*)/g, '');
      // Replace doc_id
      url = `${url.match(/^(.*[\\\/])/)[0]}${_.replace(destID, 'rid-', '')}`;
    }
    newState.shareUrl = url;
    newState.bookmarkUrl = url;

    if (action == CodeAction.SHARE) {
      this.props.logFirebaseEvent({
        eventName: 'share',
        params: { value: url },
      });
    }

    await this.setState(newState);
    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));

    //NOTE: Need to implement ADA focusing
    // this.titleInput.current.focus();
  }

  async toggleCopy () {
    if (!this.state.copied) {
      await this.setState({ copied:true });

      // wait 2 seconds, then reset to false
      setTimeout(() => this.setState({ copied:false }), 2000);
    }
  }

  async download () {
    const { exportsAvailable } = this.props;

    if (this.scopeInput.current) {
      await this.setState({
        exportScope: this.scopeInput.current.wrappedInstance.getScope(),
      });
    }

    const doDownload = async () => await this.setState({
      downloadSelected: true,
      onConfirmExport: null,
    });

    if (!exportsAvailable && !this.state.onConfirmExport) {
      await this.setState({ onConfirmExport: doDownload });
    } else {
      doDownload();
    }
  }

  async exportData (format, print=false) {
    const {
      exportsAvailable,
      userHash,
      exportData,
      selectedVersion,
    } = this.props;
    const {
      downloadSelected,
      onConfirmExport,
    } = this.state;

    if (this.scopeInput.current) {
      await this.setState({
        exportScope: this.scopeInput.current.wrappedInstance.getScope(),
      });
    }

    const doExport = async () => {
      await exportData(
        {
          version: _.get(selectedVersion, 'uuid'),
          scope: JSON.stringify(this.state.exportScope),
          output_format: format,
          for_print: print,
        },
        userHash,
      );
      await this.setState({
        downloadSelected: false,
        open: false,
        onConfirmExport: null,
      });
    };

    if (!exportsAvailable && !downloadSelected && !onConfirmExport) {
      await this.setState({ onConfirmExport: doExport });
    } else {
      doExport();
    }
  }

  async submitForm (e) {
    e.preventDefault();
    const { createBookmark } = this.props;

    const {
      formValue,
      bookmarkUrl,
    } = this.state;

    const l = document.createElement('a');
    l.href = bookmarkUrl;

    const bookmarkFields = {
      url: l.pathname,
      title: formValue.title,
    };

    await createBookmark(bookmarkFields);

    await this.setState({formValue: {title: '', url: ''}});
    await this.toggle();
  }

  render () {
    const {
      user,
      selectedVersion,
      scopeCodeLookup,
      scopeTocLookup,
      createBookmarkPending,
      createBookmarkError,
      canDownloadScope,
      location,
    } = this.props;
    const {
      shareUrl,
      destID,
      formValue,
      onConfirmExport,
    } = this.state;

    const haveSelections = (
      _.some(scopeCodeLookup, (code) => (
        _.get(code, 'selected', false) &&
        !_.get(code, 'partial', false)
      )) ||
      _.some(scopeTocLookup, (entry) => (
        _.get(entry, 'selected', false) &&
        !_.get(entry, 'partial', false)
      ))
    );

    const confirmDownloadModal = <div>
      <ModalBody>
        <p className="text-center">
          You’ve hit your maximum active exports.
        </p>
        <p>
          Click Continue to automatically remove your oldest export
          from the queue and continue with this current export, or
          click Cancel to choose which export you would like to
          remove before trying again.
        </p>
      </ModalBody>
      <div style={{padding: '1rem'}}>
        <Button onClick={() => this.setState({ onConfirmExport: null })}>
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          Back to file selection
        </Button>
        <span style={{float: 'right'}}>
          <Button
            onClick={this.toggle}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onConfirmExport}
          >
            Continue
          </Button>
        </span>
      </div>
    </div>;

    const downloadScopeModal = <div>
      <ModalBody>
        <p className="text-center">
          Select which section you would like to download to your computer.
        </p>
        <ScopeInput
          ref={this.scopeInput}
          version={selectedVersion}
          hash={destID || ''}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.toggle}>
          Cancel
        </Button>
        <Button
          id="download-button"
          color="primary"
          disabled={!haveSelections}
          onClick={this.download}
        >
          Download
        </Button>
      </ModalFooter>
    </div>;

    const downloadFormatSelectionModal = <div>
      <ModalBody>
        <p className="text-center">
          Select which file type you would like to download to your computer.
        </p>
        {
          _.map(
            EXPORT_FORMATS,
            (format) => (
              <ExportButton
                key={`export-${format.value}`}
                format={format}
                onClick={() => this.exportData(format.value)}
              />
            ),
          )
        }
      </ModalBody>
      <div style={{padding: '1rem'}}>
        <Button onClick={() => this.setState({downloadSelected: false})}>
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          Back to file selection
        </Button>
        <Button
          style={{float: 'right'}}
          onClick={this.toggle}
        >
          Cancel
        </Button>
      </div>
    </div>;

    const showScope = !this.state.downloadSelected;

    let downloadModal;
    if (onConfirmExport) {
      downloadModal = confirmDownloadModal;
    } else if (showScope) {
      downloadModal = downloadScopeModal;
    } else {
      downloadModal = downloadFormatSelectionModal;
    }

    const confirmPrintModal = <div>
      <ModalBody>
        <p className="text-center">
          You’ve hit your maximum active exports.
        </p>
        <p>
          Click Continue to automatically remove your oldest export
          from the queue and continue with this current export, or
          click Cancel to choose which export you would like to
          remove before trying again.
        </p>
      </ModalBody>
      <div style={{padding: '1rem'}}>
        <Button onClick={() => this.setState({ onConfirmExport: null })}>
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          Back to file selection
        </Button>
        <Button
          style={{float: 'right'}}
          color="primary"
          onClick={() => onConfirmExport()}
        >
          Continue
        </Button>
        <Button
          style={{float: 'right'}}
          onClick={this.toggle}
        >
          Cancel
        </Button>
      </div>
    </div>;

    const printScopeModal = <div>
      <ModalBody>
        <p className="text-center">
          Select which section you would like to print.
        </p>
        <ScopeInput
          ref={this.scopeInput}
          version={selectedVersion}
          hash={destID || ''}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.toggle}>
          Cancel
        </Button>
        <Button
          id="print-button"
          color="primary"
          disabled={!haveSelections}
          onClick={() => this.exportData('html', true)}
        >
          Print
        </Button>
      </ModalFooter>
    </div>;

    const printModal = onConfirmExport
      ? confirmPrintModal
      : printScopeModal;

    return (
      <Modal
        className="code-options-modal"
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Code Options
        </ModalHeader>
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList>
            <Tab>
              <FontAwesomeIcon icon={faShareAlt} />
              Share
            </Tab>
            {
              canDownloadScope &&
              <Tab>
                <FontAwesomeIcon icon={faDownload} />
                Download
              </Tab>
            }
            <Tab>
              <FontAwesomeIcon icon={faBookmark} />
              Bookmark
            </Tab>
            {
              canDownloadScope &&
              <Tab>
                <FontAwesomeIcon icon={faPrint} />
                Print
              </Tab>
            }
          </TabList>
          <TabPanel>
            <ModalBody>
              <p className="text-center" style={{marginBottom: '1.5rem'}}>
                Copy and paste the below URL to share it with others.
              </p>
              <InputGroup>
                <Input
                  aria-label="Share URL"
                  type="text"
                  value={shareUrl}
                  style={{paddingLeft: '2rem'}}
                  disabled
                />
                <FontAwesomeIcon
                  icon={faLink}
                  size="sm"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    type="submit"
                    color={this.state.copied ? 'success' : 'primary'}
                    onClick={() => {copy(shareUrl); this.toggleCopy();}}
                    style={{width: 115}}
                  >
                    { this.state.copied ? 'COPIED!' : 'COPY LINK' }
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </ModalBody>
          </TabPanel>
          {
            canDownloadScope &&
            <TabPanel>
              {downloadModal}
            </TabPanel>
          }
          <TabPanel>
            <ModalBody>
              {!!user ?
                <p className="text-center" style={{marginBottom: '1.5rem'}}>
                  Input a title and click the Save button to save this bookmark to your account,
                  or copy the below URL and save the link in your browser.
                </p>
                :
                <p className="text-center" style={{marginBottom: '1.5rem'}}>
                  {
                    // eslint-disable-next-line max-len
                  }<Link to={`/login?next=${_.get(location, 'pathname')}%3Fbookmark%3D${encodeURIComponent(destID || '')}`}>
                    Log In
                  </Link>
                  &nbsp;to add this bookmark to your account, or copy the below URL and save the link
                  in your browser. If you don&apos;t have an account, you can&nbsp;
                  <Link to="/signup">
                    Sign Up
                  </Link>!
                </p>
              }
              <FormGroup>
                <InputGroup
                  style={{paddingBottom: '2.1875rem'}}
                >
                  <Input
                    innerRef={this.titleInput}
                    required
                    name="title"
                    placeholder="Save bookmark as..."
                    type="text"
                    value={ formValue.title }
                    invalid={ _.has(createBookmarkError, 'title') }
                    onChange={ this.handleChange }
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={ !user || createBookmarkPending }
                      onClick={this.submitForm}
                      style={{width: 115}}
                    >
                      Save
                    </Button>
                  </InputGroupAddon>
                  <FormFeedback role="alert">
                    { _.get(createBookmarkError, 'title') }
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  value={shareUrl}
                  style={{paddingLeft: '2rem'}}
                  disabled
                />
                <FontAwesomeIcon
                  icon={faLink}
                  size="sm"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    aria-label="Share URL"
                    type="submit"
                    color={this.state.copied ? 'success' : 'primary'}
                    onClick={() => {copy(shareUrl); this.toggleCopy();}}
                    style={{width: 115}}
                  >
                    { this.state.copied ? 'COPIED!' : 'COPY LINK' }
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </ModalBody>
          </TabPanel>
          {
            canDownloadScope &&
            <TabPanel>
              {printModal}
            </TabPanel>
          }
        </Tabs>
      </Modal>
    );
  }
}
