import {
  faFileCode,
  faFileWord,
  faFilePdf,
  faFileAlt,
  faFile,
} from '@fortawesome/free-regular-svg-icons';

export const EXPORT_FORMATS = [
  {
    value: 'pdf',
    icon: faFilePdf,
    display: 'PDF',
  },
  {
    value: 'docx',
    icon: faFileWord,
    display: 'Word',
  },
  {
    value: 'txt',
    icon: faFileAlt,
    display: 'Text',
  },
  {
    value: 'html',
    icon: faFileCode,
    display: 'HTML',
  },
  {
    value: 'epub',
    icon: faFile,
    display: 'EPUB',
  },
  {
    value: 'mobi',
    icon: faFile,
    display: 'MOBI',
  },
];
