import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, meta, preload } from 'react-website';
import * as _ from 'lodash';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import { getClientRegions } from '../../redux/clients';
import { clearSearch } from '../../redux/search';
import { metaKey } from '../../utils/helpers';

import './Browse.scss';

@meta(() => ({ ...metaKey('title', 'American Legal Publishing') }))
@preload(async ({ dispatch }) => {
  await dispatch(getClientRegions());
})
@connect(({ clients }) => ({
  clientRegions: clients.clientRegions,
}), { clearSearch })
export default class BrowseClientRegions extends Component {
  componentDidMount () {
    this.props.clearSearch();
  }

  render () {
    const { clientRegions } = this.props;

    const regionItems = _.map(
      clientRegions,
      (region) => {
        return (
          <div key={region.slug}>
            <Link
              className="browse-link roboto"
              to={`/regions/${region.slug}`}
            >
              {region.name}
            </Link>
          </div>
        );
      }
    );

    return (
      <div className="browse-page">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Select Location</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="am-page am-page--scroll">
          <div className="am-page__content am-page__content--nobg">
            <div className="browse-header-box">
              <h2 className="browse-header">Welcome to American Legal Publishing’s Code Library!</h2>

              <p className="roboto">
                To view a code, choose a state from the list below,
                then click on the name of the appropriate municipality.
              </p>

              <a
                className="browse-link roboto"
                href="https://mapsengine.google.com/map/embed?mid=zFzZAb7ybZPs.kGgyDSTJK1A4"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Opens map in a new tab"
              >
                View Google Map of Online Clients
              </a>
            </div>

            <div className="browse-columns roboto">
              { regionItems }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
