import React, { Component } from 'react';
import JsxParser from 'react-jsx-parser';
import { connect } from 'react-redux';
import { Link, redirect } from 'react-website';
import {
  Button,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import * as Mark from 'mark.js';
import * as _ from 'lodash';

import {
  addHighlightMarks,
  setCurrMark,
} from '../../redux/codes';
import { InterCodeLink } from './InterCodeLink';
import { QueryLink } from './QueryLink';
import { CodeOptions } from './CodeOptions';
import { AnnotationDrawer } from './AnnotationDrawer';
import { ScrollTable } from '../../components/ScrollTable';

@connect(({ codes, sections }, ownProps) => {
  const { origDocIdx } = ownProps;
  const section = sections.items[origDocIdx];

  return {
    section,
    htmlDiff: sections.itemDiffs[_.get(section, 'doc_id')],
    highlightTerms: codes.highlightTerms,
    highlightMarks: codes.highlightMarks,
  };
}, {
  redirect,
  addHighlightMarks,
  setCurrMark,
})
export class Section extends Component {
  changingSections = false;

  constructor () {
    super();

    this.section = React.createRef();
  }

  async componentDidMount () {
    const {
      highlightTerms,
      addHighlightMarks,
      setCurrMark,
      origDocIdx,
      section,
    } = this.props;

    // Changing section client-side
    if (highlightTerms) {
      new Mark(this.section.current).mark(
        highlightTerms,
        {
          accuracy: {
            value: 'exactly',
            limiters: [',', '.',':','(',')',';'],
          },
          acrossElements: true,
          separateWordSearch: false,
          each: (mark) => {
            // Clicking a mark sets it as the current
            mark.setAttribute('data-sectionid', section.id);
            mark.addEventListener('click', () => setCurrMark(mark));
          },
        },
      );
    }

    // This timeout is needed for Safari
    setTimeout(() => {
      addHighlightMarks({
        origDocIdx,
        marks: this.section.current
          ? Array.prototype.slice.call(this.section.current.querySelectorAll('mark'))
          : [],
      });
    }, 100);
  }

  shouldComponentUpdate (nextProps) {
    const {
      htmlDiff: oldHtmlDiff,
    } = this.props;
    const {
      htmlDiff: newHtmlDiff,
      section,
      getSectionInfoPending,
    } = nextProps;

    // If we're switching sections, let's ignore updates on the instance
    // of <Section/> that's about to be discarded
    if (this.changingSections || getSectionInfoPending) {
      this.changingSections = true;

      return false;
    }

    // // Re-render if diff existence changed
    if (!!_.get(section, 'html') && !!oldHtmlDiff != !!newHtmlDiff) {
      return true;
    }

    // Never re-render. The first time is enough
    return false;
  }

  render () {
    const {
      section,
      htmlDiff,
      origDocIdx,
    } = this.props;

    if (!section) {
      return null;
    }

    const html = htmlDiff || section.html;

    return (
      <div
        id={`section-${origDocIdx}`}
        ref={this.section}
      >
        <JsxParser
          bindings={{ origDocIdx }}
          components={{
            Button,
            Link,
            UncontrolledPopover,
            PopoverBody,
            InterCodeLink,
            QueryLink,
            CodeOptions,
            AnnotationDrawer,
            ScrollTable,
          }}
          showWarnings={true}
          jsx={html && html.replace(/="(\{\{[^\}\}"]+\}\})"/g, '=$1')}
        />
      </div>
    );
  }
}
