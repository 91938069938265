import React, { Component } from 'react';
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Input,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';

export default class Playground extends Component {

  render () {
    return (
      <section className="page-content container">
        <Button>Cancel</Button>{' '}
        <Button id="popover" color="primary">Primary</Button>{' '}
        <Button color="success">Success</Button>{' '}
        <Button color="danger">Danger</Button>
        <br/><br/>
        <Button disabled>Cancel</Button>{' '}
        <Button color="primary" disabled>Primary</Button>{' '}
        <Button color="success" disabled>Success</Button>{' '}
        <Button color="danger" disabled>Danger</Button>
        <br/><br/>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
        </FormGroup>
        <FormGroup>
          <Label for="text">Text</Label>
          <Input type="textarea" name="email" id="text" placeholder="stuff" />
        </FormGroup>
        <FormGroup>
          <Input type="checkbox" name="check" id="check" />
          <Label for="check" check>Check me out</Label>{' '}
        </FormGroup>
        <FormGroup>
          <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="Switch me on" />
        </FormGroup>

        <UncontrolledPopover placement="bottom" target="popover">
          <PopoverHeader>
            Popover Title
            <button type="button" className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </PopoverHeader>
          <PopoverBody>
            Sed posuere consectetur est at lobortis. Aenean eu leo quam.
            Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </PopoverBody>
        </UncontrolledPopover>
      </section>
    );
  }
}
