import { ReduxModule } from 'react-website';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

import { default as config } from '../../configuration';
import { logFirebaseEvent } from './analytics';

const redux = new ReduxModule('EXPORTS');

export const getExportRequests = redux.action(
  'GET_EXPORT_REQUESTS',
  (user_hash) => async (http) => await http.get(
    '/api/export-requests/',
    null,
    { headers: { 'Fingerprint': user_hash }}
  ),
  (state, exportRequests) => ({
    ...state,
    exportRequests,
  }),
);

export const deleteRequest = redux.action(
  'DELETE_REQUEST',
  (uuid, user_hash) => async (http) => {
    await http.delete(
      `/api/export-requests/${uuid}/`,
      null,
      { headers: { 'Fingerprint': user_hash }}
    );

    return uuid;
  },
  (state, uuid) => ({
    ...state,
    exportRequests: _.filter(state.exportRequests, (r) => r.uuid != uuid),
  }),
);

export const exportData = redux.action(
  'EXPORT_DATA',
  (params, user_hash) => async (http) => await http.post(
    '/api/export-requests/',
    params,
    { headers: { 'Fingerprint': user_hash }}
  ),
  (state, newRequest) => ({
    ...state,
    exportRequests: [
      newRequest,
      ...state.exportRequests,
    ],
    // Keep track of print requests that the browser initiates
    printRequests: {
      ...state.printRequests,
      [newRequest.uuid]: newRequest,
    },
  }),
);

const printRequestDone = redux.simpleAction(
  'PRINT_REQUEST_DONE',
  (state, uuid) => ({
    ...state,
    printRequests: _.pickBy(state.printRequests, (r) => r.uuid !== uuid),
  }),
);

export const startPolling = redux.simpleAction(
  'START_POLLING',
  (state, clearResults=false) => ({
    ...state,
    polling: true,
    exportRequests: clearResults ? [] : state.exportRequests,
  }),
);

export const updateInterval = redux.simpleAction(
  'UPDATE_POLLING',
  (state, interval) => ({
    ...state,
    pollInterval: interval,
  }),
);

export const stopPolling = redux.simpleAction(
  'STOP_POLLING',
  (state) => ({
    ...state,
    polling: false,
    pollInterval: null,
  }),
);

const MAX_LOGGED_IN_EXPORTS = 5;
const MAX_ANONYMOUS_EXPORTS = 2;

export const getExportsAvailable = createSelector(
  ({ account }) => account.user,
  ({ exports }) => exports.exportRequests,
  (user, requests) => {
    let exportsAvailable = requests.length < MAX_ANONYMOUS_EXPORTS;
    if (!!user) {
      exportsAvailable = requests.length < MAX_LOGGED_IN_EXPORTS;
    }

    return exportsAvailable;
  }
);

export const exportsMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const { type } = action;
    const { codes, exports, account } = getState();

    if (!exports.pollInterval) {
      if (type == 'EXPORTS: DELETE_REQUEST_ERROR') {
        dispatch(getExportRequests(account.user ? undefined : account.userHash));
      } else if (type == 'EXPORTS: EXPORT_DATA_SUCCESS') {
        // Start polling if we successfully enqueued a job
        dispatch(startPolling());

        const { currClient } = codes;
        // Track export parameters
        dispatch(logFirebaseEvent({
          eventName: 'export_request',
          params: {
            for_print: action.value.for_print,
            output_format: action.value.output_format,
            client_slug: currClient.slug,
          },
        }));
      } else if (
        type == 'ACCOUNT: LOGIN_SUCCESS' ||
        type == 'ACCOUNT: LOGOUT_SUCCESS'
      ) {
        // Delay so the user object can be cleared out
        setTimeout(() => dispatch(startPolling(true)), 100);
      } else if (type == 'EXPORTS: START_POLLING') {
        // Update store with reference to interval
        dispatch(
          updateInterval(
            setInterval(() => dispatch(getExportRequests(
              account.user ? undefined : account.userHash,
            )), 3000),
          ),
        );
      }
    } else if (!!exports.pollInterval) {
      if (type == 'EXPORTS: STOP_POLLING') {
        clearInterval(exports.pollInterval);
      } else if (
        type == 'EXPORTS: GET_EXPORT_REQUESTS_ERROR' ||
        (
          type == 'EXPORTS: GET_EXPORT_REQUESTS_SUCCESS' &&
          _.every(action.value, (r) => r.task.completed)
        )
      ) {
        // No reason to poll if there's nothing to get
        dispatch(stopPolling());

        if (type == 'EXPORTS: GET_EXPORT_REQUESTS_SUCCESS') {
          // Automatically open print requests made in this session
          _.forEach(
            action.value, //exportRequests,
            async (request) => {
              if (
                request.for_print &&
                _.has(exports.printRequests, request.uuid)
              ) {
                await dispatch(printRequestDone(request.uuid));

                window.open(`${config.exports_host || ''}/api/export-requests/${request.uuid}/download/`, '_blank');
              }
            }
          );
        }
      }
    }

    // Calls reducer with action
    return next(action);
  };
};

const initialState = {
  exportRequests: [],
  printRequests: {},
  polling: false,
  pollInterval: null,
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
