import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

export class RedirectModal extends Component {
  state = { open: false }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Welcome!
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            Welcome to the new American Legal Publishing code library!  As you
            may have noticed, our website has changed.  If you have any old
            links or bookmarks for city codes and ordinances lying around,
            please be sure to update them.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
