import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { delay, reloadClient, updateMinute } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    updateMinutePending: admin.updateMinutePending,
    updateMinuteError: admin.updateMinuteError,
  }),
  { reloadClient, updateMinute },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class PublishMinuteModal extends Component {
  state = {
    open: false,
    firstGoDone: false,
    minuteToReplace: null,
  }

  constructor () {
    super();

    this.publishMinute = this.publishMinute.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (minute) {
    await this.setState({
      minute,
      firstGoDone: false,
      minuteToReplace: null,
    });

    await this.toggle();
  }

  async toggle () {
    if (!this.props.updateMinutePending) {
      await this.setState((prevState) => ({
        open: !prevState.open,
      }));
    }
  }

  async publishMinute () {
    const {
      currClient,
      reloadClient,
      updateMinute,
    } = this.props;
    const {
      minute,
      minuteToReplace,
    } = this.state;

    await this.setState({ firstGoDone: true });

    await updateMinute({
      id: minute.id,
      newValues: {
        published: true,
        replace_minute: _.get(minuteToReplace, 'id'),
      },
    });

    await delay(200);

    await reloadClient(currClient.slug);

    this.toggle();
  }

  render () {
    const {
      currClient,
      updateMinutePending,
      updateMinuteError,
    } = this.props;

    const {
      minute,
      minuteToReplace,
    } = this.state;

    const minutes = _.filter(currClient.published_minutes, (m) => m.date == _.get(minute, 'date'));

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={updateMinutePending ? undefined : this.toggle}>Publish Meeting Minutes</ModalHeader>
        <ModalBody>
          <div>
            {
              minutes.length > 0 &&
              <p className="text-center">
                There are existing published minutes for <b>{_.get(minute, 'date')}</b>{' '}.
                If you would like to overwrite one, select it from the list below:
              </p> ||
              <p className="text-center">
                Would you like to proceed with publishing the minutes for <b>{_.get(minute, 'date')}</b>?
              </p>
            }
            <ListGroup>
              {_.map(
                minutes,
                (m) => (
                  <ListGroupItem
                    key={`modal-version-${m.id}`}
                    className={classnames({
                      'list-item--selected': _.get(minuteToReplace, 'id') == m.id,
                    })}
                    onClick={() => this.setState({minuteToReplace: m})}
                    disabled={updateMinutePending}
                  >
                    {m.title}
                  </ListGroupItem>
                ),
              )}
              {(
                minuteToReplace &&
                <p
                  className="text-danger text-center"
                  style={{margin: '1.875rem 0 0 0'}}
                >
                  The selection will be replaced with the newly published minutes.
                </p>
              )}
            </ListGroup>
            {
              updateMinuteError && this.state.firstGoDone &&
              <p
                className="text-danger text-center"
                style={{margin: '1.875rem 0 0 0'}}
              >
                { updateMinuteError.detail }
              </p>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.toggle}
            disabled={updateMinutePending}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={this.publishMinute}
            disabled={updateMinutePending}
          >
            {
              updateMinutePending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            { minuteToReplace ? 'Overwrite' : 'Publish' }
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
