import React from 'react';
import { connect } from 'react-redux';
import { meta, preload } from 'react-website';
import {
  Button,
  Table,
} from 'reactstrap';
import * as _ from 'lodash';

import {
  getFollows,
  createFollow,
  deleteFollow,
} from '../../redux/account';
import { metaKey } from '../../utils/helpers';

@meta(() => ({ ...metaKey('title', 'Account Following') }))
@preload(async ({ dispatch }) => {
  await dispatch(getFollows());
})
@connect(({ account }) => ({
  follows: _.get(account, 'user.follows', []),
}), { createFollow, deleteFollow })
export default class Following extends React.Component {
  render () {
    const {
      follows,
      createFollow,
      deleteFollow,
    } = this.props;

    const tableItems = _.map(
      follows,
      (target) => {
        return (
          <tr key={`item-${target.id}`}>
            <td>
              <span>
                {target.client_name}{target.target_title && ` - ${target.target_title}`}
              </span>
            </td>
            <td className="following-buttons">
              <Button
                color="primary"
                size="sm"
                title={target.deleted ? 'Follow' : 'Unfollow'}
                onClick={() => {
                  if (target.deleted) {
                    createFollow(target);
                  } else {
                    deleteFollow(target.id);
                  }
                }}
              >
                {target.deleted ? 'Follow' : 'Unfollow'}
              </Button>
            </td>
          </tr>
        );
      },
    );

    return (
      <div>
        {_.isEmpty(follows) ?
          <div className="no-data">
            You aren&apos;t following anything.
          </div>
          :
          <div>
            <Table
              hover
              responsive
              striped
            >
              <thead>
                <tr>
                  <th colSpan="2">Following</th>
                </tr>
              </thead>
              <tbody>
                {tableItems}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}
