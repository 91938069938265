import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import './BackToSearch.scss';

@connect(({ search }) => ({
  searchResult: search.searchResult,
}))
export class BackToSearch extends Component {
  render () {
    const {
      searchResult,
    } = this.props;

    const showBackToSearch = !_.isEmpty(_.get(searchResult, 'results'), []);

    return (
      <div className="back-to-search">
        {showBackToSearch &&
          <div
            className="dark-header"
          >
            <div>
              { !_.isEmpty(_.get(searchResult, 'results'), []) &&
                <Link
                  className="search-return"
                  to="/search"
                >
                  <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                  />
                  Back to search results
                </Link>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}
