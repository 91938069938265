import { ReduxModule } from 'react-website';
import diff from 'node-htmldiff';
import cheerio from 'cheerio';
import * as _ from 'lodash';

import { RenderType } from './codes';
import { offsetTop } from '../utils/helpers';

const redux = new ReduxModule('SECTIONS');

export const Direction = {
  UNSET: 'unset',
  UP: 'up',
  DOWN: 'down',
};

const initialState = {
  origDocId: null,
  beforeIndex: -1,
  afterIndex: 1,
  currIndex: 0,
  loadingBefore: false,
  loadingAfter: false,
  loadingCurr: false,
  total: 0,
  items: { },
  itemDiffs: { },
  compareItemErrors: { },
  isSearching: false,
  jumpToDocId: null,
  firstBeforeDone: false,
};

export const initialize = redux.simpleAction(
  'INITIALIZE',
  (state, params) => ({
    ...state,
    loadingBefore: false,
    loadingAfter: false,
    items: { },
    itemDiffs: { },
    compareItemErrors: { },
    origDocId: params.origDocId,
    total: params.origDocTocCt,
    clientSlug: params.clientSlug,
    versionUUID: params.versionUUID,
    codeSlug: params.codeSlug,
    codeUUID: params.codeUUID,
    renderType: params.renderType,
    beforeIndex: params.origDocIdx - 1,
    currIndex: params.origDocIdx,
    afterIndex: params.origDocIdx + 1,
    isSearching: params.isSearching,
    jumpToDocId: null,
    firstBeforeDone: params.origDocIdx == 0,
  }),
);

export const clearCompare = redux.simpleAction(
  'CLEAR_COMPARE',
  (state) => ({
    ...state,
    itemDiffs: { },
    compareItemErrors: { },
  }),
);

export const loadBefore = redux.simpleAction(
  'LOAD_BEFORE',
  (state) => ({
    ...state,
    loadingBefore: true,
  })
);

export const loadAfter = redux.simpleAction(
  'LOAD_AFTER',
  (state) => ({
    ...state,
    loadingAfter: true,
  }),
);

const _sectionLoaded = (index, sections, compareOpen) => {
  const item = _.get(sections.items, index);
  if (item) {
    if (!compareOpen) {
      return true;
    } else {
      return _.has(sections.itemDiffs, item.doc_id);
    }
  }

  return false;
};

export const jumpTo = redux.simpleAction(
  'JUMP_TO',
  (state, { index, compareOpen }) => {
    if (!_sectionLoaded(index, state, compareOpen)) {
      return {
        ...state,
        beforeIndex: index - 1,
        currIndex: index,
        afterIndex: index + 1,
        loadingCurr: true,
        loadingBefore: false,
        loadingAfter: false,
        firstBeforeDone: false,
      };
    }

    let beforeIndex = index - 1;
    while (beforeIndex >= Math.max(index - 2, 0)) {
      if (!_sectionLoaded(beforeIndex, state, compareOpen)) {
        break;
      }
      beforeIndex = beforeIndex - 1;
    }

    let afterIndex = index + 1;
    while (afterIndex < Math.min(index + 2, state.total)) {
      if (!_sectionLoaded(afterIndex, state, compareOpen)) {
        break;
      }
      afterIndex = afterIndex + 1;
    }

    return {
      ...state,
      beforeIndex,
      currIndex: index,
      afterIndex,
    };
  },
);

export const renderSection = redux.action(
  'RENDER_SECTION',
  (params) => async (http) => {
    const {
      clientSlug,
      versionUUID,
      codeSlug,
      codeUUID,
      origDocId,
      origDocIdx,
      renderType,
    } = params;

    let urlPath = 'render-section/';
    if (renderType == RenderType.PREVIEW_VERSION) {
      urlPath = 'preview-version-section/';
    } else if (renderType == RenderType.PREVIEW_CODE) {
      urlPath = 'preview-code-section/';
    }

    if (clientSlug) {
      urlPath += `${clientSlug}/`;
    }

    let codeInstance;
    if (versionUUID) {
      codeInstance = `${versionUUID}/${codeSlug}`;
    } else {
      codeInstance = codeUUID;
    }

    return {
      item: await http.get(
        `/api/${urlPath}${codeInstance}/${encodeURIComponent(origDocId)}/${origDocIdx}/`
      ),
      origDocIdx,
    };
  },
  (state, { origDocIdx, item }) => ({
    ...state,
    items: {
      ...state.items,
      [origDocIdx]: item,
    },
  }),
);

export const renderDirectionDone = redux.simpleAction(
  'RENDER_DIRECTION_DONE',
  (state, direction) => {
    if (direction == Direction.UP) {
      return {
        ...state,
        firstBeforeDone: true,
        loadingBefore: false,
        beforeIndex: state.beforeIndex - 1,
      };
    } else if (direction == Direction.DOWN) {
      return {
        ...state,
        loadingAfter: false,
        afterIndex: state.afterIndex + 1,
      };
    } else {
      return {
        ...state,
        loadingCurr: false,
      };
    }
  },
);

export const setJumpToDocId = redux.simpleAction(
  'SET_JUMP_TO_DOC_ID',
  'jumpToDocId',
);

export const compareSection = redux.action(
  'COMPARE_SECTION',
  (clientSlug, version, codeSlug, docId, index) => async (http) => {
    let section;
    try {
      section = await http.get(
        `/api/render-doc/${clientSlug}/${version}/${codeSlug}/${encodeURIComponent(docId)}/`
      );
    } catch {
      // no-op
    }

    return {
      html: _.get(section, 'html', ''),
      index,
      docId,
    };
  },
  (state, { html, index, docId }) => {

    html = diff(
      html,
      _.get(state, `items.${index}.html`),
      null, // className
      null, // dataPrefix
      'iframe,object,math,svg,script,video,head,style,img', // atomicTags
    );

    html = cheerio.load(
      html,
      {
        xml: {
          xmlMode: true,
          decodeEntities: false,
        },
      },
    ).html();

    return {
      ...state,
      itemDiffs: {
        ...state.itemDiffs,
        [docId]: html,
      },
    };
  },
);

const _scrollToIndex = (index, sections) => {
  const { renderType, versionUUID, isSearching } = sections;

  if (typeof document === 'undefined') {
    return;
  }

  const isPreview = RenderType.isPreview(renderType);
  const isArchive = versionUUID != 'latest';
  const containerTop = isPreview || isArchive || isSearching ? 130 : 100;

  const scrollContainer = document.querySelector('.codenav__section-body');
  const el = document.querySelector(`#section-${index}`);
  if (el) {
    const newTop = offsetTop(el) - containerTop;
    scrollContainer.scrollTop = newTop;
  }
};

export const renderSectionFlow = async (index, direction, getState, dispatch, doAsync = false) => {
  const doRender = async () => {
    let alreadyLoaded = !!_.get(getState(), `sections.items.${index}`);

    if (!alreadyLoaded) {
      await dispatch(renderSection({
        ..._.pick(
          getState().sections,
          [
            'origDocId',
            'clientSlug',
            'versionUUID',
            'codeSlug',
            'codeUUID',
            'renderType',
          ],
        ),
        origDocIdx: index,
      }));
    }

    const { codes, sections } = getState();
    if (codes.compareOpen && codes.versionToCompare) {
      alreadyLoaded = !!_.get(sections.itemDiffs, `${index}.doc_id`);

      if (!alreadyLoaded) {
        await dispatch(compareSection(
          sections.clientSlug,
          codes.versionToCompare.uuid,
          sections.codeSlug,
          _.get(sections.items, `${index}.doc_id`),
          index,
        ));
      }
    }

    // finish loading
    await dispatch(renderDirectionDone(direction));

    if (direction == Direction.UNSET) {
      dispatch(setJumpToDocId(_.get(sections.items, `${index}.doc_id`)));

      _scrollToIndex(sections.currIndex, sections);
    }
  };

  if (doAsync) {
    setTimeout(doRender, 10);
  } else {
    await doRender();
  }
};

export const sectionsMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const { type } = action;
    const sections = getState().sections;
    const codes = getState().codes;
    let alreadyLoaded;

    if (type == 'SECTIONS: JUMP_TO') {
      const { index } = action.value;
      if (_sectionLoaded(index, sections, codes.compareOpen)) {
        dispatch(setJumpToDocId(_.get(sections.items, `${index}.doc_id`)));

        if (index > sections.beforeIndex && index < sections.afterIndex) {
          return;
        }

        return next(action);
      }
      renderSectionFlow(index, Direction.UNSET, getState, dispatch);
    } else if (type == 'SECTIONS: LOAD_BEFORE') {
      if (!sections.firstBeforeDone) {
        // Scroll to current section while previous section loads
        setTimeout(() => _scrollToIndex(sections.currIndex, sections), 10);
      }
      // Discard unwanted calls from infinite scroll
      if (sections.loadingBefore || sections.loadingCurr || sections.beforeIndex < 0) {
        return;
      }

      alreadyLoaded = _sectionLoaded(sections.beforeIndex, sections, codes.compareOpen);

      renderSectionFlow(
        sections.beforeIndex,
        Direction.UP,
        getState,
        dispatch,
      );

      // Avoid setting loadingBefore if nothing to load
      if (alreadyLoaded) {
        return;
      }
    } else if (type == 'SECTIONS: LOAD_AFTER') {
      // Discard unwanted calls from infinite scroll
      if (sections.loadingAfter || sections.loadingCurr || sections.afterIndex > (sections.total - 1)) {
        return;
      }
      renderSectionFlow(
        sections.afterIndex,
        Direction.DOWN,
        getState,
        dispatch,
      );

      alreadyLoaded = _sectionLoaded(sections.afterIndex, sections, codes.compareOpen);

      // Avoid setting loadingAfter if nothing to load
      if (alreadyLoaded) {
        return;
      }
    } else if (type == 'CODES: COMPARE_VERSION') {
      // Fetch compare section if we're already viewing a section
      if (
        action.value && codes.currClient &&
        codes.selectedCode && codes.currSection &&
        !codes.currSection.pdf_path
      ) {
        setTimeout(async () => {
          await dispatch(clearCompare());
          await dispatch(jumpTo({
            index: sections.currIndex,
            compareOpen: codes.compareOpen,
          }));
        }, 500);
      }
    }  else if (type == 'CODES: TOGGLE_COMPARE') {
      // We're turning off compare
      if (codes.compareOpen) {
        dispatch(clearCompare());
      }
    }

    // Calls reducer with action
    return next(action);
  };
};

export default redux.reducer(initialState);
