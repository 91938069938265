import React from 'react';
import { connect } from 'react-redux';
import { Link, meta, redirect } from 'react-website';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';
import * as _ from 'lodash';

import { logout } from '../../redux/account';
import { metaKey } from '../../utils/helpers';

import './SiteMap.scss';

@meta(() => ({ ...metaKey('title', 'American Legal Publishing Sitemap') }))
@connect(({ account }) => ({
  user: account.user,
}), { logout, redirect })
export default class SiteMap extends React.Component {
  render () {
    const {
      logout,
      redirect,
      user,
    } = this.props;

    const bookmarkList = (
      !!user && user.bookmarks.length > 0
        ? _.map(
          user.bookmarks,
          (aBookmark) => (
            <li
              className="bookmark-list__item"
              key={ `bookmark-${aBookmark.id}` }
            >
              <Link
                className="sitemap__bookmark-link"
                to={aBookmark.url}
              >
                {aBookmark.title}
              </Link>
            </li>
          ),
        )
        : <div className="text-center">No Bookmarks</div>
    );

    return (
      <div className="sitemap">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Link to="/sitemap">Sitemap</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="sitemap--content">
          <div>
            <h3>General</h3>
            <ul>
              <li>
                <Link to="/">American Legal Publishing Home</Link>
              </li>
              <li>
                <a href="https://www.amlegal.com/tutorials">Help Center</a>
              </li>
              <li>
                <Link to="/search">Code Search</Link>
              </li>
              <li>
                <Link to="/all-regions-sitemap">Browse States</Link>
              </li>
              <li>
                <Link to="/accessibility">Accessibility Statement</Link>
              </li>
            </ul>
          </div>
          { !user ?
            <div>
              <h3>Login / Sign-up</h3>
              <ul>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
                <li>
                  <Link to="/forgot-password">Forgot Password</Link>
                </li>
              </ul>
            </div>
            :
            <div>
              <h3>Manage Account</h3>
              <ul>
                <li>
                  <Link to="/account/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/account/bookmarks">Bookmarks</Link>
                </li>
                <li>
                  <Link to="/account/saved-searches">Saved Searches</Link>
                </li>
                <li>
                  <Link to="/account/following">Following</Link>
                </li>
                <li>
                  <Button
                    className="btn-link sitemap__btn-link"
                    onClick={async () => {
                      await logout();
                      redirect('/sitemap');
                    }}
                  >
                    Logout
                  </Button>
                </li>
              </ul>
            </div>
          }
          { !!user && !!user.bookmarks &&
            <div className="sitemap__bookmarks">
              <h3>Bookmarks</h3>
              <ul>
                { bookmarkList }
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}
