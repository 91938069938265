import * as _ from 'lodash';

// Based off axios & ckeditor example:
//
// https://github.com/axios/axios
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/upload-adapter.html
//
export class HttpUtil {
  constructor (options, mapResponse) {
    this.options = options;
    this.mapResponse = mapResponse || ((r) => r);
  }

  // Wrap xhr in promise
  send () {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the request.
  abort () {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object
  _initRequest () {
    // eslint-disable-next-line no-undef
    const xhr = this.xhr = new XMLHttpRequest();

    const {
      method,
      url,
    } = this.options;

    xhr.open(_.toUpper(method), url, true);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners (resolve, reject) {
    const xhr = this.xhr;
    const genericErrorText = 'Request failed';

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      // Handler errors
      if (!response || xhr.status >= 400) {
        return reject(response || genericErrorText);
      }

      resolve(this.mapResponse(response));
    });
  }

  // Prepares the data and sends the request.
  _sendRequest () {
    // eslint-disable-next-line no-undef
    const data = new FormData();

    for (let key in _.get(this.options, 'data', {})) {
      data.append(key, this.options.data[key]);
    }

    for (let key in _.get(this.options, 'headers', {})) {
      this.xhr.setRequestHeader(key, this.options.headers[key]);
    }

    // Send the request.
    this.xhr.send(data);
  }
}
