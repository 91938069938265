import React from 'react';
import { connect } from 'react-redux';

import { getCodeTheme } from '../../redux/codes';

@connect(({ codes }) => ({ theme: getCodeTheme(codes) }))
export class CodeFooter extends React.Component {

  render () {
    const { theme } = this.props;

    return (
      <div className="code-footer">
        {!theme.exclude_disclaimer &&
          <p>
            <b>Disclaimer:</b> {theme.disclaimer}
          </p>
        }
        <p>
          <b>Hosted by:</b> American Legal Publishing
        </p>
      </div>
    );
  }
}
