import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goto } from 'react-website';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

@connect(
  () => ({}),
  { goto },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class BackToSearchModal extends Component {
  state = { open: false }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    const { goto } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          No More Matches
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            You&apos;ve exhausted the search matches in this location. Would you like to go back to search results?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              this.toggle();
              goto('/search');
            }}
          >
            Back to Search Results
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
