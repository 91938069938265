import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-website';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faExternalLinkAlt,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import * as _ from 'lodash';

import LocationSelection from './LocationSelection';
import VersionIcon from '../../../assets/images/version.svg';
import { SearchSyntaxHelp } from '../../modals/SearchSyntaxHelp';
import {
  clearFilters,
  selectSavedSearch,
  updateFilters,
  getVersionDisplay,
  getPartialVersionSelected,
  getIsClientSearch,
} from '../../redux/search';
import { onEnterPressed } from '../../utils/helpers';

import './AdvancedSearch.scss';

@connect(({ account, search }) => ({
  getSearchResultsPending: search.getSearchResultsPending,
  savedSearches: search.savedSearches,
  filters: search.filters,
  getClientSearchDetailsPending: search.getClientSearchDetailsPending,
  searchDetails: search.searchDetails,
  versionDisplay: getVersionDisplay(search),
  partialVersionSelected: getPartialVersionSelected(search),
  isClientSearch: getIsClientSearch(search),
  user: account.user,
}), {
  clearFilters,
  selectSavedSearch,
  updateFilters,
})
export default class AdvancedSearch extends React.Component {
  static propTypes = {
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    toggleAdvanced: PropTypes.func,
  }

  constructor () {
    super();

    this.searchSyntaxHelp = React.createRef();
    this.advancedQueryChange = this.advancedQueryChange.bind(this);
    this.switchVersion = this.switchVersion.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.submitSearchOnEnter = onEnterPressed(this.submitSearch.bind(this));
    this.canDateFilter = this.canDateFilter.bind(this);
  }

  componentDidMount () {
    this.minDate = new Date('1850-01-01T00:00Z');
  }

  async advancedQueryChange (val, queryType) {
    const { updateFilters } = this.props;

    await updateFilters({[queryType]: val});
  }

  submitSearch (e) {
    e.preventDefault();
    const { onSearch } = this.props;

    onSearch();
  }

  canDateFilter () {
    const { filters, searchDetails } = this.props;

    return (
      _.get(filters, 'docType') == 'minutes' ||
      _.get(searchDetails, 'date_filtering_enabled', false)
    );
  }

  async switchVersion (version) {
    const {
      filters,
      updateFilters,
    } = this.props;

    const partialClient = _.find(
      filters.partialClients,
      (c) => c.slug == version.client_slug,
    );

    const newFilters = { version: version.uuid };

    if (partialClient) {
      // Remove partial
      newFilters.partialClients = _.filter(
        filters.partialClients,
        (c) => c.slug !== version.client_slug,
      );

      // Add client if region is not included
      if (!_.some(filters.includeRegions, ['slug', partialClient.region.slug])) {
        newFilters.includeClients = _.uniqBy(
          [
            ...filters.includeClients,
            _.pick(partialClient, ['name', 'slug', 'region.slug']),
          ],
          'slug',
        );
      }
    }

    await updateFilters(newFilters);
  }

  render () {
    const {
      canSearch,
      clearFilters,
      filters,
      getClientSearchDetailsPending,
      savedSearches,
      selectSavedSearch,
      searchDetails,
      updateFilters,
      user,
      versionDisplay,
      partialVersionSelected,
      isClientSearch,
    } = this.props;

    const savedSearchItems = _.map(
      savedSearches,
      (search) => (
        <DropdownItem
          key={`saved-search-${search.id}`}
          className="overflow-ellipsis"
          onClick={() => selectSavedSearch(search)}
        >
          {search.title}
        </DropdownItem>
      )
    );

    const versionItems = _.map(
      _.get(searchDetails, 'versions', []),
      (v, idx) => (
        <DropdownItem
          key={ `version-${_.snakeCase(v.uuid)}` }
          onClick={async () => await this.switchVersion(v)}
        >
          { v.name }{ idx == 0 && ' (current version)' }
        </DropdownItem>
      )
    );

    let docTypeDisplay = 'All';
    if (_.get(filters, 'docType') == 'codes') {
      docTypeDisplay = 'Codes Only';
    } else if (_.get(filters, 'docType') == 'minutes') {
      docTypeDisplay = 'Meeting Minutes Only';
    }

    const snippetItems = _.map(
      ['None', 'Small', 'Medium', 'Large'],
      (size) => (
        <DropdownItem
          key={ `snippet-${size}` }
          onClick={async () => await updateFilters({snippetSize: size})}
        >
          {size}
        </DropdownItem>
      )
    );

    return (
      <div className="advanced-search">
        <hr className="advanced-search__hr"/>
        <div className="advanced-filter__header">
          <span>Advanced Filters</span>
          <Button
            className="syntax-help"
            onClick={() => this.searchSyntaxHelp.current.toggle()}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt}/>
            Search syntax help
          </Button>
        </div>
        <div className="advanced-form">
          <div className="row">
            <div className={classnames('col-md-6 advanced-search__left', {
              'advanced-search__left__anonymous': !user,
              'advanced-search__left__user': user,
            })}>
              {!!user &&
                <div>
                  <div className="advanced-form__labels">Saved searches</div>
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        className="form-control"
                      >
                        Select a saved search (will override all search fields)
                      </DropdownToggle>
                      <DropdownMenu
                        className="roboto"
                      >
                        {
                          !!savedSearches
                            ? savedSearchItems
                            : <DropdownItem>No saved searches</DropdownItem>
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div>
                    <Link
                      className="manage-search btn btn-secondary"
                      to="/account/saved-searches"
                    >
                      <FontAwesomeIcon
                        color="#000000"
                        icon={faCog}
                        size="lg"
                      />
                      Manage Saved Searches
                    </Link>
                  </div>
                </div>
              }

              <div className="location-search">
                <div className="advanced-form__labels">Select locations to search</div>
                <LocationSelection/>
              </div>

              <div className="advanced-form__dropdowns">
                <div className="advanced-form__labels">
                  Select code version to search
                  { partialVersionSelected && ' (changing versions will clear scope)'}
                </div>
                <UncontrolledDropdown className="version-dropdown" tabIndex={0}>
                  <DropdownToggle
                    className="form-control overflow-ellipsis multi-version-dropdown"
                    style={{textAlign: 'left'}}
                    caret={(versionItems.length > 1)}
                    disabled={
                      _.get(searchDetails, 'versions.length', 0) == 0 ||
                      getClientSearchDetailsPending ||
                      (versionItems.length === 1) ||
                      _.get(filters, 'includeRegions.length', 0) > 0 ||
                      (
                        _.get(filters, 'includeClients.length', 0) +
                        _.get(filters, 'partialClients.length', 0)
                      ) > 1
                    }
                  >
                    <VersionIcon
                      fill="black"
                      className="version-icon"
                    />
                    { versionDisplay }
                  </DropdownToggle>
                  <DropdownMenu style={{ 'right': '0px'}}>
                    { versionItems }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="advanced-form__dropdowns">
                <div className="advanced-form__labels">Select ordering method</div>
                <UncontrolledDropdown className="snippet-size-dropdown">
                  <DropdownToggle
                    className="form-control overflow-ellipsis"
                    disabled={!isClientSearch}
                    caret
                  >
                    { _.get(filters, 'docOrder', false) ? 'Document Order' : 'Most Relevant' }
                  </DropdownToggle>
                  <DropdownMenu style={{ 'right': '0px'}}>
                    <DropdownItem onClick={async () => await updateFilters({ docOrder: false })}>
                      Most Relevant
                    </DropdownItem>
                    <DropdownItem onClick={async () => await updateFilters({ docOrder: true })}>
                      Document Order
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="advanced-form__dropdowns">
                <div className="advanced-form__labels">Select document types to include</div>
                <UncontrolledDropdown className="snippet-size-dropdown">
                  <DropdownToggle
                    className="form-control overflow-ellipsis"
                    caret
                  >
                    { docTypeDisplay }
                  </DropdownToggle>
                  <DropdownMenu style={{ 'right': '0px'}}>
                    <DropdownItem onClick={async () => await updateFilters({ docType: 'all' })}>
                      All
                    </DropdownItem>
                    <DropdownItem
                      onClick={async () => await updateFilters({ docType: 'codes' })}
                      disabled={searchDetails && searchDetails.versions.length == 0}
                    >
                      Codes Only
                    </DropdownItem>
                    <DropdownItem
                      onClick={async () => await updateFilters({ docType: 'minutes' })}
                      disabled={(
                        (searchDetails && searchDetails.minutes_ct == 0) ||
                        _.get(filters, 'version') != 'latest'
                      )}
                    >
                      Meeting Minutes Only
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="advanced-form__dropdowns">
                <div className="advanced-form__labels">Show document excerpts in the results list</div>
                <UncontrolledDropdown className="snippet-size-dropdown">
                  <DropdownToggle
                    className="form-control overflow-ellipsis"
                    caret
                  >
                    { _.get(filters, 'snippetSize') }
                  </DropdownToggle>
                  <DropdownMenu style={{ 'right': '0px'}}>
                    { snippetItems }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div
                className="advanced-form__dropdowns row"
                style={{display: this.canDateFilter() ? 'flex' : 'none'}}
              >
                <div className="col">
                  <div className="advanced-form__labels">Start Date</div>
                  <DatePicker
                    selected={_.get(filters, 'startDate')}
                    onChange={(date) => this.advancedQueryChange(date, 'startDate')}
                    customInput={<Input />}
                    placeholderText="Click to select date"
                    maxDate={new Date()}
                    minDate={this.minDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable
                  />
                </div>
                <div className="col">
                  <div className="advanced-form__labels">End Date</div>
                  <DatePicker
                    selected={_.get(filters, 'endDate')}
                    onChange={(date) => this.advancedQueryChange(date, 'endDate')}
                    customInput={<Input />}
                    placeholderText="Click to select date"
                    maxDate={new Date()}
                    minDate={this.minDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable
                  />
                </div>
              </div>
              <div className="advanced-form__checkbox">
                <Input
                  id="stemming"
                  type="checkbox"
                  checked={ _.get(filters, 'stemming') }
                  onChange={() => this.advancedQueryChange(!(_.get(filters, 'stemming')), 'stemming')}
                />
                <Label for="stemming" check>Find alternate word forms (stemming)</Label>
              </div>
              <div className="advanced-form__checkbox">
                <Input
                  id="synonyms"
                  type="checkbox"
                  checked={ _.get(filters, 'synonyms') }
                  onChange={() => this.advancedQueryChange(!(_.get(filters, 'synonyms')), 'synonyms')}
                />
                <Label for="synonyms" check>Find synonyms (thesaurus)</Label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="advanced-form__query-filters__header row">
                Search for documents containing:
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="title-label"
                  className="advanced-form__labels"
                >
                  Search <b>{ _.toLower(_.get(searchDetails, 'title_terminology', 'heading')) }</b> only
                </div>
                <Input
                  name="title"
                  aria-labelledby="title-label"
                  type="text"
                  placeholder={_.get(searchDetails, 'title_terminology', 'Heading')}
                  value={_.get(filters, 'title')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'title')}
                />
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="allwords-label"
                  className="advanced-form__labels"
                >
                  <b>All</b> of these words
                </div>
                <Input
                  name="allWords"
                  aria-labelledby="allwords-label"
                  type="text"
                  placeholder="Required words"
                  value={_.get(filters, 'allWords')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'allWords')}
                />
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="nowords-label"
                  className="advanced-form__labels"
                >
                  <b>None</b> of these words
                </div>
                <Input
                  name="noWords"
                  aria-labelledby="nowords-label"
                  type="text"
                  placeholder="Exclude words"
                  value={_.get(filters, 'noWords')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'noWords')}
                />
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="oneormore-label"
                  className="advanced-form__labels"
                >
                  <b>One or more</b> of these words
                </div>
                <Input
                  name="oneOrMore"
                  aria-labelledby="oneormore-label"
                  type="text"
                  placeholder="One or more of these words"
                  value={_.get(filters, 'oneOrMore')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'oneOrMore')}
                />
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="exactphrase-label"
                  className="advanced-form__labels"
                >
                  This <b>exact phrase</b>
                </div>
                <Input
                  name="exactPhrase"
                  aria-labelledby="exactphrase-label"
                  type="text"
                  placeholder="Exact phrase"
                  value={_.get(filters, 'exactPhrase')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'exactPhrase')}
                />
              </div>
              <div className="advanced-form__query-filters">
                <div
                  id="near-label"
                  className="advanced-form__labels"
                >
                  These words <b>near each other</b>
                </div>
                <Input
                  name="nearEachOther"
                  aria-labelledby="near-label"
                  type="text"
                  placeholder="Word proximity"
                  value={_.get(filters, 'nearEachOther')}
                  onKeyDown={this.submitSearchOnEnter}
                  onChange={(e) => this.advancedQueryChange(e.target.value, 'nearEachOther')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="search__buttons">
          <div>
            <Button onClick={clearFilters}>
              <FontAwesomeIcon
                color="#000000"
                icon={faTimesCircle}
                size="lg"
              />
              Reset Advanced Filters
            </Button>
          </div>
          <Button
            type="submit"
            color="success"
            disabled={!canSearch}
            onClick={this.submitSearch}
          >
            Search
          </Button>
        </div>
        <SearchSyntaxHelp ref={this.searchSyntaxHelp} />
      </div>
    );
  }
}

