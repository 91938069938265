import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, meta, preload } from 'react-website';
import * as _ from 'lodash';

import { getClientRegions } from '../../redux/clients';
import { metaKey } from '../../utils/helpers';

@meta(() => ({ ...metaKey('title', 'Browse States Sitemap') }))
@preload(async ({ dispatch }) => {
  await dispatch(getClientRegions());
})
@connect(({ clients }) => ({
  clientRegions: clients.clientRegions,
}))
export default class AllRegionsSiteMap extends Component {
  render () {
    const { clientRegions } = this.props;

    const regionSiteMapItems = _.map(
      clientRegions,
      (region) => {
        return (
          <li key={`sitemap-${region.slug}`}>
            <Link to={`/region-clients-sitemap/${region.slug}`}>
              {region.name}
            </Link>
          </li>
        );
      }
    );

    return (
      <div className="sitemap all-regions-sitemap">
        <ul>
          <h3>States</h3>
          { regionSiteMapItems }
        </ul>
      </div>
    );
  }
}
