import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { ScopeInput } from '../components/ScopeInput';
import {
  getClientVersion,
  clearClientVersion,
  toggleCodeSelect,
  toggleTocSelect,
} from '../redux/codes';
import { getLocalSearchCtx } from '../redux/search';

@connect(
  ({ codes, search }) => ({
    clientVersion: codes.clientVersion,
    getClientVersionPending: codes.getClientVersionPending,
    getClientVersionError: codes.getClientVersionError,
    settingScope: codes.settingScope,
    localSearchCtx: getLocalSearchCtx(search),
  }),
  {
    getClientVersion,
    clearClientVersion,
    toggleCodeSelect,
    toggleTocSelect,

  },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class RefineSearchModal extends Component {
  state = {
    open: false,
    client: null,
  }

  constructor () {
    super();

    this.scopeInput = React.createRef();

    this.saveScope = this.saveScope.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (versionStr, client) {
    const {
      getClientVersion,
      clearClientVersion,
      localSearchCtx,
    } = this.props;

    await this.setState({ client });

    await this.toggle();
    await clearClientVersion();

    const scopeStr = _.get(client, 'scope', 'null');
    getClientVersion({
      clientSlug: client.slug,
      version: versionStr,
      searchCtx: scopeStr != 'null' ? localSearchCtx : null,
      scope: JSON.parse(scopeStr),
    });
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));
  }

  async saveScope () {
    const { clientVersion } = this.props;
    const { client } = this.state;

    let scope = this.scopeInput.current.wrappedInstance.getScope();

    const wholeVersion = _.every(
      clientVersion.toc,
      (code) => !!_.find(
        scope,
        (entry) => (
          entry.code_slug == code.slug &&
          !_.has(entry, 'doc_id') &&
          !_.has(entry, 'record_id')
        ),
      ),
    );

    _.invoke(
      this.props,
      'onSave',
      client,
      wholeVersion ? null : scope,
    );

    this.toggle();
  }

  render () {
    const {
      clientVersion,
      getClientVersionPending,
      getClientVersionError,
      settingScope,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          {_.get(this.state, 'client.name')}
        </ModalHeader>
        <ModalBody>
          {
            (!clientVersion || getClientVersionPending || settingScope)
              ? (
                <div style={{textAlign: 'center'}}>
                  {
                    getClientVersionError
                      ? <div>Failed to load version</div>
                      : (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )
                  }
                </div>
              )
              : (

                <div>
                  <p className="text-center">
                    Select which sections you would like to search.
                  </p>
                  <ScopeInput
                    ref={this.scopeInput}
                    version={clientVersion}
                    clearOnMount={false}
                    pdfEnabled={true}
                  />
                </div>
              )
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={this.saveScope}
            disabled={getClientVersionPending}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
