import React, { Component } from 'react';
import { Link, meta, preload } from 'react-website';

import { verifyEmail } from '../../redux/account';
import { metaKey } from '../../utils/helpers';

import './Auth.scss';

@preload(async ({ dispatch, params }) => {
  await dispatch(verifyEmail(params.key));
})
@meta(() => ({ ...metaKey('title', 'Verify Email') }))
export default class VerifyEmail extends Component {
  render () {
    return (
      <div>
        <h3 className="secondary-header">Email Verification</h3>
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              Email Successfully Verified
            </div>
            <div className="am-page__pane">
              <p className="text-center">
                Thank you for verifying your email address. You can now
                <Link
                  to="/login"
                  style={{ marginLeft: '.3125rem'}}
                >
                    login
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
