import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goto } from 'react-website';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { createClient } from '../redux/admin';

@connect(
  ({ admin, clients }) => ({
    createClientPending: admin.createClientPending,
    createClientError: admin.createClientError,
    allClientRegions: clients.allClientRegions,
  }),
  { createClient, goto },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class CreateClientModal extends StatefulForm {
  static propTypes = {
    createClientPending : PropTypes.bool,
    createClientError : PropTypes.object,
  }
  state = {
    open: false,
    firstGoDone: false,
    formValue: {
      name: '',
      slug: '',
    },
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
    this.nameInput = React.createRef();
  }

  async toggle () {
    if (!this.state.open) {
      // Focus is ignored without delay
      setTimeout(() => this.nameInput.current.focus(), 100);

      this.setState({
        formValue: {
          name: '',
          slug: '',
        },
        firstGoDone: false,
      });
    }

    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      createClient,
      goto,
    } = this.props;

    this.setState({firstGoDone: true});

    await createClient(this.state.formValue);

    if (!_.get(this.props, 'createClientError')) {
      await this.setState({open: false});
      await goto(`/admin/${this.state.formValue.slug}/splash`);
    }
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const {
      allClientRegions,
      createClientPending,
      createClientError,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Create Client</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Input client details and click Save.
          </p>
          <FormGroup>
            <Input
              autoComplete="off"
              innerRef={this.nameInput}
              name="name"
              type="text"
              placeholder="Client Name"
              value={ formValue.name }
              invalid={ firstGoDone && _.has(createClientError, 'name') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createClientError, 'name') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              autoComplete="off"
              name="slug"
              type="text"
              placeholder="Client Slug"
              value={ formValue.slug }
              invalid={ firstGoDone && _.has(createClientError, 'slug') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createClientError, 'slug') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              name="region"
              type="select"
              value={ formValue.region }
              invalid={ firstGoDone && _.has(createClientError, 'region') }
              onChange={ this.handleChange }
            >
              <option value="">-- Select State --</option>
              {_.map(
                allClientRegions,
                (r) => (
                  <option
                    key={`region-${r.slug}`}
                    value={r.slug}
                  >
                    {r.name}
                  </option>
                ),
              )}
            </Input>
            <FormFeedback role="alert">
              { _.get(createClientError, 'region') }
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={ createClientPending }
            onClick={this.submitForm}
          >
            {
              createClientPending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
