import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  FormFeedback,
  Input,
} from 'reactstrap';
import * as _ from 'lodash';

import {
  updateAnnotation,
  deleteAnnotation,
} from '../../redux/annotations';
import { StatefulForm } from '../../utils/StatefulForm';
import './Annotation.scss';

@connect(({ annotations }) => {
  return {
    updateAnnotationPending: annotations.updateAnnotationPending,
    updateAnnotationError: annotations.updateAnnotationError,
    deleteAnnotationPending: annotations.deleteAnnotationPending,
  };
}, { updateAnnotation, deleteAnnotation })
export class Annotation extends StatefulForm {
  static propTypes = {
    annotation : PropTypes.object,
  }
  state = {
    editMode: false,
    deleteMode: false,
  }

  constructor (props) {
    super(props);

    this.updateAnnotation = this.updateAnnotation.bind(this);
    this.deleteAnnotation = this.deleteAnnotation.bind(this);
  }

  async updateAnnotation () {
    const {
      annotation,
      updateAnnotation,
    } = this.props;

    await updateAnnotation({
      id: annotation.id,
      note: this.state.formValue.note,
    });
    await this.setState({ editMode: false });
  }

  async deleteAnnotation () {
    const {
      annotation,
      deleteAnnotation,
    } = this.props;

    await deleteAnnotation(annotation);
  }

  render () {
    const {
      annotation,
      updateAnnotationPending,
      updateAnnotationError,
      deleteAnnotationPending,
    } = this.props;
    const {
      formValue,
      editMode,
      deleteMode,
    } = this.state;

    return (
      <div className="annotation">
        <div className="annotation__header">
          <span className="annotation__author overflow-ellipsis">
            {annotation.author.name}
          </span>
          <span className="annotation__date">
            {annotation.created.toLocaleDateString()}
          </span>
          <span className="annotation__buttons">
            <Button
              aria-label="Edit annotation"
              color="link"
              title="Edit"
              disabled={editMode || deleteMode}
              onClick={() => this.setState({
                editMode: true,
                formValue: _.pick(annotation, ['note']),
              })}
            >
              <FontAwesomeIcon
                icon={faEdit}
                size="xs"
              />
            </Button>
            <Button
              aria-label="Delete annotation"
              color="link"
              title="Delete"
              disabled={editMode || deleteMode}
              onClick={() => this.setState({deleteMode: true})}
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                size="xs"
              />
            </Button>
          </span>
        </div>
        {
          editMode &&
          <div>
            <Input
              name="note"
              aria-label="Note"
              type="textarea"
              placeholder="Edit annotation..."
              value={ formValue.note }
              disabled={updateAnnotationPending}
              invalid={ _.has(updateAnnotationError, 'note') }
              onChange={this.handleChange}
            />
            <FormFeedback role="alert">
              { _.get(updateAnnotationError, 'note') }
            </FormFeedback>
            <div className="annotation__edit-buttons">
              <Button
                size="sm"
                onClick={() => this.setState({editMode: false})}
                disabled={updateAnnotationPending}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="primary"
                onClick={this.updateAnnotation}
                disabled={(
                  updateAnnotationPending ||
                  !formValue.note ||
                  formValue.note == annotation.note
                )}
              >
                {
                  updateAnnotationPending &&
                  <div
                    className="spinner-border"
                    role="status"
                    style={{height: '1rem', width: '1rem'}}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                }
                Save
              </Button>
            </div>
          </div>
        }
        {
          deleteMode &&
          <div>
            <div
              className="text-center"
              style={{padding: '0 1rem'}}
            >
              Are you sure you want to delete this annotation?
            </div>
            <div className="annotation__delete-buttons">
              <Button
                size="sm"
                onClick={() => this.setState({deleteMode: false})}
                disabled={deleteAnnotationPending}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="danger"
                onClick={this.deleteAnnotation}
                disabled={deleteAnnotationPending}
              >
                {
                  deleteAnnotationPending &&
                  <div
                    className="spinner-border"
                    role="status"
                    style={{height: '1rem', width: '1rem'}}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                }
                Delete
              </Button>
            </div>
          </div>
        }
        {
          !editMode && !deleteMode &&
          <p>{annotation.note}</p>
        }
      </div>
    );
  }
}
