import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, goto, preload, redirect } from 'react-website';
import {
  Nav,
  NavItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import ClientDropdown from '../../components/ClientDropdown';
import { getAllClientRegions } from '../../redux/clients';
import './Admin.scss';

import { clearClient } from '../../redux/admin';
import { clearSearch } from '../../redux/search';

@preload(async ({ dispatch, getState }) => {
  const { account, found } = getState();
  if (!account.user) {
    await dispatch(redirect(`/login?next=${_.get(found, 'match.location.pathname')}`));

    return;
  } else if (!_.get(account, 'user.is_superuser')) {
    await dispatch(redirect('/'));

    return;
  }

  await dispatch(getAllClientRegions());
})
@connect(({ admin }) => ({
  currClient: admin.currClient,
  switchClientPending: admin.switchClientPending,
}), {
  goto,
  clearClient,
  clearSearch,
  redirect,
})
export default class Admin extends React.Component {
  static propTypes = {
    children : PropTypes.node.isRequired,
  }

  constructor () {
    super();

    this.tabUrl = this.tabUrl.bind(this);
  }

  componentDidMount () {
    this.props.clearSearch();
  }

  tabUrl (page) {
    const { match } = this.props;

    return `/admin/${match.params.clientslug}/${page}`;
  }

  render () {
    const {
      children,
      currClient,
      location,
      switchClientPending,
    } = this.props;

    const isMinutes = location.pathname == this.tabUrl('minutes');

    const tabs = !!currClient && (
      <Nav tabs>
        <NavItem>
          <Link
            to={this.tabUrl('splash')}
            className={classnames('nav-link', { active: location.pathname == this.tabUrl('splash') })}
          >
            Splash Page
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={this.tabUrl('customize')}
            className={classnames('nav-link', { active: location.pathname == this.tabUrl('customize') })}
          >
            Customize
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={this.tabUrl('manage')}
            className={classnames('nav-link', { active: location.pathname == this.tabUrl('manage') })}
          >
            Codes
          </Link>
        </NavItem>
        <NavItem>
          <Link
            to={this.tabUrl('minutes')}
            className={classnames('nav-link', { active: isMinutes })}
          >
            Meeting Minutes
          </Link>
        </NavItem>
      </Nav>
    );

    return (
      <div className="admin-page">
        <h3 className="secondary-header">Admin</h3>
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              <ClientDropdown location={location}/>
              <div className="tabs-wrapper">
                { tabs }
                {
                  currClient &&
                  <a
                    aria-label={`Overview of ${currClient.slug} code. Opens client code in a new tab`}
                    href={`/codes/${currClient.slug}/latest/overview`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLinkSquareAlt}
                    />
                    Go to Client
                  </a>
                }
              </div>
            </div>
            <div className="am-page__pane">
              {
                switchClientPending && (
                  <div className="no-data">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )
              }
              <div style={{display: switchClientPending ? 'none' : 'block'}}>
                { children }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
