import { ReduxModule } from 'react-website';
import { createSelector } from 'reselect';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';
import * as pako from 'pako';

import { logFirebaseEvent } from './analytics';

export const PAGE_SIZE = 10;
const LAST_DOC_ORDER = '_alp_last_doc_order';

// "Sleep" using `Promise`
const delay = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const redux = new ReduxModule('SEARCH');

const initialState = {
  clients: [],
  currClientPage: 0,
  currPage: 0,
  hasMoreClients: true,
  lastSearchText: null,
  searchClient: '',
  savedSearches: null,
  searchResult: null,
  searchContext: null,
  savedSearchApplied: false,
  searchDetails: null,
  showTranslateDisclaimer: false,
  filters: {
    includeClients: [],
    excludeClients: [],
    partialClients: [],
    includeRegions: [],
    excludeRegions: [],
    docOrder: false,
    title: '',
    allWords: '',
    exactPhrase: '',
    nearEachOther: '',
    noWords: '',
    oneOrMore: '',
    searchText: '',
    docType: 'all',
    snippetSize: 'Medium',
    stemming: true,
    synonyms: false,
    version: 'latest',
    startDate: null,
    endDate: null,
  },
};

const stripUrlDomain = (url) => {
  const l = document.createElement('a');
  l.href = url;

  let strippedUrl = '';
  if (l.pathname && l.search) {
    strippedUrl = l.pathname + l.search;
  }

  return strippedUrl;
};

export const compressSearch = (params) => {
  params = {
    ...params,
    ..._.mapValues(
      _.pick(params, ['startDate', 'endDate']),
      (date) => {
        if (date) {
          // Adjust local dates to start of day in UTC
          const tzOffset = date.getTimezoneOffset();
          const utc =  new Date(date.getTime() - (tzOffset * 60 * 1000));

          return utc.toISOString();
        }

        return date;
      },
    ),
  };

  const compressedZlib = pako.deflate(
    JSON.stringify(params),
    { to: 'string' },
  );

  // We use this instead of btoa() to allow for server-side execution
  return Buffer.from(compressedZlib, 'binary').toString('base64');
};

export const decompressSearch = (searchCtx) => {
  // We use this instead of atob() to allow for server-side execution
  const decoded = Buffer.from(searchCtx, 'base64').toString('binary');
  let uncompressed = pako.inflate(decoded, { to: 'string' });
  uncompressed = JSON.parse(uncompressed);

  return {
    ...uncompressed,
    ..._.mapValues(
      _.pick(uncompressed, ['startDate', 'endDate']),
      (utc_iso) => {
        if (utc_iso) {
          // Add tz offset
          const date = new Date(utc_iso);
          const tzOffset = date.getTimezoneOffset();

          return new Date(date.getTime() + (tzOffset * 60 * 1000));
        }

        return utc_iso;
      },
    ),
  };
};

export const clearSearch = redux.simpleAction(
  'CLEAR_SEARCH',
  (state, searchClient = null) => {
    const newState = _.cloneDeep(initialState);

    if (searchClient) {
      _.set(
        newState,
        'filters.includeClients',
        [_.pick(searchClient, ['name', 'slug', 'version_ct', 'region.slug'])],
      );
    }

    return {
      ...newState,
      savedSearches: state.savedSearches,
      searchClient,
    };
  }
);

export const clearFilters = redux.simpleAction(
  'CLEAR_FILTERS',
  (state) => ({
    ...state,
    savedSearchApplied: false,
    searchDetails: null,
    filters: {
      ...state.filters,
      ..._.omit(initialState.filters, 'searchText'),
      includeClients: state.searchClient
        ? [_.pick(state.searchClient, ['name', 'slug', 'version_ct', 'region.slug'])]
        : [],
    },
  })
);

export const getSearchResults = redux.action(
  'GET_SEARCH_RESULTS',
  (params) => async (http) => {
    const {
      currPage,
      searchCtx,
    } = params;

    const offset = (currPage) * PAGE_SIZE;
    const limit = PAGE_SIZE;

    return {
      response: await http.get(
        `/api/search/?s=${encodeURIComponent(searchCtx)}&offset=${offset}&limit=${limit}`,
      ),
      currPage,
      searchCtx,
    };
  },
  (state, payload) => {
    const lastSearchText = decompressSearch(payload.searchCtx).searchText;

    return ({
      ...state,
      currPage: payload.currPage,
      nextUrl: payload.next,
      searchResult: {
        ...payload.response,
        nextUrl: stripUrlDomain(payload.response.next),
        previousUrl: stripUrlDomain(payload.response.previous),
      },
      searchContext: payload.searchCtx,
      lastSearchText,
      getSearchResultsError: null,
      prevSearchResultsError: null,
      nextSearchResultsError: null,
    });
  }
);

export const prevSearchResults = redux.action(
  'PREV_SEARCH_RESULTS',
  (searchUrl) => async (http) => {
    return {
      response: await http.get(`${searchUrl}`),
    };
  },
  (state, payload) => {
    return ({
      ...state,
      searchResult: {
        ...payload.response,
        nextUrl: stripUrlDomain(payload.response.next),
        previousUrl: stripUrlDomain(payload.response.previous),
      },
      currPage: state.currPage - 1,
      getSearchResultsError: null,
      prevSearchResultsError: null,
      nextSearchResultsError: null,
    });
  }
);

export const nextSearchResults = redux.action(
  'NEXT_SEARCH_RESULTS',
  (searchUrl) => async (http) => {
    return {
      response: await http.get(`${searchUrl}`),
    };
  },
  (state, payload) => {
    return ({
      ...state,
      searchResult: {
        ...payload.response,
        nextUrl: stripUrlDomain(payload.response.next),
        previousUrl: stripUrlDomain(payload.response.previous),
      },
      currPage: state.currPage + 1,
      getSearchResultsError: null,
      prevSearchResultsError: null,
      nextSearchResultsError: null,
    });
  }
);

export const clearSearchResults = redux.simpleAction(
  'CLEAR_SEARCH_RESULTS',
  (state) => {
    return {
      ...state,
      searchResult: null,
      searchContext: null,
    };
  },
);

// SAVED SEARCHES
export const getSavedSearches = redux.action(
  'GET_SAVED_SEARCHES',
  () => async (http) => await http.get('/api/saved-search/'),
  'savedSearches',
);

export const createSavedSearch = redux.action(
  'CREATE_SAVED_SEARCH',
  (params) => async (http) => await http.post('/api/saved-search/', params),
  (state, result) => ({
    ...state,
    savedSearches: [
      ...state.savedSearches,
      result,
    ],
  })
);

export const clearSaveError = redux.simpleAction(
  'CLEAR_SAVE_ERROR',
  (state) => {
    return {
      ..._.omit(state, 'createSavedSearchError'),
    };
  },
);

export const updateSavedSearch = redux.action(
  'UPDATE_SAVED_SEARCH',
  (savedSearch) => async (http) => await http.patch(`/api/saved-search/${savedSearch.id}/`, savedSearch),
  (state, result) => {
    const newSavedSearches = _.cloneDeep(state.savedSearches);
    const searchIdx = _.findIndex(newSavedSearches, (s) => s.id === result.id);

    if (searchIdx >= 0) {
      newSavedSearches.splice(searchIdx, 1, result);
    }

    return {
      ...state,
      savedSearches: newSavedSearches,
    };
  }
);

export const selectSavedSearch = redux.simpleAction(
  'SELECT_SAVED_SEARCH',
  (state, savedSearch) => ({
    ...state,
    savedSearchApplied: true,
    filters: {
      ..._.cloneDeep(initialState.filters),
      ...decompressSearch(savedSearch.search_context),
    },
  }),
);

export const deleteSavedSearch = redux.action(
  'DELETE_SAVED_SEARCH',
  (id) => async (http) => {
    await http.delete(`/api/saved-search/${id}/`);

    return id;
  },
  (state, deleted_id) => ({
    ...state,
    savedSearches: _.filter(state.savedSearches, (s) => s.id !== deleted_id),
  }),
);

// CLIENTS AND VERSIONS
export const getClients = redux.action(
  'GET_CLIENTS',
  (params) => async (http) => {
    const tmpParams = {
      limit: PAGE_SIZE,
      offset: (params.page - 1) * PAGE_SIZE,
      search: encodeURIComponent(_.trim(params.search)),
      clientSlug: params.clientSlug,
    };
    const query = _.map(
      _.omitBy(_.keys(tmpParams), (k) => !tmpParams[k]),
      (k) => `${k}=${tmpParams[k]}`,
    ).join('&');

    await delay(300);

    return {
      response: await http.get(`/api/clients-search/?${query}`),
      page: params.page,
    };
  },
  (state, payload) => ({
    ...state,
    hasMoreClients: !!payload.response.next,
    clients: [
      ...(!payload.response.previous ? [] : state.clients),
      ...payload.response.results,
    ],
    currClientPage: payload.page,
  }),
);

export const getClientSearchDetails = redux.action(
  'GET_CLIENT_SEARCH_DETAILS',
  (clientSlug) => async (http) => {
    return await http.get(`/api/clients-search/${clientSlug}/`);
  },
  (state, details) => {

    const version = _.find(
      details.versions,
      (v) => v.uuid == state.filters.version,
    );

    if (details.versions.length) {
      details.versions[0].uuid = 'latest';
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        // Default to latest if version has been deleted
        version: _.get(version, 'uuid', 'latest'),
      },
      searchDetails: details,
    };
  },
);

export const clearClientSearchDetails = redux.simpleAction(
  'CLEAR_CLIENT_SEARCH_DETAILS',
  (state) => ({
    ...state,
    searchDetails: null,
    filters: {
      ...state.filters,
      version: 'latest',
    },
  }),
);

export const startClientSearch = redux.simpleAction(
  'START_CLIENT_SEARCH',
  (state) => ({
    ...state,
    clients: [],
    currClientPage: 0,
    hasMoreClients: true,
  }),
);

const sortFilters = (filters) => {
  return {
    ...filters,
    includeClients: _.sortBy(_.get(filters, 'includeClients', []), ['slug']),
    excludeClients: _.sortBy(_.get(filters, 'excludeClients', []), ['slug']),
    partialClients: _.sortBy(_.get(filters, 'partialClients', []), ['slug']),
    includeRegions: _.sortBy(_.get(filters, 'includeRegions', []), ['slug']),
    excludeRegions: _.sortBy(_.get(filters, 'excludeRegions', []), ['slug']),
  };
};

export const getIsClientSearch = createSelector(
  (state) => state.filters,
  (filters) => {
    const includeRegions = _.get(filters, 'includeRegions');
    const includeClients = _.get(filters, 'includeClients');
    const partialClients = _.get(filters, 'partialClients');

    const clientSlugs = _.uniq(_.map(
      [
        ...includeClients,
        ...partialClients,
      ],
      'slug',
    ));

    return includeRegions.length == 0 && clientSlugs.length == 1;
  },
);

const needSearchDetails = (search) => {
  const includeRegions = _.get(search, 'filters.includeRegions');
  const includeClients = _.get(search, 'filters.includeClients');
  const partialClients = _.get(search, 'filters.partialClients');

  return (
    includeRegions.length == 0 &&
    (includeClients.length + partialClients.length) == 1
  );
};

export const updateFilters = redux.simpleAction(
  'UPDATE_FILTERS',
  (state, newValues) => {
    const newFilters = {
      ...state.filters,
      ...newValues,
    };

    if (
      newFilters.version != 'latest' &&
      newFilters.docType == 'minutes'
    ) {
      // Reset doc type if selecting archived version
      newFilters.docType = 'all';
    }

    if (
      newFilters.docType != 'minutes' &&
      !_.get(state.searchDetails, 'date_filtering_enabled', false)
    ) {
      // Clear dates if no longer allowed
      newFilters.startDate = null;
      newFilters.endDate = null;
    }

    if (!_.has(newValues, 'docOrder')) {
      if (!needSearchDetails({ filters: newFilters })) {
        newFilters.docOrder = false;
      } else {
        newFilters.docOrder = new Cookies().get(LAST_DOC_ORDER) == 'true';
      }
    }

    return {
      ...state,
      savedSearchApplied: false,
      filters: sortFilters(newFilters),
    };
  },
);

export const getLocations = redux.action(
  'GET_LOCATIONS',
  () => async (http) => await http.get('/api/saved-search/'),
  'savedSearches',
);

export const setShowTranslateDiclaimer = redux.simpleAction(
  'SET_SHOW_TRANSLATE_DISCLAIMER',
  (state, show) => ({
    ...state,
    showTranslateDisclaimer: show,
  }),
);

export const getLocalSearchCtx = createSelector(
  (state) => state.filters,
  (filters) => compressSearch(filters),
);

export const getFiltersOn = createSelector(
  (state) => state.filters,
  (filters) => (
    !_.isEmpty(filters.includeClients) ||
    !_.isEmpty(filters.includeRegions)
  ),
);

export const getVersionDisplay = createSelector(
  (state) => state.filters.includeClients,
  (state) => state.filters.partialClients,
  (state) => state.filters.includeRegions,
  (state) => state.filters.version,
  (state) => _.get(state, 'searchDetails.versions'),
  (includeClients, partialClients, includeRegions, version_str, versions) => {
    if (
      !!versions && !!version_str &&
      (includeClients.length + partialClients.length) == 1 && includeRegions.length == 0
    ) {
      const version = _.find(versions, (v) => v.uuid == version_str) || _.get(versions, '[0]');

      if (!_.get(version, 'name')) {
        return 'No versions';
      }
      
      return `${version.name}${version.uuid == 'latest' ? ' (current version)': ''}`;
    }

    return 'Version search not available with multiple locations';
  },
);

export const getSearchTextValid = createSelector(
  (state) => state.filters,
  (filters) => _.some(
    _.values(_.pick(
      filters,
      [
        'searchText',
        'title',
        'allWords',
        'oneOrMore',
        'exactPhrase',
        'nearEachOther',
        'noWords',
        'startDate',
        'endDate',
      ],
    )),
    Boolean,
  ),
);

export const getPartialVersionSelected = createSelector(
  (state) => state.filters.includeClients,
  (state) => state.filters.partialClients,
  (state) => state.filters.includeRegions,
  (includeClients, partialClients, includeRegions) => (
    includeRegions.length == 0 &&
    includeClients.length == 0 &&
    partialClients.length == 1
  ),
);

export const getSearchError = createSelector(
  (state) => state.getSearchResultsError,
  (state) => state.prevSearchResultsError,
  (state) => state.nextSearchResultsError,
  (searchError, nextPageError, prevPageError) => {
    return searchError || nextPageError || prevPageError;
  }
);

export const searchMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const { type } = action;

    if (
      type == 'SEARCH: SELECT_SAVED_SEARCH' ||
      (
        type == 'SEARCH: UPDATE_FILTERS' &&
        (
          _.has(action.value, 'includeRegions') ||
          _.has(action.value, 'includeClients') ||
          _.has(action.value, 'partialClients')
        )
      )
    ) {
      const oldNeedSearchDetails = needSearchDetails(getState().search);
      setTimeout(async () => {
        const { search } = getState();
        const newNeedSearchDetails = needSearchDetails(search);

        // Only sync versions if we need to
        if (oldNeedSearchDetails != newNeedSearchDetails) {
          if (newNeedSearchDetails) {
            await dispatch(getClientSearchDetails(
              _.get(search.filters.includeClients, '[0].slug')
              ||
              _.get(search.filters.partialClients, '[0].slug'),
            ));
          } else {
            await dispatch(clearClientSearchDetails());
          }

          // Default version to latest
          if (type == 'SEARCH: UPDATE_FILTERS') {
            await dispatch(updateFilters({version: 'latest'}));
          }
        }
      }, 10);
    } else if ( type == 'SEARCH: CLEAR_SEARCH') {
      if (action.value) {
        dispatch(getClientSearchDetails(action.value.slug));
      }
    } else if (type == 'SEARCH: GET_SEARCH_RESULTS_SUCCESS') {
      // Track search terms if getting first page
      if (_.get(action, 'value.currPage') == 0) {
        const { search } = getState();

        const params = {
          value: _.get(search, 'filters.searchText'),
          doc_order: _.get(search, 'filters.docOrder', false),
        };

        if (needSearchDetails(search)) {
          new Cookies().set(LAST_DOC_ORDER, params.doc_order, { path: '/' });

          params.client_slug = (
            _.get(search.filters.includeClients, '[0].slug')
            ||
            _.get(search.filters.partialClients, '[0].slug')
          );
        }

        dispatch(logFirebaseEvent({
          eventName: 'search_terms',
          params,
        }));
      }
    } else if (
      type == 'SEARCH: GET_SEARCH_RESULTS_ERROR' ||
      type == 'SEARCH: PREV_SEARCH_RESULTS_ERROR' ||
      type == 'SEARCH: NEXT_SEARCH_RESULTS_ERROR'
    ) {
      dispatch(clearSearchResults());
    }

    // Calls reducer with action
    return next(action);
  };
};

export default redux.reducer(initialState);
