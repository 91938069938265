import merge from 'lodash/merge';

import default_configuration from './configuration.defaults';
import development_configuration from './configuration.development';
import production_configuration from './configuration.production';

const configuration = merge({}, default_configuration);

// https://github.com/webpack-contrib/webpack-serve/issues/81#issuecomment-378469110
export default configuration;

if (process.env.NODE_ENV === 'production') {
  merge(configuration, production_configuration);
} else {
  merge(configuration, development_configuration);
}

// For services like Amazon Elastic Compute Cloud and Heroku
if (process.env.NODE_APP_INSTANCE) {
  const instance_id = process.env.NODE_APP_INSTANCE;
  configuration.webserver.port = 3000 + (instance_id * 2);
  configuration.services.rendering.port = (
    configuration.webserver.port + 1
  );
}

// For passing custom configuration
try {
  require.resolve('./configuration.local');
  const local_configuration = require('./configuration.local');
  merge(configuration, local_configuration);
} catch (e) {
  console.error('No configuration.local.json found');
}
