import * as _ from 'lodash';

export const metaKey = (key, value) => ({
  [key]: value,
  [`twitter:${key}`]: value,
});

const key = (e) => e.which || e.keyCode || 0;

export const onUserInteract = (callback) => {
  return (e) => {
    if (_.includes([13, 32], key(e))) {
      callback(e);
    }
  };
};

export const onEnterPressed = (callback) => {
  return (e) => {
    if (key(e) == 13) {
      callback(e);
    }
  };
};

export const offsetTop = (elem) => {
  let top = elem.offsetTop;

  while (elem = elem.offsetParent) {
    top += elem.offsetTop;
  }

  return top;
};

const interpolate = (y, from , to) => {
  return (from * (1 - y) + to * y);
};

const flip = (fn) => {
  return (x) => 1 - fn(1 - x);
};

const pow3 = (x) => {
  return x * x * x;
};

const easeScroll = (el, startTime, currentTime, duration, toOffset, fromOffset) => {
  const fn = pow3;
  const fnFlipped = flip(fn);

  const runtime = currentTime - startTime;
  let progress = runtime / duration;
  progress = Math.min(progress, 1);

  const y = (progress < 0.5)
    ? (fn(2 * progress) * 0.5)
    : (0.5 + fnFlipped(2 * (progress - 0.5)) * 0.5);
  el.scrollTop = interpolate(y, fromOffset, toOffset);

  if (runtime < duration) {
    // eslint-disable-next-line no-undef
    requestAnimationFrame((timestamp) => {
      const currentTime = timestamp || new Date().getTime();

      easeScroll(el, startTime, currentTime, duration, toOffset, fromOffset);
    });
  }
};

export const smoothScrollTo = (el, scrollTo, duration = 300) => {
  // eslint-disable-next-line no-undef
  requestAnimationFrame((timestamp) => {
    const now = timestamp || new Date().getTime();

    easeScroll(el, now, now, duration, scrollTo, el.scrollTop);
  });
};

const safeCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');

export const randomString = (len, arr = safeCharacters) => {
  return _.times(len, () => arr[_.random(arr.length - 1)]).join('');
};

export const difference = (object, base) => {
  function changes (object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
};

export const monthToLocale = (m) => {
  return new Date(1988, m, 1).toLocaleString('default', { month: 'long' });
};

export const dateForUrl = (m) => `${m.year}/${m.month}/${m.day}`;
