import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { createPdfCode } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    createPdfCodePending: admin.createPdfCodePending,
    createPdfCodeError: admin.createPdfCodeError,
  }),
  { createPdfCode },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class CreatePdfCodeModal extends StatefulForm {
  static propTypes = {
    createPdfCodePending : PropTypes.bool,
    createPdfCodeError : PropTypes.object,
  }
  state = {
    open: false,
    firstGoDone: false,
    formValue: {
      title: '',
      slug: '',
      zip_file: null,
    },
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
    this.titleInput = React.createRef();
  }

  async toggle () {
    if (!this.state.open) {
      // Focus is ignored without delay
      setTimeout(() => this.titleInput.current.focus(), 100);

      this.setState({
        formValue: {
          title: '',
          slug: '',
          zip_file: null,
        },
        firstGoDone: false,
      });
    }

    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      createPdfCode,
      currClient,
    } = this.props;

    this.setState({firstGoDone: true});

    await createPdfCode({
      ..._.omitBy(this.state.formValue, _.isNil),
      client: currClient.id,
    });

    if (!_.get(this.props, 'createPdfCodeError')) {
      await this.setState({open: false});
    }
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const { createPdfCodePending, createPdfCodeError } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Create PDF Code</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Input code details and click Save.
          </p>
          <FormGroup>
            <Input
              autoComplete="off"
              innerRef={this.titleInput}
              name="title"
              type="text"
              placeholder="Code Title"
              value={ formValue.title }
              invalid={ firstGoDone && _.has(createPdfCodeError, 'title') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createPdfCodeError, 'title') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              autoComplete="off"
              name="slug"
              type="text"
              placeholder="Code Slug"
              value={ formValue.slug }
              invalid={ firstGoDone && _.has(createPdfCodeError, 'slug') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createPdfCodeError, 'slug') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              name="zip_file"
              type="file"
              id="zip_file"
              accept=".zip"
              invalid={ firstGoDone && _.has(createPdfCodeError, 'zip_file') }
              onChange={ this.handleChange }
            />
            <FormText color="muted">
              One zip file with all PDF files in the root directory.
            </FormText>
            <FormFeedback role="alert">
              { _.get(createPdfCodeError, 'zip_file') }
            </FormFeedback>
          </FormGroup>
          {
            firstGoDone && _.get(createPdfCodeError, 'code') == 413 &&
            <FormGroup>
              <Input
                type="hidden"
                invalid={ true }/>
              <FormFeedback role="alert">
                File exceeds maximum size of 1GB
              </FormFeedback>
            </FormGroup>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={ createPdfCodePending }
            onClick={this.submitForm}
          >
            {
              createPdfCodePending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
