import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, meta, preload } from 'react-website';
import * as _ from 'lodash';

import { getRegionDetails } from '../../redux/clients';
import { metaKey } from '../../utils/helpers';

@preload(async ({ dispatch, params }) => {
  await dispatch(getRegionDetails(params.region));
})
@connect(({ clients }) => ({ 
  regionDetails: clients.regionDetails,
}))
@meta((state) => ({
  ...metaKey(
    'title',
    _.get(state, 'clients.regionDetails.name') + ' Laws Sitemap',
  ),
}))
export default class RegionClientsSiteMap extends Component {
  render () {
    const { regionDetails } = this.props;

    const regionClients = _.map(
      regionDetails.clients,
      (client) => {
        return (
          <div key={client.slug}>
            <Link to={`/codes/${client.slug}/latest/overview`}>
              {client.name}
            </Link>
          </div>
        );
      }
    );

    return (
      <div className="sitemap region-client-sitemap">
        <ul>
          <h3>State Locations</h3>
          { regionClients }
        </ul>
      </div>
    );
  }
}
