import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { goto } from 'react-website';
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import './VersionDropdown.scss';
import VersionIcon from '../../../assets/images/version.svg';

import {
  getRenderType,
  toggleCompare,
  compareVersion,
  getViewingPdf,
  RenderType,
} from '../../redux/codes';
import { clearSearch } from '../../redux/search';

@connect(({ codes, search, found }) => ({
  currClient: codes.currClient,
  selectedVersion: codes.selectedVersion,
  compareOpen: codes.compareOpen,
  versionToCompare: codes.versionToCompare,
  previewVersion: codes.previewVersion,
  renderType: getRenderType(found),
  searchCtx: search.searchContext,
  viewingPdf: getViewingPdf(codes),
}), {
  toggleCompare,
  compareVersion,
  clearSearch,
  goto,
})
export class VersionDropdown extends React.Component {
  static propTypes = {
    urlParams : PropTypes.object.isRequired,
  }

  constructor () {
    super();

    this.switchVersion = this.switchVersion.bind(this);
    this.compare = this.compare.bind(this);
    this.toggleCompare = this.toggleCompare.bind(this);
  }

  async switchVersion (version, idx) {
    if (this.props.renderType === RenderType.NORMAL) {
      const {
        goto,
        urlParams,
        clearSearch,
        location,
      } = this.props;

      await clearSearch();

      const versionStr = idx == 0 ? 'latest' : version.uuid;

      if (_.has(urlParams, 'docid')) {
        try {
          goto(
            `/codes/${urlParams.clientslug}/${versionStr}/` +
            `${urlParams.codeslug}/${urlParams.docid}`
          );
        } catch {
          location.href = `/codes/${urlParams.clientslug}/${versionStr}/overview`;
        }
      } else {
        goto(`/codes/${urlParams.clientslug}/${versionStr}/overview`);
      }
    }
  }

  compare (version) {
    const { compareVersion } = this.props;

    compareVersion(version);
  }

  async toggleCompare () {
    const {
      toggleCompare,
      clearSearch,
    } = this.props;

    await clearSearch();
    await toggleCompare();
  }

  render () {
    const {
      currClient,
      renderType,
      previewVersion,
      selectedVersion,
      compareOpen,
      versionToCompare,
      viewingPdf,
    } = this.props;

    if (renderType === RenderType.NORMAL) {
      let selectedIdx = -1;

      const versionItems = _.map(
        _.get(currClient, 'versions', []),
        (v, idx) => {
          if (v.uuid == _.get(selectedVersion, 'uuid')) {
            selectedIdx = idx;
          }

          return (
            <DropdownItem
              key={ `region-${_.snakeCase(v.uuid)}` }
              className="overflow-ellipsis"
              title={ v.name }
              onClick={() => this.switchVersion(v, idx)}
            >
              { v.name }{ idx == 0 && ' (current)' }
            </DropdownItem>
          );
        },
      );

      const compareVersionItems = _.map(
        _.filter(
          _.get(currClient, 'versions', []),
          (v, idx) => idx > selectedIdx,
        ),
        (v) => (
          <DropdownItem
            key={ `region-${_.snakeCase(v.uuid)}` }
            className="overflow-ellipsis"
            title={ v.name }
            onClick={() => this.compare(v)}
          >
            { v.name }
          </DropdownItem>
        ),
      );

      return (
        <div className="codenav__versions notranslate">
          <div className="codenav__dropdown">
            <UncontrolledDropdown className={classnames(
              'version-dropdown',
              {'version-dropdown--multiple': versionItems.length > 1}
            )}>
              <DropdownToggle
                aria-label="Select version"
                tag="button"
                className={classnames(
                  'form-control overflow-ellipsis',
                  {
                    'multi-version-dropdown': versionItems.length > 1,
                    'single-version-dropdown': versionItems.length === 1,
                  }
                )}
                title={ _.get(selectedVersion, 'name') }
                caret={(versionItems.length > 1)}
                disabled={(versionItems.length === 1)}
              >
                <VersionIcon
                  fill="black"
                  className="version-icon"
                />
                { _.get(selectedVersion, 'name') }
                {
                  _.findIndex(
                    _.get(currClient, 'versions', []),
                    (v) => v.uuid == _.get(selectedVersion, 'uuid')
                  ) == 0 && ' (current)'
                }
              </DropdownToggle>
              <DropdownMenu style={{ 'right': '0px'}}>
                { versionItems }
              </DropdownMenu>
            </UncontrolledDropdown>
            {
              versionItems.length > 1 &&
              <Button
                aria-label="Compare versions"
                tag="button"
                className="codenav__compare-btn"
                onClick={() => setTimeout(() => this.toggleCompare())}
                title="Compare"
              >
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  size="lg"
                />
              </Button>
            }
          </div>
          <Collapse isOpen={compareOpen}>
            <div className="codenav__compare">
              <span>Compare to:</span>
              <UncontrolledDropdown className="compare__dropdown">
                <DropdownToggle
                  aria-label="Compare versions"
                  className={classnames(
                    'form-control overflow-ellipsis',
                    {
                      'multi-version-dropdown': compareVersionItems.length > 1,
                      'single-version-dropdown': compareVersionItems.length == 1,
                    }
                  )}
                  caret={(compareVersionItems.length > 1)}
                  disabled={(compareVersionItems.length <= 1) || viewingPdf}
                >
                  {viewingPdf
                    ? '- Comparison Disabled for PDFs -'
                    : _.get(versionToCompare, 'name', '- No Earlier Versions -')
                  }
                  {
                    _.findIndex(
                      _.get(currClient, 'versions', []),
                      (v) => v.uuid == _.get(versionToCompare, 'uuid')
                    ) == 0 && ' (current)'
                  }
                </DropdownToggle>
                <DropdownMenu style={{ 'right': '0px'}}>
                  { compareVersionItems }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Collapse>
        </div>
      );
    } else if (renderType === RenderType.PREVIEW_VERSION) {
      return (
        <div className="codenav__versions">
          <div>
            <div className="form-control version-header disabled overflow-ellipsis">
              <VersionIcon
                fill="black"
                className="version-icon"
              />
              { _.get(previewVersion, 'name') }
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}
