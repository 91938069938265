import { ReduxModule } from 'react-website';

const redux = new ReduxModule('CLIENTS');

export const getClientRegions = redux.action(
  'GET_CLIENT_REGIONS',
  () => async (http) => await http.get('/api/client-regions/'),
  'clientRegions',
);

export const getAllClientRegions = redux.action(
  'GET_ALL_CLIENT_REGIONS',
  () => async (http) => await http.get('/api/all-client-regions/'),
  'allClientRegions',
);

export const getRegionDetails = redux.action(
  'GET_REGION_DETAILS',
  (slug) => async (http) => await http.get(`/api/client-regions/${slug}/`),
  'regionDetails',
);

const initialState = {
  clientRegions: null,
  regionDetails: null,
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
