import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { publishCode } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    currVersionId: admin.currVersionId,
    publishCodePending: admin.publishCodePending,
    publishCodeError: admin.publishCodeError,
  }),
  { publishCode },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class PublishCodeModal extends Component {
  state = {
    open: false,
    codeSlug: null,
    firstGoDone: false,
    vertionToPublish: null,
    not_visible: false,
  }

  constructor () {
    super();

    this.publishCode = this.publishCode.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (codeSlug) {
    const { currClient } = this.props;

    await this.setState({
      codeSlug,
      firstGoDone: false,
      versionToPublish: _.get(currClient.versions, '0'),
    });

    await this.toggle();
  }

  async toggle () {
    if (!this.props.publishCodePending) {
      await this.setState((prevState) => ({
        open: !prevState.open,
        not_visible: prevState.open && !prevState.not_visible,
      }));
    }
  }

  async publishCode () {
    const {
      currClient,
      publishCode,
    } = this.props;

    const {
      codeSlug,
      versionToPublish,
    } = this.state;

    await this.setState({ firstGoDone: true });

    await publishCode({
      client_slug: currClient.slug, 
      code_slug: codeSlug,
      version_id: versionToPublish.id, 
      visible: !this.state.not_visible,
    });

    this.toggle();
  }

  render () {
    const {
      currClient,
      currVersionId,
      publishCodePending,
      publishCodeError,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={publishCodePending ? undefined : this.toggle}>Publish Code</ModalHeader>
        <ModalBody>
          <div>
            <p className="text-center">
              Select the version that you would like this code to be added to.
            </p>
            <ListGroup>
              {_.map(currClient.versions, (v) => {
                return (
                  <ListGroupItem
                    key={`modal-version-${v.id}`}
                    className={classnames(
                      '',
                      {
                        'list-item--hidden': !v.visible || v.deleting,
                        'list-item--selected': _.get(this.state, 'versionToPublish.id') == v.id,
                      }
                    )}
                    onClick={() => this.setState({versionToPublish: v})}
                    disabled={publishCodePending}
                  >
                    {v.name}{v.deleting && ' (deleting)' || (!v.visible && ' (hidden)')}
                    {v.id == currVersionId && <Badge>CURRENT VERSION</Badge>}
                  </ListGroupItem>
                );
              })}
              {(
                _.find(
                  _.get(this.state, 'versionToPublish.codes', []),
                  (c) => c.slug === this.state.codeSlug
                ) &&
                <p
                  className="text-danger text-center"
                  style={{margin: '1.875rem 0 0 0'}}
                >
                  This will replace the existing <b>{this.state.codeSlug}</b>{' '}
                  code and cannot be undone.
                </p>
              )}
            </ListGroup>
            <FormGroup>
              <Input
                id="not_visible"
                name="not_visible"
                type="checkbox"
                checked={ this.state.not_visible }
                disabled={publishCodePending}
                onChange={async () => {
                  await this.setState((prev) => ({not_visible: !prev.not_visible}));
                }}
              />
              <Label for="not_visible" check>Mark this code as hidden</Label>
            </FormGroup>
            {
              publishCodeError && this.state.firstGoDone &&
              <p
                className="text-danger text-center"
                style={{margin: '1.875rem 0 0 0'}}
              >
                { publishCodeError.detail }
              </p>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.toggle}
            disabled={publishCodePending}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={this.publishCode}
            disabled={publishCodePending || _.get(this.state, 'versionToPublish.deleting')}
          >
            {
              publishCodePending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Publish
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
