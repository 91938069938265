import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPencilAlt,
  faSearch,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import * as _ from 'lodash';

import './LocationSelection.scss';

import { RefineSearchModal } from '../../modals/RefineSearchModal';
import { onUserInteract } from '../../utils/helpers';
import {
  getClients,
  startClientSearch,
  updateFilters,
} from '../../redux/search';

@connect(({ search, global }) => ({
  allClients: search.clients,
  version: _.get(search, 'filters.version'),
  excludeClients: _.get(search, 'filters.excludeClients'),
  excludeRegions: _.get(search, 'filters.excludeRegions'),
  includeClients: _.get(search, 'filters.includeClients'),
  includeRegions: _.get(search, 'filters.includeRegions'),
  partialClients: _.get(search, 'filters.partialClients'),
  getClientsPending: search.getClientsPending,
  currPage: search.currClientPage,
  hasMoreClients: search.hasMoreClients,
  mobile: global.mobile,
  searchClient: search.searchClient,
}), {
  getClients,
  startClientSearch,
  updateFilters,
})
export default class LocationSelection extends React.Component {
  state = {
    searchLocationText: '',
    open: false,
    searchExecuted: false,
  }

  constructor () {
    super();

    this.searchInput = React.createRef();
    this.refineSearch = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.getClients = this.getClients.bind(this);
    this.debouncedSearch = _.debounce(this.onLocationSearch, 500);
    this.clearLocations = this.clearLocations.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onLocationSearch = this.onLocationSearch.bind(this);
    this.onLocationSearchChange = this.onLocationSearchChange.bind(this);
    this.setRegionSelected = this.setRegionSelected.bind(this);
    this.setClientSelected = this.setClientSelected.bind(this);
    this.refineClient = this.refineClient.bind(this);
    this.renderRefineClient = this.renderRefineClient.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));

    if (!this.props.mobile && this.state.open) {
      this.searchInput.current.focus();
    }
  }

  async getClients () {
    const {
      currPage,
      getClients,
      searchClient,
    } = this.props;

    await getClients({
      page: currPage + 1,
      search: this.state.searchLocationText,
      clientSlug: _.get(searchClient, 'slug'),
    });
  }

  async onLocationSearchChange (val) {
    await this.setState({
      searchLocationText: val,
      searchExecuted: !!val,
    });

    this.debouncedSearch();
  }

  async clearSearch () {
    await this.setState({
      searchLocationText: '',
      searchExecuted: false,
    });

    this.onLocationSearch();
  }

  async onLocationSearch () {
    const { startClientSearch } = this.props;

    // Triggers search and resets to page 1
    await startClientSearch();
  }

  async setRegionSelected (region, selected) {
    const {
      excludeClients,
      includeClients,
      partialClients,
      includeRegions,
      updateFilters,
    } = this.props;

    let newFilters = {};

    if (selected) {
      // Add the region
      newFilters.includeRegions = _.uniqBy(
        [
          ...includeRegions,
          _.pick(region, ['name', 'slug', 'country_iso_code']),
        ],
        'slug',
      );
      // Remove explicit client inclusions
      newFilters.includeClients = _.filter(includeClients, (c) => c.region.slug !== region.slug);
    } else {
      // Remove the region
      newFilters.includeRegions = _.filter(includeRegions, (r) => r.slug !== region.slug);
      // Remove client exclusions
      newFilters.excludeClients = _.filter(excludeClients, (c) => c.region.slug !== region.slug);
    }
    // Remove client partials
    newFilters.partialClients = _.filter(partialClients, (c) => c.region.slug !== region.slug);

    await updateFilters(newFilters);
  }

  async setClientSelected (client, selected) {
    const {
      excludeClients,
      includeClients,
      partialClients,
      includeRegions,
      updateFilters,
    } = this.props;

    let newFilters = {};

    if (selected) {
      if (_.some(includeRegions, ['slug', client.region.slug])) {
        // Remove client exclusion if the region is selected
        newFilters.excludeClients = _.filter(excludeClients, (c) => c.slug !== client.slug);
      } else {
        // Add client
        newFilters.includeClients = _.uniqBy(
          [
            ...includeClients,
            _.pick(client, ['name', 'slug', 'version_ct', 'region.slug']),
          ],
          'slug',
        );
      }
    } else {
      if (_.some(includeRegions, ['slug', client.region.slug])) {
        // Exclude client if the region is selected
        newFilters.excludeClients = _.uniqBy(
          [
            ...excludeClients,
            _.pick(client, ['name', 'slug', 'version_ct', 'region.slug']),
          ],
          'slug',
        );
      }
      // Remove client inclusion
      newFilters.includeClients = _.filter(includeClients, (c) => c.slug !== client.slug);
    }
    // Always remove client partial
    newFilters.partialClients = _.filter(partialClients, (c) => c.slug !== client.slug);

    await updateFilters(newFilters);
  }

  async refineClient (client, scope) {
    const {
      includeClients,
      partialClients,
      updateFilters,
    } = this.props;

    const partialIdx = _.findIndex(partialClients, ['slug', client.slug]);

    let newFilters;

    if (scope == null) {
      // Include entire client
      this.setClientSelected(client, true);
    } else if (_.isEmpty(scope)) {
      // Remove client
      this.setClientSelected(client, false);
    } else if (partialIdx >= 0) {
      // Update existing partial
      newFilters = { partialClients: [ ...partialClients ] };
      newFilters.partialClients[partialIdx] = {
        ..._.pick(client, ['name', 'slug', 'version_ct', 'region.slug']),
        scope: JSON.stringify(scope),
      };
    } else {
      newFilters = {
        // Add partial client
        partialClients: _.uniqBy(
          [
            ...partialClients,
            {
              ..._.pick(client, ['name', 'slug', 'version_ct', 'region.slug']),
              scope: JSON.stringify(scope),
            },
          ],
          'slug',
        ),
      };
      if (_.some(includeClients, ['slug', client.slug])) {
        // Remove client inclusion
        newFilters.includeClients = _.filter(includeClients, (c) => c.slug !== client.slug);
      }
    }

    await updateFilters(newFilters);
  }

  async clearLocations (e) {
    e.stopPropagation();
    const { updateFilters } = this.props;

    await updateFilters({
      excludeClients: [],
      excludeRegions: [],
      includeClients: [],
      includeRegions: [],
      partialClients: [],
    });
  }

  renderRefineClient (client) {
    return (
      <Button
        color="link"
        title="Refine"
        aria-label="Refine"
        className="client-refine"
        onClick={(e) => {
          e.stopPropagation();
          this.setState({ open: false });
          this.refineSearch.current.wrappedInstance.show(this.props.version, client);
        }}
        onKeyDown={onUserInteract((e) => {
          e.stopPropagation();
          this.setState({ open: false });
          this.refineSearch.current.wrappedInstance.show(this.props.version, client);
        })}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
    );
  }

  render () {
    const {
      allClients,
      excludeClients,
      excludeRegions,
      includeClients,
      partialClients,
      includeRegions,
      getClientsPending,
      hasMoreClients,
    } = this.props;

    let currRegion = null;
    const clientItems = _.map(
      allClients,
      (client) => {
        const items = [];

        // Client should show selected if it or its parent region are selected
        // Should not show as selected if in excluded list
        const isRegionSelected = _.some(includeRegions, ['slug', _.get(client, 'region.slug')]);
        const isPartialRegion = (
          isRegionSelected && (
            _.some(excludeClients, ['region.slug', client.region.slug]) ||
            _.some(partialClients, ['region.slug', client.region.slug])
          )
        );
        const isClientSelected = (
          (
            _.some(includeClients, ['slug', client.slug]) ||
            _.some(partialClients, ['slug', client.slug]) ||
            isRegionSelected
          ) &&
          !_.some(excludeClients, ['slug', client.slug])
        );
        const partialClient = _.find(partialClients, ['slug', client.slug]);

        if (currRegion != client.region.name) {
          currRegion = client.region.name;
          items.push(
            <DropdownItem
              className="region-header"
              key={ `region-${_.get(client, 'region.slug')}` }
              onClick={(e) => {
                e.preventDefault();
                this.setRegionSelected(client.region, !isRegionSelected);
              }}
              onKeyDown={onUserInteract((e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setRegionSelected(client.region, !isRegionSelected);
              })}
              toggle={false}
            >
              <Input
                id={`region-checkbox-${client.region.slug}`}
                type="checkbox"
                checked={isRegionSelected}
                readOnly
              />
              <Label
                for={`region-checkbox-${client.region.slug}`}
                className={classnames({'check--partial': isPartialRegion})}
                check
              ></Label>
              {client.region.name}
            </DropdownItem>
          );
        }

        items.push(
          <DropdownItem
            className="client-region-list__client"
            tag="div"
            key={ `client-${client.id}` }
            onClick={(e) => {
              e.preventDefault();
              this.setClientSelected(client, !isClientSelected);
            }}
            onKeyDown={onUserInteract((e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setClientSelected(client, !isClientSelected);
            })}
            toggle={false}
          >
            <Input
              id={`client-checkbox-${client.slug}`}
              type="checkbox"
              checked={isClientSelected}
              readOnly
            />
            <Label
              for={`client-checkbox-${client.slug}`}
              className={classnames({'check--partial': !!partialClient})}
              check
            ></Label>
            {
              isClientSelected && (_.get(partialClient || client, 'version_ct', 0) > 0) &&
              <Button
                color="link"
                title="Refine"
                aria-label="Refine"
                className="client-refine"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ open: false });
                  this.refineSearch.current.wrappedInstance.show(this.props.version, partialClient || client);
                }}
                onKeyDown={onUserInteract((e) => {
                  e.stopPropagation();
                  this.setState({ open: false });
                  this.refineSearch.current.wrappedInstance.show(this.props.version, partialClient || client);
                })}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            }
            {client.name}
          </DropdownItem>
        );

        return items;
      },
    );

    const includedRegionBadges = _.map(
      includeRegions,
      (region) => (
        <Badge
          key={`include-region-${region.slug}`}
          className="badge--region"
        >
          {region.name}
          <Button
            color="link"
            aria-label={`Remove included state ${region.name}`}
            onClick={() => this.setRegionSelected(region, false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="sm"/>
          </Button>
        </Badge>
      )
    );

    const includedClientBadges = _.map(
      includeClients,
      (client) => (
        <Badge key={`include-client-${client.slug}`}>
          { client.version_ct > 0 && this.renderRefineClient(client) }
          {client.name}, {_.upperCase(client.region.slug)}
          <Button
            color="link"
            aria-label={`Remove included city ${client.name}`}
            onClick={() => this.setClientSelected(client, false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </Badge>
      )
    );

    const partialClientBadges = _.map(
      partialClients,
      (client) => (
        <Badge key={`partial-client-${client.slug}`}>
          { client.version_ct > 0 && this.renderRefineClient(client) }
          {client.name}, {_.upperCase(client.region.slug)}
          <Button
            color="link"
            aria-label={`Remove partial city ${client.name}`}
            onClick={() => this.setClientSelected(client, false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </Badge>
      )
    );

    const excludedRegionBadges = _.map(
      excludeRegions,
      (region) => (
        <Badge key={`exclude-region-${region.slug}`}>
          <FontAwesomeIcon icon={faMinus} />
          {region.name}
          <Button
            color="link"
            aria-label={`Remove excluded region ${region.name}`}
            onClick={() => this.setRegionSelected(region, false)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </Badge>
      )
    );

    const excludedClientBadges = _.map(
      excludeClients,
      (client) => (
        <Badge key={`exclude-client-${client.slug}`}>
          <FontAwesomeIcon icon={faMinus} />
          {client.name}, {_.upperCase(client.region.slug)}
          <Button
            color="link"
            aria-label={`Remove excluded city ${client.name}`}
            onClick={() => this.setClientSelected(client, true)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </Badge>
      )
    );

    const singleLocationSelected = (
      (_.size(includeRegions) + _.size(includeClients) + _.size(partialClients) === 1) &&
      _.isEmpty(excludeRegions) &&
      _.isEmpty(excludeClients) &&
      _.isEmpty(includeRegions)
    );

    const shouldShowRefineIcon = singleLocationSelected && 
      _.get(includeClients[0] || partialClients[0], 'version_ct', 0) > 0;

    const handleRefineClick = (e) => {
      e.stopPropagation();
      
      this.refineSearch.current.wrappedInstance.show(
        this.props.version,
        includeRegions[0] || includeClients[0] || partialClients[0]
      );
    };

    return (
      <div>
        <Dropdown
          id="location-selection"
          isOpen={this.state.open}
          toggle={this.toggle}
        >
          <DropdownToggle
            className="form-control location-selection__dropdown"
            caret
          >
            <FontAwesomeIcon
              color="inherit"
              icon={faSearch}
            />
            {
              !_.isEmpty(includeRegions) ||
              !_.isEmpty(includeClients) ||
              !_.isEmpty(partialClients) ||
              !_.isEmpty(excludeRegions) ||
              !_.isEmpty(excludeClients) ?
                [
                  <span className="location-selection__results-toggle" key="location-quantity">
                    Location filters applied
                  </span>,
                  shouldShowRefineIcon && (
                    <FontAwesomeIcon 
                      icon={faPencilAlt}
                      key="refine-button"
                      color="link"
                      title="Refine"
                      aria-label="Refine"
                      className="refine-button"
                      tabIndex={0}
                      onClick={handleRefineClick}
                      onKeyDown={onUserInteract((e) => {
                        handleRefineClick(e);
                      })}
                    />
                  ),
                  <FontAwesomeIcon
                    aria-label="Remove all selected locations"
                    key="remove-all"
                    className="remove-all-locations"
                    icon={faTimes}
                    tabIndex={0}
                    onClick={(e) => {this.clearLocations(e);}}
                    onKeyDown={onUserInteract((e) => { this.clearLocations(e); })}
                  />,
                ]
                :
                'All locations'
            }
          </DropdownToggle>
          <DropdownMenu>
            <div className="dropdown-search">
              <FormGroup>
                <Input
                  innerRef={this.searchInput}
                  type="text"
                  name="search"
                  placeholder="Type location or state name"
                  autoComplete="off"
                  tabIndex={0}
                  value={this.state.searchLocationText}
                  onChange={(e) => this.onLocationSearchChange(e.target.value)}
                />
                <Button
                  close
                  aria-label="Clear location search"
                  className="search-close"
                  onClick={this.clearSearch}
                />
              </FormGroup>
            </div>
            <div className="location-badges">
              {(
                !_.isEmpty(includeClients) ||
                !_.isEmpty(partialClients) ||
                !_.isEmpty(includeRegions) ||
                !_.isEmpty(excludeRegions) ||
                !_.isEmpty(excludeClients)
              )
                ?
                <div className="location-selection__badges">
                  <span className="included-badges">
                    {includedRegionBadges}
                    {includedClientBadges}
                  </span>
                  <span className="partial-badges">
                    {partialClientBadges}
                  </span>
                  <span className="excluded-badges">
                    {excludedRegionBadges}
                    {excludedClientBadges}
                  </span>
                </div>
                :
                <em style={{paddingLeft: '1rem'}}>
                  Click locations below to narrow your search
                </em>
              }
            </div>
            <div className="client-items">
              { !getClientsPending && clientItems.length === 0 && this.state.searchExecuted && (
                <div className="text-center">
                  <em>No results found.</em>
                </div>
              )}
              <InfiniteScroll
                loadMore={this.getClients}
                hasMore={!getClientsPending && hasMoreClients}
                useWindow={false}
              >
                { clientItems }
              </InfiniteScroll>
              {
                getClientsPending &&
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
            </div>
          </DropdownMenu>
        </Dropdown>
        <RefineSearchModal
          ref={this.refineSearch}
          onSave={this.refineClient}
        />
      </div>
    );
  }
}
