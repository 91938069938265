import { ReduxModule } from 'react-website';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

import { getRenderType, RenderType } from './codes';

const redux = new ReduxModule('ANNOTATIONS');

export const toggleAnnotations = redux.simpleAction(
  'TOGGLE_ANNOTATIONS',
  (state) => ({
    ...state,
    annotationsOn: !state.annotationsOn,
    annotations: state.annotationsOn ? {} : state.annotations,
    openRecordId: null,
  }),
);

export const openAnnotation = redux.simpleAction(
  'OPEN_ANNOTATION',
  (state, recordId) => ({
    ...state,
    openRecordId: recordId,
  }),
);

export const closeAnnotation = redux.simpleAction(
  'CLOSE_ANNOTATION',
  (state) => ({
    ...state,
    openRecordId: null,
  }),
);

export const getAnnotations = redux.action(
  'GET_ANNOTATIONS',
  (client_slug, code_slug, doc_id) => async (http) => {
    return await http.get(`/api/annotations/?client_slug=${client_slug}&code_slug=${code_slug}&doc_id=${doc_id}`);
  },
  (state, annotations) => ({
    ...state,
    annotations: _.reduce(
      annotations,
      (result, a) => {
        if (!_.has(result, a.record_id)) {
          result[a.record_id] = [];
        }
        result[a.record_id].push(a);

        return result;
      },
      {},
    ),
  }),
);

export const createAnnotation = redux.action(
  'CREATE_ANNOTATION',
  (params) => async (http) => await http.post('/api/annotations/', params),
  (state, result) => ({
    ...state,
    annotations: {
      ...state.annotations,
      [result.record_id]: [
        ..._.get(state.annotations, result.record_id, []),
        result,
      ],
    },
  })
);

export const deleteAnnotation = redux.action(
  'DELETE_ANNOTATION',
  (annotation) => async (http) => {
    await http.delete(`/api/annotations/${annotation.id}/`);

    return annotation;
  },
  (state, deletedAnnotation) => ({
    ...state,
    annotations: {
      ...state.annotations,
      [deletedAnnotation.record_id]: _.filter(
        state.annotations[deletedAnnotation.record_id],
        (a) => a.id !== deletedAnnotation.id,
      ),
    },
  }),
);

export const updateAnnotation = redux.action(
  'UPDATE_ANNOTATION',
  (params) => async (http) => {
    return await http.patch(`/api/annotations/${params.id}/`, params);
  },
  (state, result) => {
    const newAnnotations =  _.cloneDeep(state.annotations);

    const annotationIdx = _.findIndex(
      newAnnotations[result.record_id],
      (a) => a.id == result.id,
    );
    if (annotationIdx >= 0) {
      newAnnotations[result.record_id].splice(
        annotationIdx,
        1,
        result,
      );
    }

    return{
      ...state,
      annotations: newAnnotations,
    };
  },
);

export const getShowAnnotations = createSelector(
  ({ codes }) => codes.currSection,
  ({ found }) => _.get(found, 'match.params.version'),
  ({ found }) => getRenderType(found),
  (currSection, versionStr, renderType) => (
    !!currSection && !currSection.pdf_path &&
    (renderType == RenderType.PREVIEW_VERSION || versionStr == 'latest')
  ),
);

export const annotationsMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const { type } = action;
    const { codes, annotations } = getState();

    if (
      type == 'ANNOTATIONS: TOGGLE_ANNOTATIONS' &&
      !annotations.annotationsOn
    ) {
      // Fetch annotations if turning them on after loading page
      dispatch(getAnnotations(
        _.get(codes, 'currClient.slug'),
        _.get(codes, 'currSection.code_slug'),
        _.get(codes, 'currSection.orig_doc_id'),
      ));
    } else if (
      type == 'CODES: CLEAR_CLIENT' &&
      annotations.annotationsOn
    ) {
      // Hide annotations if leaving a client
      dispatch(toggleAnnotations());
    } else if(
      (
        type == 'CODES: RENDER_SECTION_PENDING' ||
        (
          type == 'CODES: SET_LEFT_NAV_OPEN' &&
          action.value
        )
      ) &&
      annotations.annotationsOn
    ) {
      dispatch(closeAnnotation());
    }

    // Calls reducer with action
    return next(action);
  };
};

const initialState = {
  annotationsOn: false,
  openRecordId: null,
  annotations: {},
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
