import React, { Component } from 'react';
import { meta } from 'react-website';

import './Error.scss';

import { metaKey } from '../utils/helpers';

@meta(() => ({ ...metaKey('title', 'Unauthorized') }))
export default class Unauthorized extends Component {
  render () {
    return (
      <section className="page-content">
        <h1 className="page-header">
          You&#39;re not authorized to perform this action
        </h1>
      </section>
    );
  }
}
