import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from 'reactstrap';

export class SearchSyntaxHelp extends Component {
  state = { open: false }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Search Syntax
        </ModalHeader>
        <ModalBody>
          <Table bordered responsive striped size="sm">
            <thead>
              <tr>
                <th>Operator</th>
                <th>Example</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>AND</td>
                <td>one AND two</td>
                <td>Finds documents with both &#39;one&#39; and &#39;two&#39;.</td>
              </tr>
              <tr>
                <td>OR</td>
                <td>
                  me || you
                  me OR you
                </td>
                <td>Finds documents with either &#39;me&#39; or &#39;you&#39; or finds both.</td>
              </tr>
              <tr>
                <td>NOT</td>
                <td>chapter NOT Purpose</td>
                <td>Finds documents with &#39;chapter&#39; but without &#39;Purpose&#39;.</td>
              </tr>
              <tr>
                <td>Phrase</td>
                <td>
                  &#34;unconstitutional or invalid&#34;
                </td>
                <td>Finds documents with the exact phrase &#39;unconstitutional or invalid&#39;.</td>
              </tr>
              <tr>
                <td>Single character wildcard</td>
                <td>
                  wom?n
                  tru??
                </td>
                <td>
                  Finds documents with 
                  &#39;woman&#39;, &#39;women&#39;, etc., or &#39;truth&#39;, &#39;trunk&#39;, &#39;trust&#39;, etc.
                </td>
              </tr>
              <tr>
                <td>Multiple character wildcard</td>
                <td>
                  a*n
                  work*
                </td>
                <td>
                  Finds documents with 
                  &#39;addition&#39;, &#39;assign&#39;, etc., 
                  or &#39;work&#39;, &#39;works&#39;, &#39;working&#39;, etc.
                </td>
              </tr>
              <tr>
                <td>Ordered proximity</td>
                <td>&#34;corporate tax law&#34;~5</td>
                <td>Finds documents with &#39;corporate&#39;, &#39;tax&#39; and &#39;law&#39; within 10 words.</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}
