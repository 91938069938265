import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, meta, redirect } from 'react-website';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../../utils/StatefulForm';
import { metaKey } from '../../utils/helpers';
import { resetPassword } from '../../redux/account';

@meta(() => ({ ...metaKey('title', 'Reset Password') }))
export default class PasswordReset extends Component {
  state = { resetSubmitted: false }

  constructor () {
    super();
    this.onReset = this.onReset.bind(this);
  }

  onReset () {
    this.setState({ resetSubmitted: true });
  }

  render () {
    const { params } = this.props;

    return (
      <div>
        <h3 className="secondary-header">Reset Password</h3>
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              Reset Password
            </div>
            <div className="am-page__pane">
              <p className="text-center">
                {
                  this.state.resetSubmitted
                    ? 'Password has successfully been updated!'
                    : 'Please provide a new password'
                }
              </p>
              {
                !this.state.resetSubmitted &&
                <PasswordResetForm onSubmit={this.onReset} {...params} />
              }
            </div>
          </div>
        </div>
        <div className="auth__message">
          <p>
            <Link
              to="/signup"
              className="auth__link"
            >
              Sign up
            </Link>
            for an American Legal account or
            <Link
              to="/login"
              className="auth__link"
            >
              log in
            </Link>
            to your existing account.
          </p>
        </div>
      </div>
    );
  }
}

@connect(({ account }) => ({
  user: account.user,
  resetPasswordPending: account.resetPasswordPending,
  resetPasswordError: account.resetPasswordError,
}), { resetPassword, redirect })
class PasswordResetForm extends StatefulForm {
  static propTypes = {
    resetPasswordPending : PropTypes.bool,
    resetPasswordError : PropTypes.object,
  }
  state = {
    formValue: {
      new_password1: '',
      new_password2: '',
    },
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      uidb64,
      token,
      onSubmit,
      resetPassword,
    } = this.props;

    await resetPassword({
      ...this.state.formValue,
      uid: uidb64,
      token,
    });
    onSubmit();
  }

  render () {
    const { formValue } = this.state;
    const { resetPasswordPending, resetPasswordError } = this.props;

    let formErrors = null;
    if (
      _.has(resetPasswordError, 'uid') ||
      _.has(resetPasswordError, 'token')
    ) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ true }/>
          <FormFeedback role="alert">
            Link is invalid or has been used.
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <Form onSubmit={ this.submitForm }>

        <FormGroup>
          <Input
            required
            label="New Password"
            name="new_password1"
            autoComplete="new-password"
            placeholder="New Password"
            type="password"
            value={ formValue.password }
            invalid={ _.has(resetPasswordError, 'new_password1') }
            onChange={ this.handleChange }/>
          <FormFeedback role="alert">
            { _.get(resetPasswordError, 'new_password1') }
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            required
            label="Confirm Password"
            name="new_password2"
            autoComplete="new-password"
            placeholder="Confirm Password"
            type="password"
            value={ formValue.password }
            invalid={ _.has(resetPasswordError, 'new_password2') }
            onChange={ this.handleChange }/>
          <FormFeedback role="alert">
            { _.get(resetPasswordError, 'new_password2') }
          </FormFeedback>
        </FormGroup>

        { formErrors }

        <div className="am-page__bottom">
          <Button
            type="submit"
            color="primary"
            disabled={ resetPasswordPending }>
            Reset Password
          </Button>
        </div>
      </Form>
    );
  }
}
