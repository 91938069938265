import React from 'react';
import { Redirect, Route } from 'react-website';

import Application from './pages/Application';
import GenericError from './pages/Error';
import Unauthenticated from './pages/Unauthenticated';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/NotFound';
import Playground from './pages/Playground';

// Sitemaps
import SiteMap from './pages/sitemaps/SiteMap';
import AllRegionsSiteMap from './pages/sitemaps/AllRegionsSiteMap';
import RegionClientsSiteMap from './pages/sitemaps/RegionClientsSiteMap';

import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import VerifyEmail from './pages/auth/VerifyEmail';
import ForgotPassword from './pages/auth/ForgotPassword';
import PasswordReset from './pages/auth/PasswordReset';

import BrowseClientRegions from './pages/browse/BrowseClientRegions';
import BrowseClients from './pages/browse/BrowseClients';

import ManageAccount from './pages/account/ManageAccount';
import Profile from './pages/account/Profile';
import Bookmarks from './pages/account/Bookmarks';
import SavedSearches from './pages/account/SavedSearches';
import Following from './pages/account/Following';

import Admin from './pages/admin/Admin';
import AdminIndex from './pages/admin/AdminIndex';
import AdminClient from './pages/admin/AdminClient';
import SplashPage from './pages/admin/SplashPage';
import CustomizeCode from './pages/admin/CustomizeCode';
import ManageCodes from './pages/admin/ManageCodes';
import ManageMinutes from './pages/admin/ManageMinutes';

import Nav from './pages/nav/Nav';

import ClientOverview from './pages/codes/ClientOverview';
import SectionLoader from './pages/codes/SectionLoader';
import Minutes from './pages/codes/Minutes';

import Search from './pages/search/Search';
import Accessibility from './pages/Accessibility';

export default
(
  <Route
    path="/"
    Component={Application}
  >
    <Route
      Component={BrowseClientRegions}/>

    <Route
      path="regions/:regionslug"
      Component={BrowseClients}/>

    <Route
      path="accessibility"
      Component={Accessibility}/>

    <Route
      path="sitemap"
      Component={SiteMap}/>

    <Route
      path="all-regions-sitemap"
      Component={AllRegionsSiteMap}/>

    <Route
      path="region-clients-sitemap/:region"
      Component={RegionClientsSiteMap}/>
    
    <Route
      path="login"
      Component={Login}/>

    <Route
      path="signup"
      Component={Signup}/>

    <Route
      path="verify-email/:key"
      Component={VerifyEmail}/>

    <Route
      path="forgot-password"
      Component={ForgotPassword}/>

    <Route
      path="password-reset/:uidb64/:token"
      Component={PasswordReset}/>
    
    <Route
      path="account"
      Component={ManageAccount}
    >
      <Redirect from="/" to="/account/profile" />

      <Route
        path="profile"
        Component={Profile}/>

      <Route
        path="bookmarks"
        Component={Bookmarks}/>

      <Route
        path="saved-searches"
        Component={SavedSearches}/>

      <Route
        path="following"
        Component={Following}/>
    </Route>

    <Route
      path="admin"
      Component={Admin}
    >
      <Route
        path="/"
        Component={AdminIndex} />

      <Route
        path=":clientslug"
        Component={AdminClient}
      >
        <Redirect from="/" to="/admin/:clientslug/splash" />

        <Route
          path="splash"
          Component={SplashPage}/>

        <Route
          path="customize"
          Component={CustomizeCode}/>

        <Route
          path="manage"
          Component={ManageCodes}/>

        <Route
          path="minutes"
          Component={ManageMinutes}/>
      </Route>
    </Route>

    <Redirect from="codes/:clientslug" to="/codes/:clientslug/latest/overview" />

    <Route
      path=":sectionRenderType(codes)/:clientslug/:version"
      Component={Nav}
    >
      <Redirect from="/" to="/codes/:clientslug/:version/overview"/>

      <Route
        path="overview"
        Component={ClientOverview}/>
      <Route
        path="m/:year/:month/:day/:index?"
        Component={Minutes}/>
      <Route
        path=":codeslug/:docid?"
        Component={SectionLoader}/>
    </Route>

    <Route
      path=":sectionRenderType(preview)/v/:version"
      Component={Nav}
    >
      {/* Placeholder to render during preload */}
      <Route Component={() => <div></div>}/>

      <Route
        path=":codeslug/:docid"
        Component={SectionLoader}/>
    </Route>

    <Route
      path=":sectionRenderType(preview)/c/:uuid"
      Component={Nav}
    >
      {/* Placeholder to render during preload */}
      <Route Component={() => <div></div>}/>

      <Route
        path=":docid"
        Component={SectionLoader}/>
    </Route>

    <Route
      path="search"
      Component={Search}/>

    <Route
      path="playground"
      Component={Playground}/>

    <Route
      path="unauthenticated"
      Component={Unauthenticated}
      status={401}/>

    <Route
      path="unauthorized"
      Component={Unauthorized}
      status={403}/>

    <Route
      path="not-found"
      Component={NotFound}
      status={404}/>

    <Route
      path="error"
      Component={GenericError}
      status={500}/>

    <Route
      path="*"
      Component={NotFound}
      status={404}/>
  </Route>
);
