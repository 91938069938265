import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { clearSaveError, createSavedSearch } from '../redux/search';

@connect(
  ({ account, search }) => ({
    createSavedSearchPending: search.createSavedSearchPending,
    createSavedSearchError: search.createSavedSearchError,
    user: account.user,
  }),
  { clearSaveError, createSavedSearch },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class SaveSearchModal extends StatefulForm {
  static propTypes = {
    search: PropTypes.object,
  }
  state = {
    open: false,
    formValue: {
      title: '',
    },
  }

  constructor (props) {
    super(props);

    this.titleInput = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (search) {
    await this.setState({
      formValue: { title: search.title },
    });

    await this.toggle();
  }

  async toggle () {
    await this.props.clearSaveError();
    await this.setState((prevState) => ({open: !prevState.open}));

    this.titleInput.current.focus();
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      formValue,
    } = this.state;

    this.props.onSubmit(formValue);
    await this.setState({formValue: {title: ''}});
  }

  render () {
    const { formValue } = this.state;
    const {
      createSavedSearchPending,
      createSavedSearchError,
      user,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Save Search</ModalHeader>
        <ModalBody>
          {!!user ?
            <p className="text-center">
              Input a title and click the Save button to save this search to your account.
            </p>
            :
            <p className="text-center">
              <Link to="/login?next=/search">Log In</Link> to save this search to your account.
              <br/>
              If you don&apos;t have an account, you can <Link to="/signup">Sign Up</Link>!
            </p>
          }
          <FormGroup>
            <InputGroup>
              <Input
                innerRef={this.titleInput}
                required
                name="title"
                placeholder="Save search as..."
                type="text"
                disabled={ !user }
                value={ formValue.title }
                invalid={ _.has(createSavedSearchError, 'title') }
                onChange={ this.handleChange }
              />
              <InputGroupAddon addonType="append">
                <Button
                  type="submit"
                  color="primary"
                  disabled={ createSavedSearchPending || !user }
                  onClick={this.submitForm}
                >
                  Save
                </Button>
              </InputGroupAddon>
              <FormFeedback role="alert">
                { _.get(createSavedSearchError, 'title') }
              </FormFeedback>
            </InputGroup>
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  }
}
