import React, { Component } from 'react';
import { connect } from 'react-redux';
import { meta, preload, redirect } from 'react-website';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { CodeFooter } from './CodeFooter';
import { clearSection, getCurrencyInfo } from '../../redux/codes';
import { metaKey } from '../../utils/helpers';

import './ClientOverview.scss';

@preload(async ({ dispatch }) => {
  await dispatch(clearSection());
})
@connect(({ codes }) => ({
  currClient: codes.currClient,
  currencyInfo: getCurrencyInfo(codes),
}), { redirect })
@meta((state) => ({
  ...metaKey(
    'title',
    (
      _.get(state, 'codes.currClient.name') +
      ', ' +
      _.upperCase(_.get(state, 'codes.currClient.region.slug')) +
      ' Laws'
    ),
  ),
}))
export default class ClientOverview extends Component {
  state = { addressLines: [] }

  constructor (props) {
    super(props);

    this.state.addressLines = this.getAddressLines();
  }

  getAddressLines () {
    const { currClient } = this.props;
    const lines = [];

    if (_.get(currClient, 'address_name')) {
      lines.push(currClient.address_name);
      lines.push(<br key={`address-name`}/>);
    }

    if (_.get(currClient, 'address_line_1')) {
      lines.push(currClient.address_line_1);
      lines.push(<br key={`address-line-1`}/>);
    }

    if (_.get(currClient, 'address_line_2')) {
      lines.push(currClient.address_line_2);
      lines.push(<br key={`address-line-2`}/>);
    }

    if (
      _.get(currClient, 'address_city') &&
      _.get(currClient, 'address_state') &&
      _.get(currClient, 'address_zip_code')
    ) {
      lines.push(
        currClient.address_city + ', ' +
        currClient.address_state + ' ' +
        currClient.address_zip_code
      );
      lines.push(<br key={`address-city-state`}/>);
    }

    return lines;
  }

  render () {
    const {
      currClient,
      currencyInfo,
    } = this.props;

    return (
      <div>
        <h1>{_.get(currClient, 'name')}, {_.get(currClient, 'region.name')}</h1>
        {
          currencyInfo &&
          <div
            className='currency-info'
            dangerouslySetInnerHTML={{__html: currencyInfo }}
          ></div>
        }
        {
          _.get(currClient, 'splash_content') &&
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{__html: (
              _.get(currClient, 'splash_content') +
              '<div class="clearfix"></div>'
            )}}
          ></div>
        }
        <div className="overview-buttons">
          {
            _.get(currClient, 'order_code_url') && (
              <a
                className="btn btn-primary"
                href={currClient.order_code_url}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Opens order code in a new tab"
              >
                Order Code
              </a>
            )
          }
          {
            _.get(currClient, 'new_legislation_url') && (
              <a
                className="btn btn-primary"
                href={currClient.new_legislation_url}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Opens new legislation in a new tab"
              >
                New Legislation
              </a>
            )
          }
          {
            _.get(currClient, 'meeting_minutes_url') && (
              <a
                className="btn btn-primary"
                href={currClient.meeting_minutes_url}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Opens meeting minutes in a new tab"
              >
                Meeting Minutes
              </a>
            )
          }
          <a
            className="btn btn-primary"
            href="http://www.amlegal.com/support"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Opens help center in a new tab"
          >
            GET HELP
          </a>
        </div>
        {
          _.get(currClient, 'website_url') && (
            <section>
              <h3>Website</h3>
              <p>
                <a
                  href={currClient.website_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faExternalLinkSquareAlt}
                  />
                  {currClient.website_url}
                </a>
              </p>
            </section>
          )
        }
        {
          this.state.addressLines.length > 0 && (
            <section>
              <h3>Address</h3>
              <p>{this.state.addressLines}</p>
            </section>
          )
        }
        {
          _.get(currClient, 'city_officials') && (
            <section>
              <h3>Officials</h3>
              <p style={{whiteSpace: 'pre'}}>
                {currClient.city_officials}
              </p>
            </section>
          )
        }
        <div className="overview-footer">
          <CodeFooter/>
        </div>
      </div>
    );
  }
}
