import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { createFollow } from '../redux/account';

@connect(
  ({ account }) => ({ user: account.user }),
  { createFollow },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class FollowCodeModal extends Component {
  state = {
    open: false,
    followTarget: null,
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async show (followTarget) {
    this.setState({ followTarget });

    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    const { user } = this.props;
    const { followTarget } = this.state;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Follow
        </ModalHeader>
        <ModalBody>
          {!!user ?
            <p className="text-center">
              Would you like to follow all of&nbsp;
              <b>{_.get(followTarget, 'codeTitle')}</b> or just this document?
            </p>
            :
            <p className="text-center">
              <Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> to follow provisions.
            </p>
          }
        </ModalBody>
        {!!user ?
          <ModalFooter>
            <Button
              color="primary"
              onClick={async () => {
                await this.props.createFollow(_.pick(followTarget, ['client', 'code_slug']));
                this.toggle();
              }}
            >
              Follow Code
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                await this.props.createFollow(_.pick(followTarget, ['client', 'code_slug', 'doc_id']));
                this.toggle();
              }}
            >
              Follow Document
            </Button>
          </ModalFooter>
          :
          <ModalFooter>
            <Button onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        }
      </Modal>
    );
  }
}
