import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  meta, preload, redirect } from 'react-website';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import * as _ from 'lodash';

import { default as config } from '../../../configuration';
import {
  getSectionInfo,
  getRenderType,
  getPreviewCodeSecInfo,
  getPreviewVersionSecInfo,
  tocChainMissing,
  setViewedHash,
  highlightSection,
  RenderType,
} from '../../redux/codes';
import {
  initialize,
  loadBefore,
  loadAfter,
  renderSectionFlow,
  Direction,
} from '../../redux/sections';
import { getAnnotations } from '../../redux/annotations';
import { CodeFooter } from './CodeFooter';
import { Section } from './Section';
import { metaKey } from '../../utils/helpers';

import './SectionLoader.scss';

const delay = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

@preload(async ({ dispatch, getState, params, location }) => {
  const {
    codes,
    search,
    annotations,
  } = getState();

  let renderType;

  let promise;
  if (/^\/preview\/c\//.exec(location.pathname)) {
    if (
      _.get(codes, 'currSection.code_uuid') != params.uuid ||
      _.get(codes, 'currSection.doc_id') != params.docid
    ) {
      promise = dispatch(getPreviewCodeSecInfo(params.uuid, params.docid));
      renderType = RenderType.PREVIEW_CODE;
    }
  } else {
    const latestUUID = _.get(codes, 'currClient.versions[0].uuid');
    const selectedUUID = _.get(codes, 'currSection.version_uuid');

    const versionChanged = (
      (params.version == 'latest' && selectedUUID != latestUUID) ||
      (params.version != 'latest' && selectedUUID != params.version)
    );

    if (
      _.get(codes, 'currSection.code_slug') != params.codeslug ||
      _.get(codes, 'currSection.doc_id') != params.docid ||
      versionChanged
    ) {
      if (/^\/preview\/v\//.exec(location.pathname)) {
        promise = dispatch(
          getPreviewVersionSecInfo(params.version, params.codeslug, params.docid),
        );
        renderType = RenderType.PREVIEW_VERSION;
      } else {
        const code = _.find(
          _.get(codes, 'selectedVersion.toc'),
          (c) => c.slug == params.codeslug,
        );
        if (_.get(code, 'is_ingesting', false)) {
          await dispatch(redirect(
            location.pathname.split(`${params.version}/`)[0] + `${params.version}/overview`,
          ));

          return;
        }
        promise = dispatch(
          getSectionInfo(params.clientslug, params.version, params.codeslug, params.docid),
        );
        renderType = RenderType.NORMAL;
      }
    }
  }

  if (promise) {
    await promise.catch(
      async () => {
        // This might happen if the current version changes while
        // viewing it. It can also happen when switching to a version
        // that doesn't have the doc_id you're looking for.
        //
        // The best we can do is take them to the overview.
        const overviewUrl = location.pathname.split(`${params.version}/`)[0] + `${params.version}/overview`;
        if (typeof window !== 'undefined') {
          window.location.href = overviewUrl;
        } else {
          await dispatch(redirect(overviewUrl));
        }
      }
    );

    if (_.get(getState(), 'codes.currSection')) {
      const currSection = getState().codes.currSection;
      const origDocId = currSection.orig_doc_id || currSection.doc_id;
      await dispatch(initialize({
        origDocId,
        origDocIdx: currSection.orig_doc_idx,
        origDocTocCt: currSection.orig_doc_toc_ct,
        clientSlug: params.clientslug,
        versionUUID: params.version,
        codeSlug: params.codeslug,
        codeUUID: currSection.code_uuid,
        renderType,
        isSearching: !_.isEmpty(_.get(search, 'searchResult.results'), []),
      }));

      await renderSectionFlow(
        currSection.orig_doc_idx,
        Direction.UNSET,
        getState,
        dispatch,
      );
    }

    // Get highlighting for entire original document
    if (search.searchContext) {
      const sectionId = _.get(getState(), 'codes.currSection.id');
      if (sectionId) {
        await dispatch(highlightSection(sectionId, search.searchContext));
      }
    }

    // Get annotations for entire original document
    if (annotations.annotationsOn) {
      await dispatch(getAnnotations(
        _.get(getState(), 'codes.currClient.slug'),
        _.get(getState(), 'codes.selectedCode.slug'),
        _.get(getState(), 'codes.currSection.orig_doc_id'),
      ));
    }
  }
})
@connect(({ codes, sections, found }) => ({
  currSection: codes.currSection,
  renderType: getRenderType(found),
  getSectionInfoPending: codes.getSectionInfoPending,
  currIndex: sections.currIndex,
  beforeIndex: sections.beforeIndex,
  afterIndex: sections.afterIndex,
  items: sections.items,
  loadingBefore: sections.loadingBefore,
  loadingAfter: sections.loadingAfter,
  loadingCurr: sections.loadingCurr,
  total: sections.total,
  jumpToDocId: sections.jumpToDocId,
  selectedVersion: codes.selectedVersion,
  previewCode: codes.previewCode,
  previewVersion: codes.previewVersion,
  navTocLookup: codes.navTocLookup,
  firstTocFetch: codes.firstTocFetch,
  highlightTerms: codes.highlightTerms,
}), {
  tocChainMissing,
  redirect,
  setViewedHash,
  loadBefore,
  loadAfter,
})
@meta((state) => ({ ...metaKey('title', _.get(state, 'codes.currSection.title')) }))
export default class SectionLoader extends Component {
  changingSections = false;

  constructor () {
    super();

    this.section = React.createRef();
    this.checkMissingTocChain = this.checkMissingTocChain.bind(this);
    this.addScroll = this.addScroll.bind(this);
    this.removeScroll = this.removeScroll.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.throttledScroll = _.throttle(this.onScroll, 250, { trailing: true });
    this.currRef = React.createRef();
    this.startSpacer = React.createRef();
    this.endSpacer = React.createRef();
    this.loadBefore = this.loadBefore.bind(this);
    this.loadAfter = this.loadAfter.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.getStartSpacerHeight = this.getStartSpacerHeight.bind(this);
    this.getEndSpacerHeight = this.getEndSpacerHeight.bind(this);
    this.doubleEncode = this.doubleEncode.bind(this);
  }

  getScrollContainer () {
    if (typeof document !== 'undefined') {
      return document.querySelector('.codenav__section-body');
    }

    return null;
  }

  async onScroll () {
    const { setViewedHash } = this.props;
    const tocs = document.querySelectorAll('.toc-destination');
    const scrollContainer = this.getScrollContainer();

    const start = scrollContainer.offsetTop;
    const halfHeight = scrollContainer.clientHeight / 2;
    const leftOver = (
      scrollContainer.scrollHeight
      - scrollContainer.scrollTop
      - scrollContainer.clientHeight
    );
    const scrollLength = scrollContainer.scrollHeight - scrollContainer.clientHeight;

    // Target center of scroll area
    let target = scrollContainer.scrollTop + halfHeight;

    if (scrollLength < scrollContainer.clientHeight) {
      // Percentage of scroll length
      target = scrollContainer.scrollHeight * (scrollContainer.scrollTop / scrollLength);
    } else if (scrollContainer.scrollTop < halfHeight) {
      // Ease target towards top of screen
      target -= halfHeight * (1 - (scrollContainer.scrollTop / halfHeight));
    } else if (leftOver < halfHeight) {
      // Ease target towards bottom of screen
      target += halfHeight * (1 - (leftOver / halfHeight));
    }

    let tocOffset;
    let lastPassedToc = null;
    for (let toc of tocs) {
      tocOffset = toc.offsetTop - start;
      if (target < tocOffset) {
        break;
      }
      lastPassedToc = toc;
    }

    const hash = _.get(lastPassedToc, 'id', '');
    if (hash || tocs.length) {
      await setViewedHash(hash);
    }
  }

  addScroll () {
    // Delay to allow browser to scroll to hash
    setTimeout(() => {
      this.getScrollContainer().addEventListener('scroll', this.throttledScroll);
    }, 400);
  }

  removeScroll () {
    this.getScrollContainer().removeEventListener('scroll', this.throttledScroll);
  }

  componentWillUnmount () {
    this.removeScroll();
  }

  async componentDidMount () {
    const {
      location,
      match,
      currSection,
      setViewedHash,
    } = this.props;

    // Changing section client-side
    if (_.has(location, 'key')) {
      this.removeScroll();

      this.getScrollContainer().scrollTop = 0;
      this.checkMissingTocChain(match.params.docid);
      await setViewedHash(_.get(currSection, 'doc_id', ''));

      this.addScroll();
    }
  }

  shouldComponentUpdate (nextProps) {
    const {
      location: oldLocation,
      currIndex: oldCurrIndex,
      beforeIndex: oldBeforeIndex,
      afterIndex: oldAfterIndex,
      loadingBefore: oldLoadingBefore,
      loadingAfter: oldLoadingAfter,
      loadingCurr: oldLoadingCurr,
      total: oldTotal,
      jumpToDocId: oldJumpToDocId,
    } = this.props;
    const {
      setViewedHash,
      getSectionInfoPending,
      location: newLocation,
      currIndex: newCurrIndex,
      beforeIndex: newBeforeIndex,
      afterIndex: newAfterIndex,
      loadingBefore: newLoadingBefore,
      loadingAfter: newLoadingAfter,
      loadingCurr: newLoadingCurr,
      total: newTotal,
      jumpToDocId: newJumpToDocId,
    } = nextProps;

    // If we're switching sections, let's ignore updates on the instance
    // of <Section/> that's about to be discarded
    if (this.changingSections || getSectionInfoPending) {
      this.changingSections = true;

      return false;
    }

    // First load or inter-section changing client-side
    if (
      _.get(oldLocation, 'key') != _.get(newLocation, 'key') ||
      oldLocation.pathname != newLocation.pathname ||
      (oldJumpToDocId != newJumpToDocId && newJumpToDocId)
    ) {
      this.removeScroll();

      // This blocks the render loop if synchronous
      setTimeout(() => {
        this.checkMissingTocChain(newJumpToDocId);
        setViewedHash(newJumpToDocId);

        this.addScroll();
      });
    }

    const container = this.getScrollContainer();
    if (oldBeforeIndex != newBeforeIndex || oldAfterIndex != newAfterIndex) {
      this.beforeScrollHeight = container.scrollHeight;
      this.beforeScrollTop = container.scrollTop;
    }

    return (
      newCurrIndex != oldCurrIndex ||
      newBeforeIndex != oldBeforeIndex ||
      newAfterIndex != oldAfterIndex ||
      newLoadingBefore != oldLoadingBefore ||
      newLoadingAfter != oldLoadingAfter ||
      newLoadingCurr != oldLoadingCurr ||
      newTotal != oldTotal
    );
  }

  async checkMissingTocChain (docID) {
    const {
      currSection,
      tocChainMissing,
      selectedVersion,
      previewCode,
      previewVersion,
      navTocLookup,
      firstTocFetch,
    } = this.props;

    if (!currSection) {
      return;
    }

    let code;
    if (previewCode) {
      code = previewCode;
    } else if (previewVersion) {
      code = _.find(previewVersion.toc, (c) => c.uuid == currSection.code_uuid);
    } else {
      code = _.find(selectedVersion.toc, (c) => c.uuid == currSection.code_uuid);
    }

    // If there are children, we don't have them
    const isTocInStore = _.findKey(
      navTocLookup,
      (entry) => {
        return (
          entry.code_uuid == currSection.code_uuid &&
          entry.doc_id == docID
        );
      },
    ) !== undefined;

    // If the entry cannot be found, we need it by default
    let needToc = true;

    // Consult first-level sections for has_children
    let child = !!code && _.find(
      code.sections,
      (s) => _.get(s, 'doc_id') == docID,
    );

    if (!child) {
      // Consult navTocLookup for entry
      for (let key in navTocLookup) {
        child = (
          navTocLookup[key].code_uuid == currSection.code_uuid &&
          _.find(
            navTocLookup[key].children,
            (c) => _.get(c, 'doc_id') == docID,
          )
        );
        if (!!child) {
          // Found it. Bail
          break;
        }
      }
    }

    if (!!child) {
      // No need if there are no nested children
      needToc = child.has_children;
    }

    // Only fetch TOC chain if we need to
    if (!isTocInStore && needToc) {
      await tocChainMissing({
        ctx: 'nav',
        rootEntry: { doc_id: currSection.root_section_doc_id },
        code,
        docID,
        firstTocFetch,
      });
    }
  }

  async loadBefore () {
    const { loadBefore } = this.props;

    await loadBefore();
    await delay(10);
  }

  async loadAfter () {
    const { loadAfter } = this.props;

    await loadAfter();
    await delay(10);
  }

  renderItem (idx) {
    const {
      currSection,
      currIndex,
      loadingCurr,
    } = this.props;
    const showSpinner = idx == currIndex && loadingCurr;

    return showSpinner
      ? (
        <div
          key="curr-section-spinner"
          className="text-center"
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
      : (
        <Section
          key={`section-${_.get(currSection, 'orig_doc_id')}-${idx}`}
          origDocIdx={idx}
        />
      );
  }

  getStartSpacerHeight () {
    const {
      afterIndex,
      total,
    } = this.props;
    const container = this.getScrollContainer();

    if (container && this.section.current) {
      const { clientHeight: parentClientHeight } = container;

      if (this.section.current.offsetHeight < parentClientHeight && afterIndex == total) {
        // Haven't loaded the last section but can't fill height
        return parentClientHeight - this.section.current.offsetHeight;
      }
    }

    return 'unset';
  }

  getEndSpacerHeight () {
    const {
      afterIndex,
      total,
    } = this.props;

    const container = this.getScrollContainer();

    if (container && this.endSpacer.current && this.currRef.current) {
      const diff = (this.endSpacer.current.offsetTop - this.currRef.current.offsetTop);
      const { clientHeight: parentClientHeight } = container;

      if (diff < parentClientHeight && afterIndex < total) {
        // Not the end
        return parentClientHeight - Math.min(parentClientHeight, diff);
      }
    }

    return 'unset';
  }

  componentDidUpdate (prevProps) {
    const { beforeIndex: oldBeforeIndex } = prevProps;
    const { beforeIndex: newBeforeIndex } = this.props;
    const container = this.getScrollContainer();

    // This replaces the infinite scroll logic for adjustReverseScroll
    if (
      oldBeforeIndex != newBeforeIndex &&
      this.beforeScrollHeight != container.scrollHeight
    ) {
      container.scrollTop = container.scrollHeight - this.beforeScrollHeight + this.beforeScrollTop;
    }
  }

  doubleEncode (str) {
    str = _.replace(str, '#', '%23');

    return encodeURIComponent(str);
  }

  render () {
    const {
      currSection,
      highlightTerms,
      currIndex,
      beforeIndex,
      afterIndex,
      loadingBefore,
      loadingAfter,
      loadingCurr,
      total,
    } = this.props;

    if (!currSection || loadingCurr) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    if (currSection.pdf_path) {
      let pdfUrl = `/highlighter/viewer/?file=${this.doubleEncode(currSection.pdf_path)}` +
        '&hitNavLoc=2&powerSearch=1&hideHlMessages=1&hideHlErrors=1&roll=0&nativePrint=0' +
        `&script=${config.dev ? '/api/static' : '/static'}/js/highlighter-hooks.js`;
      if (highlightTerms) {
        pdfUrl = `/highlighter/highlight-for-query?uri=${this.doubleEncode(currSection.pdf_path)}` +
          `&query=${encodeURIComponent(_.join(_.map(highlightTerms, (t) => `"${t}"`), ' '))}&language=general`;
      }

      return (
        <iframe
          className="highlighter-viewer"
          src={`${config.exports_host || ''}${pdfUrl}`}
          title={_.get(currSection, 'title', 'pdf')}
        ></iframe>
      );
    } else {
      return (
        <div ref={this.section}>
          <style dangerouslySetInnerHTML={{__html: currSection.styles}}></style>
          <div
            ref={this.startSpacer}
            style={{height: this.getStartSpacerHeight()}}
          ></div>
          {
            loadingBefore && !loadingCurr &&
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          <InfiniteScroll
            loadMore={this.loadBefore}
            hasMore={!loadingBefore && !loadingCurr && beforeIndex >= 0}
            isReverse={true}
            adjustReverseScroll={false}
            useWindow={false}
            getScrollParent={this.getScrollContainer}
            threshold={1000}
          >
            { _.map(_.range(beforeIndex + 1, currIndex), this.renderItem) }
          </InfiniteScroll>
          <div
            id="curr-section"
            ref={this.currRef}
          >
            { this.renderItem(currIndex) }
          </div>
          <InfiniteScroll
            loadMore={this.loadAfter}
            hasMore={!loadingAfter && !loadingCurr && afterIndex < total}
            useWindow={false}
            getScrollParent={this.getScrollContainer}
          >
            { _.map(_.range(currIndex + 1, afterIndex), this.renderItem) }
          </InfiniteScroll>
          {
            loadingAfter && !loadingCurr &&
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          <div
            ref={this.endSpacer}
            style={{height: this.getEndSpacerHeight()}}
          ></div>
          <CodeFooter/>
        </div>
      );
    }
  }
}
