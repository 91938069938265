import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { goto, redirect, Link } from 'react-website';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faUserCircle,
  faUserCog,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { logout } from '../redux/account';
import { getCodeTheme } from '../redux/codes';
import { onUserInteract } from '../utils/helpers';

@connect(({ account, codes, global, found }) => ({
  user: account.user,
  bookmarks: _.get(account, 'user.bookmarks'),
  mobile: global.mobile,
  theme: getCodeTheme(codes),
  location: _.get(found, 'match.location'),
}), { logout, goto, redirect })
export default class UserMenu extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    onDropdownClose: PropTypes.func,
    smallWindow: PropTypes.bool,
  }

  state = {
    isTopMenuOpen: false,
    isBookmarksOpen: false,
    isMobileBookmarksOpen: false,
  }

  constructor () {
    super();

    this.logout = this.logout.bind(this);
    this.toggleTop = this.toggleTop.bind(this);
    this.toggleBookmarks = this.toggleBookmarks.bind(this);
    this.toggleMobileBookmarks = this.toggleMobileBookmarks.bind(this);
    this.bookmarkRoute = this.bookmarkRoute.bind(this);
  }

  async logout () {
    const { logout, redirect } = this.props;

    await logout();
    redirect('/');
  }

  async toggleTop () {
    await this.setState((prevState) => ({
      isTopMenuOpen: !prevState.isTopMenuOpen,
      isBookmarksOpen: false,
    }));
  }

  async toggleBookmarks (e) {
    e.stopPropagation();
    e.preventDefault();

    await this.setState((prevState) => ({
      isBookmarksOpen: !prevState.isBookmarksOpen,
    }));
    
    if (this.state.isBookmarksOpen) {
      const firstBookmark = document.getElementById('bookmark-list-0');
      if (!!firstBookmark) {
        firstBookmark.focus();
      }
    }
  }
  
  async toggleMobileBookmarks (e) {
    e.stopPropagation();
    e.preventDefault();

    await this.setState((prevState) => ({
      isMobileBookmarksOpen: !prevState.isMobileBookmarksOpen,
    }));
    
    if (this.state.isMobileBookmarksOpen) {
      const firstBookmark = document.getElementById('bookmark-list-0');
      if (!!firstBookmark) {
        firstBookmark.focus();
      }
    }
  }

  bookmarkRoute (e) {
    const { goto } = this.props;

    goto(e.target.value);

    e.target.value = '';
    this.toggleTop();

    _.invoke(this.props, 'onDropdownClose');
  }

  render () {
    const {
      bookmarks,
      goto,
      mobile,
      user,
      theme,
      location,
    } = this.props;

    const loginUrl = `/login${location.pathname == '/' ? '' : `?next=${encodeURIComponent(location.pathname)}`}`;

    let menu;
    if (!user) {
      menu = null;
      menu = [
        <div
          className="my-account-large header-links"
          key="logged-out-large"
        >
          <Link
            className="btn btn-secondary roboto"
            to={loginUrl}
          >
            <FontAwesomeIcon
              color={theme.header_color}
              icon={faUserCircle}
              size="lg"
            />
            Login
          </Link>
        </div>,
        <span
          className="my-account-small roboto header-links"
          key="logged-out-small"
        >
          <DropdownItem
            className="my-account-small"
            onClick={ () => goto(loginUrl)}
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
            />
            Login
          </DropdownItem>
        </span>,
      ];
    } else {
      const bookmarkMobileOptions = _.map(
        bookmarks,
        (aBookmark, idx) => (
          <option
            value={aBookmark.url}
            id={`bookmark-list-${idx}`}
            key={ `bookmark-${aBookmark.id}` }
          >
            {aBookmark.title}
          </option>
        ),
      );

      const bookmarkMobile = <DropdownItem
        tag="div"
        key="bookmark-mobile"
        className="bookmark-mobile my-account-small dropdown-item dropright"
        style={{padding: '0'}}
        toggle={false}
      >
        <label
          className="dropdown-item dropdown-toggle roboto"
          htmlFor="mobile-bookmark-select"
        >
          <FontAwesomeIcon
            icon={faBookmark}
            size="lg"
            transform="shrink-2"
            style={{marginRight: '.75rem'}}
          />
          Bookmarks
        </label>
        <select
          id="mobile-bookmark-select"
          className="bookmarks__select"
          onBlur={(e) => this.bookmarkRoute(e)}
          onChange={(e) => this.bookmarkRoute(e)}
        >
          <option value="">-- Select A Bookmark --</option>
          { bookmarkMobileOptions }
        </select>
      </DropdownItem>;

      const bookmarkList = (
        bookmarks.length > 0
          ? _.map(
            bookmarks,
            (aBookmark, idx) => (
              <DropdownItem
                className="button-link"
                id={`bookmark-list-${idx}`}
                key={ `bookmark-${aBookmark.id}` }
                onClick={ () => this.bookmarkRoute({ target: { value: aBookmark.url } }) }
              >
                {aBookmark.title}
              </DropdownItem>
            ),
          )
          : <div className="text-center">No Bookmarks</div>
      );

      const bookmarkMenu = (
        <UncontrolledDropdown
          className="bookmark-menu"
          direction='right'
          isOpen={this.state.isBookmarksOpen}
        >
          <DropdownToggle
            caret
            className="roboto dropdown-item"
            style={{color: 'inherit'}}
          >
            Bookmarks
          </DropdownToggle>
          <DropdownMenu className="roboto">
            {this.state.isBookmarksOpen && bookmarkList}
          </DropdownMenu>
        </UncontrolledDropdown>
      );

      const smallBookmarkMenu = (
        <DropdownItem
          tag="div"
          key="small-bookmark-menu"
          style={{padding: '0'}}
          toggle={false}
          onClick={this.toggleMobileBookmarks}
          onKeyDown={onUserInteract((e) => {
            this.toggleMobileBookmarks(e);
          })}
        >
          <UncontrolledDropdown
            key="mobile-bookmark-menu"
            className="bookmark-menu my-account-small"
            direction='right'
            isOpen={this.state.isMobileBookmarksOpen}
          >
            <DropdownToggle
              caret
              color="secondary"
              className="roboto dropdown-item"
              style={{color: 'inherit'}}
            >
              <FontAwesomeIcon
                icon={faBookmark}
                size="lg"
                transform="shrink-2"
                style={{marginRight: '.75rem'}}
              />
              Bookmarks
            </DropdownToggle>
            <DropdownMenu className="roboto">
              {this.state.isMobileBookmarksOpen && bookmarkList}
            </DropdownMenu>
          </UncontrolledDropdown>
        </DropdownItem>
      );

      const smallScreenBookmarks = mobile ? bookmarkMobile : smallBookmarkMenu;

      if (this.props.smallWindow) {
        menu = [
          // Mobile version
          <DropdownItem
            key="divider-start"
            className="my-account-small"
            divider
          />,
          <DropdownItem
            key="my-account"
            className="my-account-small roboto"
            header
          >
            My Account
          </DropdownItem>,
          <DropdownItem
            key="manage-account"
            className="my-account-small button-link"
            onClick={ () => goto('/account') }
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
              transform="shrink-2"
            />
            Manage Account
          </DropdownItem>,
          _.get(user, 'is_superuser') &&
          <DropdownItem
            key="admin"
            className="my-account-small button-link"
            onClick={ () => goto('/admin') }
          >
            <FontAwesomeIcon
              icon={faUserCog}
              size="lg"
              transform="shrink-5"
              style={{marginRight: '.25rem'}}
            />
            Admin
          </DropdownItem>,
          smallScreenBookmarks,
          <DropdownItem
            key="logout"
            className="my-account-small"
            onClick={ this.logout }
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="lg"
              transform="shrink-2"
            />
            Logout
          </DropdownItem>,
          <DropdownItem
            key="divider-end"
            className="my-account-small"
            divider
          />,
        ];
      } else {
        menu = <Dropdown
          className="my-account-large"
          key="large"
          isOpen={this.state.isTopMenuOpen}
          toggle={this.toggleTop}
        >
          <DropdownToggle
            caret
            color="secondary"
            className="roboto"
          >
            <FontAwesomeIcon
              color={theme.header_color}
              icon={faUserCircle}
              size="lg"
            />
            My Account
          </DropdownToggle>
          <DropdownMenu className="roboto">
            <DropdownItem
              className="button-link"
              onClick={() => goto('/account')}
            >
              Manage Account
            </DropdownItem>
            {
              _.get(user, 'is_superuser') &&
              <DropdownItem
                className="button-link"
                onClick={() => goto('/admin')}
              >
                Admin
              </DropdownItem>
            }
            <DropdownItem
              tag="div"
              style={{padding: '0'}}
              toggle={false}
              onClick={this.toggleBookmarks}
              onKeyDown={onUserInteract((e) => {
                this.toggleBookmarks(e);
              })}
            >
              {bookmarkMenu}
            </DropdownItem>
            <DropdownItem onClick={this.logout}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>;
      }
    }

    return menu;
  }
}
