import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import classnames from 'classnames';
import {
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  Table,
  Collapse,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faCloudUploadAlt,
  faExternalLinkSquareAlt,
  faGripVertical,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-regular-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as _ from 'lodash';

import {
  ConfirmDeleteModal,
  PublishCodeModal,
  EditCodeModal,
  CreateVersionModal,
  EditVersionModal,
  DeleteClientModal,
  CreatePdfCodeModal,
  CodeProblemsModal,
} from '../../modals';
import {
  updateVersion,
  deleteVersion,
  reorderVersions,
  updateCode,
  deleteCode,
  reorderCodes,
  updateClient,
} from '../../redux/admin';

import './ManageCodes.scss';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  boxShadow: isDragging ? '0 1px 6px 0 rgba(0,0,0,.3)': 'none',
  background: isDragging ? 'rgb(236, 236, 236)' : undefined,
  ...draggableStyle,
});

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    currVersionId: admin.currVersionId,
    updateClientPending: admin.updateClientPending,
    deleteCodePending: admin.deleteCodePending,
    deleteCodeError: admin.deleteCodeError,
    deleteVersionPending: admin.deleteVersionPending,
    deleteVersionError: admin.deleteVersionError,
    reorderCodesPending: admin.reorderCodesPending,
    reorderVersionsPending: admin.reorderVersionsPending,
    versionsUpdating: admin.versionsUpdating,
    codesUpdating: admin.codesUpdating,
  }),
  {
    updateVersion,
    deleteVersion,
    reorderVersions,
    updateCode,
    deleteCode,
    reorderCodes,
    updateClient,
  }
)
export default class ManageCodes extends React.Component {
  state = {
    reorderVersions: false,
    versionOpen: {},
  }

  constructor () {
    super();

    this.deleteCodeModal = React.createRef();
    this.deleteVersionModal = React.createRef();
    this.publishCodeModal = React.createRef();
    this.editCodeModal = React.createRef();
    this.deleteClientModal = React.createRef();
    this.createVersionModal = React.createRef();
    this.editVersionModal = React.createRef();
    this.createPdfCodeModal = React.createRef();
    this.codeProblemsModal = React.createRef();

    this.toggleReorder = this.toggleReorder.bind(this);
    this.versionToggle = this.versionToggle.bind(this);
    this.renderVersions = this.renderVersions.bind(this);
    this.renderReordableVersions = this.renderReordableVersions.bind(this);
    this.renderCodes = this.renderCodes.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updateVersion = this.updateVersion.bind(this);
    this.deleteVersion = this.deleteVersion.bind(this);
    this.updateCode = this.updateCode.bind(this);
    this.deleteCode = this.deleteCode.bind(this);
    this.updateClient = this.updateClient.bind(this);
  }

  onDragEnd (result) {
    if (!result.destination) {
      return;
    }

    const { currClient } = this.props;

    if (result.destination.droppableId === 'version-droppable') {
      const { reorderVersions } = this.props;

      const order = _.map(currClient.versions, (v) => v.id);
      const [removed] = order.splice(result.source.index, 1);
      order.splice(result.destination.index, 0, removed);

      reorderVersions(currClient.slug, order);
    } else {
      const { reorderCodes } = this.props;
      const matches = /version-(\d+)-codes-droppable/.exec(result.destination.droppableId);
      const versionId = matches[1];
      const version = _.find(currClient.versions, (v) => v.id == versionId);

      const order = _.map(version.codes, (c) => c.id);
      const [removed] = order.splice(result.source.index, 1);
      order.splice(result.destination.index, 0, removed);

      reorderCodes(versionId, order);
    }
  }

  toggleReorder () {
    this.setState((prevState) => ({
      reorderVersions: !prevState.reorderVersions,
    }));
  }

  versionToggle (id) {
    this.setState({
      versionOpen: {
        ...this.state.versionOpen,
        [id]: !_.get(this.state, `versionOpen.${id}`, false),
      },
    });
  }

  async updateVersion (id, newValues) {
    const { updateVersion } = this.props;

    await updateVersion({id, newValues});
  }

  async deleteVersion (v) {
    const { deleteVersion } = this.props;

    await deleteVersion(v.id);
    this.deleteVersionModal.current.toggle();
  }

  async updateCode (id, newValues) {
    const { updateCode } = this.props;

    await updateCode({id, newValues});
  }

  async deleteCode (c) {
    const { deleteCode } = this.props;

    await deleteCode(c.id);
    this.deleteCodeModal.current.toggle();
  }

  async updateClient (newValues) {
    const {
      currClient,
      updateClient,
    } = this.props;

    await updateClient(currClient.slug, newValues);
  }

  renderCodes (version) {
    const {
      codesUpdating,
    } = this.props;

    return (
      (
        version.codes.length &&
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={`version-${version.id}-codes-droppable`}>
            {(provided) => (
              <tbody ref={provided.innerRef}>
                {_.map(version.codes, (c, index) => (
                  <Draggable
                    key={c.id}
                    draggableId={`code-draggble-${c.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => ([
                      <tr
                        ref={provided.innerRef}
                        key={`code-${c.id}`}
                        className={classnames({
                          'tr--hidden': (
                            !c.visible ||
                            !_.get(c, 'ingest_task.completed') ||
                            c.deleting ||
                            c.publishing
                          ),
                        })}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <td className="handle">
                          <Button
                            aria-label="Change version order"
                            color="link"
                            disabled
                          >
                            <FontAwesomeIcon
                              color="#959595"
                              icon={faGripVertical}
                            />
                          </Button>
                        </td>
                        <td>{c.title}{!c.visible && ' (hidden)'}</td>
                        <td>{c.slug}</td>
                        <td>
                          {
                            (
                              !_.get(c, 'ingest_task.completed') &&
                              <div className="draft-progress">
                                <div className="spinner-border spinner-border-sm m-1" role="status">
                                  <span className="sr-only"></span>
                                </div>
                                Importing...
                              </div>
                            ) ||
                            (
                              c.deleting &&
                              <div className="draft-progress">
                                <div className="spinner-border spinner-border-sm m-1" role="status">
                                  <span className="sr-only"></span>
                                </div>
                                Deleting...
                              </div>
                            ) ||
                            (
                              c.publishing &&
                              <div className="draft-progress">
                                <div className="spinner-border spinner-border-sm m-1" role="status">
                                  <span className="sr-only"></span>
                                </div>
                                Publishing...
                              </div>
                            ) ||
                            (
                              _.get(c, 'ingest_task.post_state') != 'SUCCESS' &&
                              <div className="draft-progress text-danger">
                                {_.get(c, 'ingest_task.post_state')}
                                <Button
                                  aria-label="Delete code"
                                  color="link"
                                  title="Delete"
                                  style={{marginLeft: 8}}
                                  onClick={() => this.deleteCodeModal.current.show(c)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                  />
                                </Button>
                              </div>
                            ) ||
                            <div className="draft-actions">
                              <Button
                                aria-label="Edit code"
                                color="link"
                                title="Edit"
                                disabled={codesUpdating[c.id]}
                                onClick={() => this.editCodeModal.current.wrappedInstance.show(c)}
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                />
                              </Button>
                              <Button
                                aria-label={c.visible ? `${c.title} visible` : `${c.title} hidden`}
                                color="link"
                                title={c.visible ? 'Hide' : 'Show'}
                                disabled={codesUpdating[c.id]}
                                onClick={() => {
                                  this.updateCode(c.id, {visible: !c.visible});
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={c.visible ? faEye : faEyeSlash}
                                />
                              </Button>
                              <Link
                                aria-label={`Preview ${c.title}. Opens preview in a new tab`}
                                title="Preview"
                                to={`/preview/c/${c.uuid}/`}
                                className="btn-link"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                />
                              </Link>
                              <Button
                                aria-label={`Delete ${c.title}`}
                                color="link"
                                title="Delete"
                                onClick={() => this.deleteCodeModal.current.show(c)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                />
                              </Button>
                            </div>
                          }
                        </td>
                      </tr>,
                    ]
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      ) ||
      <tbody>
        <tr className="tr--no-data">
          <td colSpan={4}>
            <p>No codes in this version yet.</p>
          </td>
        </tr>
      </tbody>
    );
  }

  renderVersions () {
    const {
      currClient,
      currVersionId,
      versionsUpdating,
    } = this.props;

    return (
      <tbody>
        {_.map(currClient.versions, (v) => {
          return [
            <tr
              key={`version-${v.id}`}
              className={classnames({'tr--hidden': !v.visible || v.deleting})}
            >
              <td className="handle">
                <Button
                  aria-label="View codes in version"
                  id={`toggle-codes-${v.id}`}
                  color="link"
                  className={classnames(
                    'version-arrow',
                    {'version-arrow--expanded': _.get(this.state, `versionOpen.${v.id}`, false)}
                  )}
                  onClick={() => this.versionToggle(v.id)}
                >
                  <FontAwesomeIcon
                    color="#959595"
                    icon={faAngleRight}
                  />
                </Button>
              </td>
              <td>
                {v.name}{!v.visible && ' (hidden)'}
                {v.id == currVersionId && <Badge>CURRENT VERSION</Badge>}
              </td>
              {
                (
                  v.deleting &&
                  <td className="draft-progress">
                    <div className="spinner-border spinner-border-sm m-1" role="status">
                      <span className="sr-only"></span>
                    </div>
                    Deleting...
                  </td>
                ) ||
                (
                  <td>
                    <div className="version-actions">
                      <Button
                        aria-label={`Edit ${v.name}`}
                        color="link"
                        title="Edit"
                        disabled={versionsUpdating[v.id]}
                        onClick={() => this.editVersionModal.current.wrappedInstance.show(v)}
                      >
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                        />
                      </Button>
                      <Button
                        aria-label={v.visible ? `${v.name} visible` : `${v.name} hidden`}
                        color="link"
                        title={v.visible ? 'Hide' : 'Show'}
                        disabled={versionsUpdating[v.id]}
                        onClick={() => {
                          this.updateVersion(v.id, {visible: !v.visible});
                        }}
                      >
                        <FontAwesomeIcon
                          icon={v.visible ? faEye : faEyeSlash}
                        />
                      </Button>
                      <Link
                        aria-label={`Preview ${v.name}. Opens preview in a new tab`}
                        title="Preview"
                        to={`/preview/v/${v.uuid}/`}
                        className="btn-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkSquareAlt}
                        />
                      </Link>
                      <Button
                        aria-label={`Delete ${v.name}`}
                        color="link"
                        title="Delete"
                        onClick={() => this.deleteVersionModal.current.show(v)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                        />
                      </Button>
                    </div>
                  </td>
                )
              }
            </tr>,
            <tr
              key={`version-codes-${v.id}`}
              className="version-codes"
            >
              <td colSpan={5}>
                <Collapse isOpen={_.get(this.state, `versionOpen.${v.id}`, false)}>
                  <div className="version-code-line"></div>
                  <Table
                    className="table--reorder"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Code Name</th>
                        <th>Slug</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {this.renderCodes(v)}
                  </Table>
                </Collapse>
              </td>
            </tr>,
          ];
        })}
      </tbody>
    );
  }

  renderReordableVersions () {
    const {
      currClient,
      currVersionId,
    } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="version-droppable">
          {(provided) => (
            <tbody ref={provided.innerRef}>
              {_.map(currClient.versions, (v, index) => (
                <Draggable
                  key={v.id}
                  draggableId={`version-draggable-${v.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      key={`version-${v.id}`}
                      className={classnames({'tr--hidden': !v.visible})}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <td className="handle">
                        <Button
                          aria-label="Reorder version"
                          color="link"
                          disabled
                        >
                          <FontAwesomeIcon
                            color="#959595"
                            icon={faGripVertical}
                          />
                        </Button>
                      </td>
                      <td>
                        {v.name}{!v.visible && ' (hidden)'}
                        {v.id == currVersionId && <Badge>CURRENT VERSION</Badge>}
                      </td>
                      <td>
                        <div className="version-actions">
                          <Button
                            aria-label={`Edit ${v.name}`}
                            color="link"
                            title="Edit"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                            />
                          </Button>
                          <Button
                            aria-label={v.visible ? `${v.name} visible` : `${v.name} hidden`}
                            color="link"
                            title={v.visible ? 'Hide' : 'Show'}
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={v.visible ? faEye : faEyeSlash}
                            />
                          </Button>
                          <Button
                            aria-label={`Preview ${v.name}`}
                            color="link"
                            title="Preview"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkSquareAlt}
                            />
                          </Button>
                          <Button
                            aria-label={`Delete ${v.name}`}
                            color="link"
                            title="Delete"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render () {
    const {
      currClient,
      updateClientPending,
      deleteCodePending,
      deleteCodeError,
      deleteVersionPending,
      deleteVersionError,
      reorderCodesPending,
      reorderVersionsPending,
    } = this.props;

    if (!currClient) {
      return null;
    }

    const drafts = (
      currClient.draft_codes.length && _.map(
        currClient.draft_codes,
        (c) => {
          return (
            <tr
              key={`draft-${c.id}`}
              className={classnames({
                'tr--hidden': (
                  !_.get(c, 'ingest_task.completed') ||
                  c.deleting ||
                  c.publishing
                ),
              })}
            >
              <td className="handle"></td>
              <td>{c.title}</td>
              <td>{c.slug}</td>
              <td>
                { c.num_problems > 0 && (
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => this.codeProblemsModal.current.wrappedInstance.show(c)}
                  >
                    { `${c.num_problems} problem(s)` }
                  </Button>
                )}
              </td>
              <td className="td--imported">
                {_.get(c, 'ingest_task.started') && c.ingest_task.started.toLocaleString('en-US')}
              </td>
              <td>
                {
                  (
                    !_.get(c, 'ingest_task.completed') &&
                    <div className="draft-progress">
                      <div className="spinner-border spinner-border-sm m-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                      Importing...
                    </div>
                  ) ||
                  (
                    c.deleting &&
                    <div className="draft-progress">
                      <div className="spinner-border spinner-border-sm m-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                      Deleting...
                    </div>
                  ) ||
                  (
                    c.publishing &&
                    <div className="draft-progress">
                      <div className="spinner-border spinner-border-sm m-1" role="status">
                        <span className="sr-only"></span>
                      </div>
                      Publishing...
                    </div>
                  ) ||
                  (
                    _.get(c, 'ingest_task.post_state') != 'SUCCESS' &&
                    <div className="draft-progress text-danger">
                      {_.get(c, 'ingest_task.post_state')}
                      <Button
                        aria-label={`Delete ${c.title}`}
                        color="link"
                        title="Delete"
                        style={{marginLeft: 8}}
                        onClick={() => this.deleteCodeModal.current.show(c)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                        />
                      </Button>
                    </div>
                  ) ||
                  <div className="draft-actions">
                    <Button
                      aria-label={`Publish ${c.title}`}
                      color="link"
                      title="Publish"
                      onClick={() => this.publishCodeModal.current.wrappedInstance.show(c.slug)}
                      disabled={currClient.versions.length == 0}
                    >
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                      />
                    </Button>
                    <Button
                      aria-label={`Edit ${c.title}`}
                      color="link"
                      title="Edit"
                      onClick={() => this.editCodeModal.current.wrappedInstance.show(c)}
                    >
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                      />
                    </Button>
                    <Link
                      aria-label={`Preview ${c.title}. Opens preview in a new tab`}
                      title="Preview"
                      to={`/preview/c/${c.uuid}/`}
                      className="btn-link"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLinkSquareAlt}
                      />
                    </Link>
                    <Button
                      aria-label={`Delete ${c.title}`}
                      color="link"
                      title="Delete"
                      onClick={() => this.deleteCodeModal.current.show(c)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                      />
                    </Button>
                  </div>
                }
              </td>
            </tr>
          );
        },
      ) ||
      <tr className="tr--no-data">
        <td colSpan={6}>
          <p>You don&#39;t have any drafts imported yet.</p>
        </td>
      </tr>
    );

    const versions = (
      currClient.versions.length && (
        (
          this.state.reorderVersions
            ? this.renderReordableVersions()
            : this.renderVersions()
        )
      ) ||
      <tbody>
        <tr className="tr--no-data">
          <td colSpan={3}>
            <p>
              You don&#39;t have any versions created yet. Would you like to
              <Button
                className="btn-link-create"
                color="link"
                onClick={() => this.createVersionModal.current.wrappedInstance.toggle()}
              >
                create one
              </Button>?
            </p>
          </td>
        </tr>
      </tbody>
    );

    return (
      <div>
        <h3>Manage Code Versions</h3>
        <p>View and edit the location&#39;s versions and codes here by publishing, hiding, deleting and more.</p>
        {/* Drafts */}
        <Table
          className="table--drafts"
          hover
          responsive
        >
          <thead>
            <tr>
              <th colSpan={5} style={{textAlign: 'left'}}>DRAFTS</th>
              <th
                className="text-right"
                style={{ padding: '0 .75rem' }}
              >
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.createPdfCodeModal.current.wrappedInstance.toggle()}
                >
                  Create PDF Code
                </Button>
              </th>
            </tr>
            <tr>
              <th className="handle"></th>
              <th>Name</th>
              <th>Slug</th>
              <th style={{width: '150px'}}>Problems</th>
              <th style={{textAlign: 'right', width: '200px'}}>Imported</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { drafts }
          </tbody>
        </Table>

        {/* Versions */}
        <Table
          className={
            classnames(
              'table--versions',
              {
                'table--reorder': this.state.reorderVersions,
                'table--dimmed': reorderCodesPending || reorderVersionsPending,
              }
            )
          }
          hover
          responsive
        >
          <thead>
            <tr>
              <th colSpan={2}>CODES &amp; VERSIONS</th>
              <th
                className="text-right"
                style={{ padding: '0 .75rem' }}
              >
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.createVersionModal.current.wrappedInstance.toggle()}
                >
                  Create Version
                </Button>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          { versions }
        </Table>

        <Button
          color="primary"
          disabled={currClient.versions.length < 2}
          onClick={this.toggleReorder}
        >
          {this.state.reorderVersions ? 'Done Reordering' : 'Reorder Versions'}
        </Button>

        {/* Settings */}
        <h3 style={{marginTop: 30}}>Settings</h3>
        <div className="row">
          <div className="col">
            <FormGroup>
              <Input
                id="hide_codes_from_search"
                type="checkbox"
                name="hide_codes_from_search"
                checked={currClient.hide_codes_from_search}
                disabled={updateClientPending}
                onChange={() => this.updateClient({hide_codes_from_search: !currClient.hide_codes_from_search})}
              />
              <Label for="hide_codes_from_search" check>
                Hide client&#39;s code from all searches and location code listings.
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                id="scroll_tables_enabled"
                type="checkbox"
                name="scroll_tables_enabled"
                checked={currClient.scroll_tables_enabled}
                disabled={updateClientPending}
                onChange={() => this.updateClient({scroll_tables_enabled: !currClient.scroll_tables_enabled})}
              />
              <Label for="scroll_tables_enabled" check>
                Tables will have a maximum height and scroll the body <b>(reingest required)</b>
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                id="date_filtering_enabled"
                type="checkbox"
                name="date_filtering_enabled"
                checked={currClient.date_filtering_enabled}
                disabled={updateClientPending}
                onChange={() => this.updateClient({date_filtering_enabled: !currClient.date_filtering_enabled})}
              />
              <Label for="date_filtering_enabled" check>
                Display date pickers when searching codes (not just minutes)
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                id="display_hawaiian_enabled"
                type="checkbox"
                name="display_hawaiian_enabled"
                checked={currClient.display_hawaiian_enabled}
                disabled={updateClientPending}
                onChange={() => this.updateClient({display_hawaiian_enabled: !currClient.display_hawaiian_enabled})}
              />
              <Label for="display_hawaiian_enabled" check>
                Convert Hawaiian diacritical marks <b>(reingest required)</b>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                Title terminology
              </Label>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="form-control overflow-ellipsis"
                  disabled={updateClientPending}
                  caret
                >
                  { currClient.title_terminology }
                </DropdownToggle>
                <DropdownMenu style={{ 'right': '0px'}}>
                  <DropdownItem onClick={() => this.updateClient({title_terminology: 'title'})}>
                    Title
                  </DropdownItem>
                  <DropdownItem onClick={() => this.updateClient({title_terminology: 'reso'})}>
                    Resolution #
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </FormGroup>
          </div>
          <div className="col delete-client">
            <Button
              color="danger"
              onClick={() => this.deleteClientModal.current.wrappedInstance.toggle()}
            >
              Delete Client
            </Button>
            <Label style={{marginLeft: 10}}>
              Deletes client and all associated codes.
              <br/>
              <b>THIS CANNOT BE UNDONE.</b>
            </Label>
          </div>
        </div>

        {/* Modals */}
        <PublishCodeModal ref={this.publishCodeModal} />
        <EditCodeModal ref={this.editCodeModal}/>
        <DeleteClientModal ref={this.deleteClientModal} />
        <CreateVersionModal ref={this.createVersionModal}/>
        <EditVersionModal ref={this.editVersionModal}/>
        <ConfirmDeleteModal
          ref={this.deleteCodeModal}
          onDelete={this.deleteCode}
          subject={'code'}
          busy={deleteCodePending}
          error={deleteCodeError}
        />
        <ConfirmDeleteModal
          ref={this.deleteVersionModal}
          onDelete={this.deleteVersion}
          subject={'version'}
          busy={deleteVersionPending}
          error={deleteVersionError}
        />
        <CreatePdfCodeModal ref={this.createPdfCodeModal} />
        <CodeProblemsModal ref={this.codeProblemsModal} />
      </div>
    );
  }
}
