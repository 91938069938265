import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { updateMinute } from '../redux/admin';

@connect(
  ({ admin }) => ({
    doUpdateMinutePending: admin.doUpdateMinutePending,
    doUpdateMinuteError: admin.doUpdateMinuteError,
  }),
  { updateMinute },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class EditMinuteModal extends StatefulForm {
  static propTypes = {
    minute: PropTypes.object,
    doUpdateMinutePending : PropTypes.bool,
    doUpdateMinuteError : PropTypes.object,
  }
  state = {
    open: false,
    minute: null,
    formValue: {
      title: '',
      date: '',
    },
  }

  constructor (props) {
    super(props);

    this.titleInput = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (minute) {
    await this.setState({
      firstGoDone: false,
      minute,
      formValue: {
        title: minute.title,
        date: minute.date == null ? '' : minute.date,
      },
    });

    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const { updateMinute } = this.props;
    const {
      minute,
      formValue,
    } = this.state;

    this.setState({firstGoDone: true});

    await updateMinute({
      id: minute.id,
      newValues: formValue,
      onSuccess: () => this.setState({open: false}),
    });
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const { doUpdateMinutePending, doUpdateMinuteError } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Edit Minute</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Input minute details and click Save.
          </p>
          <FormGroup>
            <InputGroup>
              <Input
                autoComplete="off"
                innerRef={this.titleInput}
                name="title"
                type="text"
                placeholder="Minute Title"
                value={ formValue.title }
                invalid={ firstGoDone && _.has(doUpdateMinuteError, 'title') }
                onChange={ this.handleChange }
              />
              <FormFeedback role="alert">
                { _.get(doUpdateMinuteError, 'title') }
              </FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Input
              type="date"
              name="date"
              id="date"
              placeholder="Date of Minutes"
              value={formValue.date}
              invalid={ firstGoDone && _.has(doUpdateMinuteError, 'date') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(doUpdateMinuteError, 'date') }
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={ doUpdateMinutePending }
            onClick={this.submitForm}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
