import { ReduxModule } from 'react-website';

import * as _ from 'lodash';

const redux = new ReduxModule('ANALYTICS');

export const setFirebase = redux.simpleAction(
  'SET_FIREBASE',
  'firebase',
);

const setCurrScreen = redux.simpleAction(
  'SET_CURR_SCREEN',
  'currScreen',
);

export const logFirebaseEvent = redux.simpleAction(
  'LOG_FIREBASE_EVENT',
  (state) => state,
);

export const analyticsMiddleware = ({ dispatch, getState }) => {
  return (next) => async (action) => {
    const { type } = action;
    const {
      firebase,
      currScreen,
    } = getState().analytics;

    if (!!firebase) {
      const analytics = firebase.analytics();
      if (type == '@@found/RESOLVE_MATCH') {
        const screenName = _.get(action, 'payload.location.pathname');

        if (currScreen != screenName) {
          await dispatch(setCurrScreen(screenName));
          analytics.setCurrentScreen(screenName);
          analytics.logEvent('screen_view');
        }
      } else if (type == 'ANALYTICS: LOG_FIREBASE_EVENT') {
        const {
          eventName,
          params,
        } = action.value;
        analytics.logEvent(eventName, params);
      }
    }

    // Calls reducer with action
    return next(action);
  };
};

const initialState = {
  firebase: null,
  currScreen: null,
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
