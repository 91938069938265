import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import {
  ConfirmDeleteModal,
} from '../modals';

import { EditTableInput } from './EditTableInput';
import './EditTable.scss';

export class EditTable extends React.Component {
  static propTypes = {
    subject: PropTypes.string,
    subjectPlural: PropTypes.string,
    tableValues: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ),
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  }

  state = {
    editOpen: {},
  }

  constructor (props) {
    super(props);

    this.cancel = this.cancel.bind(this);
    this.editToggle = this.editToggle.bind(this);
    this.titleChange = this.titleChange.bind(this);
    this.update = this.update.bind(this);
    this.deleteModal = React.createRef();

    this.state = {
      cloneValues: _.cloneDeep(props.tableValues),
    };
  }

  componentDidUpdate (prevProps) {
    const { tableValues } = this.props;
    const match = _.isMatch(tableValues, prevProps.tableValues);

    if (!match) {
      this.setState({
        cloneValues: _.cloneDeep(tableValues),
      });
    }
  }

  async editToggle (id) {
    this.setState({
      editOpen: {
        ...this.state.editOpen,
        [id]: !_.get(this.state, `editOpen.${id}`, false),
      },
    });
  }

  async cancel (item) {
    const { tableValues } = this.props;
    const itemIdx = _.findIndex(tableValues, (i) => i.id === item.id);

    this.titleChange(tableValues[itemIdx].title, item);
    this.editToggle(item.id);
  }

  async titleChange (newTitle, item) {
    const { cloneValues } = this.state;
    const itemIdx = _.findIndex(cloneValues, (i) => i.id === item.id);
    cloneValues.splice(itemIdx, 1, {...cloneValues[itemIdx], title: newTitle});

    await this.setState({ cloneValues });
  }

  async update (data) {
    const { onUpdate } = this.props;

    await onUpdate(data);
    this.editToggle(data.id);
  }

  render () {
    const {
      onDelete,
      subject,
      subjectPlural,
    } = this.props;

    const { cloneValues } = this.state;

    const tableItems = _.map(
      cloneValues,
      (item) => {
        return (
          <tr
            aria-label={`${item.title}`}
            className="table-item"
            key={`item-${item.id}`}
          >
            <td className="title-input">
              {_.get(this.state, `editOpen.${item.id}`, false) ?
                <EditTableInput
                  item={item}
                  titleChange={this.titleChange}
                />
                :
                <span>{item.title}</span>
              }
            </td>
            <td className="edit-delete-buttons">
              {_.get(this.state, `editOpen.${item.id}`, false) ?
                <span>
                  <Button
                    aria-label="Submit table change"
                    color="link"
                    title="Submit"
                    onClick={() => this.update(item)}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                    />
                  </Button>
                  <Button
                    aria-label="Cancel table changes"
                    color="link"
                    title="Cancel"
                    onClick={() => this.cancel(item)}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                    />
                  </Button>
                </span>
                :
                <span>
                  <Button
                    aria-label={`Edit ${item.id}`}
                    color="link"
                    title="Edit"
                    onClick={() => this.editToggle(item.id)}
                  >
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                    />
                  </Button>
                  <Button
                    aria-label={`Delete ${item.id}`}
                    color="link"
                    title="Delete"
                    onClick={() => this.deleteModal.current.show(item)}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                    />
                  </Button>
                </span>
              }
            </td>
          </tr>
        );
      }
    );

    return (
      <div className="table--edit">
        {_.isEmpty(this.state.cloneValues) ?
          <div className="no-data">
            You do not have any {subjectPlural}.
          </div>
          :
          <div>
            <Table
              hover
              responsive
              striped
            >
              <thead>
                <tr>
                  <th>{_.startCase(subject)} Title</th>
                </tr>
              </thead>
              <tbody>
                {tableItems}
              </tbody>
            </Table>
          </div>
        }
        <ConfirmDeleteModal
          ref={this.deleteModal}
          onDelete={onDelete}
          subject={subject}
        />
      </div>
    );
  }
}
