import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, Link, meta, redirect } from 'react-website';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../../utils/StatefulForm';
import { metaKey } from '../../utils/helpers';
import { login } from '../../redux/account';

import './Auth.scss';

@meta(() => ({ ...metaKey('title', 'Login') }))
export default class LoginPage extends Component {
  render () {
    return (
      <div>
        <h3 className="secondary-header">Log In</h3>
        <LoginForm />
        <div className="auth__message">
          <p>
            Don&#39;t have an American Legal account?
            <Link
              to="/signup"
              className="btn btn-primary btn-signup"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

@connect(({ account, found }) => ({
  user: account.user,
  loginPending: account.loginPending,
  loginError: account.loginError,
  next: _.get(found, 'match.location.query.next', '/'),
}), { goBack, login, redirect })
class LoginForm extends StatefulForm {
  static propTypes = {
    loginPending : PropTypes.bool,
    loginError : PropTypes.object,
  }
  state = {
    firstGoDone: false,
    formValue: {
      email: '',
      password: '',
    },
  }

  async submitForm (e) {
    e.preventDefault();
    const { login, next, redirect } = this.props;

    this.setState({firstGoDone: true});

    await login(this.state.formValue);
    await redirect(next);
  }

  render () {
    const {
      firstGoDone,
      formValue,
    } = this.state;
    const {
      goBack,
      loginPending,
      loginError,
    } = this.props;

    let formErrors = null;
    if (firstGoDone && _.has(loginError, 'non_field_errors')) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ true }/>
          <FormFeedback role="alert">
            { loginError.non_field_errors }
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <div className="am-page">
        <div className="am-page__content">
          <div className="am-page__header">
            Log In
          </div>
          <Form
            className="am-page__pane"
            onSubmit={ this.submitForm }
          >
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                required
                label="Email"
                name="email"
                placeholder="Email"
                type="email"
                value={ formValue.email }
                invalid={ firstGoDone && _.has(loginError, 'email') }
                onChange={ this.handleChange }
              />
              <FormFeedback role="alert">
                { _.get(loginError, 'email') }
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                required
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                value={ formValue.password }
                invalid={ firstGoDone && _.has(loginError, 'password') }
                onChange={ this.handleChange }/>
              <FormFeedback role="alert">
                { _.get(loginError, 'password') }
              </FormFeedback>
            </FormGroup>
            { formErrors }
            <div className='am-page__bottom am-page__bottom--login'>
              <div>
                <Link to="/forgot-password">
                  Forgot Password
                </Link>
              </div>
              <Button onClick={goBack} >
                Cancel
              </Button>
              <Button
                type="submit"
                color="success"
                disabled={ loginPending }
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
