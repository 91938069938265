import React from 'react';
import { connect } from 'react-redux';
import { TwitterPicker } from 'react-color';
import classnames from 'classnames';
import {
  Button,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import * as _ from 'lodash';

import {
  setPreviewTheme,
  defaultTheme,
} from '../../redux/codes';
import { onUserInteract } from '../../utils/helpers';
import { updateClient } from '../../redux/admin';

import './CustomizeCode.scss';

@connect(({ admin }) => ({
  currClient: admin.currClient,
  updateClientPending: admin.updateClientPending,
  updateClientError: admin.updateClientError,
}), { updateClient, setPreviewTheme })
export default class CustomizeCode extends React.Component {
  constructor (props) {
    super(props);

    this.pickerOpen = this.pickerOpen.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.formUnchanged = this.formUnchanged.bind(this);
    this.resetValue = this.resetValue.bind(this);
    this.defaultValue = this.defaultValue.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.ariaCloseColorPickers = this.ariaCloseColorPickers.bind(this);

    this.state = {
      preview: false,
      formValue: _.pick(props.currClient,
        [
          'header_background',
          'header_color',
          'subheader_background',
          'logo',
          'disclaimer',
          'exclude_disclaimer',
        ]
      ),
      files: { logo: null },
      headerBgPickerOpen: false,
      headerTextPickerOpen: false,
      subHeaderBgPickerOpen: false,
    };
  }

  async componentWillUnmount () {
    const { setPreviewTheme } = this.props;

    await setPreviewTheme(null);
  }

  pickerOpen () {
    return (
      this.state.headerBgPickerOpen ||
      this.state.headerTextPickerOpen ||
      this.state.subHeaderBgPickerOpen
    );
  }

  async togglePreview () {
    const { setPreviewTheme } = this.props;

    await this.setState((prevState) => ({preview: !prevState.preview}));

    if (!this.state.preview) {
      await setPreviewTheme(null);
    } else {
      await setPreviewTheme(this.state.formValue);
    }
  }

  async setValue (key, value) {
    await this.setState((prevState) => ({
      formValue: {
        ...prevState.formValue,
        [key]: value,
      },
    }));

    if (this.state.preview) {
      const { setPreviewTheme } = this.props;

      await setPreviewTheme(this.state.formValue);
    }
  }

  getValue (key) {
    return this.state.formValue[key] || defaultTheme[key];
  }

  async resetValue (key) {
    const { currClient } = this.props;

    await this.setValue(key, currClient[key]);
    await this.ariaCloseColorPickers();
  }

  async defaultValue (key) {
    await this.setValue(key, '');
    await this.ariaCloseColorPickers();
  }

  async onFileChanged (files) {
    if (files.length) {
      const file = files[0];

      // Keep file for lookup
      await this.setState({ files: { logo: file } });

      // eslint-disable-next-line no-undef
      const r = new FileReader();
      r.onload = () => {
        // Store base64 url
        this.setValue('logo', r.result);
      };
      r.readAsDataURL(file);
    }
  }

  formUnchanged () {
    const { currClient } = this.props;
    const { formValue } = this.state;

    return (
      _.isMatch(currClient, formValue)
    );
  }

  async submitForm () {
    const {
      currClient,
      updateClient,
    } = this.props;

    // Pass base64 urls to trick formUnchanged on success
    const fileUrls = _.pick(this.state.formValue, _.keys(this.state.files));

    await this.setState({
      formValue: {
        ...this.state.formValue,
        disclaimer: _.trim(this.state.formValue.disclaimer),
      },
    });
  
    updateClient(
      currClient.slug,
      _.mapValues(
        // Omit file fields if they haven't changed
        _.omitBy(this.state.formValue, (v, k) => {
          return _.has(this.state.files, k) && currClient[k] == v;
        }),
        // Pass files instead of base64 urls
        (v, k) => this.state.files[k] || v
      ),
      fileUrls,
    );
  }

  async ariaCloseColorPickers () {
    this.setState({
      headerBgPickerOpen: false,
      headerTextPickerOpen: false,
      subHeaderBgPickerOpen: false,
    });
  }

  render () {
    const {
      currClient,
      updateClientPending,
      updateClientError,
    } = this.props;

    const { formValue } = this.state;

    const popover = {
      position: 'absolute',
      zIndex: '2',
    };

    if (!currClient) {
      return null;
    }

    let formErrors = null;
    if (_.has(updateClientError, 'non_field_errors')) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ !!updateClientError }/>
          <FormFeedback role="alert">
            { updateClientError.non_field_errors }
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <div style={{position: 'relative'}}>
        <h3>Adjust Colors</h3>
        <p>Choose a color or photo, if allowed, for each of the client&#39; navigation components.</p>
        <div className="preview-toggle">
          <CustomInput
            type="switch"
            id="preview"
            name="preview"
            label="Preview Changes"
            checked={this.preview}
            onChange={this.togglePreview}
          />
        </div>
        {
          this.pickerOpen() && (
            <div
              role="button"
              tabIndex={0}
              className="color-swatch-cover"
              onClick={async () => this.setState({
                headerBgPickerOpen: false,
                headerTextPickerOpen: false,
                subHeaderBgPickerOpen: false,
              })}
              onKeyDown={onUserInteract(async () => {
                this.setState({
                  headerBgPickerOpen: false,
                  headerTextPickerOpen: false,
                  subHeaderBgPickerOpen: false,
                });
              })}
            />
          )
        }
        <h4>Main Header Background</h4>
        <div className="color-input-row">
          <span
            role="button"
            tabIndex={0}
            aria-label="Open main header background color selector"
            className="color-swatch-button"
            style={{backgroundColor: this.getValue('header_background')}}
            onClick={async () => this.setState((prevState) => ({
              headerBgPickerOpen: !prevState.headerBgPickerOpen,
            }))}
            onKeyDown={onUserInteract(async () => {
              this.setState((prevState) => ({
                headerBgPickerOpen: !prevState.headerBgPickerOpen,
              }));
            })}
          />
          <span className="color-hex">
            {this.getValue('header_background')}
          </span>
          {
            this.state.headerBgPickerOpen && (
              <div
                className="row"
                style={ popover }
              >
                <TwitterPicker
                  color={this.getValue('header_background')}
                  triangle="top-left"
                  onChange={async (color) => {
                    await this.setValue('header_background', _.toUpper(color.hex));
                    await this.ariaCloseColorPickers();
                  }}
                />
              </div>
            )
          }
          <Button
            color="primary"
            size="sm"
            aria-label="Reset main header background color"
            onClick={() => this.resetValue('header_background')}
            disabled={formValue['header_background'] == currClient['header_background']}
          >
            Reset
          </Button>
          <Button
            color="primary"
            size="sm"
            aria-label="Default main header background color"
            onClick={() => this.defaultValue('header_background')}
            disabled={this.getValue('header_background') == defaultTheme['header_background']}
          >
            Default
          </Button>
        </div>
        <h4>Navigation Buttons</h4>
        <div className="color-input-row">
          <span
            role="button"
            tabIndex={0}
            aria-label="Open navigation buttons color selector"
            className="color-swatch-button"
            style={{backgroundColor: this.getValue('header_color')}}
            onClick={async () => this.setState((prevState) => ({
              headerTextPickerOpen: !prevState.headerTextPickerOpen,
            }))}
            onKeyDown={onUserInteract(async () => {
              this.setState((prevState) => ({
                headerTextPickerOpen: !prevState.headerTextPickerOpen,
              }));
            })}
          />
          <span className="color-hex">
            {this.getValue('header_color')}
          </span>
          {
            this.state.headerTextPickerOpen && (
              <div
                className="row"
                style={ popover }
              >
                <TwitterPicker
                  color={this.getValue('header_color')}
                  triangle="top-left"
                  onChange={async (color) => {
                    await this.setValue('header_color', _.toUpper(color.hex));
                    await this.ariaCloseColorPickers();
                  }}
                />
              </div>
            )
          }
          <Button
            color="primary"
            size="sm"
            aria-label="Reset navigation button color"
            onClick={() => this.resetValue('header_color')}
            disabled={formValue['header_color'] == currClient['header_color']}
          >
            Reset
          </Button>
          <Button
            color="primary"
            size="sm"
            aria-label="Default navigation button color"
            onClick={() => this.defaultValue('header_color')}
            disabled={this.getValue('header_color') == defaultTheme['header_color']}
          >
            Default
          </Button>
        </div>
        <h4>Subheader Background</h4>
        <div className="color-input-row">
          <span
            role="button"
            tabIndex={0}
            aria-label="Open subheader background color selector"
            className="color-swatch-button"
            style={{backgroundColor: this.getValue('subheader_background')}}
            onClick={async () => this.setState((prevState) => ({
              subHeaderBgPickerOpen: !prevState.subHeaderBgPickerOpen,
            }))}
            onKeyDown={onUserInteract(async () => {
              this.setState((prevState) => ({
                subHeaderBgPickerOpen: !prevState.subHeaderBgPickerOpen,
              }));
            })}
          />
          <span className="color-hex">
            {this.getValue('subheader_background')}
          </span>
          {
            this.state.subHeaderBgPickerOpen && (
              <div
                className="row"
                style={ popover }
              >
                <TwitterPicker
                  color={this.getValue('subheader_background')}
                  triangle="top-left"
                  onChange={async (color) => {
                    await this.setValue('subheader_background', _.toUpper(color.hex));
                    await this.ariaCloseColorPickers();
                  }}
                />
              </div>
            )
          }
          <Button
            color="primary"
            size="sm"
            aria-label="Reset subheader background color"
            onClick={() => this.resetValue('subheader_background')}
            disabled={formValue['subheader_background'] == currClient['subheader_background']}
          >
            Reset
          </Button>
          <Button
            color="primary"
            size="sm"
            aria-label="Default subheader background color"
            onClick={() => this.defaultValue('subheader_background')}
            disabled={this.getValue('subheader_background') == defaultTheme['subheader_background']}
          >
            Default
          </Button>
        </div>
        <h3 className="h3-padding">Replace Logo</h3>
        <div>
          <div className="img-upload">
            <Dropzone
              accept="image/x-png,image/gif,image/jpeg"
              onDrop={this.onFileChanged}
            >
              {({getRootProps, getInputProps, isDragActive}) => (
                <div
                  className={classnames(
                    'img-upload__wrapper',
                    { 'img-upload__wrapper--active': isDragActive},
                  )}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive && <span>Drop image here!</span> ||
                    (
                      formValue.logo
                        ? <img
                          src={formValue.logo}
                          alt="Current logo"
                        />
                        : <span>No image selected</span>
                    )
                  }
                </div>
              )}
            </Dropzone>
            <div className="img-upload__actions">
              <p>
                To replace the current logo image in the header, click on the
                &quot;Upload&quot; button and search for your image. The image
                will look best with a height of 40px and a maximum width of
                250px.
              </p>
              <input
                id="logo-file"
                type="file"
                name="logo"
                className="am-file-input"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => this.onFileChanged(e.target.files)}
              />
              <label
                htmlFor="logo-file"
                className="btn btn-sm btn-primary"
                style={{marginBottom: 0}}
              >
                Upload
              </label>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.resetValue('logo')}
                disabled={formValue['logo'] == currClient['logo']}
              >
                Reset
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.defaultValue('logo')}
                disabled={this.getValue('logo') == defaultTheme['logo']}
              >
                Default
              </Button>
            </div>
          </div>
        </div>

        <h3 className="h3-padding">Edit Disclaimer</h3>

        <FormGroup>
          <div className="exclude_disclaimer">
            <Input
              id="exclude_disclaimer"
              type="checkbox"
              name="exclude_disclaimer"
              disabled={updateClientPending}
              checked={formValue.exclude_disclaimer}
              onChange={() => this.setValue('exclude_disclaimer', !formValue.exclude_disclaimer)}
            />
            <Label for="exclude_disclaimer" check>
              Exclude disclaimer from client&#39;s codes.
            </Label>
          </div>

          {!_.get(formValue, 'exclude_disclaimer') && 
            <div>
              <p>
                The following disclaimer is shown at the bottom of every page of this city’s code. 
                Enter/edit what you would like it to say in the text box below.
              </p>
              <Input
                name="disclaimer"
                aria-label="Disclaimer"
                type="textarea"
                placeholder="If this text area is left empty, a default message will display."
                value={ formValue.disclaimer }
                invalid={ _.has(updateClientError, 'disclaimer') }
                onChange={(e) => this.setValue('disclaimer', e.target.value) }
              />
              <FormFeedback role="alert">
                { _.get(updateClientError, 'disclaimer') }
              </FormFeedback>
            </div>
          }
        </FormGroup>

        { formErrors }

        <div className="am-page__bottom">
          <Button
            type="submit"
            color="success"
            disabled={updateClientPending || this.formUnchanged()}
            onClick={this.submitForm}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
