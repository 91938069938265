import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

export class ConfirmDeleteModal extends Component {
  static propTypes = {
    subject: PropTypes.string.isRequired,
    onDelete : PropTypes.func.isRequired,
    busy : PropTypes.bool,
    error: PropTypes.object,
  }
  state = {
    open: false,
    firstGoDone: false,
    params: [],
  }

  constructor () {
    super();

    this.delete = this.delete.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (...params) {
    await this.setState({params});

    await this.toggle();
  }

  async toggle () {
    if (!this.props.busy) {
      if (!this.state.open) {
        await this.setState({ firstGoDone: false });
      }
      await this.setState((prevState) => ({open: !prevState.open}));
    }
  }

  async delete () {
    const { onDelete } = this.props;

    await this.setState({ firstGoDone: true });
    await onDelete.apply(null, this.state.params);
  }

  render () {
    const {
      busy,
      error,
      subject,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={busy ? undefined : this.toggle}>
          Delete { _.startCase(subject) }
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            Are you sure you want to delete this { _.lowerCase(subject) }? All associated data will be lost.
          </p>
          {
            error && this.state.firstGoDone &&
            <p
              className="text-danger text-center"
              style={{margin: '1.875rem 0 0 0'}}
            >
              { error.detail }
            </p>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.toggle}
            disabled={busy}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.delete}
            disabled={busy}
          >
            {
              busy &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
