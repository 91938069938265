import { ReduxModule } from 'react-website';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';

import { default as config } from '../../configuration';
import { HttpUtil } from '../utils/HttpUtil';

const PAGE_SIZE = 10;

// "Sleep" using `Promise`
export const delay = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const redux = new ReduxModule('ADMIN');

const initialState = {
  clients: [],
  hasMoreClients: true,
  currClientPage: 0,
  currClient: null,
  currVersionId: null,
  versionsUpdating: {},
  codesUpdating: {},
  ignoreReload: false,
  clientPolling: false,
  clientPollingInterval: -1,
  minutesUpdating: {},
};

// The current version is always the first visible one
const getCurrVersionId = (client) => {
  for (let idx in _.get(client, 'versions', [])) {
    if (
      _.get(client, `versions.${idx}.visible`) &&
      !_.get(client, `versions.${idx}.deleting`)
    ) {
      return _.get(client, `versions.${idx}.id`);
    }
  }

  return null;
};

const processClient = (client) => _.mapValues(
  client,
  (val, key) => {
    if (_.isNil(val)) {
      return '';
    } else if (_.includes(key, '_minutes')) {
      _.forEach(
        val,
        (m) => {
          if (m.date) {
            m.date_obj = new Date(m.date);
          }
        },
      );
    }

    return val;
  },
);

export const getClients = redux.action(
  'GET_CLIENTS',
  (params) => async (http) => {
    const tmpParams = {
      limit: PAGE_SIZE,
      offset: (params.page - 1) * PAGE_SIZE,
      search: encodeURIComponent(_.trim(params.search)),
    };
    const query = _.map(
      _.omitBy(_.keys(tmpParams), (k) => !tmpParams[k]),
      (k) => `${k}=${tmpParams[k]}`,
    ).join('&');

    await delay(300);

    return {
      response: await http.get(`/api/clients-admin/?${query}`),
      page: params.page,
    };
  },
  (state, payload) => ({
    ...state,
    hasMoreClients: !!payload.response.next,
    clients: [
      ...(!payload.response.previous ? [] : state.clients),
      ...payload.response.results,
    ],
    currClientPage: payload.page,
  }),
);

export const startClientSearch = redux.simpleAction(
  'START_CLIENT_SEARCH',
  (state) => ({
    ...state,
    clients: [],
    currClientPage: 0,
    hasMoreClients: true,
  }),
);

export const switchClient = redux.action(
  'SWITCH_CLIENT',
  (slug) => async (http) => await http.get(`/api/clients-admin/${slug}/`),
  (state, client) => ({
    ...state,
    currClient: processClient(client),
    currVersionId: getCurrVersionId(client),
  }),
);

export const reloadClient = redux.action(
  'RELOAD_CLIENT',
  (slug) => async (http) => await http.get(`/api/clients-admin/${slug}/`),
  (state, client) => {
    if (state.ignoreReload) {
      return {
        ...state,
        ignoreReload: false,
      };
    } else {
      return {
        ...state,
        currClient: processClient(client),
        currVersionId: getCurrVersionId(client),
      };
    }
  },
);

export const clearClient = redux.simpleAction(
  'CLEAR_CLIENT',
  (state) => ({
    ...state,
    currClient: null,
    currVersionId: null,
  }),
);

export const deleteClient = redux.action(
  'DELETE_CLIENT',
  (slug) => async (http) => {
    await http.delete(`/api/clients-admin/${slug}/`);

    return slug;
  },
  (state, slug) => ({
    ...state,
    clients: _.filter(state.clients, (c) => c.slug !== slug),
  }),
);

export const updateClient = redux.action(
  'UPDATE_CLIENT',
  (slug, newValues, fileUrls={}) => async () => {
    let newState = {};
    try {
      const response = await new HttpUtil(
        {
          url: `/api/clients-admin/${slug}/`,
          method: 'patch',
          data: newValues,
          headers: { 'Authorization': 'Bearer ' + new Cookies().get('token') },
        },
      ).send();

      newState['currClient'] = _.mapValues(
        {
          ...response,
          ...fileUrls, // replace remote urls with base64
        },
        (v) => _.isNil(v) ? '' : v,
      );
    } catch (e) {
      // Mimic exception handling of react-website http utility
      newState['updateClientError'] = e;
    }

    return newState;
  },
  (state, newState) => ({
    ...state,
    ...newState,
  }),
);

export const createClient = redux.action(
  'CREATE_CLIENT',
  (client) => async (http) => await http.post(`/api/clients-admin/`, client),
  (state) => state,
);

export const createVersion = redux.action(
  'CREATE_VERSION',
  (version) => async (http) => await http.post(`/api/versions-admin/`, version),
  (state, result) => {
    const currClient = {
      ...state.currClient,
      versions: [
        {
          ...result,
          codes: [],
        },
        ...state.currClient.versions,
      ],
    };

    return {
      ...state,
      currClient,
      currVersionId: getCurrVersionId(currClient),
    };
  },
);

export const createPdfCode = redux.action(
  'CREATE_PDF_CODE',
  (code) => async () => {
    try {
      return await new HttpUtil({
        url: `${config.exports_host || ''}/api/pdf-code-ingest/`,
        method: 'post',
        data: code,
        headers: { 'Authorization': 'Bearer ' + new Cookies().get('token') },
      }).send();
    } catch (error) {
      return { error };
    }
  },
  (state, result) => {
    if (_.has(result, 'error')) {
      return {
        ...state,
        // Mimic exception handling of react-website http utility
        createPdfCodeError: result.error,
      };
    }

    return {
      ...state,
      currClient: {
        ...state.currClient,
        draft_codes: [
          ...state.currClient.draft_codes,
          result,
        ],
      },
      createPdfCodeError: null,
    };
  },
);

export const createMinute = redux.action(
  'CREATE_MINUTE',
  (minute) => async () => {
    try {
      return await new HttpUtil({
        url: `${config.exports_host || ''}/api/minutes-admin/`,
        method: 'post',
        data: minute,
        headers: { 'Authorization': 'Bearer ' + new Cookies().get('token') },
      }).send();
    } catch (error) {
      return { error };
    }
  },
  (state, result) => {
    if (_.has(result, 'error')) {
      return {
        ...state,
        // Mimic exception handling of react-website http utility
        createMinuteError: result.error,
      };
    } else if (result.date) {
      result.date_obj = new Date(result.date);
    }

    return {
      ...state,
      currClient: {
        ...state.currClient,
        unpublished_minutes: _.orderBy([
          ...state.currClient.unpublished_minutes,
          result,
        ], 'date_obj', 'desc'),
      },
      createMinuteError: null,
      ignoreReload: state.reloadClientPending,
    };
  },
);

export const updateMinute = redux.simpleAction(
  'UPDATE_MINUTE',
  (state, params) => ({
    ...state,
    minutesUpdating: {
      ...state.minutesUpdating,
      [params.id]: true,
    },
  }),
);

export const deleteMinute = redux.simpleAction(
  'DELETE_MINUTE',
  (state, id) => {
    return {
      ...state,
      minutesUpdating: {
        ...state.minutesUpdating,
        [id]: true,
      },
    };
  },
);

export const publishAllMinutes = redux.action(
  'PUBLISH_ALL_MINUTES',
  (clientSlug) => async (http) => await http.get(
    `/api/clients-admin/${clientSlug}/publish_all_minutes/`
  ),
  (state, client) => ({
    ...state,
    currClient: processClient(client),
  }),
);

export const updateVersion = redux.simpleAction(
  'UPDATE_VERSION',
  (state, params) => ({
    ...state,
    versionsUpdating: {
      ...state.versionsUpdating,
      [params.id]: true,
    },
  }),
);

const doUpdateVersion = redux.action(
  'DO_UPDATE_VERSION',
  (params) => async (http) => ({
    onSuccess: params.onSuccess,
    result: await http.patch(`/api/versions-admin/${params.id}/`, params.newValues),
  }),
  (state, params) => {
    const {
      result,
      onSuccess,
    } = params;

    const newVersions = _.cloneDeep(state.currClient.versions);

    const versionIndex = _.findIndex(newVersions, (v) => v.id == result.id);
    newVersions.splice(
      versionIndex,
      1,
      {
        ...newVersions[versionIndex],
        ...result,
      }
    );

    const currClient = {
      ...state.currClient,
      versions: newVersions,
    };

    if(onSuccess){
      onSuccess();
    }

    return {
      ...state,
      currClient,
      currVersionId: getCurrVersionId(currClient),
      versionsUpdating: {
        ...state.versionsUpdating,
        [result.id]: false,
      },
    };
  },
);

export const deleteVersion = redux.action(
  'DELETE_VERSION',
  (id) => async (http) => {
    await delay(3000);

    return await http.delete(`/api/versions-admin/${id}/`);
  },
  (state, version) => {
    const newVersions = _.cloneDeep(state.currClient.versions);
    const vIdx = _.findIndex(newVersions, (v) => v.id == version.id);
    newVersions.splice(vIdx, 1, version);

    const newClient = {
      ...state.currClient,
      versions: newVersions,
    };

    return {
      ...state,
      currClient: newClient,
      currVersionId: getCurrVersionId(newClient),
    };
  },
);

export const reorderVersions = redux.action(
  'REORDER_VERSIONS',
  (slug, new_order) => async (http) => await http.post(`/api/clients-admin/${slug}/reorder_versions/`, {new_order}),
  (state, client) => ({
    ...state,
    currClient: processClient(client),
    currVersionId: getCurrVersionId(client),
  }),
);

export const updateCode = redux.simpleAction(
  'UPDATE_CODE',
  (state, params) => ({
    ...state,
    codesUpdating: {
      ...state.codesUpdating,
      [params.id]: true,
    },
  }),
);

const doUpdateCode = redux.action(
  'DO_UPDATE_CODE',
  (params) => async (http) => ({
    onSuccess: params.onSuccess,
    result: await http.patch(`/api/codes-admin/${params.id}/`, params.newValues),
  }),
  (state, params) => {
    const {
      result,
      onSuccess,
    } = params;
    // Try to find code in drafts
    const newDrafts = _.cloneDeep(state.currClient.draft_codes);
    const draftIdx = _.findIndex(newDrafts, (c) => c.id == result.id);
    if (draftIdx >= 0) {
      newDrafts.splice(
        draftIdx,
        1,
        {
          ...newDrafts[draftIdx],
          ...result,
        },
      );

      if(onSuccess){
        onSuccess();
      }

      return {
        ...state,
        currClient: {
          ...state.currClient,
          draft_codes: newDrafts,
        },
        codesUpdating: {
          ...state.codesUpdating,
          [result.id]: false,
        },
      };
    }

    // Try to find code in versions
    const newVersions = _.cloneDeep(state.currClient.versions);
    let vIdx = -1;
    let cIdx = -1;
    for (vIdx in newVersions) {
      cIdx = _.findIndex(newVersions[vIdx].codes, (c) => c.id == result.id);
      if (cIdx >= 0) {
        break;
      }
    }

    if (cIdx >= 0) {
      newVersions[vIdx].codes.splice(cIdx, 1, result);
    }

    if(onSuccess){
      onSuccess();
    }

    return {
      ...state,
      currClient: {
        ...state.currClient,
        versions: newVersions,
      },
      codesUpdating: {
        ...state.codesUpdating,
        [result.id]: false,
      },
    };
  },
);

export const deleteCode = redux.action(
  'DELETE_CODE',
  (id) => async (http) => {
    return await http.delete(`/api/codes-admin/${id}/`);
  },
  (state, code) => {
    const newDrafts = _.cloneDeep(state.currClient.draft_codes);
    let cIdx = _.findIndex(newDrafts, (c) => c.id == code.id);
    if (cIdx >= 0) {
      newDrafts.splice(cIdx, 1, code);
    }

    const newVersions = _.cloneDeep(state.currClient.versions);
    _.forEach(newVersions, (v) => {
      let cIdx = _.findIndex(v.codes, (c) => c.id == code.id);
      if (cIdx >= 0) {
        v.codes.splice(cIdx, 1, code);
      }
    });

    return {
      ...state,
      currClient: {
        ...state.currClient,
        draft_codes: newDrafts,
        versions: newVersions,
      },
    };
  },
);

export const doUpdateMinute = redux.action(
  'DO_UPDATE_MINUTE',
  (params) => async (http) => ({
    onSuccess: params.onSuccess,
    result: await http.patch(`/api/minutes-admin/${params.id}/`, params.newValues),
  }),
  (state, params) => {
    const {
      result,
      onSuccess,
    } = params;

    result.date_obj = new Date(result.date);

    let unpublished_minutes = _.cloneDeep(state.currClient.unpublished_minutes);
    const unpublishedIdx = _.findIndex(unpublished_minutes, (m) => m.id == result.id);

    if (unpublishedIdx >= 0) {
      if (result.published) {
        unpublished_minutes = _.filter(unpublished_minutes, (m) => m.id != result.id);
      } else {
        unpublished_minutes.splice(unpublishedIdx, 1, result);
      }
    }

    let published_minutes = _.cloneDeep(state.currClient.published_minutes);
    const publishedIdx = _.findIndex(published_minutes, (m) => m.id == result.id);
    if (publishedIdx >= 0) {
      published_minutes.splice(publishedIdx, 1, result);
    }

    // Newly published minutes need to be added
    if (publishedIdx < 0 && result.published) {
      published_minutes = _.orderBy([
        ...state.currClient.published_minutes,
        result,
      ], 'date_obj', 'desc');
    }

    if (onSuccess) {
      onSuccess();
    }

    return {
      ...state,
      currClient: {
        ...state.currClient,
        unpublished_minutes,
        published_minutes,
      },
      minutesUpdating: _.pickBy(
        state.minutesUpdating,
        (v, k) => k != result.id,
      ),
      ignoreReload: state.reloadClientPending,
    };
  },
);

export const doDeleteMinute = redux.action(
  'DO_DELETE_MINUTE',
  (id) => async (http) => {
    await delay(500);
    await http.delete(`/api/minutes-admin/${id}/`);

    return id;
  },
  (state, deleted_id) => {
    return {
      ...state,
      currClient: {
        ...state.currClient,
        unpublished_minutes: _.filter(
          state.currClient.unpublished_minutes,
          (m) => m.id !== deleted_id,
        ),
        published_minutes: _.filter(
          state.currClient.published_minutes,
          (m) => m.id !== deleted_id,
        ),
      },
      minutesUpdating: _.pickBy(
        state.minutesUpdating,
        (v, k) => k != deleted_id,
      ),
    };
  },
);

export const publishCode = redux.action(
  'PUBLISH_CODE',
  (code) => async (http) => await http.post(
    `/api/clients-admin/${code.client_slug}/publish_code/`,
    code
  ),
  (state, client) => ({
    ...state,
    currClient: processClient(client),
  }),
);

export const reorderCodes = redux.action(
  'REORDER_CODES',
  (id, new_order) => async (http) => await http.post(`/api/versions-admin/${id}/reorder_codes/`, {new_order}),
  (state, version) => {
    const newVersions = _.cloneDeep(state.currClient.versions);

    const vIdx = _.findIndex(newVersions, (v) => v.id == version.id);
    newVersions.splice(vIdx, 1, version);

    return {
      ...state,
      currClient: {
        ...state.currClient,
        versions: newVersions,
      },
    };
  },
);

export const clearCodeProblems = redux.action(
  'CLEAR_CODE_PROBLEMS',
  (code) => async (http) => {
    await http.get(`/api/codes-admin/${code.id}/clear_problems/`);

    return code.id;
  },
  (state, id) => {
    const draft_codes = _.cloneDeep(state.currClient.draft_codes);
    const cIdx = _.findIndex(draft_codes, (c) => c.id == id);

    if (cIdx >= 0) {
      draft_codes[cIdx].num_problems = 0;
      draft_codes[cIdx].problems = [];
    }

    return {
      ...state,
      currClient: {
        ...state.currClient,
        draft_codes,
      },
    };
  },
);

export const startClientPolling = redux.simpleAction(
  'START_CLIENT_POLLING',
  (state) => ({
    ...state,
    clientPolling: true,
  })
);

export const setClientPollingInterval = redux.simpleAction(
  'SET_CLIENT_POLLING_INTERVAL',
  'clientPollingInterval',
);

export const stopClientPolling = redux.simpleAction(
  'STOP_CLIENT_POLLING',
  (state) => ({
    ...state,
    clientPolling: false,
    clientPollingInterval: -1,
  })
);

redux.on('ADMIN: DO_UPDATE_VERSION_ERROR', (state) => ({
  ...state,
  versionsUpdating: _.mapValues(state.versionsUpdating, () => false),
}));

redux.on('ADMIN: DO_UPDATE_CODE_ERROR', (state) => ({
  ...state,
  codesUpdating: _.mapValues(state.codesUpdating, () => false),
}));

redux.on('ADMIN: DO_UPDATE_MINUTE_ERROR', (state) => ({
  ...state,
  minutesUpdating: _.mapValues(state.minutesUpdating, () => false),
}));

redux.on('ADMIN: DO_DELETE_MINUTE_ERROR', (state) => ({
  ...state,
  minutesUpdating: _.mapValues(state.minutesUpdating, () => false),
}));

export const adminMiddleware = ({ dispatch, getState }) => {
  return (next) => async (action) => {
    const { type } = action;
    const { admin } = getState();

    if (type == 'ADMIN: UPDATE_VERSION') {
      dispatch(doUpdateVersion(action.value));
    } else if (type == 'ADMIN: UPDATE_CODE') {
      dispatch(doUpdateCode(action.value));
    } else if (type == 'ADMIN: UPDATE_MINUTE') {
      dispatch(doUpdateMinute(action.value));
    } else if (type == 'ADMIN: DELETE_MINUTE') {
      dispatch(doDeleteMinute(action.value));
    } else if (type == 'ADMIN: START_CLIENT_POLLING') {
      if (admin.clientPolling) {
        await dispatch(stopClientPolling());
      }
      if (action.value) {
        dispatch(reloadClient(admin.currClient.slug));
      }
      dispatch(setClientPollingInterval(setInterval(
        () => dispatch(reloadClient(admin.currClient.slug)),
        10000,
      )));
    } else if (type == 'ADMIN: STOP_CLIENT_POLLING') {
      if (admin.clientPollingInterval >= 0) {
        clearInterval(admin.clientPollingInterval);
      }
    } else if (
      _.get(action, 'error.detail') == 'Client has codes that are ingesting.'
    ) {
      window['alert']('Cannot edit client with ingesting codes');
    }

    // Calls reducer with action
    return next(action);
  };
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
