import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, meta, preload } from 'react-website';
import * as _ from 'lodash';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import { getRegionDetails } from '../../redux/clients';
import { clearSearch } from '../../redux/search';
import { metaKey } from '../../utils/helpers';

@preload(async ({ dispatch, params }) => {
  await dispatch(getRegionDetails(params.regionslug));
})
@connect(({ clients }) => ({ 
  regionDetails: clients.regionDetails,
}), { clearSearch })
@meta((state) => ({
  ...metaKey('title', _.get(state, 'clients.regionDetails.name') + ' Laws'),
}))
export default class BrowseClients extends Component {
  componentDidMount () {
    this.props.clearSearch();
  }

  render () {
    const { regionDetails } = this.props;

    const regionClients = _.map(
      regionDetails.clients,
      (client) => {
        return (
          <div key={client.slug}>
            <Link
              className="browse-link roboto"
              to={`/codes/${client.slug}/latest/overview`}
            >
              {client.name}
            </Link>
          </div>
        );
      }
    );

    return (
      <div className="browse-page">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Select Location</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{_.get(regionDetails, 'name')}</BreadcrumbItem>
        </Breadcrumb>
        <div className="am-page am-page--scroll">
          <div className="am-page__content am-page__content--nobg">
            <div className="browse-header-box">
              <h2 className="browse-header">{ regionDetails.name }</h2>
            </div>

            <div className="browse-columns roboto">
              { regionClients }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
