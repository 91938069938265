import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import CodeProblem from '../components/CodeProblem';
import { clearCodeProblems } from '../redux/admin';

import './CodeProblemsModal.scss';

@connect(
  ({ admin }) => ({ clearCodeProblemsPending: admin.clearCodeProblemsPending }),
  { clearCodeProblems },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class CodeProblemsModal extends Component {
  state = {
    open: false,
    code: { problems: [] },
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
    this.clearCodeProblems = this.clearCodeProblems.bind(this);
  }

  // Public method to initialize state and toggle on
  async show (code) {
    await this.setState({ code });
    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async clearCodeProblems () {
    const { clearCodeProblems } = this.props;

    await clearCodeProblems(this.state.code);
    await this.toggle();
  }

  render () {
    const { clearCodeProblemsPending } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Code Problems
        </ModalHeader>
        <ModalBody className="ingest-problems">
          {_.map(
            this.state.code.problems,
            (problem, idx) => (
              <CodeProblem
                key={`problem-${idx}`}
                problem={problem}
                idx={idx}
              />
            )
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={ clearCodeProblemsPending }
            onClick={this.clearCodeProblems}
          >
            Clear Problems
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
