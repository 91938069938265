import { ReduxModule } from 'react-website';
import { createSelector } from 'reselect';
import diff from 'node-htmldiff';
import * as _ from 'lodash';

import { offsetTop, smoothScrollTo } from '../utils/helpers';
import { jumpTo } from './sections';

export const RenderType = {
  NORMAL: 'normal',
  PREVIEW_VERSION: 'preview_version',
  PREVIEW_CODE: 'preview_code',
  isPreview: (t) => _.includes(t, 'preview'),
};

// "Sleep" using `Promise`
const delay = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const SCOPE_INPUT_PADDING = 62;

const redux = new ReduxModule('CODES');

export const getClient = redux.action(
  'GET_CLIENT',
  (id) => async (http) => await http.get(`/api/clients/${id}/`),
  'currClient',
);

export const clearClient = redux.simpleAction(
  'CLEAR_CLIENT',
  (state) => ({
    ...state,
    currClient: null,
    currSection: null,
    defaultDocId: '',
    previewVersion: null,
    previewCode: null,
    highlightTerms: null,
    highlightMarks: {},
    currMark: null,
    nextMatch: null,
    prevMatch: null,
    selectedVersion: null,
    compareOpen: false,
    sectionToCompare: null,
    versionToCompare: null,
    firstTocFetch: true,
    navTocLookup: {},
    navCodeLookup: {},
    scopeTocLookup: {},
    scopeCodeLookup: {},
    minutesLookup: {},
  }),
);

export const setCodeOptionsModal = redux.simpleAction(
  'SET_OPTIONS_MODAL',
  (state, modal) => ({
    ...state,
    codeOptionsModal: modal,
  }),
);

export const setLeftNavClosed = redux.simpleAction(
  'SET_LEFT_NAV_CLOSED',
  'leftNavClosed',
);

export const setScreenWidth = redux.simpleAction(
  'SET_SCREEN_WIDTH',
  'screenWidth',
);

const defaultCompareVersion = (state, selectedVersion) => {
  let selectedPassed = false;
  const compareVersions = _.filter(
    _.get(state, 'currClient.versions', []),
    (v) => {
      if (!selectedPassed) {
        if (v.uuid == _.get(selectedVersion, 'uuid')) {
          selectedPassed = true;
        }

        return false;
      }

      return true;
    },
  );

  return _.get(compareVersions, '[0]');
};

export const switchVersion = redux.action(
  'SWITCH_VERSION',
  (uuid) => async (http) => await http.get(`/api/code-versions/${uuid}/`),
  (state, version) => {
    const versionToCompare = state.compareOpen ? defaultCompareVersion(state, version) : null;
    const codeExpanded = _.get(state, 'currClient.minutes_years.length', 0) == 0 && version.toc.length <= 1;
    const minutesOpen = _.get(state, 'currClient.minutes_years.length', 0) > 0 && version.toc.length == 0;

    return {
      ...state,
      selectedVersion: version,
      versionToCompare,
      sectionToCompare: versionToCompare ? state.sectionToCompare : null,
      previewVersion: null,
      previewCode: null,
      minutesOpen,
      navCodeLookup: _.mapValues(
        _.keyBy(version.toc, 'uuid'),
        () => ({ expanded: codeExpanded }),
      ),
      scopeCodeLookup: _.mapValues(
        _.keyBy(version.toc, 'uuid'),
        () => ({ expanded: codeExpanded }),
      ),
    };
  },
);

export const clearClientVersion = redux.simpleAction(
  'CLEAR_CLIENT_VERSION',
  (state) => ({
    ...state,
    clientVersion: null,
    settingScope: true,
  }),
);

export const getClientVersion = redux.action(
  'GET_CLIENT_VERSION',
  (params) => async (http) => {
    const {
      clientSlug,
      version,
      searchCtx,
      scope,
    } = params;

    // Modal spinner doesn't display without this delay
    await delay(500);

    return {
      version: await http.get(`/api/client-version/${clientSlug}/${version}/`),
      chain: searchCtx
        ? await http.get(`/api/version-toc-chain/${clientSlug}/${version}/?s=${encodeURIComponent(searchCtx)}`)
        : [],
      scope,
    };
  },
  (state, response) => ({
    ...state,
    clientVersion: response.version,
    scopeCodeLookup: _.mapValues(
      _.keyBy(response.version.toc, 'uuid'),
      () => ({ expanded: response.version.toc.length <= 1 }),
    ),
    firstTocFetch: false,
    scopeTocLookup: {
      ..._.reduce(
        response.chain,
        (result, entry) => {
          const key = `${entry.doc_id}${entry.code_uuid}`;

          result[key] = {
            loading: false,
            expanded: true,
            ...entry,
          };

          return result;
        },
        {},
      ),
    },
  }),
);

const setScope = redux.simpleAction(
  'SET_SCOPE',
  (state) => ({
    ...state,
    settingScope: true,
  }),
);

const setScopeDone = redux.simpleAction(
  'SET_SCOPE_DONE',
  (state) => ({
    ...state,
    settingScope: false,
  }),
);

export const previewVersion = redux.action(
  'PREVIEW_VERSION',
  (uuid) => async (http) => await http.get(`/api/code-versions/${uuid}/`),
  (state, version) => {
    const docId = _.get(version, 'toc.0.sections.0.doc_id');

    return {
      ...state,
      defaultDocId: docId,
      previewVersion: version,
      previewCode: null,
      selectedVersion: null,
      navCodeLookup: _.mapValues(
        _.keyBy(version.toc, 'uuid'),
        () => ({ expanded: version.toc.length <= 1 }),
      ),
    };
  },
);

export const previewCode = redux.action(
  'PREVIEW_CODE',
  (uuid) => async (http) => await http.get(`/api/code-toc/${uuid}/`),
  (state, code) => {
    const docId = _.get(code, 'sections.0.doc_id');

    return {
      ...state,
      defaultDocId: docId,
      previewCode: code,
      selectedVersion: null,
      previewVersion: null,
      navCodeLookup: { [code.uuid]: { expanded: true } },
    };
  },
);

export const highlightSection = redux.action(
  'HIGHLIGHT_SECTION',
  (section_id, search_ctx) => async (http) => {
    return await http.get(`/api/highlight/${section_id}/?s=${encodeURIComponent(search_ctx)}`);
  },
  (state, terms) => ({
    ...state,
    highlightTerms: terms,
  })
);

export const highlightMinutes = redux.action(
  'HIGHLIGHT_MINUTES',
  (minuteId, search_ctx) => async (http) => {
    return await http.get(
      `/api/highlight-minutes/${minuteId}/?s=${encodeURIComponent(search_ctx)}`
    );
  },
  (state, terms) => ({
    ...state,
    highlightTerms: terms,
  })
);

export const getSectionInfo = redux.action(
  'GET_SECTION_INFO',
  (clientSlug, versionUUID, codeSlug, docId) => async (http) => {
    return await http.get(
      `/api/sec-info/${clientSlug}/${versionUUID}/${codeSlug}/${encodeURIComponent(docId)}/`
    );
  },
  (state, currSection) => {
    const selectedCode = _.find(
      state.selectedVersion.toc,
      (toc) => toc.uuid == currSection.code_uuid,
    );
    const docId = _.get(selectedCode, 'sections.0.doc_id');

    return {
      ...state,
      currSection,
      navCodeLookup: {
        ...state.navCodeLookup,
        [currSection.code_uuid]: {
          ..._.get(state.navCodeLookup, currSection.code_uuid, {}),
          expanded: true,
        },
      },
      highlightTerms: null,
      highlightMarks: {},
      currMark: null,
      selectedCode: {
        ..._.pick(selectedCode, ['slug', 'title']),
        docId,
      },
    };
  }
);

export const getPreviewVersionSecInfo = redux.action(
  'GET_PREVIEW_VERSION_SEC_INFO',
  (versionUUID, codeSlug, docId) => async (http) => {
    return await http.get(
      `/api/preview-version-sec-info/${versionUUID}/${codeSlug}/${encodeURIComponent(docId)}/`
    );
  },
  (state, currSection) => {
    const selectedCode = _.find(
      state.previewVersion.toc,
      (toc) => toc.uuid == currSection.code_uuid,
    );
    const docId = _.get(selectedCode, 'sections.0.doc_id');

    return {
      ...state,
      currSection,
      selectedCode: {
        ..._.pick(selectedCode, ['slug', 'title']),
        docId,
      },
    };
  }
);

export const getPreviewCodeSecInfo = redux.action(
  'GET_PREVIEW_CODE_SEC_INFO',
  (codeUUID, docId) => async (http) => {
    return await http.get(
      `/api/preview-code-sec-info/${codeUUID}/${encodeURIComponent(docId)}/`
    );
  },
  'currSection',
);

export const getFirstMatch = redux.action(
  'GET_FIRST_MATCH',
  (clientSlug, searchCtx) => async (http) => http.get(
    `/api/first-match/${clientSlug}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'nextMatch',
);

export const getNextMatch = redux.action(
  'GET_NEXT_MATCH',
  (sectionID, searchCtx) => async (http) => http.get(
    `/api/next-match/${sectionID}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'nextMatch',
);

export const getPrevMatch = redux.action(
  'GET_PREV_MATCH',
  (sectionID, searchCtx) => async (http) => http.get(
    `/api/prev-match/${sectionID}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'prevMatch',
);

export const getLastMinutesMatch = redux.action(
  'GET_LAST_MINUTES_MATCH',
  (clientSlug, searchCtx) => async (http) => http.get(
    `/api/last-minutes-match/${clientSlug}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'prevMatch',
);

export const getNextMinutesMatch = redux.action(
  'GET_NEXT_MINUTES_MATCH',
  (minutesId, searchCtx) => async (http) => http.get(
    `/api/next-minutes-match/${minutesId}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'nextMatch',
);

export const getPrevMinutesMatch = redux.action(
  'GET_PREV_MINUTES_MATCH',
  (minutesId, searchCtx) => async (http) => http.get(
    `/api/prev-minutes-match/${minutesId}/?s=${encodeURIComponent(searchCtx)}`
  ),
  'prevMatch',
);

export const addHighlightMarks = redux.simpleAction(
  'ADD_HIGHLIGHT_MARKS',
  (state, { origDocIdx, marks }) => {
    const {
      highlightMarks,
      currMark,
      prevMatch,
      nextMatch,
    } = state;

    let newCurrMark = currMark;
    if (
      marks.length && (
        _.isEmpty(highlightMarks) || // first load
        !!prevMatch || // loading previous
        !!nextMatch || // loading next
        !currMark // no current
      )
    ) {
      newCurrMark = !!prevMatch ? _.last(marks) : _.first(marks);
    }

    return {
      ...state,
      prevMatch: null,
      nextMatch: null,
      highlightMarks: {
        ...state.highlightMarks,
        [origDocIdx]: marks,
      },
      currMark: newCurrMark,
    };
  },
);

export const gotoNextMatch = redux.simpleAction(
  'GOTO_NEXT_MATCH',
  (state, params) => {
    const { marks }  = params;
    const { currMark } = state;

    const index = _.indexOf(marks, currMark);

    return {
      ...state,
      currMark: _.get(marks, _.min([marks.length - 1, index + 1])),
    };
  }
);

export const gotoPrevMatch = redux.simpleAction(
  'GOTO_PREV_MATCH',
  (state, params) => {
    const { marks }  = params;
    const { currMark } = state;

    const index = _.indexOf(marks, currMark);

    return {
      ...state,
      currMark: _.get(marks, _.max([0, index - 1])),
    };
  }
);

export const setCurrMark = redux.simpleAction(
  'SET_CURR_MARK',
  'currMark',
);

export const toggleCompare = redux.simpleAction(
  'TOGGLE_COMPARE',
  (state) => ({
    ...state,
    compareSectionError: null,
    compareOpen: !state.compareOpen,
  }),
);

export const compareVersion = redux.simpleAction(
  'COMPARE_VERSION',
  (state, versionToCompare) => ({
    ...state,
    versionToCompare,
    // Clear section if turning compare off
    sectionToCompare: versionToCompare ? state.sectionToCompare : null,
    compareSectionError: null,
  }),
);

export const clearSection = redux.simpleAction(
  'CLEAR_SECTION',
  (state) => ({
    ...state,
    currSection: null,
    firstTocFetch: true,
    selectedCode: null,
    highlightTerms: null,
    nextMatch: null,
    prevMatch: null,
  }),
);

export const tocKey = (code, entry) => {
  return `${entry.doc_id}${code.uuid}`;
};

export const tocChainMissing = redux.simpleAction(
  'TOC_CHAIN_MISSING',
  (state, params) => {
    const {
      ctx,
      rootEntry,
      code,
      docID,
    } = params;

    const rootKey = tocKey(code, rootEntry);
    const entryKey = `${docID}${code.uuid}`;

    const root = _.get(state, [`${ctx}TocLookup` , rootKey]);

    return {
      ...state,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [rootKey]: {
          ..._.get(state, [`${ctx}TocLookup` , rootKey], {}),
          loading: !root,
          expanded: true,
        },
        [entryKey]: {
          ..._.get(state, [`${ctx}TocLookup` , entryKey], {}),
          loading: true,
          expanded: true,
        },
      },
    };
  },
);

export const getTocChain = redux.action(
  'GET_TOC_CHAIN',
  (params) => async (http) => {
    const {
      code,
      docID,
    } = params;

    if (typeof document !== 'undefined') {
      await delay(500);
    }

    return {
      chain: await http.get(
        `/api/toc-chain/${code.uuid}/${encodeURIComponent(docID)}/`
      ),
      params,
    };
  },
  (state, response) => {
    const {
      ctx,
      code,
    } = response.params;

    return {
      ...state,
      firstTocFetch: false,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        ..._.reduce(
          response.chain,
          (result, entry) => {
            const key = `${entry.doc_id}${code.uuid}`;

            result[key] = {
              ..._.get(state, [`${ctx}TocLookup` , key], {}),
              loading: false,
              expanded: true,
              ...entry,
            };

            return result;
          },
          {},
        ),
      },
    };
  },
);

export const getSectionToc = redux.action(
  'GET_SECTION_TOC',
  (params) => async (http) => {
    const { entry } = params;

    if (typeof document !== 'undefined') {
      await delay(500);
    }

    return {
      ...params,
      entry: await http.get(`/api/section-toc/${entry.id}/`),
    };
  },
  (state, params) => {
    const {
      ctx,
      entry,
      code,
    } = params;

    return {
      ...state,
      firstTocFetch: false,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          loading: false,
          ...entry,
        },
        // Default new children to share parent selected
        ..._.reduce(
          entry.children,
          (result, child_entry) => {
            result[tocKey(code, child_entry)] = {
              selected: _.get(state, [`${ctx}TocLookup`, tocKey(code, entry), 'selected'], false),
            };

            return result;
          },
          {},
        ),
      },
    };
  }
);

export const getToc = redux.action(
  'GET_TOC',
  (params) => async (http) => {
    const { entry } = params;

    if (typeof document !== 'undefined') {
      await delay(500);
    }

    return {
      ...params,
      entry: await http.get(`/api/toc/${entry.id}/`),
    };
  },
  (state, result) => {
    const {
      ctx,
      entry,
      code,
    } = result;

    return {
      ...state,
      firstTocFetch: false,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          loading: false,
          ...entry,
        },
        // Default new children to share parent selected
        ..._.reduce(
          entry.children,
          (result, child_entry) => {
            result[tocKey(code, child_entry)] = {
              selected: _.get(state, [`${ctx}TocLookup`, tocKey(code, entry), 'selected'], false),
            };

            return result;
          },
          {},
        ),
      },
    };
  }
);

export const markTocLoading = redux.simpleAction(
  'TOC_LOADING',
  (state, params) => {
    const {
      ctx,
      code,
      entry,
    } = params;

    return {
      ...state,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          loading: true,
          expanded: true,
        },
      },
    };
  },
);

export const expandToc = redux.simpleAction(
  'EXPAND_TOC',
  (state, params) => {
    const {
      ctx,
      code,
      entry,
    } = params;

    return {
      ...state,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          expanded: true,
        },
      },
    };
  },
);

export const collapseToc = redux.simpleAction(
  'COLLAPSE_TOC',
  (state, params) => {
    const {
      ctx,
      code,
      entry,
    } = params;

    return {
      ...state,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          expanded: false,
        },
      },
    };
  },
);

export const codeToggle = redux.simpleAction(
  'CODE_TOGGLE',
  (state, params) => {
    const {
      ctx,
      uuid,
    } = params;

    return {
      ...state,
      [`${ctx}CodeLookup`]: {
        ...state[`${ctx}CodeLookup`],
        [uuid]: {
          ..._.get(state, [`${ctx}CodeLookup`, uuid], {}),
          expanded: !state[`${ctx}CodeLookup`][uuid].expanded,
        },
      },
    };
  },
);

export const minutesKey = (params) => {
  const {
    year,
    month,
    minuteID,
  } = params;

  return `${year}${month || ''}${minuteID || ''}`;
};

export const toggleMinutesHeader = redux.simpleAction(
  'TOGGLE_MINUTES_HEADER',
  (state) => ({
    ...state,
    minutesOpen: !state.minutesOpen,
  }),
);

export const showMinutesHeader = redux.simpleAction(
  'SHOW_MINUTES_HEADER',
  (state) => ({
    ...state,
    minutesOpen: true,
  }),
);

export const toggleMinutes = redux.simpleAction(
  'TOGGLE_MINUTES',
  (state, params) => {
    const key = minutesKey(params);

    return {
      ...state,
      minutesLookup: {
        ...state.minutesLookup,
        [key]: {
          ..._.get(state.minutesLookup, key, {}),
          expanded: !_.get(state.minutesLookup, [key, 'expanded'], false),
        },
      },
    };
  },
);

export const markMinutesLoading = redux.simpleAction(
  'MINUTES_LOADING',
  (state, params) => {
    const key = minutesKey(params);

    return {
      ...state,
      minutesLookup: {
        ...state.minutesLookup,
        [key]: {
          ..._.get(state.minutesLookup, key, {}),
          loading: true,
          expanded: true,
        },
      },
    };
  },
);

export const getMinutesYear = redux.action(
  'GET_MINUTES_YEAR',
  (clientSlug, year) => async (http) => {
    if (typeof document !== 'undefined') {
      await delay(500);
    }

    return {
      year,
      months: await http.get(`/api/minutes-years/${clientSlug}/${year}/`),
    };
  },
  (state, result) => {
    const {
      year,
      months,
    } = result;

    const key = minutesKey({ year });

    return {
      ...state,
      minutesLookup: {
        ...state.minutesLookup,
        [key]: {
          ..._.get(state.minutesLookup, key, {}),
          expanded: true,
          loading: false,
          children: months,
        },
      },
    };
  },
);

export const getMinutesMonth = redux.action(
  'GET_MINUTES_MONTH',
  (clientSlug, year, month, delayResult=true) => async (http) => {
    if (delayResult && typeof document !== 'undefined') {
      await delay(500);
    }

    return {
      year,
      month,
      minutes: await http.get(`/api/minutes/${clientSlug}/${year}/${month}/`),
    };
  },
  (state, result) => {
    const {
      year,
      month,
      minutes,
    } = result;

    const key = minutesKey({ year, month });

    return {
      ...state,
      minutesLookup: {
        ...state.minutesLookup,
        [key]: {
          ..._.get(state.minutesLookup, key, {}),
          expanded: true,
          loading: false,
          children: minutes,
        },
      },
    };
  },
);

export const viewMinutes = redux.simpleAction(
  'VIEW_MINUTES',
  (state, minutes) => {
    const yearKey = minutesKey(_.pick(minutes, ['year']));
    const monthKey = minutesKey(_.pick(minutes, ['year', 'month']));

    return {
      ...state,
      currSection: minutes,
      minutesOpen: true,
      minutesLookup: {
        ...state.minutesLookup,
        [yearKey]: {
          ..._.get(state.minutesLookup, yearKey, {}),
          expanded: true,
        },
        [monthKey]: {
          ..._.get(state.minutesLookup, monthKey, {}),
          expanded: true,
        },
      },
    };
  },
);

export const clearSelected = redux.simpleAction(
  'CLEAR_SELECTED',
  (state, version) => ({
    ...state,
    scopeCodeLookup: _.mapValues(
      _.keyBy(version.toc, 'uuid'),
      () => ({ expanded: version.toc.length <= 1 }),
    ),
    scopeTocLookup: {},
  }),
);

const getTocChildren = (tocLookup, code, entry) => {
  return _.reduce(
    _.get(tocLookup, [tocKey(code, entry), 'children'], []),
    (result, childEntry) => {
      result.push(tocKey(code, childEntry));

      if (childEntry.has_children) {
        result = [
          ...result,
          ...getTocChildren(tocLookup, code, childEntry),
        ];
      }

      return result;
    },
    [],
  );
};

const getCodeChildren = (params) => {
  const {
    version,
    tocLookup,
    code,
  } = params;

  const sections = (
    code.sections ||
    _.find(version.toc, (c) => c.uuid == code.uuid).sections
  );

  return _.reduce(
    sections,
    (result, section) => {
      const entry = { doc_id: section.doc_id };

      result.push(tocKey(code, entry));

      if (section.has_children) {
        result = [
          ...result,
          ...getTocChildren(tocLookup, code, entry),
        ];
      }

      return result;
    },
    [],
  );
};

export const toggleCodeSelect = redux.simpleAction(
  'TOGGLE_CODE_SELECT',
  (state, params) => {
    const {
      version,
      ctx,
      code,
    } = params;

    const newSelected = !state[`${ctx}CodeLookup`][code.uuid].selected;

    const childEntries = _.reduce(
      getCodeChildren({version, tocLookup: state[`${ctx}TocLookup`], code}),
      (result, key) => {
        result[key] = {
          ..._.get(state, [`${ctx}TocLookup`, key]),
          selected: newSelected,
          partial: false,
        };

        return result;
      },
      {},
    );

    return {
      ...state,
      [`${ctx}CodeLookup`]: {
        ...state[`${ctx}CodeLookup`],
        [code.uuid]: {
          ..._.get(state, [`${ctx}CodeLookup`, code.uuid], {}),
          selected: newSelected,
          partial: false,
        },
      },
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        ...childEntries,
      },
    };
  },
);

const getParentSections = (lookup, code, entry, chain = []) => {
  let parentEntry;

  if (entry) {
    // Push section on to chain
    chain.push(tocKey(code, entry));

    // Keep walking if there's a parent section
    if (_.get(entry, 'parent_doc_id')) {
      parentEntry = { doc_id: entry.parent_doc_id };

      return getParentSections(lookup, code, lookup[tocKey(code, parentEntry)], chain);
    }
  }

  return chain;
};

const getEntireChain = (lookup, code, entry, chain = []) => {
  let child;

  chain.push(entry);

  let parentEntry;
  if (_.get(entry, 'parent_doc_id')) {
    // Sections
    parentEntry = { doc_id: entry.parent_doc_id };
    parentEntry = {
      ...parentEntry,
      ..._.get(lookup, [tocKey(code, parentEntry)]),
    };

    // Keep walking
    return getEntireChain(lookup, code, parentEntry, chain);
  } else {
    // TOCs
    for (let key in lookup) {
      child = _.find(
        lookup[key].children,
        (c) => (
          lookup[key].code_uuid == code.uuid &&
          _.get(c, 'doc_id') == entry.doc_id
        ),
      );

      // We found what we were looking for
      if (!!child) {
        _.set(chain, `[${chain.length - 1}].title`, child.title);

        parentEntry = lookup[key];

        // Keep walking
        return getEntireChain(lookup, code, parentEntry, chain);
      }
    }
  }

  return chain;
};

export const toggleTocSelect = redux.simpleAction(
  'TOGGLE_TOC_SELECT',
  (state, params) => {
    const {
      ctx,
      code,
      entry,
    } = params;

    const oldSelected = _.get(
      state,
      [`${ctx}TocLookup`, tocKey(code, entry), 'selected'],
      false,
    );
    const oldPartial = _.get(
      state,
      [`${ctx}TocLookup`, tocKey(code, entry), 'partial'],
      false,
    );
    const newSelected = !(oldSelected || oldPartial);

    const tocLookup = state[`${ctx}TocLookup`];
    let childKeys = getTocChildren(tocLookup, code, entry);

    let childEntries = _.reduce(
      childKeys,
      (result, key) => {
        result[key] = {
          ..._.get(state, [`${ctx}TocLookup`, key]),
          selected: newSelected,
          partial: false,
        };

        return result;
      },
      {},
    );

    const newState = {
      ...state,
      [`${ctx}TocLookup`]: {
        ...state[`${ctx}TocLookup`],
        [tocKey(code, entry)]: {
          ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
          selected: newSelected,
          partial: false,
        },
        ...childEntries,
      },
    };

    const lookupEntry = {
      ...entry,
      ..._.get(state, [`${ctx}TocLookup`, tocKey(code, entry)], {}),
    };

    // Recalculate section partial flags
    const parentEntries = _.reduce(
      getParentSections(newState[`${ctx}TocLookup`], code, lookupEntry),
      (result, key) => {
        const children = getTocChildren(
          newState[`${ctx}TocLookup`],
          code,
          _.get(newState, [`${ctx}TocLookup`, key]),
        );

        const numSelected = _.sumBy(
          children,
          (childKey) => _.get(newState, [`${ctx}TocLookup`, childKey, 'selected'], false)
            ? 1
            : 0,
        );

        result[key] = {
          ..._.get(newState, [`${ctx}TocLookup`, key]),
          partial: numSelected > 0 && numSelected != children.length,
        };

        // Add / remove selected for parents with all children selected
        if (children.length) {
          result[key].selected = numSelected == children.length;
        }

        return result;
      },
      {},
    );

    newState[`${ctx}TocLookup`] = {
      ...newState[`${ctx}TocLookup`],
      ...parentEntries,
    };

    // Recalculate code partial flags
    const codeChildren = getCodeChildren({tocLookup: newState[`${ctx}TocLookup`], code});

    const numSelected = _.sumBy(
      codeChildren,
      (childKey) => _.get(newState, [`${ctx}TocLookup`, childKey, 'selected'], false)
        ? 1
        : 0,
    );
    newState[`${ctx}CodeLookup`][code.uuid] = {
      ...newState[`${ctx}CodeLookup`][code.uuid],
      selected: _.some(
        codeChildren,
        (childKey) => _.get(newState, [`${ctx}TocLookup`, childKey, 'selected'], false),
      ),
      partial: numSelected > 0 && numSelected != codeChildren.length,
    };

    return newState;
  },
);

export const setPreviewTheme = redux.simpleAction(
  'SET_PREVIEW_THEME',
  (state, previewTheme) => ({
    ...state,
    previewTheme: !!previewTheme
      ? _.omitBy(previewTheme, _.isEmpty)
      : null,
  }),
);

export const setViewedHash = redux.simpleAction(
  'SET_VIEWED_HASH',
  (state, hash) => ({
    ...state,
    viewedHash: _.replace(hash, 'rid-', ''),
  }),
);

let loadMissingHash;

export const codesMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const { type } = action;
    const { codes } = getState();
    let entry;

    if (type == 'CODES: EXPAND_TOC') {
      const { ctx, code, entry } = action.value;

      if (!_.get(codes, [`${ctx}TocLookup`, tocKey(code, entry), 'id'])) {
        dispatch(markTocLoading(action.value));

        if (_.get(entry, 'type', 'section') == 'section') {
          dispatch(getSectionToc(action.value));
        } else {
          dispatch(getToc(action.value));
        }
      }
    } else if (type == 'CODES: TOC_CHAIN_MISSING') {
      entry = action.value;

      dispatch(getTocChain(entry));
    } else if (type == 'CODES: SET_VIEWED_HASH') {
      // If scrolling sets the viewed hash to a TOC we haven't loaded, load it
      let viewedHash = action.value;
      let currSection = codes.currSection;

      if (viewedHash && currSection) {
        const code = { uuid: currSection.code_uuid };
        viewedHash = _.replace(viewedHash, 'rid-', '');
        const entry = { doc_id: viewedHash };

        let chain = getEntireChain(codes.navTocLookup, code, entry);

        // Couldn't find entry in lookup
        if (
          chain.length == 1 &&
          !_.get(codes, [`navTocLookup`, tocKey(code, chain[0]), 'id'])
        ) {
          // Create debounced function once with proper scope
          if (!loadMissingHash) {
            loadMissingHash = _.debounce(
              () => {
                // Fetch latest state values
                currSection = getState().codes.currSection;
                viewedHash = getState().codes.viewedHash;

                if (!viewedHash || currSection.pdf_path) {
                  // No longer in need of a toc. Bail.
                  return;
                }

                const lookup = getState().codes.navTocLookup;
                const code = { uuid: currSection.code_uuid};
                let rootEntry = { doc_id: currSection.root_section_doc_id };

                // We don't know what TOC this entry belongs to. This attempts
                // to find rootEntry by iterating the DOM elements in reverse.
                const tocs = document.querySelectorAll('.toc-destination');
                let el = document.querySelector(`#rid-${viewedHash.replace(/\./g, '\\.')}.toc-destination`);
                if (!el) {
                  return;
                }

                let entry = { };

                // Start at element and back up until you find something
                let index = _.indexOf(tocs, el);
                do {
                  if (index < 0) {
                    break;
                  }

                  el = tocs[index];
                  entry.doc_id = _.replace(el.id, 'rid-', '');

                  chain = getEntireChain(lookup, code, entry);

                  // We found something we know about if the chain includes
                  // more than just the entry
                  if (chain.length > 1) {
                    rootEntry = chain[0];
                    break;
                  }

                  index -= 1;
                } while (index >= 0);

                if (!_.get(lookup, [tocKey(code, rootEntry), 'loading'])) {
                  // We haven't loaded it yet
                  dispatch(tocChainMissing({
                    ctx: 'nav',
                    rootEntry,
                    code: { uuid: currSection.code_uuid },
                    docID: viewedHash,
                    firstTocFetch: false,
                  }));
                }
              },
              300,
            );
          }

          loadMissingHash();
        }
      }
    } else if (type == 'CODES: GET_TOC_CHAIN_SUCCESS') {
      entry = action.value.params;

      if (entry.fromScope) {
        setTimeout(async () => {
          if (_.get(codes, 'selectedVersion.toc.length') > 1) {
            await dispatch(codeToggle({ ctx: 'scope', uuid: entry.code.uuid }));
          }
          await dispatch(toggleTocSelect({
            code: entry.code,
            entry: {
              doc_id: entry.docID,
            },
            ctx: 'scope',
          }));

          if (typeof document !== 'undefined') {
            const queryString = `.scope-input .toc-link[data-docid="${entry.docID}"]`;

            // Wait for render
            setTimeout(() => {
              const scopeInput = document.querySelector('.scope-input');
              const tocEntry = document.querySelector(queryString);

              smoothScrollTo(
                scopeInput,
                tocEntry.parentElement.offsetTop - SCOPE_INPUT_PADDING - (scopeInput.offsetHeight / 2),
              );
            }, 350);
          }
        }, 10);
      } else if (typeof document !== 'undefined') {
        // Delay for render
        setTimeout(() => {
          const queryString = `.codenav a[data-docid="${entry.docID}"][data-codeuuid="${entry.code.uuid}"]`;
          const codeNav = document.querySelector('.codenav__toc');
          const tocEntry = document.querySelector(queryString);
          if (tocEntry) {
            smoothScrollTo(
              codeNav,
              Math.max(
                offsetTop(tocEntry) - SCOPE_INPUT_PADDING - (codeNav.offsetHeight / 2),
                0,
              ),
            );
          }

          const location = window.location;
          if (location.hash && codes.firstTocFetch) {
            const hash = _.replace(location.hash, '#rid-', '');
            const link = document.querySelector(
              `.codenav a[data-docid="${hash}"][data-codeuuid="${entry.code.uuid}"]`,
            );
            if (_.get(link, 'dataset.origDocIdx')) {
              location.href = link.pathname;
            }
          }
        }, 10);
      }
    } else if (type == '@@found/UPDATE_MATCH') {
      // Hide the left nav (on small screens) anytime the route changes
      if (
        !_.has(action.payload.location, 'state') && // Ignore initial page load
        typeof window !== 'undefined' &&
        window.innerWidth < 1000
      ) {
        dispatch(setLeftNavClosed(true));
      }
    } else if (type == 'CODES: TOGGLE_COMPARE') {
      // Kick off version compare with default
      let version = null;
      if (!codes.compareOpen) {
        version = defaultCompareVersion(codes, _.get(codes, 'selectedVersion'));
      }

      setTimeout(
        () => { dispatch(compareVersion(version)); },
        !!version ? 10 : 300, // Mitigates close animation stalling
      );
    } else if (
      type == 'CODES: ADD_HIGHLIGHT_MARKS' ||
      type == 'CODES: GOTO_NEXT_MATCH' ||
      type == 'CODES: GOTO_PREV_MATCH' ||
      type == 'CODES: SET_CURR_MARK'
    ) {
      const oldCurrMark = codes.currMark;
      setTimeout(async () => {
        const state = getState();
        // Moving from one search match to another
        if (state.codes.currMark != oldCurrMark) {
          const currMark = state.codes.currMark;
          if (oldCurrMark) {
            oldCurrMark.classList.remove('mark--selected');
          }

          if (currMark) {
            currMark.classList.add('mark--selected');

            const scrollToMark = () => {
              const sectionBody = document.querySelector('.codenav__section-body');
              sectionBody.scrollTop = (
                offsetTop(currMark) - 130 - (sectionBody.offsetHeight / 2)
              );
            };

            if (oldCurrMark) {
              scrollToMark();
            } else {
              setTimeout(scrollToMark, 100);
            }
          }
        } else if (
          type == 'CODES: GOTO_NEXT_MATCH' ||
          type == 'CODES: GOTO_PREV_MATCH'
        ) {
          // Navigating didn't do anything. Load another result
          let sectionID = _.get(state.codes, 'currSection.id');
          if (state.codes.currMark) {
            sectionID = state.codes.currMark.getAttribute('data-sectionid');
          }
          const searchCtx = state.search.searchContext;
          const {
            urlPrefix,
            urlClient,
            urlVersion,
            goto,
            errorModal,
          } = action.value;

          // Grab it if we haven't already
          if (type == 'CODES: GOTO_NEXT_MATCH') {
            if (!state.codes.nextMatch && !state.codes.getNextMatchPending) {
              try {
                await dispatch(getNextMatch(sectionID, searchCtx));

                const nextMatch = getState().codes.nextMatch;
                if (!!nextMatch) {
                  // Just jump if next match is within the current document
                  if (
                    nextMatch.code_slug == _.get(state.codes, 'currSection.code_slug') &&
                    nextMatch.orig_doc_id == _.get(state.codes, 'currSection.orig_doc_id')
                  ) {
                    await dispatch(setCurrMark(null));
                    dispatch(jumpTo({
                      index: nextMatch.orig_doc_idx,
                      compareOpen: state.codes.compareOpen,
                    }));
                  } else {
                    goto(`/${urlPrefix}${urlClient}${urlVersion}${nextMatch.code_slug}/${nextMatch.doc_id}`);
                  }
                }
              } catch {
                errorModal.toggle();
              }
            }
          } else if (type == 'CODES: GOTO_PREV_MATCH') {
            if (!state.codes.prevMatch && !state.codes.getPrevMatchPending) {
              try {
                await dispatch(getPrevMatch(sectionID, searchCtx));

                const prevMatch = getState().codes.prevMatch;
                if (!!prevMatch) {
                  // Just jump if next match is within the current document
                  if (
                    prevMatch.code_slug == _.get(state.codes, 'currSection.code_slug') &&
                    prevMatch.orig_doc_id == _.get(state.codes, 'currSection.orig_doc_id')
                  ) {
                    await dispatch(setCurrMark(null));
                    dispatch(jumpTo({
                      index: prevMatch.orig_doc_idx,
                      compareOpen: state.codes.compareOpen,
                    }));
                  } else {
                    goto(`/${urlPrefix}${urlClient}${urlVersion}${prevMatch.code_slug}/${prevMatch.doc_id}`);
                  }
                }
              } catch {
                const currSection = _.get(state.codes, 'currSection');
                const currClient = _.get(state.codes, 'currClient');
                let displayError = true;
                try {
                  if (!_.has(currSection, 'date') && currClient.minutes_years.length > 0) {
                    // Ran out of codes
                    await dispatch(getLastMinutesMatch(currClient.slug, searchCtx));
                    const prevMatch = getState().codes.prevMatch;
                    if (!!prevMatch) {
                      const suffix = prevMatch.minute_order_idx > 0 ? `/${prevMatch.minute_order_idx}` : '';

                      goto(`/${urlPrefix}${urlClient}${urlVersion}m/${prevMatch.date_url}${suffix}`);
                    }
                    displayError = false;
                  }
                } finally {
                  if (displayError) {
                    errorModal.toggle();
                  }
                }
              }
            }
          }
        }
      }, 10);
    } else if (type == 'CODES: GET_CLIENT_VERSION_SUCCESS') {
      setTimeout(() => {
        dispatch(setScope(action.value.scope));
      }, 10);
    } else if (type == 'CODES: SET_SCOPE') {
      const version = _.get(codes, 'clientVersion');
      if (_.isNull(action.value)) {
        _.forEach(
          version.toc,
          (code) => dispatch(toggleCodeSelect({ ctx: 'scope', version, code })),
        );
      } else {
        _.forEach(
          action.value,
          (entry) => {
            const code = _.find(version.toc, (c) => c.slug == entry.code_slug);
            if (!_.has(entry, 'doc_id')) {
              dispatch(toggleCodeSelect({ ctx: 'scope', version, code }));
            } else {
              dispatch(toggleTocSelect({ ctx: 'scope', code, entry }));
            }
          },
        );
      }

      setTimeout(() => dispatch(setScopeDone()), 10);
    } else if (type == 'CODES: TOGGLE_MINUTES') {
      const params = action.value;
      if (!_.get(codes.minutesLookup, [minutesKey(params), 'children'])) {
        dispatch(markMinutesLoading(params));

        const clientSlug = _.get(getState().codes, 'currClient.slug');

        if (_.has(params, 'month')) {
          dispatch(getMinutesMonth(clientSlug, params.year, params.month));
        } else {
          dispatch(getMinutesYear(clientSlug, params.year));
        }

        return;
      }
    } else if (type == 'CODES: VIEW_MINUTES') {
      if (typeof document !== 'undefined') {
        setTimeout(() => {
          const queryString = `.codenav a[data-minuteid="${action.value.id}"]`;
          const codeNav = document.querySelector('.codenav__toc');
          const tocEntry = document.querySelector(queryString);
          if (tocEntry) {
            smoothScrollTo(
              codeNav,
              Math.max(
                offsetTop(tocEntry) - SCOPE_INPUT_PADDING - (codeNav.offsetHeight / 2),
                0,
              ),
            );
          }
        }, 10);
      }
    }

    // Calls reducer with action
    return next(action);
  };
};

// Selectors

export const defaultTheme = {
  header_background: '#FFFFFF',
  header_color: '#333333',
  subheader_background: '#4275BD',
  exclude_disclaimer: false,
  disclaimer:
    'This Code of Ordinances and/or any other documents that appear on this ' +
    'site may not reflect the most current legislation adopted by the ' +
    'Municipality. American Legal Publishing provides these ' +
    'documents for informational purposes only. These documents should not ' +
    'be relied upon as the definitive authority for local legislation. ' +
    'Additionally, the formatting and pagination of the posted documents ' +
    'varies from the formatting and pagination of the official copy. The ' +
    'official printed copy of a Code of Ordinances should be consulted ' +
    'prior to any action being taken.' +
    '\n\n' +
    'For further information regarding the official version of any of this ' +
    'Code of Ordinances or other documents posted on this site, please ' +
    'contact the Municipality directly or contact American Legal Publishing ' +
    'toll-free at 800-445-5588.',
};

export const getCodeTheme = createSelector(
  (state) => _.omitBy(
    _.pick(state.currClient, [
      'header_background',
      'header_color',
      'subheader_background',
      'disclaimer',
      'exclude_disclaimer',
      'logo',
    ]),
    (val, key) => {
      if (key === 'exclude_disclaimer') {
        return false;
      }

      return _.isEmpty(val);
    }
  ),
  (state) => state.previewTheme,
  (clientTheme, previewTheme) => ({
    ...defaultTheme,
    ...(
      _.isNil(previewTheme)
        ? clientTheme
        : previewTheme
    ),
  }),
);

export const getRenderType = createSelector(
  (found) => _.get(found, 'match.params.sectionRenderType'),
  (found) => _.get(found, 'match.params.uuid'),
  (found) => _.get(found, 'match.params.version'),
  (sectionRenderType, uuid, version) => {
    // Default to standard for navigating away from Nav
    if (sectionRenderType === 'codes') {
      return RenderType.NORMAL;
    } else if (uuid) {
      return RenderType.PREVIEW_CODE;
    } else if (version) {
      return RenderType.PREVIEW_VERSION;
    }

    return RenderType.NORMAL;
  }
);

export const getCurrencyInfo = createSelector(
  (codes) => codes.selectedVersion,
  (codes) => codes.versionToCompare,
  (selectedVersion, versionToCompare) => {
    // Compute diff if we're comparing
    if (selectedVersion && versionToCompare) {
      return diff(
        versionToCompare.currency_info,
        selectedVersion.currency_info,
      );
    }

    return _.get(selectedVersion, 'currency_info');
  },
);

export const getViewedToc = createSelector(
  (state) => state.currSection,
  (state) => state.viewedHash,
  (state) => state.navTocLookup,
  (state) => state.navCodeLookup,
  (state) => state.minutesLookup,
  (currSection, viewedHash, navTocLookup, navCodeLookup, minutesLookup) => {
    if (!currSection) {
      return '';
    }

    if (_.has(currSection, 'date')) {
      let entry = _.pick(currSection, 'year');
      if (_.get(minutesLookup, [minutesKey(entry), 'expanded'])) {
        entry.month = currSection.month;

        if (_.get(minutesLookup, [minutesKey(entry), 'expanded'])) {
          entry.minuteID = currSection.id;
        }
      }

      return {
        key: minutesKey(entry),
        title: undefined,
      };
    }

    const code = { uuid: _.get(currSection, 'code_uuid') };

    // Collapsed code. We're done
    if (!_.get(navCodeLookup, [code.uuid, 'expanded'])) {
      return {
        key: code.uuid,
        code_uuid: code.uuid,
        title: undefined,
      };
    }

    // Traverse tree from top. Bail when something is collapsed
    const entry = {
      doc_id: viewedHash || currSection.orig_doc_id,
    };
    const chain = getEntireChain(navTocLookup, code, entry);

    let lookupEntry;
    for (let index = chain.length - 1; index >= 0; index--) {
      lookupEntry = chain[index];
      if (!_.get(lookupEntry, 'expanded')) {
        break;
      }
    }

    return {
      key: tocKey(code, lookupEntry),
      title: _.get(lookupEntry, 'title'),
      code_uuid: code.uuid,
      lookupEntry,
    };
  },
);

export const getViewingPdf = createSelector(
  (codes) => codes.currSection,
  (currSection) => !!_.get(currSection, 'pdf_path'),
);

export const getCanDownloadScope = createSelector(
  (codes) => codes.selectedVersion,
  (version) => !!_.find(_.get(version, 'toc', []), (c) => !c.is_pdf),
);

export const getShowDownloadAndPrint = createSelector(
  (codes) => codes.selectedVersion,
  (codes) => codes.currSection,
  (version, currSection) => (
    !_.get(currSection, 'pdf_path') && !!_.find(_.get(version, 'toc', []), (c) => !c.is_pdf)
  ),
);

export const getScreenSmall = createSelector(
  (codes) => codes.screenWidth,
  (width) => width != null ? width < 1000 : null,
);

export const getVisibleMarks = (start, end, marks) => _.reduce(
  _.range(start + 1, end),
  (result, index) => _.concat(result, _.get(marks, index, [])),
  [],
);

const findPrevMinutes = (section, currClient, minutesLookup) => {
  const currMonth = _.get(minutesLookup, minutesKey(_.pick(section, ['year', 'month'])));
  const minutesIndex = _.findIndex(currMonth.children, ['id', section.id]);
  if (minutesIndex > 0) {
    const m = currMonth.children[minutesIndex - 1];
    const suffix = m.order_idx > 0 ? `/${m.order_idx}` : '';

    return `${m.year}/${m.month}/${m.day}${suffix}`;
  }

  const currYear = _.get(minutesLookup, minutesKey(_.pick(section, ['year'])));
  const monthIndex = _.findIndex(currYear.children, ['month', section.month]);
  if (monthIndex > 0) {
    return currYear.children[monthIndex - 1].bot_date + '/-1';
  }

  const yearIndex = _.findIndex(currClient.minutes_years, ['year', section.year]);
  if (yearIndex > 0) {
    return currClient.minutes_years[yearIndex - 1].bot_date + '/-1';
  }

  return null;
};

const findNextMinutes = (section, currClient, minutesLookup) => {
  const currMonth = _.get(minutesLookup, minutesKey(_.pick(section, ['year', 'month'])));
  const minutesIndex = _.findIndex(currMonth.children, ['id', section.id]);
  if (minutesIndex < (currMonth.children.length - 1)) {
    const m = currMonth.children[minutesIndex + 1];
    const suffix = m.order_idx > 0 ? `/${m.order_idx}` : '';

    return `${m.year}/${m.month}/${m.day}${suffix}`;
  }

  const currYear = _.get(minutesLookup, minutesKey(_.pick(section, ['year'])));
  const monthIndex = _.findIndex(currYear.children, ['month', section.month]);
  if (monthIndex < (currYear.children.length - 1)) {
    return currYear.children[monthIndex + 1].top_date;
  }

  const yearIndex = _.findIndex(currClient.minutes_years, ['year', section.year]);
  if (yearIndex < (currClient.minutes_years.length - 1)) {
    return currClient.minutes_years[yearIndex + 1].top_date;
  }

  return null;
};

export const getPrevDocUrl = createSelector(
  ({ found }) => _.get(found, 'match.params'),
  ({ codes }) => codes.currClient,
  ({ codes }) => codes.currSection,
  ({ codes }) => codes.minutesLookup,
  (match, currClient, currSection, minutesLookup) => {
    const urlClient = _.get(match, 'clientslug') ? `${match.clientslug}/` : '';
    const urlVersion = _.get(match, 'version') ? `${match.version}/` : `${match.uuid}/`;

    let urlPrefix = 'codes/';
    if (
      _.get(match, 'sectionRenderType') === 'preview'
      && _.get(match, 'uuid')
    ) {
      urlPrefix = 'preview/c/';
    } else if (_.get(match, 'sectionRenderType') === 'preview') {
      urlPrefix = 'preview/v/';
    }

    // Viewing minutes
    if (_.has(currSection, 'date')) {
      const prevMinutes = findPrevMinutes(currSection, currClient, minutesLookup);

      if (prevMinutes) {
        return `/${urlPrefix}${urlClient}${urlVersion}m/${prevMinutes}/`;
      }

      // Ran out of minutes. Go to overview
      return `/${urlPrefix}${urlClient}${urlVersion}overview`;
    }

    const urlPrevSection = _.get(match, 'version') && _.get(currSection, 'prev_doc')
      ? `${_.get(currSection, 'prev_doc.code_slug')}/`
      : '';

    let prevUrl = '';
    if (
      _.get(currSection, 'prev_doc')
      && _.get(match, 'uuid')
    ) {
      prevUrl = `/${urlPrefix}${urlClient}${urlVersion}${_.get(currSection, 'prev_doc.doc_id')}`;
    } else if (_.get(currSection, 'prev_doc')) {
      prevUrl = `/${urlPrefix}${urlClient}${urlVersion}${urlPrevSection}${_.get(currSection, 'prev_doc.doc_id')}`;
    } else if (
      _.get(match, 'sectionRenderType') === 'codes'
      && !!currSection
    ) {
      // Ran out of codes. Check minutes
      if (urlVersion == 'latest/' && _.get(currClient, 'minutes_years.length', 0)) {
        const lastYear = _.last(currClient.minutes_years);
        prevUrl = `/${urlPrefix}${urlClient}${urlVersion}m/${lastYear.bot_date}/-1`;
      } else {
        prevUrl = `/${urlPrefix}${urlClient}${urlVersion}overview`;
      }
    }

    return prevUrl;
  }
);

export const getNextDocUrl = createSelector(
  ({ found }) => _.get(found, 'match.params'),
  ({ codes }) => codes.currClient,
  ({ codes }) => codes.currSection,
  ({ codes }) => codes.selectedVersion,
  ({ codes }) => codes.minutesLookup,
  (match, currClient, currSection, selectedVersion, minutesLookup) => {
    const urlClient = _.get(match, 'clientslug') ? `${match.clientslug}/` : '';
    const urlVersion = _.get(match, 'version') ? `${match.version}/` : `${match.uuid}/`;

    let urlPrefix = 'codes/';
    if (
      _.get(match, 'sectionRenderType') === 'preview'
      && _.get(match, 'uuid')
    ) {
      urlPrefix = 'preview/c/';
    } else if (_.get(match, 'sectionRenderType') === 'preview') {
      urlPrefix = 'preview/v/';
    }

    // On overview with minutes
    if (
      !currSection &&
      urlVersion == 'latest/' &&
      _.get(currClient, 'minutes_years.length', 0)
    ) {
      const firstMinutes = _.get(currClient, 'minutes_years.0.top_date');

      return `/${urlPrefix}${urlClient}${urlVersion}m/${firstMinutes}/`;
    }

    // Viewing minutes
    if (_.has(currSection, 'date')) {
      const nextMinutes = findNextMinutes(currSection, currClient, minutesLookup);

      if (nextMinutes) {
        return `/${urlPrefix}${urlClient}${urlVersion}m/${nextMinutes}/`;
      }

      currSection = null;
    }

    // On overview or ran out of minutes
    const nextDoc = !currSection ? _.get(selectedVersion, 'toc.0') : _.get(currSection, 'next_doc');

    let urlNextSection = '';
    if (!currSection) {
      urlNextSection = `${_.get(nextDoc, 'slug')}/`;
    } else if (!!_.get(match, 'version')) {
      urlNextSection = `${_.get(nextDoc, 'code_slug')}/`;
    }

    const nextDocId = !currSection
      ? _.get(nextDoc, 'sections.0.doc_id')
      : _.get(nextDoc, 'doc_id');

    return nextDocId
      ? `/${urlPrefix}${urlClient}${urlVersion}${urlNextSection}${nextDocId}`
      : '';
  }
);

export const getNextDoc = createSelector(
  (codes) => codes.currSection,
  (codes) => codes.selectedVersion,
  (currSection, selectedVersion) => {
    return !currSection ? _.get(selectedVersion, 'toc.0') : _.get(currSection, 'next_doc');
  }
);

const initialState = {
  currClient: null,
  selectedVersion: null,
  currSection: null,
  compareOpen: false,
  sectionToCompare: null,
  versionToCompare: null,
  highlightTerms: null,
  highlightMarks: [],
  highlightIndex: -1,
  nextMatch: null,
  prevMatch: null,
  viewedHash: '',
  selectedCode: null,
  defaultDocId: '',
  firstTocFetch: true,
  leftNavClosed: false,
  navTocLookup: {},
  navCodeLookup: {},
  scopeTocLookup: {},
  scopeCodeLookup: {},
  minutesOpen: false,
  minutesLookup: {},
  settingScope: false,
  codeOptionsModal: null,
  previewVersion: null,
  previewCode: null,
  previewTheme: null,
  screenWidth: null,
};

// This is the Redux reducer which now
// handles the asynchronous actions defined above.
export default redux.reducer(initialState);
