import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import { UploadAdapter } from '../../utils/UploadAdapter';
import { StatefulForm } from '../../utils/StatefulForm';
import { updateClient } from '../../redux/admin';

import './SplashPage.scss';
import SearchDropdown from '../../components/SearchDropdown';

@connect(({ admin, clients }) => ({
  currClient: admin.currClient,
  updateClientPending: admin.updateClientPending,
  updateClientError: admin.updateClientError,
  allClientRegions: clients.allClientRegions,
}), { updateClient })
export default class SplashPage extends StatefulForm {
  constructor (props) {
    super(props);

    const { currClient } = props;

    this.editorRef = React.createRef();
    this.formUnchanged = this.formUnchanged.bind(this);
    this.changeRegion = this.changeRegion.bind(this);
    this.state = {
      editor: null,
      editorError: false,
      formValue: _.pick(
        currClient,
        [
          'address_name',
          'address_line_1',
          'address_line_2',
          'address_city',
          'address_state',
          'address_zip_code',
          'website_url',
          'order_code_url',
          'new_legislation_url',
          'meeting_minutes_url',
          'city_officials',
        ],
      ),
    };
  }

  async componentDidMount () {
    const $script = require('scriptjs');
    $script('//cdn.ckeditor.com/ckeditor5/41.3.1/classic/ckeditor.js', () => {
      if (!!window['ClassicEditor']) {
        const ClassicEditor = window['ClassicEditor'];

        ClassicEditor
          .create(
            this.editorRef.current,
            { extraPlugins: [
              (editor) => {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                  loader.on('upload:started', () => this.setState({ editorUploading: true }));
                  loader.on('upload:done', () => this.setState({ editorUploading: false }));

                  return new UploadAdapter(loader);
                };
              },
            ]}
          ).then((editor) => {
            this.setState({editor});
            editor.model.document.on('change:data', () => this.forceUpdate());
          });
      } else {
        this.setState({editorError: true});
      }
    });
  }

  async componentWillUnmount () {
    await _.result(this.state.editor, 'destroy', Promise.resolve());
  }

  formUnchanged () {
    if (!this.state.editor) {
      return true;
    }

    const { currClient } = this.props;
    const editorContent = _.result(this.state.editor, 'getData') || '';

    return (
      _.isMatch(currClient, this.state.formValue) &&
      editorContent == (currClient.splash_content || '')
    );
  }

  async changeRegion (val) {
    await this.setState((prevState) => ({
      ...prevState,
      formValue: {
        ...prevState.formValue,
        address_state: val,
      },
    }));
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      currClient,
      updateClient,
    } = this.props;

    await this.setState((prevState) => ({
      formValue: _.mapValues(
        prevState.formValue,
        (v) => _.isString(v) ? _.trim(v) : v,
      ),
    }));

    updateClient(
      currClient.slug,
      {
        splash_content: this.state.editor.getData(),
        ...this.state.formValue,
      },
    );
  }

  render () {
    const {
      formValue,
      editorUploading,
    } = this.state;

    const {
      currClient,
      updateClientPending,
      updateClientError,
      allClientRegions,
    } = this.props;

    if (!currClient) {
      return null;
    }

    let formErrors = null;
    if (_.has(updateClientError, 'non_field_errors')) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ !!updateClientError }/>
          <FormFeedback role="alert">
            { updateClientError.non_field_errors }
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <div>
        {
          !this.state.editor && (
            !this.state.editorError ?
              <div className="no-data">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :
              <div className="text-center" style={{paddingTop: '2rem'}}>
                <h3>Error loading the editor.</h3>
                Internet Explorer does not currently offer this tool.
              </div>
          )
        }
        {
          this.state.editor &&
          <div>
            <h3>Edit Splash Page</h3>
            <p>
              This is the first page of a city&#39;s code library that users see
              after selecting the location to view. Edit information, buttons and
              links here as they relate to that page.
            </p>
            <h4>Intro Text</h4>
          </div>
        }
        <textarea
          ref={this.editorRef}
          defaultValue={currClient.splash_content}
          style={{display: 'none'}}
        ></textarea>
        {
          this.state.editor &&
          <Form onSubmit={ this.submitForm }>
            <h4 className="splash-heading">Website</h4>
            <div className="row">
              <FormGroup className="col-lg-6">
                <Input
                  type="text"
                  name="website_url"
                  aria-label="Website"
                  placeholder="Website"
                  value={ formValue.website_url }
                  invalid={ _.has(updateClientError, 'website_url') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'website_url') }
                </FormFeedback>
              </FormGroup>
            </div>
            <h4 className="splash-heading">Address</h4>
            <div className="row">
              <FormGroup className="col-lg-6">
                <Label for="address_name">Name</Label>
                <Input
                  id="address_name"
                  name="address_name"
                  type="text"
                  placeholder="Name"
                  value={ formValue.address_name }
                  invalid={ _.has(updateClientError, 'address_name') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_name') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="address_line_1">Street Address 1</Label>
                <Input
                  id="address_line_1"
                  name="address_line_1"
                  type="text"
                  placeholder="Street Address 1"
                  value={ formValue.address_line_1 }
                  invalid={ _.has(updateClientError, 'address_line_1') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_line_1') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="address_line_2">Street Address 2</Label>
                <Input
                  id="address_line_2"
                  name="address_line_2"
                  type="text"
                  placeholder="Street Address 2"
                  value={ formValue.address_line_2 }
                  invalid={ _.has(updateClientError, 'address_line_2') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_line_2') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="address_zip_code">City</Label>
                <Input
                  id="address_city"
                  name="address_city"
                  type="text"
                  placeholder="City"
                  value={ formValue.address_city }
                  invalid={ _.has(updateClientError, 'address_city') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_city') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="address_zip_code">State</Label>
                {
                  !!allClientRegions &&
                  <SearchDropdown
                    data={ allClientRegions }
                    label="State"
                    value={ formValue.address_state }
                    onChange={ this.changeRegion }
                  />
                }
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_state') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="address_zip_code">Zip Code</Label>
                <Input
                  id="address_zip_code"
                  name="address_zip_code"
                  type="text"
                  placeholder="Zip Code"
                  value={ formValue.address_zip_code }
                  invalid={ _.has(updateClientError, 'address_zip_code') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'address_zip_code') }
                </FormFeedback>
              </FormGroup>
            </div>

            <h4 className="splash-heading">Officials</h4>
            <div className="row">
              <FormGroup className="col-lg-6">
                <Input
                  name="city_officials"
                  aria-label="Officials"
                  type="textarea"
                  placeholder="Officials"
                  value={ formValue.city_officials }
                  invalid={ _.has(updateClientError, 'city_officials') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'city_officials') }
                </FormFeedback>
              </FormGroup>
            </div>

            <h4 className="splash-heading">Optional URLs</h4>
            <div className="row">
              <FormGroup className="col-lg-6">
                <Label for="order_code_url">Order Code</Label>
                <Input
                  id="order_code_url"
                  name="order_code_url"
                  type="text"
                  placeholder="Order Code URL"
                  value={ formValue.order_code_url }
                  invalid={ _.has(updateClientError, 'order_code_url') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'order_code_url') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="new_legislation_url">New Legislation</Label>
                <Input
                  id="new_legislation_url"
                  name="new_legislation_url"
                  type="text"
                  placeholder="New Legislation URL"
                  value={ formValue.new_legislation_url }
                  invalid={ _.has(updateClientError, 'new_legislation_url') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'new_legislation_url') }
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-lg-6">
                <Label for="meeting_minutes_url">Meeting Minutes</Label>
                <Input
                  id="meeting_minutes_url"
                  name="meeting_minutes_url"
                  type="text"
                  placeholder="Meeting Minutes URL"
                  value={ formValue.meeting_minutes_url }
                  invalid={ _.has(updateClientError, 'meeting_minutes_url') }
                  onChange={ this.handleChange }
                />
                <FormFeedback role="alert">
                  { _.get(updateClientError, 'meeting_minutes_url') }
                </FormFeedback>
              </FormGroup>
            </div>

            { formErrors }

            <div className="am-page__bottom">
              <Button
                type="submit"
                color="success"
                className={classnames({'btn--wait': editorUploading})}
                disabled={editorUploading || updateClientPending || this.formUnchanged()}
              >
                Save
              </Button>
            </div>

          </Form>
        }
      </div>
    );
  }
}
