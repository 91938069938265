import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, Link, meta, redirect } from 'react-website';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../../utils/StatefulForm';
import { metaKey } from '../../utils/helpers';
import { setRecaptchaVisible } from '../../utils/recaptcha';
import { signup } from '../../redux/account';

import './Auth.scss';

@meta(() => ({ ...metaKey('title', 'Signup') }))
export default class SignupPage extends Component {
  state = { emailToBeVerfied: '' }

  constructor () {
    super();
    this.onSignup = this.onSignup.bind(this);
  }

  componentDidMount () {
    setRecaptchaVisible(true);
  }

  componentWillUnmount () {
    setRecaptchaVisible(false);
  }

  onSignup (email) {
    this.setState({ emailToBeVerfied: email });
  }

  render () {
    let register;
    if (this.state.emailToBeVerfied) {
      register =
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              Check Your Email
            </div>
            <div className="am-page__pane">
              <p>
                Thanks for signing up! We sent a verfication email to {this.state.emailToBeVerfied}.
                Click on the link in the email to verify your account, and then you will be able to log
                in to American Legal and access even more account features.
              </p>
            </div>
          </div>
        </div>
      ;
    } else {
      register = <SignupForm onSubmit={this.onSignup} />;
    }

    return (
      <div>
        <h3 className="secondary-header">Sign Up</h3>
        { register }
        <div className="auth__message">
          <p>
            Already have an American Legal account?
            <Link
              to="/login"
              className="auth__link"
            >
              Log in
            </Link>
            to your account here.
          </p>
        </div>
      </div>
    );
  }
}

@connect(({ account }) => ({
  user: account.user,
  signupPending: account.signupPending,
  signupError: account.signupError,
}), { goBack, signup, redirect })
class SignupForm extends StatefulForm {
  static propTypes = {
    signupPending : PropTypes.bool,
    signupError : PropTypes.object,
  }
  state = {
    firstGoDone: false,
    formValue: {
      name: '',
      email: '',
      password1: '',
      password2: '',
    },
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      onSubmit,
      signup,
    } = this.props;

    this.setState({firstGoDone: true});

    await signup(this.state.formValue);
    onSubmit(this.state.formValue.email);
  }

  render () {
    const {
      firstGoDone,
      formValue,
    } = this.state;
    const {
      goBack,
      signupPending,
      signupError,
    } = this.props;

    let formErrors = null;
    if (firstGoDone && _.has(signupError, 'non_field_errors')) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ !!signupError }/>
          <FormFeedback role="alert">
            { signupError.non_field_errors }
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <div className="am-page">
        <div className="am-page__content">
          <div className="am-page__header">
            Sign Up
          </div>
          <div className="am-page__pane">
            <p className="text-center">
              Sign up here to access more features with American Legal!
            </p>
            <Form onSubmit={ this.submitForm }>
              {/* Hack to disable autocomplete */}
              <input type="password" name="password" style={{display: 'none'}} />

              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  required
                  label="Name"
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={ formValue.name }
                  onChange={ this.handleChange }/>
              </FormGroup>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  required
                  id="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={ formValue.email }
                  invalid={ firstGoDone && _.has(signupError, 'email') }
                  onChange={ this.handleChange }/>
                <FormFeedback role="alert">
                  { _.get(signupError, 'email') }
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="password1">Password</Label>
                <Input
                  required
                  label="Password"
                  name="password1"
                  autoComplete="new-password"
                  placeholder="Password"
                  type="password"
                  value={ formValue.password1 }
                  invalid={ firstGoDone && _.has(signupError, 'password1') }
                  onChange={ this.handleChange }/>
                <FormFeedback role="alert">
                  { _.get(signupError, 'password1') }
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="password2">Confirm Password</Label>
                <Input
                  required
                  label="Confirm Password"
                  name="password2"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                  type="password"
                  value={ formValue.password2 }
                  invalid={ firstGoDone && _.has(signupError, 'password2') }
                  onChange={ this.handleChange }/>
                <FormFeedback role="alert">
                  { _.get(signupError, 'password2') }
                </FormFeedback>
              </FormGroup>

              { formErrors }

              <div className='am-page__bottom'>
                <Button
                  onClick={ goBack }
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="success"
                  disabled={ signupPending }
                >
                  Sign Up
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
