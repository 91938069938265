import React, { Component } from 'react';
import {
  Button,
  Collapse,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as _ from 'lodash';

export default class CodeProblemsModal extends Component {
  state = { open: false }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    const { problem, idx } = this.props;

    let color = 'warning';
    if (problem.level == 'error') {
      color = 'danger';
    }

    return (
      <div
        key={`problem-${idx}`}
        className={classnames(
          'problem',
          { 'problem--open': this.state.open },
        )}
      >
        <Button
          color={color}
          className={classnames(
            'problem-toggle',
            { 'open': this.state.open },
          )}
          onClick={this.toggle}
        >
          <div className="toggle-text">
            {
              problem.instances.length > 1 &&
              <b>{`[${problem.instances.length}] `}</b>
            }{problem.message}
          </div>
          <FontAwesomeIcon
            icon="angle-up"
            className="toggle-arrow"
          />
        </Button>
        <Collapse isOpen={this.state.open}>
          <div className="problem-info">
            {_.map(
              problem.instances,
              (instance, instanceIdx) => (
                <div
                  key={`prob-${idx}-inst-${instanceIdx}`}
                  className="problem-instance"
                >
                  <div className="file-path">{instance.file_path}</div>
                  <pre>{instance.more_info}</pre>
                </div>
              )
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}
