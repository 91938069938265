import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { goto } from 'react-website';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-up-n-down';
import * as _ from 'lodash';

import { CreateClientModal } from '../modals';
import './ClientDropdown.scss';
import {
  getClients,
  startClientSearch,
} from '../redux/admin';

@connect(({ admin }) => ({
  clients: admin.clients,
  getClientsPending: admin.getClientsPending,
  currPage: admin.currClientPage,
  currClient: admin.currClient,
  hasMoreClients: admin.hasMoreClients,
}), { getClients, startClientSearch, goto })
export default class ClientDropdown extends React.Component {
  static propTypes = {
    location : PropTypes.object.isRequired,
  }
  state = {
    open: false,
    searchText: '',
  }

  constructor () {
    super();

    this.searchInput = React.createRef();
    this.createClientModal = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.getClients = this.getClients.bind(this);
    this.debouncedSearch = _.debounce(this.onSearch, 500);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));

    if (this.state.open) {
      this.searchInput.current.focus();
    }
  }

  componentDidMount () {
    this.clearSearch();
  }

  async getClients () {
    const {
      getClients,
      currPage,
    } = this.props;

    await getClients({
      page: currPage + 1,
      search: this.state.searchText,
    });
  }

  async onSearchChange (val) {
    await this.setState({searchText: val});

    this.debouncedSearch();
  }

  async clearSearch () {
    await this.setState({searchText: ''});

    this.onSearch();
  }

  async onSearch () {
    const { startClientSearch } = this.props;

    // Triggers search and resets to page 1
    await startClientSearch();
  }

  render () {
    const {
      clients,
      getClientsPending,
      currClient,
      hasMoreClients,
      location,
      goto,
    } = this.props;

    const m = /\/admin\/(.+)\/(.+)/.exec(location.pathname);
    const clientPage = (m && m[2]) || 'splash';

    let currRegion = null;
    const clientItems = _.map(
      clients,
      (client) => {
        const items = [];

        if (currRegion != client.region_name) {
          currRegion = client.region_name;
          items.push(
            <DropdownItem
              key={ `region-${_.snakeCase(currRegion)}` }
              header
            >
              {currRegion}
            </DropdownItem>
          );
        }

        items.push(
          <DropdownItem
            aria-label={`${client.name}, ${client.region_name}`}
            key={ `client-${client.id}` }
            onClick={() => {
              goto(`/admin/${client.slug}/${clientPage}`);
              this.clearSearch();
            }}
          >
            {client.name}
          </DropdownItem>
        );

        return items;
      },
    );

    return (
      <div className="client-dropdown-container">
        <Dropdown
          id="client-dropdown"
          isOpen={this.state.open}
          toggle={this.toggle}
        >
          <DropdownToggle
            aria-label="Search locations"
            className={classnames('form-control', { placeholder: !currClient })}
            caret
          >
            <FontAwesomeIcon
              color="inherit"
              icon={faSearch}
            />
            { _.get(currClient, 'name') || 'Find and select a location to edit' }
          </DropdownToggle>
          <DropdownMenu>
            <div className="dropdown-search">
              <FormGroup>
                <Input
                  autoComplete="off"
                  innerRef={this.searchInput}
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={this.state.searchText}
                  onChange={(e) => this.onSearchChange(e.target.value)}
                />
                <Button
                  aria-label="Close"
                  close
                  className="search-close"
                  onClick={this.clearSearch}
                />
              </FormGroup>
            </div>
            <div className="client-items">
              <InfiniteScroll
                loadMore={this.getClients}
                hasMore={!getClientsPending && hasMoreClients}
                useWindow={false}
              >
                { clientItems }
              </InfiniteScroll>
              {
                getClientsPending &&
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
            </div>
          </DropdownMenu>
        </Dropdown>
        <Button
          color="primary"
          size="sm"
          onClick={() => this.createClientModal.current.wrappedInstance.toggle()}
        >
          Create Client
        </Button>
        <CreateClientModal ref={this.createClientModal} />
      </div>
    );
  }
}
