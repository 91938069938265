import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'react-website';
import {
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { deleteClient } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    deleteClientPending: admin.deleteClientPending,
  }),
  { deleteClient, redirect },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class DeleteClientModal extends Component {
  state = {
    deleteAllCodes: false,
    cannotBeUndone: false,
    open: false,
    params: [],
  }

  constructor () {
    super();

    this.deleteClient = this.deleteClient.bind(this);
    this.toggle = this.toggle.bind(this);
    this.disableInteraction = this.disableInteraction.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({
      open: !prevState.open,
      deleteAllCodes: prevState.open && prevState.deleteAllCodes,
      cannotBeUndone: prevState.open && prevState.cannotBeUndone,
    }));
  }

  async deleteClient () {
    const {
      currClient,
      deleteClient,
      redirect,
    } = this.props;

    await deleteClient(currClient.slug);
    redirect('/admin');
  }

  disableInteraction () {
    const { deleteClientPending } = this.props;
    const {
      deleteAllCodes,
      cannotBeUndone,
    } = this.state;

    return (
      deleteClientPending || !deleteAllCodes || !cannotBeUndone
    );
  }

  render () {
    const {
      currClient,
      deleteClientPending,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={deleteClientPending  ? undefined : this.toggle}>
          Delete Client
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p>Are you sure that you want to delete the <b>{_.get(currClient, 'name')}</b> client?</p>
            <div style={{display: 'inline-block', textAlign: 'left'}}>
              <FormGroup>
                <Input
                  id="delete_all"
                  type="checkbox"
                  checked={this.state.deleteAllCodes}
                  onChange={async () => {
                    await this.setState((prev) => ({deleteAllCodes: !prev.deleteAllCodes}));
                  }} />
                <Label for="delete_all" check>
                  I understand that this action will delete all of the client&#39;s codes.
                </Label>
              </FormGroup>
              <FormGroup>
                <Input
                  innerRef={this.cannotBeUndone}
                  id="undone"
                  type="checkbox"
                  checked={this.state.cannotBeUndone}
                  onChange={async () => {
                    await this.setState((prev) => ({cannotBeUndone: !prev.cannotBeUndone}));
                  }} />
                <Label for="undone" check>
                  I understand that this action cannot be undone.
                </Label>
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.toggle}
            disabled={deleteClientPending}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.deleteClient}
            disabled={this.disableInteraction()}
          >
            {
              deleteClientPending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
