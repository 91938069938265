// Add ES6 polyfill (for older browsers).
require('@babel/polyfill');

// Maintain CSS styles order.
require('bootstrap/dist/css/bootstrap.css').Bootstrap;
require('react-datepicker/dist/react-datepicker.css');
require('react-website/components/Loading.css');
require('./styles/style.scss');

// Run the application.
require('./render').default().catch((error) => console.error(error));
