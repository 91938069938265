import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, preload, redirect } from 'react-website';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import * as _ from 'lodash';

import './ManageAccount.scss';

@preload(async ({ dispatch, getState }) => {
  const { account, found } = getState();
  if (!account.user) {
    await dispatch(redirect(`/login?next=${_.get(found, 'match.location.pathname')}`));
  }
})
export default class ManageAccount extends Component {
  static propTypes = {
    children : PropTypes.node.isRequired,
  }

  render () {
    const { location, children } = this.props;

    return (

      <div className="account-page">
        <h3 className="secondary-header">Manage My Account</h3>
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              Manage My Account
              <div className="tabs-wrapper">
                <Nav tabs>
                  <NavItem>
                    <Link
                      to="/account/profile"
                      className={classnames('nav-link', { active: location.pathname == '/account/profile' })}
                    >
                      Profile
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/account/bookmarks"
                      className={classnames('nav-link', { active: location.pathname == '/account/bookmarks' })}
                    >
                      Bookmarks
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/account/saved-searches"
                      className={classnames('nav-link', { active: location.pathname == '/account/saved-searches' })}
                    >
                      Saved Searches
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/account/following"
                      className={classnames('nav-link', { active: location.pathname == '/account/following' })}
                    >
                      Following
                    </Link>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="am-page__pane">
              <TabContent>
                <TabPane>
                  { children }
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
