export const isoLangs = {
  'English': {
    'name': 'English',
    'slug': 'en',
  },
  'Afrikaans': {
    'name': 'Afrikaans',
    'slug': 'af',
  },
  'Albanian': {
    'name': 'Albanian',
    'slug': 'sq',
  },
  'Amharic': {
    'name': 'Amharic',
    'slug': 'am',
  },
  'Arabic': {
    'name': 'Arabic',
    'slug': 'ar',
  },
  'Armenian': {
    'name': 'Armenian',
    'slug': 'hy',
  },
  'Azerbaijani': {
    'name': 'Azerbaijani',
    'slug': 'az',
  },
  'Basque': {
    'name': 'Basque',
    'slug': 'eu',
  },
  'Belarusian': {
    'name': 'Belarusian',
    'slug': 'be',
  },
  'Bengali': {
    'name': 'Bengali',
    'slug': 'bn',
  },
  'Bosnian': {
    'name': 'Bosnian',
    'slug': 'bs',
  },
  'Bulgarian': {
    'name': 'Bulgarian',
    'slug': 'bg',
  },
  'Catalan': {
    'name': 'Catalan',
    'slug': 'ca',
  },
  'Cebuano': {
    'name': 'Cebuano',
    'slug': 'ceb',
  },
  'Chichewa': {
    'name': 'Chichewa',
    'slug': 'ny',
  },
  'Chinese (Simplified)': {
    'name': 'Chinese (Simplified)',
    'slug': 'zh-CN',
  },
  'Chinese (Traditional)': {
    'name': 'Chinese (Traditional)',
    'slug': 'zh-TW',
  },
  'Corsican': {
    'name': 'Corsican',
    'slug': 'co',
  },
  'Croatian': {
    'name': 'Croatian',
    'slug': 'hr',
  },
  'Czech': {
    'name': 'Czech',
    'slug': 'cs',
  },
  'Danish': {
    'name': 'Danish',
    'slug': 'da',
  },
  'Dutch': {
    'name': 'Dutch',
    'slug': 'nl',
  },
  'Esperanto': {
    'name': 'Esperanto',
    'slug': 'eo',
  },
  'Estonian': {
    'name': 'Estonian',
    'slug': 'et',
  },
  'Filipino': {
    'name': 'Filipino',
    'slug': 'tl',
  },
  'Finnish': {
    'name': 'Finnish',
    'slug': 'fi',
  },
  'French': {
    'name': 'French',
    'slug': 'fr',
  },
  'Frisian': {
    'name': 'Frisian',
    'slug': 'fy',
  },
  'Galician': {
    'name': 'Galician',
    'slug': 'gl',
  },
  'Georgian': {
    'name': 'Georgian',
    'slug': 'ka',
  },
  'German': {
    'name': 'German',
    'slug': 'de',
  },
  'Greek': {
    'name': 'Greek',
    'slug': 'el',
  },
  'Gujarati': {
    'name': 'Gujarati',
    'slug': 'gu',
  },
  'Haitian Creole': {
    'name': 'Haitian Creole',
    'slug': 'ht',
  },
  'Hausa': {
    'name': 'Hausa',
    'slug': 'ha',
  },
  'Hawaiian': {
    'name': 'Hawaiian',
    'slug': 'haw',
  },
  'Hebrew': {
    'name': 'Hebrew',
    'slug': 'iw',
  },
  'Hindi': {
    'name': 'Hindi',
    'slug': 'hi',
  },
  'Hmong': {
    'name': 'Hmong',
    'slug': 'hmn',
  },
  'Hungarian': {
    'name': 'Hungarian',
    'slug': 'hu',
  },
  'Icelandic': {
    'name': 'Icelandic',
    'slug': 'is',
  },
  'Igbo': {
    'name': 'Igbo',
    'slug': 'ig',
  },
  'Indonesian': {
    'name': 'Indonesian',
    'slug': 'id',
  },
  'Irish': {
    'name': 'Irish',
    'slug': 'ga',
  },
  'Italian': {
    'name': 'Italian',
    'slug': 'it',
  },
  'Japanese': {
    'name': 'Japanese',
    'slug': 'ja',
  },
  'Javanese': {
    'name': 'Javanese',
    'slug': 'jw',
  },
  'Kannada': {
    'name': 'Kannada',
    'slug': 'kn',
  },
  'Kazakh': {
    'name': 'Kazakh',
    'slug': 'kk',
  },
  'Khmer': {
    'name': 'Khmer',
    'slug': 'km',
  },
  'Kinyarwanda': {
    'name': 'Kinyarwanda',
    'slug': 'rw',
  },
  'Korean': {
    'name': 'Korean',
    'slug': 'ko',
  },
  'Kurdish (Kurmanji)': {
    'name': 'Kurdish (Kurmanji)',
    'slug': 'ku',
  },
  'Kyrgyz': {
    'name': 'Kyrgyz',
    'slug': 'ky',
  },
  'Lao': {
    'name': 'Lao',
    'slug': 'lo',
  },
  'Latin': {
    'name': 'Latin',
    'slug': 'la',
  },
  'Latvian': {
    'name': 'Latvian',
    'slug': 'lv',
  },
  'Lithuanian': {
    'name': 'Lithuanian',
    'slug': 'lt',
  },
  'Luxembourgish': {
    'name': 'Luxembourgish',
    'slug': 'lb',
  },
  'Macedonian': {
    'name': 'Macedonian',
    'slug': 'mk',
  },
  'Malagasy': {
    'name': 'Malagasy',
    'slug': 'mg',
  },
  'Malay': {
    'name': 'Malay',
    'slug': 'ms',
  },
  'Malayalam': {
    'name': 'Malayalam',
    'slug': 'ml',
  },
  'Maltese': {
    'name': 'Maltese',
    'slug': 'mt',
  },
  'Maori': {
    'name': 'Maori',
    'slug': 'mi',
  },
  'Marathi': {
    'name': 'Marathi',
    'slug': 'mr',
  },
  'Mongolian': {
    'name': 'Mongolian',
    'slug': 'mn',
  },
  'Myanmar (Burmese)': {
    'name': 'Myanmar (Burmese)',
    'slug': 'my',
  },
  'Nepali': {
    'name': 'Nepali',
    'slug': 'ne',
  },
  'Norwegian': {
    'name': 'Norwegian',
    'slug': 'no',
  },
  'Pashto': {
    'name': 'Pashto',
    'slug': 'ps',
  },
  'Persian': {
    'name': 'Persian',
    'slug': 'fa',
  },
  'Polish': {
    'name': 'Polish',
    'slug': 'pl',
  },
  'Portuguese': {
    'name': 'Portuguese',
    'slug': 'pt',
  },
  'Punjabi': {
    'name': 'Punjabi',
    'slug': 'pa',
  },
  'Romanian': {
    'name': 'Romanian',
    'slug': 'ro',
  },
  'Russian': {
    'name': 'Russian',
    'slug': 'ru',
  },
  'Samoan': {
    'name': 'Samoan',
    'slug': 'sm',
  },
  'Scots Gaelic': {
    'name': 'Scots Gaelic',
    'slug': 'gd',
  },
  'Serbian': {
    'name': 'Serbian',
    'slug': 'sr',
  },
  'Sesotho': {
    'name': 'Sesotho',
    'slug': 'st',
  },
  'Shona': {
    'name': 'Shona',
    'slug': 'sn',
  },
  'Sindhi': {
    'name': 'Sindhi',
    'slug': 'sd',
  },
  'Sinhala': {
    'name': 'Sinhala',
    'slug': 'si',
  },
  'Slovak': {
    'name': 'Slovak',
    'slug': 'sk',
  },
  'Slovenian': {
    'name': 'Slovenian',
    'slug': 'sl',
  },
  'Somali': {
    'name': 'Somali',
    'slug': 'so',
  },
  'Spanish': {
    'name': 'Spanish',
    'slug': 'es',
  },
  'Sundanese': {
    'name': 'Sundanese',
    'slug': 'su',
  },
  'Swahili': {
    'name': 'Swahili',
    'slug': 'sw',
  },
  'Swedish': {
    'name': 'Swedish',
    'slug': 'sv',
  },
  'Tajik': {
    'name': 'Tajik',
    'slug': 'tg',
  },
  'Tamil': {
    'name': 'Tamil',
    'slug': 'ta',
  },
  'Tatar': {
    'name': 'Tatar',
    'slug': 'tt',
  },
  'Telugu': {
    'name': 'Telugu',
    'slug': 'te',
  },
  'Thai': {
    'name': 'Thai',
    'slug': 'th',
  },
  'Turkish': {
    'name': 'Turkish',
    'slug': 'tr',
  },
  'Ukrainian': {
    'name': 'Ukrainian',
    'slug': 'uk',
  },
  'Urdu': {
    'name': 'Urdu',
    'slug': 'ur',
  },
  'Uyghur': {
    'name': 'Uyghur',
    'slug': 'ug',
  },
  'Uzbek': {
    'name': 'Uzbek',
    'slug': 'uz',
  },
  'Vietnamese': {
    'name': 'Vietnamese',
    'slug': 'vi',
  },
  'Welsh': {
    'name': 'Welsh',
    'slug': 'cy',
  },
  'Xhosa': {
    'name': 'Xhosa',
    'slug': 'xh',
  },
  'Yiddish': {
    'name': 'Yiddish',
    'slug': 'yi',
  },
  'Yoruba': {
    'name': 'Yoruba',
    'slug': 'yo',
  },
  'Zulu': {
    'name': 'Zulu',
    'slug': 'zu',
  },
};
