import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote as faStickyNoteSolid } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import {
  Button,
  FormFeedback,
  Input,
  Popover,
  PopoverBody,
} from 'reactstrap';
import * as _ from 'lodash';

import {
  toggleAnnotations,
  openAnnotation,
  closeAnnotation,
  createAnnotation,
  getShowAnnotations,
} from '../../redux/annotations';
import { StatefulForm } from '../../utils/StatefulForm';
import { Annotation } from './Annotation';
import './Annotation.scss';

@connect(({ codes, annotations, found }) => {
  return {
    currClient: codes.currClient,
    currSection: codes.currSection,
    showAnnotations: getShowAnnotations({ codes, found }),
    annotationsOn: annotations.annotationsOn,
    openRecordId: annotations.openRecordId,
    annotations: annotations.annotations,
    createAnnotationPending: annotations.createAnnotationPending,
    createAnnotationError: annotations.createAnnotationError,
    getAnnotationsPending: annotations.getAnnotationsPending,
  };
}, {
  toggleAnnotations,
  openAnnotation,
  closeAnnotation,
  createAnnotation,
})
export class AnnotationDrawer extends StatefulForm {
  state = {
    formValue: { note: '' },
  }

  constructor (props) {
    super(props);

    this.createAnnotation = this.createAnnotation.bind(this);
    this.disableInteraction = this.disableInteraction.bind(this);
  }

  componentDidUpdate (prevProps) {
    // Clear the form when this record's drawer is shown
    if (
      this.props.recordId == this.props.openRecordId &&
      this.props.openRecordId != prevProps.openRecordId
    ) {
      this.state.formValue.note = '';
    }
  }

  async createAnnotation () {
    const {
      recordId,
      currClient,
      currSection,
      createAnnotation,
    } = this.props;

    await createAnnotation({
      client: currClient.slug,
      code_slug: currSection.code_slug,
      doc_id: currSection.orig_doc_id,
      record_id: recordId,
      note: this.state.formValue.note,
    });
    await this.setState({ formValue: { note: '' }});
  }

  disableInteraction () {
    const {
      createAnnotationPending,
      getAnnotationsPending,
    } = this.props;

    return createAnnotationPending || getAnnotationsPending || !this.state.formValue.note;
  }

  render () {
    const {
      className,
      style,
      recordId,
      showAnnotations,
      annotationsOn,
      openRecordId,
      getAnnotationsPending,
      createAnnotationPending,
      createAnnotationError,
      annotations,
      openAnnotation,
      closeAnnotation,
    } = this.props;
    const { formValue } = this.state;

    const open = recordId == openRecordId;
    const active = open || _.get(annotations, `[${recordId}].length`, 0);

    return (
      <div
        className={`annotation-drawer ${className || ''}`}
        style={{
          ...(style || {}),
          display: (annotationsOn && showAnnotations)  ? 'block' : 'none',
        }}
      >
        <Button
          id={`note-${recordId}`}
          aria-label="Annotations dialog"
          color="link"
          className="annotation-drawer__btn"
          onClick={async () => {
            if(open) {
              closeAnnotation(recordId);
            } else {
              await this.setState({ formValue: { note: '' }});
              openAnnotation(recordId);
            }
          }}
        >
          <FontAwesomeIcon icon={active ? faStickyNoteSolid : faStickyNote} />
        </Button>

        <Popover
          className={classnames(
            'popover--annotation notranslate',
            {'popover--empty': _.get(annotations, `[${recordId}].length`, 0) == 0},
          )}
          placement="bottom"
          target={`note-${recordId}`}
          isOpen={open}
        >
          <PopoverBody className="popover-body--drawer">
            <div className="annotation-drawer__list">
              {getAnnotationsPending
                ? (
                  <div className="annotation-drawer__loading">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )
                : _.map(
                  annotations[recordId],
                  (annotation) => (
                    <Annotation
                      key={`note-${annotation.id}`}
                      annotation={annotation}
                    />
                  ),
                )
              }
            </div>
            <div className="annotation-drawer__bottom">
              <Input
                name="note"
                aria-label="Note"
                type="textarea"
                placeholder="Add an annotation here..."
                value={ formValue.note }
                disabled={createAnnotationPending || getAnnotationsPending}
                invalid={ _.has(createAnnotationError, 'note') }
                onChange={this.handleChange}
              />
              <FormFeedback role="alert">
                { _.get(createAnnotationError, 'note') }
              </FormFeedback>
              <div className="popover-footer">
                <Button
                  size="sm"
                  onClick={() => closeAnnotation(recordId)}
                  disabled={createAnnotationPending}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={this.createAnnotation}
                  disabled={this.disableInteraction()}
                >
                  {
                    createAnnotationPending &&
                    <div
                      className="spinner-border"
                      role="status"
                      style={{height: '1rem', width: '1rem'}}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                  Save
                </Button>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}
