import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, meta, preload } from 'react-website';
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Collapse,
  Form,
  Input,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faPlusCircle,
  faSearch,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { SaveSearchModal } from '../../modals';
import {
  PAGE_SIZE,
  clearSearch,
  createSavedSearch,
  getLocalSearchCtx,
  getFiltersOn,
  getSavedSearches,
  getSearchResults,
  getSearchTextValid,
  prevSearchResults,
  nextSearchResults,
  updateFilters,
  getSearchError,
} from '../../redux/search';
import SearchResults from './SearchResults.js';
import AdvancedSearch from './AdvancedSearch';
import { metaKey } from '../../utils/helpers';

import './Search.scss';

@meta(() => ({ ...metaKey('title', 'Code Search') }))
@preload(async ({ dispatch, getState }) => {
  const { account } = getState();

  if (account.user) {
    await dispatch(getSavedSearches());
  }
})
@connect(({ search }) => ({
  currPage: search.currPage || 0,
  searchResult: search.searchResult,
  searchContext: search.searchContext,
  lastSearchText: search.lastSearchText,
  count: search.searchResult && search.searchResult['count'],
  next: search.searchResult && search.searchResult.nextUrl,
  previous: search.searchResult && search.searchResult.previousUrl,
  getSearchResultsPending: search.getSearchResultsPending,
  prevSearchResultsPending: search.prevSearchResultsPending,
  nextSearchResultsPending: search.nextSearchResultsPending,
  searchError: getSearchError(search),
  createSavedSearchError: search.createSavedSearchError,
  showDisclaimer: search.showTranslateDisclaimer,
  filters: search.filters,
  filtersOn: getFiltersOn(search),
  savedSearchApplied: search.savedSearchApplied,
  searchClient: search.searchClient,
  localSearchCtx: getLocalSearchCtx(search),
  searchTextValid: getSearchTextValid(search),
}), {
  clearSearch,
  createSavedSearch,
  getSearchResults,
  prevSearchResults,
  nextSearchResults,
  updateFilters,
})
export default class Search extends Component { 
  constructor (props) {
    super(props);

    this.state = {
      isAdvancedOpen: !props.searchContext,
      searchExcerpt: true,
    };

    this.saveSearchModal = React.createRef();
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggleAdvanced = this.toggleAdvanced.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.toggleSearchExcerpt = this.toggleSearchExcerpt.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.canSearch = this.canSearch.bind(this);
  }

  async onSearchChange (val) {
    const { updateFilters } = this.props;

    await updateFilters({searchText: val});
  }

  async toggleAdvanced () {
    await this.setState((prevState) => ({isAdvancedOpen: !prevState.isAdvancedOpen}));
  }

  async onSearch (page=1, force=false) {
    if (force || this.canSearch()) {
      const { getSearchResults, localSearchCtx } = this.props;

      await this.setState(() => ({isAdvancedOpen: false}));
      await getSearchResults({
        searchCtx: localSearchCtx,
        currPage: page - 1,
      });
    }
  }

  async previous () {
    const { prevSearchResults, previous } = this.props;
    
    await prevSearchResults(previous);
  }
  
  async next () {
    const { nextSearchResults, next } = this.props;
  
    await nextSearchResults(next);
  }

  async toggleSearchExcerpt () {
    await this.setState((prevState) => ({searchExcerpt: !prevState.searchExcerpt}));
  }

  async saveSearch (modalValues) {
    const { createSavedSearch, localSearchCtx } = this.props;

    const params = {
      search_context: localSearchCtx,
      title: modalValues.title,
    };

    await createSavedSearch(params);

    if (!this.props.createSavedSearchError) {
      this.saveSearchModal.current.wrappedInstance.toggle();
    }
  }

  canSearch () {
    const {
      getSearchResultsPending,
      localSearchCtx,
      searchContext,
      searchTextValid,
    } = this.props;

    return (
      !getSearchResultsPending &&
      searchTextValid &&
      localSearchCtx !== searchContext
    );
  }

  render () {
    const {
      count,
      currPage,
      filters,
      filtersOn,
      localSearchCtx,
      savedSearchApplied,
      searchResult,
      searchContext,
      lastSearchText,
      searchClient,
      getSearchResultsPending,
      prevSearchResultsPending,
      nextSearchResultsPending,
      searchError,
      showDisclaimer,
    } = this.props;

    const startRange = (currPage * PAGE_SIZE) + 1;
    const endRange = ((currPage + 1) * PAGE_SIZE) < count ? (currPage + 1) * PAGE_SIZE : count;
    const resultRangeText = _.get(searchResult, 'results') && count !== 0 ? (
      <p className="search-header-info">
        {`Showing ${startRange}-${endRange} of ${count} results`}
      </p>
    ) : (
      <p className="search-header-info">
        Keyword Search
      </p>
    );

    const maxPage = _.ceil(count / PAGE_SIZE);
    const pages = _.map(
      _.range(
        _.max([currPage - 4, 0]) + 1,
        _.min([currPage + 5, maxPage]) + 1,
      ),
      (page) => ({
        'displayValue': page.toString(),
        'value': page,
        'title': `Page ${page}`,
      })
    );
    
    if (_.get(pages, '[0].value', 1) > 1) {
      pages.unshift({
        'displayValue': '... ',
        'value': 1,
        'title': 'First Page',
      });
    }
    
    if (_.get(pages, `[${pages.length - 1}].value`, 1) < maxPage) {
      pages.push({
        'displayValue': ' ...',
        'value': maxPage,
        'title': 'Last Page',
      });
    }

    const pageButtons = _.map(
      pages,
      (page) => (
        <Button
          className={classnames(
            'previous-next-button',
            {'previous-next-button__active': page.value === (currPage + 1)}
          )}
          key={`page-${page.value}`}
          title={page.title}
          onClick={() => this.onSearch(page.value, page.value != (currPage + 1))}
        >
          {page.displayValue}
        </Button>
      ),
    );

    return (
      <div className="search-page">
        <div className="browse-clients">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link
                className="browse-link roboto"
                to="/search"
                onClick={() => this.props.clearSearch(searchClient)}
              >
                Search
              </Link>
            </BreadcrumbItem>
            {
              lastSearchText &&
              <BreadcrumbItem active>
                Search results for &quot;{lastSearchText}&quot;
              </BreadcrumbItem>
            }
          </Breadcrumb>
        </div>
        <div className="am-page am-page--scroll">
          <div className="am-page__content am-page__content--nobg">
            <div className="above-search">
              {resultRangeText}
              <div className="saved-searches">
                {
                  savedSearchApplied &&
                  <em className="saved-search-applied">
                    Saved search applied
                  </em>
                }
                <Button
                  disabled={localSearchCtx !== searchContext || savedSearchApplied}
                  onClick={() => this.saveSearchModal.current.wrappedInstance.toggle()}
                >
                  <FontAwesomeIcon
                    style={{color: 'inherit'}}
                    icon={faPlusCircle}
                    size="lg"
                  />
                  Save Search
                </Button>
              </div>
            </div>
            <Form onSubmit={(e) => { e.preventDefault(); this.onSearch();}}>
              <div className="search">
                <Input
                  autoComplete="off"
                  className="search__input"
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={_.get(filters, 'searchText')}
                  onChange={(e) => this.onSearchChange(e.target.value)}
                />
                <FontAwesomeIcon
                  className="search__icon"
                  color="#A4A4A4"
                  icon={faSearch}
                  size="lg"
                />
                {
                  filtersOn &&
                  <Badge className="advanced-search__filters__on">
                    <FontAwesomeIcon
                      icon={faPlus}
                    />
                    Advanced Filters
                  </Badge>
                }
              </div>
              { showDisclaimer && (
                <p className="translate__disclaimer">
                  Search terms must be in English.
                </p>
              )}
              <div className="below-search">
                <Button
                  onClick={() => this.props.clearSearch(searchClient)}
                >
                  <FontAwesomeIcon
                    color="#000000"
                    icon={faTimesCircle}
                    size="lg"
                  />
                  Clear Search
                </Button>
                <Button
                  className={classnames(
                    'advanced-search__toggle',
                    { 'advanced-search__toggle--open': this.state.isAdvancedOpen }
                  )}
                  onClick={this.toggleAdvanced}
                >
                  <FontAwesomeIcon
                    color="#000000"
                    icon={faChevronDown}
                    size="lg"
                  />
                  Advanced Filters
                </Button>
              </div>

              <div className="search__buttons">
                <div>
                  <Input
                    id="search-excerpt"
                    type="checkbox"
                    name="search_excerpt"
                    checked={this.state.searchExcerpt}
                    onChange={this.toggleSearchExcerpt}
                  />
                  <Label for="search-excerpt" check>
                    Show Document Excerpts
                  </Label>
                </div>
                <Button
                  type="submit"
                  color="success"
                  disabled={!this.canSearch()}
                >
                  Search
                </Button>
              </div>
            </Form>

            <Collapse isOpen={this.state.isAdvancedOpen}>
              <AdvancedSearch
                canSearch={this.canSearch()}
                onSearch={this.onSearch}
                toggleAdvanced={this.toggleAdvanced}
              />
            </Collapse>

            {!searchError && !!searchContext &&
              _.isEmpty(_.get(searchResult, 'results'), []) &&
              !getSearchResultsPending &&
              !prevSearchResultsPending &&
              !nextSearchResultsPending &&
              <h3>No Matching Results</h3>
            }

            {
              getSearchResultsPending &&
              !searchResult &&
              <div className="search-spinner-flex">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Searching...</span>
                </div>
                <span className="searching-spinner">
                  Searching...
                </span>
              </div>
            }

            { searchError && (
              <div className="text-center">
                <h3>We&apos;re Sorry</h3>
                <p>
                  Our search service is temporarily unavilable. We&apos;re working
                  diligently to have it up and running as soon as possible!
                </p>
              </div>
            ) || !_.isEmpty(_.get(searchResult, 'results'), []) &&
              <div>
                <SearchResults searchExcerpt={this.state.searchExcerpt}/>

                <div className="roboto text-center notranslate">
                  <Button
                    aria-label="Previous results"
                    className="previous-next-button"
                    disabled={!_.get(searchResult, 'previous')}
                    title="Previous"
                    onClick={this.previous}
                  >
                    <FontAwesomeIcon
                      color={_.get(searchResult, 'previous') ? '#000000' : '#BFBEBE'}
                      icon={faChevronLeft}
                      size="sm"
                    />
                  </Button>
                  {pageButtons}
                  <Button
                    aria-label="Next results"
                    className="previous-next-button"
                    disabled={!_.get(searchResult, 'next')}
                    title="Next"
                    onClick={this.next}
                  >
                    <FontAwesomeIcon
                      color={_.get(searchResult, 'next') ? '#000000' : '#BFBEBE'}
                      icon={faChevronRight}
                      size="sm"
                    />
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
        <SaveSearchModal
          ref={this.saveSearchModal}
          onSubmit={this.saveSearch}
        />
      </div>
    );
  }
}
