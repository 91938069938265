import React from 'react';
import { connect } from 'react-redux';
import { goto } from 'react-website';
import { Button } from 'reactstrap';

import {
  getSearchResults,
  compressSearch,
} from '../../redux/search';

@connect(
  ({ codes }) => ({ currClient: codes.currClient }),
  { goto, getSearchResults },
)
export class QueryLink extends React.Component {
  render () {
    const {
      children,
      query,
      currClient,
      getSearchResults,
      goto,
    } = this.props;

    return (
      <Button
        aria-label="Link to search"
        color="link"
        className={this.props.styleName}
        onClick={async () => {
          await getSearchResults({
            currPage: 0,
            searchCtx: compressSearch({
              clients: [currClient.slug],
              searchText: query,
            }),
          });
          goto('/search');
        }}
      >
        { children }
      </Button>
    );
  }
}
