import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { publishAllMinutes } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    publishAllMinutesPending: admin.publishAllMinutesPending,
  }),
  { publishAllMinutes },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class PublishAllMinutesModal extends Component {
  state = {
    open: false,
  }

  constructor () {
    super();

    this.publishAllMinutes = this.publishAllMinutes.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({
      open: !prevState.open,
    }));
  }

  async publishAllMinutes () {
    const {
      currClient,
      publishAllMinutes,
    } = this.props;

    await publishAllMinutes(currClient.slug);
    this.toggle();
  }

  render () {
    const {
      currClient,
      publishAllMinutesPending,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={publishAllMinutesPending  ? undefined : this.toggle}>
          Publish All Minutes
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <p>
              Are you sure that you want to publish all minutes for the{' '}
              <b>{_.get(currClient, 'name')}</b> client? No minutes will be replaced.
              If minutes exist on a given date, all will show up.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.toggle}
            disabled={publishAllMinutesPending}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.publishAllMinutes}
            disabled={publishAllMinutesPending}
          >
            {
              publishAllMinutesPending &&
              <div
                className="spinner-border"
                role="status"
                style={{height: '1rem', width: '1rem'}}
              >
                <span className="sr-only">Loading...</span>
              </div>
            }
            Publish All
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
