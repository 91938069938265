import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import { Badge } from 'reactstrap';
import * as _ from 'lodash';

import './Search.scss';

import { PAGE_SIZE } from '../../redux/search';

@connect(({ search }) => ({
  searchResult: search.searchResult,
  searchContext: search.searchContext,
  currPage: search.currPage,
  getSearchResultsPending: search.getSearchResultsPending,
  prevSearchResultsPending: search.prevSearchResultsPending,
  nextSearchResultsPending: search.nextSearchResultsPending,
}))
export default class SearchResults extends Component {
  static propTypes = {
    searchExcerpt: PropTypes.bool,
  }

  render () {
    const {
      currPage,
      searchResult,
      getSearchResultsPending,
      prevSearchResultsPending,
      nextSearchResultsPending,
      searchExcerpt,
    } = this.props;

    const resultsList = _.map(
      _.get(searchResult, 'results', []),
      (result, idx) => (
        <li key={`result-${idx}`}>
          <h4 className="search-result-header">
            <Link
              className="select-search"
              to={
                result.is_minute
                  ? (
                    `/codes/${result.client_slug}/${result.version}/m/${result.date_url}` +
                    (result.minute_order_idx != 0 ? `/${result.minute_order_idx}` : '')
                  )
                  : (
                    `/codes/${result.client_slug}/${result.version}/` +
                    `${result.code_slug}/${encodeURIComponent(result.passthru_doc_id || result.doc_id)}`
                  )
              }
            >
              {result.section_title}
            </Link>
          </h4>
          <Badge className="search-badge">
            {result.client_name}, {_.toUpper(result.region_slug)}
          </Badge>
          {
            result.code_title &&
            <Badge className="search-badge search-badge--title">
              {result.code_title}
            </Badge>
          }
          {
            result.is_minute &&
            <Badge className="search-badge search-badge--title">
              Meeting Minutes
            </Badge>
          }
          {
            result.date &&
            <Badge className="search-badge search-badge--date">
              {`${result.date.getUTCMonth() + 1}/${result.date.getUTCDate()}/${result.date.getUTCFullYear()}`}
            </Badge>
          }
          {
            searchExcerpt && result.highlighting &&
            _.map(
              result.highlighting,
              (highlightResult, idx) => (
                <div key={`highlight-${result.section_id}-${idx}`}>
                  ...
                  <span
                    dangerouslySetInnerHTML={{__html: highlightResult.highlighted}}
                  ></span>
                </div>
              ),
            )
          }
        </li>
      )
    );

    return (
      <div className="search-results">
        <ol
          className={classnames(
            'results-list',
            {'results-list--dimmed': getSearchResultsPending || prevSearchResultsPending || nextSearchResultsPending}
          )}
          start={currPage * PAGE_SIZE + 1}
        >
          {resultsList}
        </ol>
      </div>
    );
  }
}
