import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-website';
import * as _ from 'lodash';

@connect(({ sections }, { doc }) => ({
  codeLookup: _.get(sections, `items[${doc.origDocIdx}].code_lookup`),
}))
export class InterCodeLink extends React.Component {
  constructor (props) {
    super(props);

    const {
      infobasePath,
      linkPrefix,
      destinationId,
      codeLookup,
    } = props;

    const filename = infobasePath.replace(/^.*[\\\/]/, '');
    const infobaseName = filename.split('.')[0].toLowerCase();
    const destID = _.get(
      codeLookup,
      [infobaseName, 'destinations', destinationId],
    );
    let codeSlug = _.get(codeLookup, [infobaseName, 'code_slug']);
    codeSlug = codeSlug ? codeSlug + '/' : '';

    if (!!destID) {
      this.state = {
        to: {
          pathname: `${linkPrefix}${codeSlug}${destID}`,
          hash: `#${destinationId}`,
        },
      };
    }
  }

  render () {
    const { children } = this.props;

    return (
      _.get(this.state, 'to')
        ? (
          <Link
            to={this.state.to}
            className={this.props.styleName}
          >
            {children}
          </Link>
        )
        : (
          <Button
            color="link"
            className={this.props.styleName}
          >
            {children}
          </Button>
        )
    );
  }
}
