import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, Link, meta } from 'react-website';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../../utils/StatefulForm';
import { metaKey } from '../../utils/helpers';
import { forgotPassword } from '../../redux/account';
import { setRecaptchaVisible } from '../../utils/recaptcha';

import './Auth.scss';

@meta(() => ({ ...metaKey('title', 'Forgot Password') }))
export default class ForgotPassword extends Component {
  state = { resetSubmitted: false }

  constructor () {
    super();
    this.onReset = this.onReset.bind(this);
  }

  componentDidMount () {
    setRecaptchaVisible(true);
  }

  componentWillUnmount () {
    setRecaptchaVisible(false);
  }

  onReset () {
    this.setState({ resetSubmitted: true });
  }

  render () {
    let reset;
    if (this.state.resetSubmitted) {
      reset =
        <div className="am-page">
          <div className="am-page__content">
            <div className="am-page__header">
              Check Your Email
            </div>
            <div className="am-page__pane">
              <p className="text-center">
                If an account is associated with the email provided, an email will be sent
                over with instructions to reset your password
              </p>
            </div>
          </div>
        </div>
      ;
    } else {
      reset = <ForgotPasswordForm onSubmit={this.onReset} />;
    }

    return (
      <div>
        <h3 className="secondary-header">Forgot Password</h3>
        { reset }
        <div className="auth__message">
          <p>
            <Link
              to="/signup"
              className="auth__link"
            >
              Sign up
            </Link>
              for an American Legal account or
            <Link
              to="/login"
              className="auth__link"
            >
              log in
            </Link>
              to your existing account.
          </p>
        </div>
      </div>
    );
  }
}

@connect(({ account }) => ({
  forgotPasswordPending: account.forgotPasswordPending,
  forgotPasswordError: account.forgotPasswordError,
}), { forgotPassword, goBack })
class ForgotPasswordForm extends StatefulForm {
  static propTypes = {
    forgotPasswordPending : PropTypes.bool,
    forgotPasswordError : PropTypes.object,
  }
  state = {
    formValue: { email: '' },
  }

  async submitForm (e) {
    e.preventDefault();

    const { onSubmit, forgotPassword } = this.props;
    await forgotPassword(this.state.formValue.email);
    onSubmit();
  }

  render () {
    const { formValue } = this.state;
    const { goBack, forgotPasswordPending, forgotPasswordError } = this.props;

    let formErrors = null;
    if (_.has(forgotPasswordError, 'non_field_errors')) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ !!forgotPasswordError }/>
          <FormFeedback role="alert">
            { forgotPasswordError.non_field_errors }
          </FormFeedback>
        </FormGroup>
      );
    }
    if (_.get(forgotPasswordError, 'status') == 500) {
      formErrors = (
        <FormGroup>
          <Input
            type="hidden"
            invalid={ true }/>
          <FormFeedback role="alert">
            The server was unable to process your request.
          </FormFeedback>
        </FormGroup>
      );
    }

    return (
      <div className="am-page">
        <div className="am-page__content">
          <div className="am-page__header">
              Forgot Password
          </div>
          <div className="am-page__pane">
            <p className="text-center">
              Submit your email here to reset your password.
            </p>
            <Form onSubmit={ this.submitForm }>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  required
                  label="Email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={ formValue.email }
                  invalid={ _.has(forgotPasswordError, 'email') }
                  onChange={ this.handleChange }/>
                <FormFeedback role="alert">
                  { _.get(forgotPasswordError, 'email') }
                </FormFeedback>
              </FormGroup>

              { formErrors }

              <div className='am-page__bottom am-page__bottom--forgot'>
                <Button
                  onClick={ goBack }
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="success"
                  disabled={ forgotPasswordPending }
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
