import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { updateVersion } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    doUpdateVersionPending: admin.doUpdateVersionPending,
    doUpdateVersionError: admin.doUpdateVersionError,
  }),
  { updateVersion },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class EditVersionModal extends StatefulForm {
  static propTypes = {
    version: PropTypes.object,
    doUpdateVersionPending : PropTypes.bool,
    doUpdateVersionError : PropTypes.object,
  }
  state = {
    open: false,
    version: null,
    formValue: {
      name: '',
      currency_info: '',
    },
  }

  constructor (props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.nameInput = React.createRef();
  }

  // Public method to initialize state and toggle on
  async show (version) {
    await this.setState({
      firstGoDone: false,
      version,
      formValue: {
        name: version.name,
        currency_info: version.currency_info || '',
      },
    });

    await this.toggle();
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const { updateVersion } = this.props;
    const {
      version,
      formValue,
    } = this.state;

    this.setState({firstGoDone: true});

    await updateVersion({
      id: version.id,
      newValues: formValue,
      onSuccess: () => this.setState({open: false}),
    });
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const { doUpdateVersionPending, doUpdateVersionError } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Create Version</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Edit the version details and click Save.
          </p>
          <FormGroup>
            <Input
              autoComplete="off"
              innerRef={this.nameInput}
              name="name"
              type="text"
              placeholder="Version Name"
              value={ formValue.name }
              invalid={ firstGoDone && _.has(doUpdateVersionError, 'name') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(doUpdateVersionError, 'name') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              name="currency_info"
              type="text"
              placeholder="Currency Information"
              value={ formValue.currency_info }
              invalid={ firstGoDone && _.has(doUpdateVersionError, 'currency_info') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(doUpdateVersionError, 'currency_info') }
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={ doUpdateVersionPending }
            onClick={this.submitForm}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
