import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-website';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faBellSlash,
  faBookmark,
  faShareAlt,
  faDownload,
  faEllipsisV,
  faPrint,
  faStickyNote as faStickyNoteSolid,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import * as _ from 'lodash';

import {
  createFollow,
  deleteFollow,
} from '../../redux/account';
import {
  toggleAnnotations,
  getShowAnnotations,
} from '../../redux/annotations';
import { getShowDownloadAndPrint } from '../../redux/codes';
import { FollowCodeModal } from '../../modals/FollowCodeModal';
import { AnnotationsModal } from '../../modals/AnnotationsModal';
import { CodeAction } from '../../utils/CodeAction';
import './CodeOptions.scss';

@connect(({ account, codes, annotations, found }) => ({
  user: account.user,
  follows: _.get(account, 'user.follows', []),
  currClient: codes.currClient,
  currSection: codes.currSection,
  selectedCode: codes.selectedCode,
  codeOptionsModal: codes.codeOptionsModal,
  annotationsOn: annotations.annotationsOn,
  showAnnotations: getShowAnnotations({ codes, found }),
  showDownloadAndPrint: getShowDownloadAndPrint(codes),
}), { createFollow, deleteFollow, toggleAnnotations })
export class CodeOptions extends React.Component {
  static propTypes = {
    color : PropTypes.string,
    destID : PropTypes.string,
  }

  constructor () {
    super();

    this.followCodeModal = React.createRef();
    this.annotationsModal = React.createRef();
    this.createFollow = this.createFollow.bind(this);
    this.toggleAnnotations = this.toggleAnnotations.bind(this);
    this.onOptionClicked = this.onOptionClicked.bind(this);
  }

  onOptionClicked (action) {
    const {
      codeOptionsModal,
      destID,
    } = this.props;

    if (typeof window !== 'undefined') {
      codeOptionsModal.current.wrappedInstance.show({
        action,
        destID,
        location: window.location,
      });
    }
  }

  async toggleAnnotations () {
    const {
      user,
      toggleAnnotations,
    } = this.props;

    if (!user) {
      this.annotationsModal.current.toggle();
    } else {
      toggleAnnotations();
    }
  }

  async createFollow (followTarget) {
    const {
      user,
      createFollow,
      selectedCode,
      currSection,
    } = this.props;

    if (
      !user ||
      (
        !!selectedCode &&
        followTarget.code_slug == selectedCode.slug &&
        followTarget.doc_id == selectedCode.docId
      )
    ) {
      // Prompt user for code or doc
      this.followCodeModal.current.wrappedInstance.show({
        ...followTarget,
        codeTitle: _.get(selectedCode, 'title'),
        documentTitle: _.get(currSection, 'title'),
      });
    } else {
      // Otherwise, just follow the target
      await createFollow(followTarget);
    }
  }

  shouldComponentUpdate (nextProps) {
    return (
      (_.get(nextProps, 'codeOptionsModal.current') != _.get(this.props, 'codeOptionsModal.current')) ||
      this.props.color == 'white'
    );
  }

  render () {
    const {
      color,
      codeOptionsModal,
      follows,
      currClient,
      currSection,
      selectedCode,
      user,
      deleteFollow,
      annotationsOn,
      showAnnotations,
      showDownloadAndPrint,
    } = this.props;

    const buttonColor = color || 'primary';
    let followTarget;
    let existingFollow;

    if (buttonColor == 'white') {
      followTarget = {
        client: _.get(currClient, 'slug'),
        code_slug: _.get(currSection, 'code_slug', null),
        doc_id: _.get(currSection, 'doc_id', null),
      };
      existingFollow = _.find(
        follows,
        (f) => (
          !_.get(f, 'deleted', false) &&
          f.client == followTarget.client &&
          f.code_slug == followTarget.code_slug &&
          (
            f.doc_id == followTarget.doc_id ||
            (
              f.doc_id == null &&
              currSection.doc_id == _.get(selectedCode, 'docId')
            )
          )
        ),
      );
    }

    return (
      <div
        className={`code-options ${this.props.className || ''}`}
        style={this.props.style}
      >
        <div className="code-options__kebab">
          <UncontrolledDropdown>
            <DropdownToggle
              aria-label="Code section options"
              className={`btn-${buttonColor}-kebab`}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </DropdownToggle>
            <DropdownMenu
              className="roboto"
              right
            >
              {
                buttonColor == 'white' && showAnnotations &&
                <DropdownItem
                  title={`Annotations ${annotationsOn ? 'On' : 'Off'}`}
                  onClick={() => this.toggleAnnotations()}
                >
                  <FontAwesomeIcon icon={annotationsOn ? faStickyNoteSolid : faStickyNote} />
                  Annotations {annotationsOn ? 'On' : 'Off'}
                </DropdownItem>
              }
              {
                buttonColor == 'white' &&
                <DropdownItem
                  title={!!existingFollow ? 'Unfollow' : 'Follow'}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!!existingFollow) {
                      deleteFollow(existingFollow.id, true);
                    } else {
                      this.createFollow(followTarget);
                    }
                  }}
                  disabled={!_.get(codeOptionsModal, 'current')}
                >
                  <FontAwesomeIcon icon={!!existingFollow ? faBell : faBellSlash} />
                  {!!existingFollow ? 'Unfollow' : 'Follow'} Changes
                </DropdownItem>
              }
              <DropdownItem
                title="Share"
                onClick={() => this.onOptionClicked(CodeAction.SHARE)}
                disabled={!_.get(codeOptionsModal, 'current')}
              >
                <FontAwesomeIcon icon={faShareAlt} />
                Share
              </DropdownItem>
              {
                showDownloadAndPrint &&
                <DropdownItem
                  title="Download"
                  onClick={() => this.onOptionClicked(CodeAction.DOWNLOAD)}
                  disabled={!_.get(codeOptionsModal, 'current')}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  Download
                </DropdownItem>
              }
              <DropdownItem
                title="Bookmark"
                onClick={() => this.onOptionClicked(CodeAction.BOOKMARK)}
                disabled={!_.get(codeOptionsModal, 'current')}
              >
                <FontAwesomeIcon icon={faBookmark} style={{marginRight: '.625rem'}} />
                Bookmark
              </DropdownItem>
              {
                showDownloadAndPrint &&
                <DropdownItem
                  title="Print"
                  onClick={() => this.onOptionClicked(CodeAction.PRINT)}
                  disabled={!_.get(codeOptionsModal, 'current')}
                >
                  <FontAwesomeIcon icon={faPrint} />
                  Print
                </DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="code-options__buttons">
          {
            buttonColor == 'white' && _.get(user, 'is_superuser') &&
            <Link
              aria-label="Admin Page"
              className={`btn btn-${buttonColor}-circle`}
              title="Admin Page"
              to={`/admin/${currClient.slug}/manage`}
            >
              <FontAwesomeIcon icon={faTools} />
            </Link>
          }
          {
            buttonColor == 'white' && showAnnotations &&
            <Button
              aria-label={`Annotations ${annotationsOn ? 'On' : 'Off'}`}
              color={`${buttonColor}-circle`}
              title={`Annotations ${annotationsOn ? 'On' : 'Off'}`}
              onClick={this.toggleAnnotations}
            >
              <FontAwesomeIcon icon={annotationsOn ? faStickyNoteSolid : faStickyNote} />
            </Button>
          }
          {
            buttonColor == 'white' &&
            <Button
              aria-label={!!existingFollow ? 'Unfollow' : 'Follow'}
              color={`${buttonColor}-circle`}
              title={!!existingFollow ? 'Unfollow' : 'Follow'}
              onClick={() => {
                if (!!existingFollow) {
                  deleteFollow(existingFollow.id, true);
                } else {
                  this.createFollow(followTarget);
                }
              }}
              disabled={!_.get(codeOptionsModal, 'current')}
            >
              <FontAwesomeIcon icon={!!existingFollow ? faBell : faBellSlash} />
            </Button>
          }
          { buttonColor == 'white' && <FollowCodeModal ref={this.followCodeModal} /> }
          <AnnotationsModal ref={this.annotationsModal} />
          <Button
            aria-label="Share"
            color={`${buttonColor}-circle`}
            title="Share"
            onClick={() => this.onOptionClicked(CodeAction.SHARE)}
            disabled={!_.get(codeOptionsModal, 'current')}
          >
            <FontAwesomeIcon icon={faShareAlt} />
          </Button>
          {
            showDownloadAndPrint &&
            <Button
              aria-label="Download"
              color={`${buttonColor}-circle`}
              title="Download"
              onClick={() => this.onOptionClicked(CodeAction.DOWNLOAD)}
              disabled={!_.get(codeOptionsModal, 'current')}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          }
          <Button
            aria-label="Bookmark"
            color={`${buttonColor}-circle`}
            title="Bookmark"
            onClick={() => this.onOptionClicked(CodeAction.BOOKMARK)}
            disabled={!_.get(codeOptionsModal, 'current')}
          >
            <FontAwesomeIcon icon={faBookmark} />
          </Button>
          {
            showDownloadAndPrint &&
            <Button
              aria-label="Print"
              color={`${buttonColor}-circle`}
              title="Print"
              onClick={() => this.onOptionClicked(CodeAction.PRINT)}
              disabled={!_.get(codeOptionsModal, 'current')}
            >
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          }
        </div>
      </div>
    );
  }
}
