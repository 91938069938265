import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import * as _ from 'lodash';

import './Translate.scss';
import { isoLangs } from '../utils/CountryLanguageCodes';
import { onUserInteract } from '../utils/helpers';
import { getCodeTheme } from '../redux/codes';
import { setShowTranslateDiclaimer } from '../redux/search';

@connect(({ codes, global }) => ({
  mobile: global.mobile,
  theme: getCodeTheme(codes),
}), { setShowTranslateDiclaimer })
export default class Translate extends Component {
  static propTypes = {
    smallWindow: PropTypes.bool,
  }

  state = {
    open: false,
    mobileOpen: false,
    language: {
      name: 'English',
      slug: 'en',
    },
  }

  constructor () {
    super();

    this.translateDetector = React.createRef();
    this.languageChange = _.debounce(this.onLanguageChange, 100);
    this.toggle = this.toggle.bind(this);
    this.toggleMobile = this.toggleMobile.bind(this);
    this.translateLang = this.translateLang.bind(this);
    this.googleTranslateElementInit2 = this.googleTranslateElementInit2.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  async componentDidMount () {
    const selector = document.querySelector('select.goog-te-combo');

    if (!selector) {
      const $script = require('scriptjs');

      // Register global callback for translate sdk to hit after it's loaded
      window['googleTranslateElementInit2'] = this.googleTranslateElementInit2;

      await $script(
        'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2',
      );
    }

    // Detect Safari browser for CSS hacks
    var isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    if (isSafari) {
      document.body.classList.add('safari');
    }
    
    // Hack to detect all variations of language being set
    this.translateDetector.current.addEventListener(
      'DOMSubtreeModified',
      this.languageChange.bind(this),
      false,
    );
  }

  componentWillUnmount () {
    this.translateDetector.current.removeEventListener(
      'DOMSubtreeModified',
      this.languageChange.bind(this),
    );

    // Remove the safari class from body
    document.body.classList.remove('safari');
  }

  async onLanguageChange () {
    const { setShowTranslateDiclaimer } = this.props;
    const selector = document.querySelector('select.goog-te-combo');
    if (selector) {
      const option = document.querySelector(
        `select.goog-te-combo option[value="${selector.value}"]`,
      );
      if (option) {
        await this.setState({
          language: {
            name: option.textContent,
            slug: option.value,
          },
        });
        await setShowTranslateDiclaimer(
          !_.isEmpty(option.value) && option.value != 'en'
        );
      }
    }
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));
  }

  async toggleMobile (e) {
    e.stopPropagation();
    e.preventDefault();

    await this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));

    if (this.state.mobileOpen) {
      const firstLanguage = document.getElementById('language-en');
      firstLanguage.focus();
    }
  }

  async translateLang (slug) {
    const selector = document.querySelector('select.goog-te-combo');
    if (!selector) {
      return;
    }

    selector.value = slug;
    // Proxy change event to hidden language selector
    if (document.createEvent) {
      const htmlEvent = document.createEvent('CustomEvent');
      htmlEvent.initEvent('change', true, true);
      selector.dispatchEvent(htmlEvent);
    } else {
      const createEvent = document.createEventObject();
      selector.fireEvent('onchange', createEvent);
    }

    if (slug === 'en') {
      const googTransFrame = document.getElementsByClassName('goog-te-banner-frame');
      if (googTransFrame && googTransFrame[0] && googTransFrame[0].contentDocument) {
        const showOriginalButton = googTransFrame[0].contentDocument.querySelector('[id*=restore]');
        if (showOriginalButton) {
          showOriginalButton.click();
        }
      }
    }

    if (this.props.smallWindow) {
      await this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
      await this.setState((prevState) => ({ open: !prevState.open }));
    }
  }

  googleTranslateElementInit2 () {
    const google = window['google'];
    if (google) {
      new google.translate.TranslateElement(
        {pageLanguage: 'en', autoDisplay: false},
        'google_translate_element2',
      );
    }
  }

  render () {
    const { theme } = this.props;
    const languages = _.map(
      isoLangs,
      (lang) => (
        <DropdownItem
          id={`language-${lang.slug}`}
          key={`lang-item-${lang.slug}`}
          onClick={() => this.translateLang(lang.slug)}
        >
          {lang.name}
        </DropdownItem>
      ),
    );

    const translateMobile = _.map(
      isoLangs,
      (lang) => (
        <option
          key={`lang-option-${lang.slug}`}
          value={lang.slug}
        >
          {lang.name}
        </option>
      )
    );

    const disclaimer = <div className="translate__disclaimer translate">
      *There may be discrepancies in the code when translating to other
      languages.  Please note that the English language version is the
      official version of the code.  American Legal Publishing and the
      jurisdiction whose laws are being translated do not vouch for the
      accuracy of any translated versions of such laws. PDF documents
      are not translated.
    </div>;

    const smallScreenTranslate = this.props.mobile ?
      <DropdownItem
        tag="div"
        translate="no"
        style={{padding: '0'}}
        toggle={false}
        key="translate-mobile"
        className="translate__mobile dropdown-item dropright"
      >
        <label className="dropdown-item dropdown-toggle roboto">
          <FontAwesomeIcon
            icon={faGoogle}
            mask={['fas', 'circle']}
            size="lg"
            transform="shrink-6"
            style={{marginRight: '.75rem'}}
          />
          {
            _.get(this.state.language, 'slug') === 'en'
              ? 'Select Language'
              : _.get(this.state.language, 'name')
          }
        </label>
        <select
          className="translate__select"
          onBlur={(e) => this.translateLang(e.target.value)}
          onChange={(e) => this.translateLang(e.target.value)}
        >
          <option value="">-- Select Language --</option>
          { translateMobile }
        </select>
      </DropdownItem>
      :
      <DropdownItem
        tag="div"
        className="translate__small notranslate"
        translate="no"
        style={{padding: '0'}}
        toggle={false}
        onClick={this.toggleMobile}
        onKeyDown={onUserInteract((e) => {
          this.toggleMobile(e);
        })}
      >
        <UncontrolledDropdown
          key="translate-menu"
          className="translate__menu"
          direction="right"
          isOpen={this.state.mobileOpen}
        >
          <DropdownToggle
            caret
            color="secondary"
            className="roboto dropdown-item"
            style={{color: 'inherit'}}
          >
            <FontAwesomeIcon
              icon={faGoogle}
              mask={['fas', 'circle']}
              size="lg"
              transform="shrink-6"
              style={{marginRight: '.75rem'}}
            />
            {
              _.get(this.state.language, 'slug') === 'en'
                ? 'Select Language'
                : _.get(this.state.language, 'name')
            }
          </DropdownToggle>
          <DropdownMenu
            className="roboto"
            right
          >
            <div className="translate__dropdown__menu">
              {this.state.mobileOpen && languages}
            </div>
            {disclaimer}
          </DropdownMenu>
        </UncontrolledDropdown>
      </DropdownItem>;

    return (
      <div className="translate_dropdown_container">
        <div
          ref={this.translateDetector}
          style={{display: 'none'}}
        >
          English
        </div>

        {this.props.smallWindow ?
          smallScreenTranslate
          :
          <Dropdown
            key="destop-translate"
            isOpen={this.state.open}
            toggle={this.toggle}
            className="translate__dropdown notranslate"
            translate="no"
          >
            <DropdownToggle
              caret
              color="secondary"
              className="roboto"
            >
              <FontAwesomeIcon
                color={theme.header_color}
                icon={faGoogle}
                mask={['fas', 'circle']}
                size="lg"
                transform="shrink-6"
              />
              {
                _.get(this.state.language, 'slug') === 'en'
                  ? 'Select Language'
                  : _.get(this.state.language, 'name')
              }
            </DropdownToggle>
            <DropdownMenu
              className="roboto"
              right
            >
              <div className="translate__dropdown__menu">
                {languages}
              </div>
              {disclaimer}
            </DropdownMenu>
          </Dropdown>
        }
      </div>
    );
  }
}
