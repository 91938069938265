import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { meta, preload } from 'react-website';
import { TabContent, TabPane } from 'reactstrap';
import * as _ from 'lodash';

import {
  switchClient,
  startClientPolling,
  stopClientPolling,
} from '../../redux/admin';
import { metaKey } from '../../utils/helpers';

import './AdminClient.scss';

const POLLING_ROUTES = /(\/manage|\/minutes)$/;

@preload(async ({ dispatch, params }) => {
  await dispatch(switchClient(params.clientslug));
})
@connect(
  ({ admin }) => ({ currClient: admin.currClient }),
  { startClientPolling, stopClientPolling },
)
@meta((state) => ({
  ...metaKey(
    'title',
    (
      _.get(state, 'admin.currClient.name') +
      ', ' +
      _.upperCase(_.get(state, 'admin.currClient.region.slug'))
    ),
  ),
}))
export default class Admin extends Component {
  static propTypes = {
    children : PropTypes.node.isRequired,
  }

  constructor () {
    super();

    this.startPolling = this.startPolling.bind(this);
    this.stopPolling = this.stopPolling.bind(this);
  }

  componentDidMount () {
    this.startPolling();

    this.onFocus = () => _.attempt(this.startPolling, true);
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.stopPolling);
  }

  componentWillUnmount () {
    this.stopPolling();
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.stopPolling);
  }

  componentDidUpdate (prevProps) {
    const { location: oldLocation } = prevProps;
    const { location: newLocation } = this.props;

    if (oldLocation.pathname != newLocation.pathname) {
      this.startPolling();
    }
  }

  async startPolling (immediate = false) {
    const {
      location,
      startClientPolling,
    } = this.props;

    this.stopPolling();

    if (!POLLING_ROUTES.test(location.pathname)) {
      // We're done if we don't need to poll
      return;
    }

    await startClientPolling(immediate);
  }

  async stopPolling () {
    const { stopClientPolling } = this.props;

    await stopClientPolling();
  }

  render () {
    const { children } = this.props;

    return (
      <TabContent>
        <TabPane>
          { children }
        </TabPane>
      </TabContent>
    );
  }
}
