import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';

import { StatefulForm } from '../utils/StatefulForm';
import { createVersion } from '../redux/admin';

@connect(
  ({ admin }) => ({
    currClient: admin.currClient,
    createVersionPending: admin.createVersionPending,
    createVersionError: admin.createVersionError,
  }),
  { createVersion },
  null,
  { withRef: true }, // Makes wrappedInstance available in ref
)
export class CreateVersionModal extends StatefulForm {
  static propTypes = {
    createVersionPending : PropTypes.bool,
    createVersionError : PropTypes.object,
  }
  state = {
    open: false,
    firstGoDone: false,
    formValue: {
      name: '',
      currency_info: '',
      not_visible: false,
    },
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
    this.nameInput = React.createRef();
  }

  async toggle () {
    if (!this.state.open) {
      // Focus is ignored without delay
      setTimeout(() => this.nameInput.current.focus(), 100);

      this.setState({
        formValue: {
          name: '',
          currency_info: '',
          not_visible: false,
        },
        firstGoDone: false,
      });
    }

    await this.setState((prevState) => ({open: !prevState.open}));
  }

  async submitForm (e) {
    e.preventDefault();
    const {
      createVersion,
      currClient,
    } = this.props;

    this.setState({firstGoDone: true});

    await createVersion({
      ..._.pick(this.state.formValue, ['name', 'currency_info']),
      visible: !this.state.formValue.not_visible,
      client: currClient.id,
    });

    await this.setState({open: false});
  }

  render () {
    const { formValue, firstGoDone } = this.state;
    const { createVersionPending, createVersionError } = this.props;

    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>Create Version</ModalHeader>
        <ModalBody>
          <p className="text-center">
            Input version details and click Save.
          </p>
          <FormGroup>
            <Input
              autoComplete="off"
              innerRef={this.nameInput}
              name="name"
              type="text"
              placeholder="Version Name"
              value={ formValue.name }
              invalid={ firstGoDone && _.has(createVersionError, 'name') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createVersionError, 'name') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              name="currency_info"
              type="text"
              placeholder="Currency Information"
              value={ formValue.currency_info }
              invalid={ firstGoDone && _.has(createVersionError, 'currency_info') }
              onChange={ this.handleChange }
            />
            <FormFeedback role="alert">
              { _.get(createVersionError, 'currency_info') }
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              id="not_visible"
              name="not_visible"
              type="checkbox"
              checked={ formValue.not_visible }
              onChange={ this.handleChange } />
            <Label for="not_visible" check>Mark this version as hidden</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={ createVersionPending }
            onClick={this.submitForm}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
