import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { goto, meta, preload, Loading, Link } from 'react-website';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from 'reactstrap';
import Cookies from 'universal-cookie';
import * as _ from 'lodash';

import { default as config } from '../../configuration';

import UserMenu from '../components/UserMenu';
import { RedirectModal } from '../modals';
import { ExportRequests } from '../components/ExportRequests';
import Translate from '../components/Translate';
import { setupAnalytics } from '../utils/analytics';
import { setFirebase } from '../redux/analytics';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  faQuestionCircle,
  fas,
  faSearch,
  faEllipsisV,
  faArrowCircleLeft,
  faSitemap,
  faWheelchair,
} from '@fortawesome/free-solid-svg-icons';
library.add(fas, far);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../assets/images/amlegal-logo.png';

import './Application.scss';

import { getUser } from '../redux/account';
import {
  getCodeTheme,
  getScreenSmall,
  setLeftNavClosed,
  setScreenWidth,
} from '../redux/codes';
import { clearSearch } from '../redux/search';
import { metaKey, onUserInteract } from '../utils/helpers';
import { loadRecaptcha } from '../utils/recaptcha';

@preload(async ({ dispatch, getState, getCookie }) => {
  const { account } = getState();

  // Don't bother preloading the user if it's already there because we just logged in
  if (!!getCookie('token') && !account.user) {
    await dispatch(getUser());
  }
}, { blocking: true })
@connect(({ account, codes, analytics, global }) => ({
  mobile: global.mobile,
  currClient: codes.currClient,
  leftNavClosed: codes.leftNavClosed,
  user: account.user,
  firebaseAnalytics: analytics.firebaseAnalytics,
  isScreenSmall: getScreenSmall(codes),
  theme: getCodeTheme(codes),
}), {
  clearSearch,
  setLeftNavClosed,
  setFirebase,
  setScreenWidth,
  goto,
})
@meta(({ found }) => {
  return {
    type : 'website',
    ['twitter:card']: 'summary_large_image',
    site_name : 'American Legal Publishing',
    ...metaKey('url', `${config.web_host}${_.get(found, 'match.location.pathname')}`),
    ...metaKey('image', `${config.web_host}/alp-logo.jpg`),
    ...metaKey(
      'description',
      (
        'Legal publisher offering ordinance codification services for local ' +
        'governments, specializing in providing codes of ordinances in print ' +
        'and on the Internet'
      ),
    ),
    locale      : 'en_US',
    locales     : ['en_US'],
    viewport    : 'width=device-width, initial-scale=1',
    robots      : config.seo_indexing ? undefined :'noindex,nofollow',
  };
})
export default class App extends Component {
  static propTypes = {
    children : PropTypes.node.isRequired,
  }
  state = {
    isKebabOpen: false,
    areResourcesOpen: false,
    showMenuTooltip: false,
  }

  constructor () {
    super();

    this.topRef = React.createRef();
    this.topCodeRef = React.createRef();
    this.redirectModal = React.createRef();

    this.toggleLeftNav = this.toggleLeftNav.bind(this);
    this.toggleKebab = this.toggleKebab.bind(this);
    this.toggleResources = this.toggleResources.bind(this);
    this.gotoSearch = this.gotoSearch.bind(this);
    this.displayMenuTooltipIfNeeded = this.displayMenuTooltipIfNeeded.bind(this);
  }

  async componentDidMount () {
    const {
      user,
      firebaseAnalytics,
      setFirebase,
      location,
    } = this.props;

    // Remove bogus token to avoid setting account for anonymous calls (ie login)
    if (!user) {
      const cookies = new Cookies();
      cookies.remove('token', { path: '/' });
    }

    if (!firebaseAnalytics) {
      setupAnalytics(setFirebase);
    }

    loadRecaptcha();

    if (_.has(location, 'query.o')) {
      setTimeout(() => {
        this.redirectModal.current.toggle();
      }, 1000);
    }

    await this.props.setScreenWidth(window.innerWidth);
    this.displayMenuTooltipIfNeeded();
  }
  
  componentDidUpdate (prevProps) {
    const {
      location: oldLocation,
      currClient: oldClient,
    } = prevProps;
    const {
      location: newLocation,
      params: propsParams,
      currClient: newClient,
    } = this.props;

    if (
      oldLocation.pathname != newLocation.pathname ||
      oldLocation.hash != newLocation.hash
    ) {
      if (_.get(propsParams, 'sectionRenderType') && (window.innerWidth <= 1000)) {
        this.topCodeRef.current.focus();
      } else {
        const home = document.querySelector('#home-link');
        if (home) {
          home.focus();
        }
      }
    }

    if (_.get(oldClient, 'slug') != _.get(newClient, 'slug')) {
      if (!_.get(newClient, 'slug')) {
        this.setState({ showMenuTooltip: false });
      } else {
        this.displayMenuTooltipIfNeeded();
      }
    }
  }

  displayMenuTooltipIfNeeded () {
    const { isScreenSmall } = this.props;
    if (!isScreenSmall || !this.topCodeRef.current) {
      return;
    }

    const tipKey = '_alp_menu_tip';
    const cookies = new Cookies();
    let seenTip = cookies.get(tipKey);

    if (!seenTip) {
      cookies.set(tipKey, 1, { path: '/' });
      setTimeout(() => {
        this.setState({ showMenuTooltip: true });
      }, 1000);

      setTimeout(() => {
        this.setState({ showMenuTooltip: false });
      }, 6000);
    }
  }

  async toggleLeftNav () {
    const {
      leftNavClosed,
      setLeftNavClosed,
    } = this.props;
    await setLeftNavClosed(!leftNavClosed);

    const page = document.querySelector('#alp-page');
    if (!page) {
      return;
    }

    if (!leftNavClosed) {
      page.classList.remove('body-fixed');
    } else {
      page.classList.add('body-fixed');
    }
  }

  async toggleKebab () {
    await this.setState((prevState) => ({
      isKebabOpen: !prevState.isKebabOpen,
    }));
  }

  async toggleResources () {
    await this.setState((prevState) => ({
      areResourcesOpen: !prevState.areResourcesOpen,
    }));
  }

  async gotoSearch () {
    const {
      currClient,
      clearSearch,
      goto,
    } = this.props;

    await clearSearch(currClient);
    goto('/search');
  }

  render () {
    const {
      children,
      currClient,
      goto,
      theme,
      mobile,
      leftNavClosed,
    } = this.props;

    return (
      <div role="main">
        {/* Page loading indicator */}
        <Loading/>

        <style dangerouslySetInnerHTML={{
          __html:`
            .admin-page .secondary-header,
            .code-nav .secondary-header,
            .search-page .browse-clients,
            .browse-page .breadcrumb,
            .sitemap .breadcrumb,
            .accessibility .breadcrumb,
            .codenav__breadcrumbs {
              background-color: ${theme.subheader_background} !important;
            }
            .codenav__breadcrumbs .code-options .btn-white-circle:not(:disabled):not(.disabled):hover,
            .codenav__breadcrumbs .code-options .btn-white-kebab:not(:disabled):not(.disabled):hover {
              color: ${theme.subheader_background} !important;
            }
            .codenav__breadcrumbs .code-options .show > .btn-white-kebab:not(:disabled):not(.disabled){
              color: ${theme.subheader_background} !important;
            }
            .codenav__bottom-left {
              background-color: ${theme.subheader_background} !important;
            }
            .top-nav__right .btn-secondary,
            .top-nav__right .btn-secondary:not(:disabled):not(.disabled),
            .top-nav__right .show > .btn-secondary.dropdown-toggle:not(:disabled):not(.disabled) {
              color: ${theme.header_color};
            }
            .hamburger__inner,
            .hamburger__inner:after,
            .hamburger__inner:before {
              background-color: ${theme.header_color};
            }
          `,
        }}></style>

        <div
          id="alp-page"
          className={classnames('webpage', { 'webpage--mobile': mobile })}
        >
          <div id="google_translate_element2"></div>

          <nav 
            role="navigation"
            className="webpage__header"
          >
            <div
              className="top-nav"
              style={{backgroundColor: theme.header_background}}
            >
              <div className="top-nav__left">
                <span id="menu-toggle">
                  {
                    currClient &&
                    <div
                      role="button"
                      tabIndex={0}
                      className={classnames(
                        'hamburger',
                        { 'hamburger--active': !leftNavClosed },
                      )}
                      aria-label={leftNavClosed
                        ? 'Expand code navigation menu'
                        : 'Collapse code navigation menu'
                      }
                      ref={this.topCodeRef}
                      onClick={this.toggleLeftNav}
                      onKeyDown={onUserInteract(() => this.toggleLeftNav())}
                    >
                      <div className="hamburger__box">
                        <div className="hamburger__inner"></div>
                      </div>
                    </div>
                  }
                </span>
                <Tooltip
                  placement="right"
                  isOpen={this.state.showMenuTooltip}
                  target="menu-toggle"
                  innerClassName="text-left"
                >
                  Click here to access the Table of Contents!
                </Tooltip>
                <Link
                  id="home-link"
                  className="logo-link"
                  to="/"
                  aria-label="American Legal Code Library"
                >
                  <img
                    alt="American Legal Publishing"
                    src={ theme.logo || logo }
                    className="header-logo"/>
                </Link>
                <a
                  tabIndex={0}
                  className="skip-main"
                  href="#maincontent"
                >
                  Skip to main content
                </a>

              </div>
              <div className="top-nav__kebab">
                <Dropdown
                  isOpen={this.state.isKebabOpen}
                  toggle={this.toggleKebab}
                >
                  <DropdownToggle
                    aria-label="Expand menu options"
                    className="btn btn-secondary-kebab"
                  >
                    <FontAwesomeIcon
                      color={theme.header_color}
                      icon={faEllipsisV}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="roboto"
                    right
                  >
                    <DropdownItem
                      className="btn-secondary button-link"
                      onClick={this.gotoSearch}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        mask={['fas', 'circle']}
                        size="lg"
                        transform="shrink-6"
                      />
                      Search
                    </DropdownItem>
                    <UserMenu
                      smallWindow={true}
                      onDropdownClose={() => this.toggleKebab()}
                    />
                    <Translate smallWindow={true} />
                    <DropdownItem
                      className="my-account-small roboto"
                      header
                    >
                      Resources
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      className="btn-secondary roboto"
                      href="/sitemap"
                    >
                      <FontAwesomeIcon
                        icon={faSitemap}
                        size="lg"
                      />
                      Sitemap
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      className="btn-secondary roboto"
                      href="/accessibility"
                    >
                      <FontAwesomeIcon
                        icon={faWheelchair}
                        size="lg"
                      />
                      Accessibility
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      className="btn-secondary roboto"
                      href="https://www.amlegal.com/tutorials"
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="Opens help center in a new tab"
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="lg"
                      />
                      Help Center
                    </DropdownItem>
                    {
                      currClient &&
                      <DropdownItem
                        className="btn-secondary roboto"
                        onClick={async () => {
                          await this.props.clearSearch();
                          goto('/');
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowCircleLeft}
                          size="lg"
                        />
                        Back to Library
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div
                className="top-nav__right"
                style={{color: theme.header_color}}
              >
                <Button
                  className="roboto button-link"
                  onClick={this.gotoSearch}
                >
                  <FontAwesomeIcon
                    color={theme.header_color}
                    icon={faSearch}
                    mask={['fas', 'circle']}
                    size="lg"
                    transform="shrink-6"
                  />
                  Search
                </Button>
                <UserMenu smallWindow={false} />
                <Translate smallWindow={false} />
                <Dropdown
                  isOpen={this.state.areResourcesOpen}
                  toggle={this.toggleResources}
                >
                  <DropdownToggle
                    caret
                    className="roboto"
                  >
                    <FontAwesomeIcon
                      color={theme.header_color}
                      icon={faQuestionCircle}
                      size="lg"
                    />
                    Resources
                  </DropdownToggle>
                  <DropdownMenu className="roboto">
                    <DropdownItem
                      tag="a"
                      className="btn-secondary roboto"
                      href="/sitemap"
                    >
                      Sitemap
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      className="btn-secondary roboto"
                      href="/accessibility"
                    >
                      Accessibility
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      aria-label="Opens help center in a new tab"
                      className="btn-secondary roboto"
                      href="https://www.amlegal.com/tutorials"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Help Center
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </nav>

          <div
            className="webpage__content"
            id="maincontent"
          >
            { children }
          </div>

          <ExportRequests />
        </div>
        <RedirectModal ref={this.redirectModal} />
      </div>
    );
  }
}
