import Cookies from 'universal-cookie';
import { HttpUtil } from './HttpUtil';

export class UploadAdapter {
  constructor (loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload () {
    this.loader.fire('upload:started');

    return this.loader.file.then((file) => {
      this.http = new HttpUtil(
        {
          url: `/api/admin-upload/`,
          method: 'put',
          data: { file },
          headers: { 'Authorization': 'Bearer ' + new Cookies().get('token') },
        },
        (res) => ({
          uploaded: true,
          default: res.url,
        }),
      );

      const promise = this.http.send();

      promise.then(() => this.loader.fire('upload:done'));

      return promise;
    });
  }

  // Aborts the upload process.
  abort () {
    this.http.abort();
  }
}
