import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from 'reactstrap';
import * as _ from 'lodash';

import './SearchDropdown.scss';

export default class SearchDropdown extends React.Component {
  static propTypes = {
    data : PropTypes.array.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
  }

  state = {
    open: false,
    searchText: '',
    filteredData: null,
  }

  constructor () {
    super();

    this.searchInput = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));

    if (this.state.open) {
      this.searchInput.current.focus();
    }
  }

  async onSearchChange (val) {
    await this.setState({searchText: val});

    await this.filterOptions(val);
  }

  filterOptions (searchText) {
    const { data } = this.props;

    searchText = _.toLower(searchText);

    const tmpData =  _.filter(data, (d) => {
      return _.includes(_.toLower(d.name), searchText);
    });
    this.setState({ filteredData : tmpData });
  }

  async changeData (val) {
    await this.setState({value: val});

    this.props.onChange(val);

    this.onSearchChange('');
  }

  render () {
    const { filteredData } = this.state;
    const { data, value, label } = this.props;

    const dataToMap = !!filteredData ? filteredData : data;

    const stateList = _.map(
      dataToMap,
      (dataList) => (
        <DropdownItem
          key={ `client-${dataList.slug}` }
          onClick={() => this.changeData(dataList.name)}
        >
          {dataList.name}
        </DropdownItem>
      ),
    );

    return (
      <Dropdown
        id={value}
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <DropdownToggle
          aria-label={`Select a ${label}`}
          caret
          className="form-control search__toggle"
        >
          {value || `Select a ${label}`}
        </DropdownToggle>
        <DropdownMenu
          className="form-control search__dropdown"
        >
          <div className="dropdown-search">
            <FormGroup>
              <Input
                innerRef={this.searchInput}
                type="text"
                name="search"
                placeholder="Search"
                value={this.state.searchText}
                onChange={(e) => this.onSearchChange(e.target.value)}
              />
              <Button
                aria-label="Close"
                close
                className="state-search-close"
                onClick={() => this.onSearchChange('')}
              />
            </FormGroup>
          </div>
          <div className="search-items">
            { stateList }
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
