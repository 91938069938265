import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import { Button, Collapse, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faExternalLinkSquareAlt,
  faMinusCircle,
  faTimesCircle,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import './ExportRequests.scss';

import { default as config } from '../../configuration';
import { onUserInteract, randomString } from '../utils/helpers';
import {
  startPolling,
  stopPolling,
  deleteRequest,
} from '../redux/exports';
import { setUserHash } from '../redux/account';

@connect(({ exports, account, codes }) => ({
  user: account.user,
  userHash: account.userHash,
  exportRequests: exports.exportRequests,
  currClient: codes.currClient,
}), { startPolling, stopPolling, deleteRequest, setUserHash })
export class ExportRequests extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }
  state = {
    open: true,
    deleteMode: {},
  }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
    this.delete = this.delete.bind(this);
  }

  async componentDidMount () {
    const { setUserHash, startPolling } = this.props;

    const hashKey = '_alp_fp';
    const cookies = new Cookies();
    let userHash = cookies.get(hashKey);

    if (!userHash) {
      userHash = randomString(32);
      cookies.set(hashKey, userHash, { path: '/' });
    }

    await setUserHash(userHash);
    startPolling();
  }

  componentWillUnmount () {
    const { stopPolling } = this.props;

    stopPolling();
  }

  async toggle () {
    await this.setState((prevState) => ({ open: !prevState.open }));
  }

  async toggleDeleteMode (uuid) {
    await this.setState((prevState) => ({
      deleteMode: {
        ...prevState.deleteMode,
        [uuid]: !_.get(prevState.deleteMode, uuid, false),
      },
    }));
  }

  async delete (uuid) {
    const {
      deleteRequest,
      userHash,
    } = this.props;

    await deleteRequest(uuid, userHash);
  }

  render () {
    const { 
      exportRequests,
      currClient,
    } = this.props;

    const requests = _.map(
      exportRequests,
      (r) => {

        return (
          <div
            key={ `request-${r.uuid}` }
            className="exports__request"
          >
            <div>
              <Button
                aria-label="Delete export"
                color="link"
                className="request__delete"
                title={this.state.deleteMode[r.uuid] ? 'Cancel' : 'Remove'}
                onClick={() => this.toggleDeleteMode(r.uuid)}
              >
                <FontAwesomeIcon
                  color="#cc4949"
                  icon={this.state.deleteMode[r.uuid] ? faTimesCircle : faMinusCircle}
                />
              </Button>
            </div>
            <div className="request__middle">
              <Button
                aria-label={`Remove ${r.name} export`}
                color="danger"
                size="sm"
                className={classnames(
                  'btn--remove',
                  { 'btn--remove-appear': this.state.deleteMode[r.uuid] },
                )}
                onClick={() => this.delete(r.uuid)}
              >
                Remove
              </Button>
              <span className={classnames(
                'request__name overflow-ellipsis',
                { 'request__name--hide': this.state.deleteMode[r.uuid] },
              )}>
                {r.name}{r.for_print ? '' : `.${r.output_format}`}
              </span>
            </div>
            <div className="request__actions">
              {
                r.task.completed
                  ? (
                    (
                      r.task.post_state == 'SUCCESS' &&
                      <a href={`${config.exports_host || ''}/api/export-requests/${r.uuid}/download/`}
                        className="btn btn-secondary request__open"
                        data-request-uuid={r.uuid}
                        rel="noopener noreferrer"
                        target={r.for_print ? '_blank' : '_self'}
                        aria-label="Opens exported or printable file in a new tab"
                      >
                        <FontAwesomeIcon
                          color="#333"
                          icon={r.for_print ? faPrint : faExternalLinkSquareAlt}
                        />
                        { r.for_print ? 'PRINT' : 'OPEN' }
                      </a>
                    ) || (
                      <span
                        className="request__failed"
                        title="Sorry. Document too large."
                      >
                        FAILED
                      </span>
                    )
                  )
                  : (
                    <div className="request__progress">
                      <Progress
                        animated
                        color="info"
                        value={ 100 }
                      />
                      PREPARING FILE...
                    </div>
                  )
              }
            </div>
          </div>
        );
      },
    );

    const itemsPreparing = _.sumBy(exportRequests, (r) => !r.task.completed ? 1 : 0);

    return (
      <div
        className={classnames(
          'exports',
          {
            'exports--visible': exportRequests.length > 0,
            'exports--bottombar': !!currClient,
          },
        )}
        aria-hidden={_.isEmpty(exportRequests)}
      >
        <div
          role="button"
          className="exports__header"
          tabIndex={_.isEmpty(exportRequests) ? -1 : 0}
          onClick={this.toggle}
          onKeyDown={onUserInteract(() => this.toggle())}
        >
          <span className="exports__preparing">
            {
              itemsPreparing > 0
                ? `Preparing ${itemsPreparing} item${itemsPreparing == 1 ? '' : 's'}...`
                : `${exportRequests.length} item${exportRequests.length == 1 ? '' : 's'} available`
            }
          </span>
          <Button
            aria-label="Expand export list"
            tabIndex={_.isEmpty(exportRequests) ? -1 : 0}
            color="link"
            className={classnames(
              'exports__arrow',
              { 'exports__arrow--expanded': this.state.open }
            )}
          >
            <FontAwesomeIcon
              color="#fff"
              icon={faAngleUp}
            />
          </Button>
        </div>
        <Collapse
          className="exports__tasks"
          isOpen={this.state.open}
        >
          { requests }
        </Collapse>
      </div>
    );
  }
}
