import routes  from './routes';
import * as reducers from './redux';
import container from './Container';
import { analyticsMiddleware } from './redux/analytics';
import { exportsMiddleware } from './redux/exports';
import { codesMiddleware } from './redux/codes';
import { sectionsMiddleware } from './redux/sections';
import { adminMiddleware } from './redux/admin';
import { searchMiddleware } from './redux/search';
import { annotationsMiddleware } from './redux/annotations';

import config from '../configuration';

// "Favicon" must be imported on the client side too
// since no assets are emitted on the server side
export { default as icon } from '../assets/images/favicon.png';

export default
{
  reducers,
  routes,
  container,

  // When the website is open in a web browser
  // hide website content under a "preloading" screen
  // until the application has finished loading.
  // It still "blinks" a bit in development mode
  // because CSS styles in development mode are included
  // not as `*.css` files but dynamically via javascript
  // by adding a `<style/>` DOM element, and that's why
  // in development mode styles are not applied immediately
  // in a web browser. In production mode CSS styles are
  // included as `*.css` files so they are applied immediately.
  showPreloadInitially: true,

  reduxMiddleware: () => [
    analyticsMiddleware,
    exportsMiddleware,
    codesMiddleware,
    sectionsMiddleware,
    adminMiddleware,
    searchMiddleware,
    annotationsMiddleware,
  ],

  onError (error, { path, url, redirect/*, dispatch, getState, server*/ }) {
    console.error(`Error while preloading "${url}"`);
    // console.error(error);

    // Not authenticated
    if (error.status === 401) {
      // Prevent double redirection to `/unauthenticated`.
      // (e.g. when two parallel `Promise`s load inside `@preload()`
      //  and both get Status 401 HTTP Response)
      if (typeof window !== 'undefined' && window.location.pathname === '/unauthenticated') {
        return;
      }

      return redirect('/unauthenticated');
    }

    // Not authorized
    if (error.status === 403) {
      return redirect('/unauthorized');
    }

    // Not found
    if (error.status === 404) {
      return redirect('/not-found');
    }

    // Redirect to a generic error page in production
    if (process.env.NODE_ENV === 'production') {
      // Prevents infinite redirect to the error page
      // in case of overall page rendering bugs, etc.
      if (path !== '/error') {
        // Redirect to a generic error page
        return redirect(`/error?url=${encodeURIComponent(url)}`);
      }
    }
  },

  http: {
    transformURL: (url, server) => {
      if (server && config.transform_url) {
        return `${config.transform_url}${url.replace('/api', '')}`;
      }

      return url;
    },
    onRequest: (request) => {
      request.set('Cache-Control', 'no-cache');
      request.set('Pragma', 'no-cache');
    },
  },

  authentication: {
    accessToken ({ getCookie }) {
      return getCookie('token');
    },
  },
};
