import React, { Component } from 'react';
import { Link } from 'react-website';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

export class AnnotationsModal extends Component {
  state = { open: false }

  constructor () {
    super();

    this.toggle = this.toggle.bind(this);
  }

  async toggle () {
    await this.setState((prevState) => ({open: !prevState.open}));
  }

  render () {
    return (
      <Modal
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle}>
          Annotations
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            <Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> to add annotations.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
