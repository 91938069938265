import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, goto, preload, redirect } from 'react-website';
import ScrollbarSize from 'react-scrollbar-size';
import {
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

import { BackToSearch } from '../codes/BackToSearch';
import { CodeOptions } from '../codes/CodeOptions';
import { CodeOptionsModal } from '../../modals';
import { VersionDropdown } from './VersionDropdown';
import { NavBottom } from './NavBottom';
import { CodeAction } from '../../utils/CodeAction';
// REDUX
import { clearSearch } from '../../redux/search';
import { logFirebaseEvent } from '../../redux/analytics';
import {
  getClient,
  clearClient,
  codeToggle,
  collapseToc,
  expandToc,
  getRenderType,
  getViewedToc,
  setViewedHash,
  previewCode,
  previewVersion,
  setCodeOptionsModal,
  setLeftNavClosed,
  setScreenWidth,
  getScreenSmall,
  switchVersion,
  tocKey,
  minutesKey,
  showMinutesHeader,
  toggleMinutesHeader,
  toggleMinutes,
  SCOPE_INPUT_PADDING,
  RenderType,
} from '../../redux/codes';
import { jumpTo } from '../../redux/sections';
import { getShowAnnotations } from '../../redux/annotations';
import { smoothScrollTo, offsetTop, monthToLocale } from '../../utils/helpers';

const delay = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

@preload(async ({ dispatch, getState, params, server }) => {
  const renderType = getRenderType(getState().found);

  if (renderType === RenderType.NORMAL) {
    if (_.get(getState().codes, 'currClient.slug') != params.clientslug) {
      await dispatch(getClient(params.clientslug));
    }

    const { codes } = getState();

    try {
      if (params.version != 'latest') {
        await dispatch(switchVersion(params.version));
      } else {
        // First version is considered latest
        const currVersion = _.get(codes.currClient, 'versions.0');
        if (currVersion) {
          await dispatch(switchVersion(currVersion.uuid));
        } else {
          await dispatch(showMinutesHeader());
        }
      }
    } catch {
      await dispatch(redirect(`/codes/${params.clientslug}/latest/overview`));

      return;
    }

    // Redirect code slug without doc ID to first doc
    if (!!params.codeslug && !params.docid) {
      const code = _.find(
        _.get(getState(), 'codes.selectedVersion.toc'),
        (c) => c.slug == params.codeslug,
      );
      const docId = _.get(code, 'sections.0.doc_id');
      await dispatch(
        redirect(`/codes/${params.clientslug}/${params.version}/${params.codeslug}/${encodeURIComponent(docId)}`)
      );
    }
  } else if (renderType === RenderType.PREVIEW_CODE) {
    await dispatch(previewCode(params.uuid));
    const { codes } = getState();

    // Only fetch if changing clients
    if (
      _.get(codes, 'currClient.slug') !=
      _.get(codes, 'previewCode.client_slug')
    ) {
      await dispatch(getClient(
        _.get(codes, 'previewCode.client_slug'),
      ));
    }

    // If root url, redirect to first section
    if (!params.docid) {
      if (_.get(codes, 'defaultDocId')) {
        await dispatch(
          redirect(`/preview/c/${params.uuid}/${encodeURIComponent(_.get(codes, 'defaultDocId'))}/`)
        );
      }
    }
  } else if (renderType === RenderType.PREVIEW_VERSION) {
    await dispatch(previewVersion(params.version));
    const { codes } = getState();

    // Only fetch if changing clients
    if (
      _.get(codes, 'currClient.slug') !=
      _.get(codes, 'previewVersion.client_slug')
    ) {
      await dispatch(getClient(
        _.get(codes, 'previewVersion.client_slug'),
      ));
    }

    // If root url, redirect to first section
    if (!!params.version && !params.codeslug) {
      const codeSlug = _.get(codes, 'previewVersion.toc.0.slug');
      const defaultDocId = encodeURIComponent(_.get(codes, 'defaultDocId'));
      if (defaultDocId) {
        await dispatch(
          redirect(`/preview/v/${params.version}/${codeSlug}/${encodeURIComponent(defaultDocId)}/`)
        );
      }
    }
  } else {
    console.log('Unknown navigation page');
  }

  // Delay client-side for theme CSS animations
  if (!server) {
    await delay(250);
  }
}, {blocking: true}) //causes children to wait for parent to load
@connect(({ codes, found, annotations, search, sections }) => ({
  currClient: codes.currClient,
  currSection: codes.currSection,
  leftNavClosed: codes.leftNavClosed,
  defaultDocId: codes.defaultDocId,
  navCodeLookup: codes.navCodeLookup,
  navTocLookup: codes.navTocLookup,
  minutesLookup: codes.minutesLookup,
  minutesOpen: codes.minutesOpen,
  previewCode: codes.previewCode,
  previewVersion: codes.previewVersion,
  selectedCode: codes.selectedCode,
  selectedVersion: codes.selectedVersion,
  viewedToc: getViewedToc(codes),
  viewedHash: codes.viewedHash,
  renderType: getRenderType(found),
  showAnnotations: getShowAnnotations({ codes, found }),
  compareOpen: codes.compareOpen,
  annotationsOn: annotations.annotationsOn,
  searchResult: search.searchResult,
  isScreenSmall: getScreenSmall(codes),
  beforeIndex: sections.beforeIndex,
  currIndex: sections.currIndex,
  afterIndex: sections.afterIndex,
  items: sections.items,
}), {
  clearClient,
  clearSearch,
  codeToggle,
  collapseToc,
  expandToc,
  toggleMinutesHeader,
  toggleMinutes,
  goto,
  redirect,
  jumpTo,
  setCodeOptionsModal,
  logFirebaseEvent,
  setLeftNavClosed,
  setScreenWidth,
  setViewedHash,
})
export default class Nav extends Component {
  static propTypes = {
    children : PropTypes.node.isRequired,
  }
  state = {
    triedToScroll: false,
    scrollbarWidth: 0,
  }

  constructor (props) {
    super(props);

    this.codeNavLeft = React.createRef();
    this.section = React.createRef();
    this.codeOptionsModal = React.createRef();

    this.toggleLeftNav = this.toggleLeftNav.bind(this);
    this.codeToggle = this.codeToggle.bind(this);
    this.tocToggle = this.tocToggle.bind(this);
    this.toggleMinutesHeader = this.toggleMinutesHeader.bind(this);
    this.toggleMinutes = this.toggleMinutes.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.scrollbarMeasured = this.scrollbarMeasured.bind(this);
    this.onTocClicked = this.onTocClicked.bind(this);
    this.codeIsAvailable = this.codeIsAvailable.bind(this);
  }

  async componentDidMount () {
    await this.props.setScreenWidth(window.innerWidth);
    await this.props.setLeftNavClosed(this.props.isScreenSmall);
    window.addEventListener('resize', this.onWindowResize);

    if (this.props.renderType === RenderType.NORMAL) {
      const {
        currClient,
        currSection,
        logFirebaseEvent,
        setCodeOptionsModal,
      } = this.props;

      await setCodeOptionsModal(this.codeOptionsModal);

      // First load -- client hit analytics
      logFirebaseEvent({
        eventName: 'code_hit',
        params: {
          region_slug: _.get(currClient, 'region.slug'),
          client_slug: _.get(currClient, 'slug'),
          code_slug: _.get(currSection, 'code_slug'),
        },
      });
    }

    const { location } = this.props;
    if (_.has(location, 'query.bookmark')) {
      const bookmark = location.query.bookmark;

      // Remove query param from URL
      window.history.replaceState('', '', location.pathname);

      setTimeout(() => {
        this.codeOptionsModal.current.wrappedInstance.show({
          action: CodeAction.BOOKMARK,
          destID: decodeURIComponent(bookmark),
          location: window.location,
        });
      }, 300);
    }
  }

  async componentDidUpdate (prevProps) {
    if (this.props.renderType === RenderType.NORMAL) {
      const {
        location: oldLocation,
        currSection: oldCurrSection,
      } = prevProps;
      const {
        currSection: newCurrSection,
        logFirebaseEvent,
      } = this.props;

      const origDocChanged = _.get(oldCurrSection, 'orig_doc_id') != _.get(newCurrSection, 'orig_doc_id');

      // Client-side navigation -- client hit analytics
      if (_.has(oldLocation, 'key') && origDocChanged) {
        const {
          currClient,
          currSection,
        } = this.props;

        logFirebaseEvent({
          eventName: 'code_hit',
          params: {
            region_slug: _.get(currClient, 'region.slug'),
            client_slug: _.get(currClient, 'slug'),
            code_slug: _.get(currSection, 'code_slug'),
          },
        });
      }

      if (origDocChanged) {
        this.codeOptionsModal.current.setState({open: false});
      }
    }

    // The viewed hash is specifically what's in the center of the screen.
    // The viewed toc will be less specific based on what's collapsed in the nav.
    // We auto-scroll the left nav on first load or if the viewed hash or current
    // document change.
    if (
      (
        !this.state.triedToScroll ||
        prevProps.viewedHash !== this.props.viewedHash ||
        _.get(prevProps, 'currSection.doc_id') !== _.get(this.props, 'currSection.doc_id') ||
        _.get(prevProps, 'currSection.code_uuid') !== _.get(this.props, 'currSection.code_uuid')
      ) &&
      typeof window !== 'undefined' &&
      _.has(this.props.viewedToc.lookupEntry, 'doc_id')
    ) {
      const lookupEntry = this.props.viewedToc.lookupEntry;
      const code_uuid = this.props.currSection.code_uuid;

      const codeNav = document.querySelector('.codenav__toc');
      const tocEntry = document.querySelector(
        `.codenav a[data-docid="${lookupEntry.doc_id}"][data-codeuuid="${code_uuid}"]`,
      );

      if (tocEntry && codeNav) {
        // This only works on first load in production mode because the styles haven't loaded locally
        smoothScrollTo(
          codeNav,
          Math.max(
            tocEntry.parentElement.offsetTop - SCOPE_INPUT_PADDING - (codeNav.offsetHeight / 2),
            0,
          ),
        );
      }

      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.triedToScroll = true;
    }

    if (prevProps.isScreenSmall != this.props.isScreenSmall) {
      this.props.setLeftNavClosed(this.props.isScreenSmall);
    }
  }

  async componentWillUnmount () {
    const { clearClient } = this.props;

    await clearClient();
    window.removeEventListener('resize', this.onWindowResize);
  }

  async scrollbarMeasured (m) {
    // Sets scrollbarWidth / scrollbarHeight
    await this.setState(m);
  }

  async onWindowResize () {
    await this.props.setScreenWidth(window.innerWidth);
  }

  async onTocClicked (e) {
    const {
      currSection,
      jumpTo,
      compareOpen,
      setLeftNavClosed,
      setViewedHash,
      renderType,
      match,
      searchResult,
    } = this.props;

    if (!currSection) {
      return;
    }

    const docId = _.get(e.target, 'dataset.docid');
    const origDocId = _.get(e.target, 'dataset.origDocId');
    const origDocIdx = parseInt(_.get(e.target, 'dataset.origDocIdx', '0'));
    const codeUUID = _.get(e.target, 'dataset.codeuuid');
    const scrollContainer = document.querySelector('.codenav__section-body');

    const isPreview = RenderType.isPreview(renderType);
    const isArchive = match.params.version != 'latest';
    const isSearching = !_.isEmpty(_.get(searchResult, 'results'), []);

    setViewedHash(docId);

    if (codeUUID != currSection.code_uuid) {
      // Allow jump between codes but to the same doc_id
      return;
    }

    e.persist();

    // Intentionally bypass router
    const doJump = async () => {
      e.preventDefault();
      e.stopPropagation();

      // Update browser url
      window.history.replaceState('', '', e.target.pathname);
      // Update lazy-loading pointers if needed
      await jumpTo({index: origDocIdx, compareOpen });

      // Close left nav when navigating
      if (window.innerWidth < 1000) {
        setLeftNavClosed(true);
      }
    };

    if (docId == currSection.orig_doc_id) {
      await doJump();
      scrollContainer.scrollTop = 0;
    } else if (origDocId == currSection.orig_doc_id) {
      await doJump();

      const containerTop = isPreview || isArchive || isSearching ? 130 : 100;

      const el = document.querySelector(`#section-${origDocIdx}`);
      if (el) {
        const newTop = offsetTop(el) - containerTop;
        scrollContainer.scrollTop = newTop;
      }
    }
  }

  async toggleLeftNav () {
    const {
      leftNavClosed,
      setLeftNavClosed,
    } = this.props;

    await setLeftNavClosed(!leftNavClosed);
  }

  async codeToggle (uuid) {
    const { codeToggle } = this.props;

    await codeToggle({ uuid, ctx: 'nav' });
  }

  async tocToggle (code, entry) {
    const {
      expandToc,
      collapseToc,
      navTocLookup,
    } = this.props;

    if (!_.get(navTocLookup, [tocKey(code, entry), 'expanded'], false)) {
      await expandToc({ ctx: 'nav', code, entry });
    } else {
      await collapseToc({ ctx: 'nav', code, entry });
    }
  }

  async toggleMinutesHeader () {
    const { toggleMinutesHeader } = this.props;

    await toggleMinutesHeader();
  }

  async toggleMinutes (params) {
    const { toggleMinutes } = this.props;

    await toggleMinutes(params);
  }

  renderSectionToc (code, entry) {
    const {
      renderType,
      match,
      navTocLookup,
      viewedToc,
    } = this.props;

    const lookupEntry = _.get(navTocLookup, [tocKey(code, entry)]);

    const collapseContent = !_.get(lookupEntry, 'loading', false)
      ? _.map(_.get(lookupEntry, `children`, []), (s) => this.renderSectionToc(code, s))
      : (
        <div className="toc-entry__loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          Loading...
        </div>
      );

    const clickHandler = _.get(lookupEntry, 'loading', false)
      ? null // disable anchor
      : () => this.tocToggle(code, entry);

    const linkUrl = {};
    const dataParams = {
      ['data-docid']: entry.doc_id,
      ['data-codeuuid']: code.uuid,
    };
    if (entry.orig_doc_id) {
      dataParams['data-orig-doc-id'] = entry.orig_doc_id;
      dataParams['data-orig-doc-idx'] = entry.orig_doc_idx;
    }
    const docId = encodeURIComponent(entry.passthru_doc_id || entry.doc_id);

    let key = `${entry.type}-${entry.id}`;

    switch(renderType) {
      case RenderType.PREVIEW_CODE:
        key = `${entry.type}-${entry.id}`;
        linkUrl.pathname = `/preview/c/${match.params.uuid}/${docId}`;
        break;
      case RenderType.PREVIEW_VERSION:
        key = `${entry.type}-${entry.doc_id}-${_.get(entry, 'record_id')}`;
        linkUrl.pathname = `/preview/v/${match.params.version}/${code.slug}/${docId}`;
        break;
      case RenderType.NORMAL:
        key = `${entry.type}-${entry.doc_id}-${_.get(entry, 'record_id')}`;
        linkUrl.pathname = `/codes/${match.params.clientslug}/${match.params.version}/${code.slug}/` +
          `${docId}`;
        break;
    }

    const isViewed = _.get(viewedToc, 'key') === tocKey(code, entry);
    const isOpen = _.get(lookupEntry, 'expanded', false);

    return (
      <div
        key={key}
        className={classnames(
          'toc-entry',
          { 'toc-entry--has-children': entry.has_children },
        )}
      >
        <div className={classnames(
          'toc-entry__wrap',
          { 'toc-entry__wrap--is-viewed': isViewed }
        )}
        >
          { entry.has_children && (
            <button
              className="toc-caret dropdown-toggle btn-toggle"
              aria-label="Expand or collapse table of contents"
              aria-haspopup="true"
              aria-expanded={isOpen}
              onClick={clickHandler}
            />
          )}
          <Link
            className="toc-link"
            to={linkUrl}
            {...dataParams}
            onClick={this.onTocClicked}
          >
            {entry.title}
          </Link>
        </div>
        { entry.has_children && (
          <Collapse isOpen={isOpen}>
            { collapseContent }
          </Collapse>
        )}
      </div>
    );
  }

  renderTocOverview () {
    const {
      currClient,
      match,
    } = this.props;
    const overviewPath = `/codes/${match.params.clientslug}/${match.params.version}/overview`;
    const overviewIsViewed = match.location.pathname === overviewPath;

    return (
      <div
        key='toc-overview'
        className={
          classnames(
            'toc-entry__wrap',
            { 'toc-entry__wrap--is-viewed': overviewIsViewed }
          )
        }
        style={{marginRight: '.5rem'}}
      >
        <Link
          className="toc-link"
          to={overviewPath}
        >
          {_.get(currClient, 'name')} Overview
        </Link>
      </div>
    );
  }

  codeIsAvailable (code) {
    return !code.is_ingesting && !code.is_deleting;
  }

  renderMinutes () {
    const {
      currClient,
      currSection,
      minutesOpen,
      match,
    } = this.props;
    const isViewed = !minutesOpen && _.has(currSection, 'date');

    const firstMinutesDate = _.get(currClient, 'minutes_years.0.top_date');

    return !_.isEmpty(_.get(currClient, 'minutes_years', [])) && (
      <div className="toc-entry toc-entry--code">
        <div
          className={classnames(
            'toc-entry__wrap',
            { 'toc-entry__wrap--is-viewed': isViewed }
          )}
        >
          <button
            className="toc-caret dropdown-toggle btn-toggle"
            aria-label="Toggle meeting minutes"
            aria-haspopup="true"
            aria-expanded={minutesOpen}
            onClick={this.toggleMinutesHeader}
          />
          <Link
            className="toc-link"
            to={`/codes/${match.params.clientslug}/${match.params.version}/m/${firstMinutesDate}/`}
            onClick={() => this.setState({ minutesOpen: true})}
          >
            Meeting Minutes
          </Link>
        </div>
        <Collapse isOpen={minutesOpen}>
          {_.map(currClient.minutes_years, this.renderMinutesYear.bind(this))}
        </Collapse>
      </div>
    );
  }

  renderMinutesYear (entry) {
    const {
      minutesLookup,
      match,
      viewedToc,
    } = this.props;

    const lookupEntry = _.get(minutesLookup, [minutesKey(entry)]);
    const isOpen = _.get(lookupEntry, 'expanded', false);
    const isViewed = _.get(viewedToc, 'key') == minutesKey(entry);

    const clickHandler = _.get(lookupEntry, 'loading', false)
      ? null // disable anchor
      : () => this.toggleMinutes(entry);

    const collapseContent = !_.get(lookupEntry, 'loading', false)
      ? _.map(
        _.get(lookupEntry, `children`, []),
        (entry) => this.renderMinutesMonth(entry)
      )
      : (
        <div className="toc-entry__loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          Loading...
        </div>
      );

    return (
      <div
        key={`year-${entry.year}`}
        className="toc-entry toc-entry--has-children"
      >
        <div className={classnames(
          'toc-entry__wrap',
          { 'toc-entry__wrap--is-viewed': isViewed }
        )}
        >
          <button
            className="toc-caret dropdown-toggle btn-toggle"
            aria-label="Expand or collapse table of contents"
            aria-haspopup="true"
            aria-expanded={isOpen}
            onClick={clickHandler}
          />
          <Link
            className="toc-link"
            to={`/codes/${match.params.clientslug}/${match.params.version}/m/${entry.top_date}`}
          >
            { entry.year }
          </Link>
        </div>
        <Collapse isOpen={isOpen}>
          { collapseContent }
        </Collapse>
      </div>
    );
  }

  renderMinutesMonth (entry) {
    const {
      minutesLookup,
      match,
      viewedToc,
    } = this.props;

    const lookupEntry = _.get(minutesLookup, [minutesKey(entry)]);

    const isOpen = _.get(lookupEntry, 'expanded', false);
    const isViewed = _.get(viewedToc, 'key') == minutesKey(entry);
    const clickHandler = _.get(lookupEntry, 'loading', false)
      ? null // disable anchor
      : () => this.toggleMinutes(entry);

    const dateUrl = (m) => {
      let orderIdx = '';
      if (m.order_idx > 0) {
        orderIdx = `/${m.order_idx}`;
      }

      return `${m.year}/${m.month}/${m.day}${orderIdx}`;
    };

    const collapseContent = !_.get(lookupEntry, 'loading', false)
      ? _.map(
        _.get(lookupEntry, `children`, []),
        (m) => (
          <div
            key={`minute-${m.id}`}
            className={classnames(
              'toc-entry__wrap',
              { 'toc-entry__wrap--is-viewed': _.get(viewedToc, 'key') == minutesKey({ ...entry, minuteID: m.id}) }
            )}
          >
            <Link
              className="toc-link"
              to={`/codes/${match.params.clientslug}/${match.params.version}/m/${dateUrl(m)}`}
              data-minuteid={m.id}
            >
              { m.title }
            </Link>
          </div>
        )
      )
      : (
        <div className="toc-entry__loading">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          Loading...
        </div>
      );

    return (
      <div
        key={`month-${entry.year}-${entry.month}`}
        className="toc-entry toc-entry--has-children"
      >
        <div
          className={classnames(
            'toc-entry__wrap',
            { 'toc-entry__wrap--is-viewed': isViewed }
          )}
        >
          <button
            className="toc-caret dropdown-toggle btn-toggle"
            aria-label="Expand or collapse table of contents"
            aria-haspopup="true"
            aria-expanded={isOpen}
            onClick={clickHandler}
          />
          <Link
            className="toc-link"
            to={`/codes/${match.params.clientslug}/${match.params.version}/m/${entry.top_date}`}
          >
            { monthToLocale(entry.month - 1) }
          </Link>
        </div>
        <Collapse isOpen={isOpen}>
          { collapseContent }
        </Collapse>
      </div>
    );
  }

  renderToc () {
    const {
      renderType,
      match,
      navCodeLookup,
      previewCode,
      previewVersion,
      selectedVersion,
      viewedToc,
    } = this.props;

    let tocCodeToMap = null;

    switch(renderType) {
      case RenderType.PREVIEW_CODE:
        tocCodeToMap = [previewCode];
        break;
      case RenderType.PREVIEW_VERSION:
        tocCodeToMap = _.get(previewVersion, 'toc');
        break;
      case RenderType.NORMAL:
        tocCodeToMap = _.get(selectedVersion, 'toc');
        break;
    }

    return _.map(
      tocCodeToMap,
      (code) => {
        const isViewed = _.get(viewedToc, 'key') === code.uuid;
        const linkUrl = {};
        const lookupCode = _.get(navCodeLookup[code.uuid], 'expanded');
        const docId = encodeURIComponent(
          _.get(code, 'sections.0.passthru_doc_id') ||
          _.get(code, 'sections.0.doc_id')
        );

        switch(renderType) {
          case RenderType.PREVIEW_CODE:
            linkUrl.pathname = `/preview/c/${match.params.uuid}/${docId}`;
            break;
          case RenderType.PREVIEW_VERSION:
            linkUrl.pathname = `/preview/v/${match.params.version}/${code.slug}/${docId}`;
            break;
          case RenderType.NORMAL:
            linkUrl.pathname = `/codes/${match.params.clientslug}/${match.params.version}/${code.slug}/${docId}`;
            break;
        }

        return (
          <div
            className="toc-entry toc-entry--code"
            key={`code-${code.id}`}
          >
            <div
              className={classnames(
                'toc-entry__wrap',
                {
                  'toc-entry__wrap--is-viewed': isViewed,
                  'toc-entry__wrap--unavailable': !this.codeIsAvailable(code),
                }
              )}
            >
              {
                this.codeIsAvailable(code) &&
                <button
                  className="toc-caret dropdown-toggle btn-toggle"
                  aria-label="Toggle code"
                  aria-haspopup="true"
                  aria-expanded={lookupCode}
                  onClick={() => this.codeToggle(code.uuid)}
                />
              }
              {
                !this.codeIsAvailable(code) &&
                (
                  <span
                    id={`toc-${code.uuid}`}
                    className="toc-link"
                  >
                    {code.title || code.name}
                  </span>
                ) ||
                (
                  <Link
                    className="toc-link"
                    to={linkUrl}
                  >
                    {code.title || code.name}
                  </Link>
                )
              }
              {
                !this.codeIsAvailable(code) &&
                <UncontrolledPopover
                  placement="bottom"
                  target={`toc-${code.uuid}`}
                  trigger="hover"
                >
                  <PopoverBody>
                    Temporarily unavailable - update in progress
                  </PopoverBody>
                </UncontrolledPopover>
              }
            </div>
            {
              this.codeIsAvailable(code) &&
              <Collapse isOpen={lookupCode}>
                {_.map(code.sections, (s) => this.renderSectionToc(code, s))}
              </Collapse>
            }
          </div>
        );
      },
    );
  }

  render () {
    const {
      children,
      renderType,
      currClient,
      currSection,
      defaultDocId,
      match,
      previewCode,
      previewVersion,
      selectedCode,
      leftNavClosed,
      viewedToc,
      annotationsOn,
      showAnnotations,
      searchResult,
    } = this.props;

    const docId = encodeURIComponent(defaultDocId);

    let clientBreadcrumb = '';
    let codeBreadcrumbTitle = '';
    let codeBreadcrumb = '';
    let firstCode = {};
    let sectionBreadcrumb = '';
    let urlPrefix = '';

    switch(renderType) {
      case RenderType.PREVIEW_CODE:
        clientBreadcrumb = `/preview/c/${_.get(previewCode, 'uuid')}/${docId}`;
        codeBreadcrumbTitle = previewCode.title;
        codeBreadcrumb = `/preview/c/${_.get(previewCode, 'uuid')}/${docId}`;
        sectionBreadcrumb = _.get(viewedToc, 'title') || _.get(currSection, 'title');
        urlPrefix = 'preview/c/';
        break;
      case RenderType.PREVIEW_VERSION:
        firstCode = {
          slug: _.get(previewVersion, 'toc.0.slug'),
          firstDocId: _.get(previewVersion, 'toc.0.sections.0.doc_id'),
        };

        clientBreadcrumb = `/preview/v/${_.get(previewVersion, 'uuid')}/${firstCode.slug}/` +
          `${encodeURIComponent(firstCode.firstDocId)}`;
        codeBreadcrumbTitle = selectedCode && selectedCode.title;
        codeBreadcrumb = selectedCode &&
          `/preview/v/${_.get(previewVersion, 'uuid')}/${selectedCode.slug}/` +
            `${encodeURIComponent(selectedCode.docId)}`;
        sectionBreadcrumb = _.get(viewedToc, 'title') || _.get(currSection, 'title') || 'Overview';
        urlPrefix = 'preview/v/';
        break;
      case RenderType.NORMAL:
        clientBreadcrumb = `/codes/${_.get(currClient, 'slug')}/${match.params.version}/`;
        codeBreadcrumbTitle = selectedCode && selectedCode.title;
        codeBreadcrumb = selectedCode &&
          `/codes/${_.get(currClient, 'slug')}/${match.params.version}/${selectedCode.slug}/` +
            `${encodeURIComponent(selectedCode.docId)}`;
        sectionBreadcrumb = _.get(viewedToc, 'title') || _.get(currSection, 'title') || 'Overview';
        urlPrefix = 'codes/';
        break;
    }

    const isArchive = !RenderType.isPreview(renderType) && match.params.version != 'latest';
    const hasSearchResults = !_.isEmpty(_.get(searchResult, 'results'), []);

    return (
      <div>
        { RenderType.isPreview(renderType) &&
          <div className="preview">
            <h3 className="dark-header">
              THIS IS A PREVIEW. DO NOT DISTRIBUTE THIS URL FOR PUBLIC USE.
            </h3>
          </div>
        }
        <div className="codenav">
          <div
            ref={this.codeNavLeft}
            className={classnames(
              'codenav__left',
              {
                'codenav__left--preview': RenderType.isPreview(renderType),
                'codenav__left--closed': leftNavClosed,
              },
            )}
          >
            <button
              className="codenav__nav-toggle"
              aria-label={leftNavClosed
                ? 'Expand code navigation menu'
                : 'Collapse code navigation menu'
              }
              onClick={this.toggleLeftNav}
            >
              <FontAwesomeIcon icon={leftNavClosed ? faAngleDoubleRight : faAngleDoubleLeft} />
            </button>
            <div
              className="codenav__left-inner"
              style={{display: leftNavClosed ? 'none' : 'flex'}}
            >
              <a
                tabIndex={0}
                className="skip-main"
                href="#codecontent"
                style={{paddingLeft: '.5rem'}}
              >
                Skip to code content (skip section selection)
              </a>
              {
                _.get(currClient, 'versions.length', 0) > 0 &&
                <VersionDropdown urlParams={match.params} />
              }
              <div
                className={classnames(
                  'codenav__toc roboto',
                  { 'codenav__toc--code-preview': renderType === RenderType.PREVIEW_CODE },
                )}
              >
                {renderType === RenderType.NORMAL && this.renderTocOverview()}
                {
                  renderType === RenderType.NORMAL &&
                  match.params.version == 'latest' &&
                  this.renderMinutes()
                }
                { this.renderToc() }
              </div>
            </div>
          </div>
          <div
            id="codecontent"
            className={classnames(
              'codenav__right',
              { 'codenav__right--closed': leftNavClosed }
            )}
          >
            <div
              className={classnames(
                'codenav__section',
                { 'codenav__section--preview': RenderType.isPreview(renderType) },
              )}
            >
              <div
                className="codenav__breadcrumbs">
                <Breadcrumb className="codenav_breadcrumbs_nav">
                  <BreadcrumbItem>
                    <Link
                      className="notranslate"
                      to={`/regions/${_.get(currClient, 'region.slug')}`}
                      title={_.get(currClient, 'region.name')}
                    >
                      {_.upperCase(_.get(currClient, 'region.slug'))}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={clientBreadcrumb}
                      title={_.get(currClient, 'name')}
                    >
                      {_.get(currClient, 'name')}
                    </Link>
                  </BreadcrumbItem>
                  { (selectedCode || renderType === RenderType.PREVIEW_CODE) &&
                    <BreadcrumbItem>
                      <Link
                        to={codeBreadcrumb}
                        title={codeBreadcrumbTitle}
                      >
                        {codeBreadcrumbTitle}
                      </Link>
                    </BreadcrumbItem>
                  }
                  <BreadcrumbItem active>
                    {sectionBreadcrumb}
                  </BreadcrumbItem>
                </Breadcrumb>
                <CodeOptions
                  destID={_.get(currSection, 'orig_doc_id') || _.get(currSection, 'doc_id')}
                  color="white"
                />
                <CodeOptionsModal ref={this.codeOptionsModal} />
              </div>
              <BackToSearch />
              { isArchive &&
                <div className="archive">
                  <h3 className="dark-header">
                    You are viewing an archived code
                  </h3>
                </div>
              }
              <div
                className={classnames(
                  'codenav__section-body',
                  {
                    'codenav__section-body--header': isArchive || hasSearchResults,
                    'codenav__section-body--annotations': showAnnotations && annotationsOn,
                    'codenav__section-body--pdf': !!_.get(currSection, 'pdf_path'),
                  },
                )}
                ref={renderType && this.section}
              >
                <ScrollbarSize
                  onLoad={this.scrollbarMeasured}
                  onChange={this.scrollbarMeasured}
                />
                {/*
                  Fix firefox horizontal scroll
                  https://stackoverflow.com/questions/39738265/firefox-displays-unnecessary-horizontal-scrollbar
                */}
                { _.get(currClient, 'scroll_tables_enabled') &&
                  <style dangerouslySetInnerHTML={{
                    __html:`
                    @-moz-document url-prefix() {
                      .xsl-table:not(.xsl-table--percent) {
                        padding-right: ${this.state.scrollbarWidth}px;
                      }
                    }
                    `,
                  }}></style>
                }
                { children }
              </div>
            </div>
          </div>
        </div>
        <NavBottom match={match.params} urlPrefix={urlPrefix}/>
      </div>
    );
  }
}
