import { default as config } from '../../configuration';

const loadjs = require('loadjs');
const RECAPTCHA_SCRIPT = `https://www.google.com/recaptcha/api.js?render=${config.recaptcha_site_key}`;

export const loadRecaptcha = () => {
  if (config.recaptcha_site_key) {
    loadjs(RECAPTCHA_SCRIPT, 'recaptcha', { async: true });
  }
};

export const setRecaptchaVisible = (visible) => {
  loadjs.ready('recaptcha', {
    success: () => {
      window['grecaptcha'].ready(() => {
        const badge = document.querySelector('.grecaptcha-badge');
        if (visible) {
          badge.classList.add('show');
        } else {
          badge.classList.remove('show');
        }
      });
    },
  });
};

export const getRecaptchaToken = () => {
  return new Promise((resolve, reject) => {
    if (!config.recaptcha_site_key) {
      resolve(undefined);

      return;
    }

    loadjs.ready('recaptcha', {
      success: () => {
        window['grecaptcha'].ready(() => {
          window['grecaptcha'].execute(config.recaptcha_site_key, {action: 'submit'})
            .then(resolve)
            .catch(reject);
        });
      },
      error: () => {
        reject(null);
      },
    });
  });
};
